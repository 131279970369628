    import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import MobileImg from "../../../../assets/hand_phone.png";
import HandImg from "../../../../assets/id ervices copy 5-01.png";

import { ReactComponent as AppleLogo } from "../../../../assets/applelogo.svg";
import { ReactComponent as Googleplaylogo } from "../../../../assets/googleplaylogo.svg";
import Qrcode from "../../../../assets/qrcode.png";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Parallax } from "react-scroll-parallax";

const Idandmobile = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    const [isOpen, setIsOpen] = useState(false);

    const closeModal = () => {
        setIsOpen(false);
    };

    const openModal = () => {
        setIsOpen(true);
    };

    return (
        //
        <>
            {isDesktopOrLaptop ? (
                <div class=' text-white  bg-id-services bg-left-top bg-cover h-fit w-full'>
                    <div className='flex flex-col pb-0 bg-black/40'>
                        <div id='pre-reg' className='flex gap-20 '>
                            <div className='flex items-center justify-center w-[70%] pt-10'>
                                <Parallax
                                    easing={"easeOutQuad"}
                                    translateX={[-60, 0]}
                                    speed={-10}
                                >
                                    <img
                                        src={HandImg}
                                        alt='Logo'
                                        className='items-start align-top'
                                    />
                                </Parallax>
                            </div>

                            <div className='flex flex-col items-start mt-32 pr-20  w-[80%]'>
                                <h1 className='text-6xl font-semibold '>
                                    {t("services-pre-reg-text")}
                                </h1>
                                <div className='w-[15%] h-1 mr-1 bg-green my-3 rounded-md mb-10 '></div>
                                <p className='text-2xl font-normal text-justify  '>
                                    {t("services-pre-reg-text2")}{" "}
                                </p>

                                <div className='flex gap-10 text-xl'>
                                    <button
                                        onClick={openModal}
                                        className='my-4  w-80 px-20 font-semibold text-white/90 shadow-md border-2  border-white/70 h-12 bg-transparent  rounded-3xl  hover:bg-[#083247] transition-all transform duration-300'
                                    >
                                        {t("services-pre-reg-button")}
                                    </button>

                                    <button
                                        onClick={() => navigate("/dataset")}
                                        className='my-4 w-80 px-20 font-semibold text-white/90 shadow-md border-2 border-white/70 h-12 bg-transparent  rounded-3xl  hover:bg-[#083247] transition-all transform duration-300'
                                    >
                                        {t("services-required-fields-button")}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div id='mobile-app' className='z-0 flex flex-col '>
                            <div className='flex items-end gap-20 px-28'>
                                <div
                                    id='mobile-app'
                                    className='flex flex-col mb-40  w-[55%]'
                                >
                                    <h1 className='text-6xl font-semibold'>
                                        {t("services-mobile-app")}
                                    </h1>
                                    <div className='w-[15%] h-1 mr-1 bg-green my-3 rounded-md mb-4 '></div>
                                    <p className='text-2xl font-normal w-[80%] text-justify '>
                                        {t("service-mobile-app-text")}{" "}
                                    </p>

                                    <div className='flex items-center grid-cols-3 gap-5 mt-8 '>
                                        {/* APP STORE AD */}
                                        <div className='w-[40%] shadow-sm gap-2 shadow-white/80 h-full justify-center flex py-1 align-middle bg-transparent border-2 border-white rounded-lg  place-items-center  hover:bg-[#083247] transition-all  duration-300 hover:scale-105'>
                                            <div className=''>
                                                <AppleLogo className='h-16 ' />
                                            </div>

                                            <div className='flex-col items-center justify-center leading-tight rounded-lg cursor-pointer   '>
                                                <h1 className='lg:text-lg md:text-lg font-extralight text-white/50'>
                                                    Download on the
                                                </h1>
                                                <h1 className='font-bold lg:text-2xl md:text-xl sm:text-lg '>
                                                    App Store
                                                </h1>
                                            </div>
                                        </div>

                                        {/* GOOGLE PLAY AD */}
                                        <div className='w-[40%] shadow-sm gap-2 shadow-white/80 h-full justify-center flex py-1 align-middle bg-transparent border-2 border-white rounded-lg  place-items-center  hover:bg-green transition-all  duration-300 hover:scale-105'>
                                            <div className='flex '>
                                                <Googleplaylogo className='h-16' />
                                            </div>

                                            <div className='flex flex-col items-center justify-center leading-tight rounded-lg cursor-pointer  '>
                                                <h1 className='lg:text-lg md:text-lg font-extralight text-white/50'>
                                                    Get it on
                                                </h1>
                                                <h1 className='font-bold lg:text-2xl md:text-xl sm:text-lg '>
                                                    Google Play
                                                </h1>
                                            </div>
                                        </div>

                                        <div className='flex items-center justify-center mx-0 transition-all duration-300 cursor-pointer h-28 rounded-xl w-fit hover:shadow-xl'>
                                            <img
                                                src={Qrcode}
                                                className='h-full rounded-xl'
                                                alt='Icon_checklist'
                                            ></img>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-center align-top  h-[40%] -mt-96 w-[40%] z-20 '>
                                    <Parallax
                                        easing={"easeOutQuad"}
                                        translateY={[0, 0]}
                                        speed={-10}
                                    >
                                        <img
                                            src={MobileImg}
                                            alt='Logo'
                                            className='items-start align-top '
                                        />
                                    </Parallax>
                                </div>
                            </div>

                            <div className='z-10 h-16 -mt-16 bg-grey/80'></div>
                        </div>
                    </div>
                </div>
            ) : (
                //************************************************************* Mobile Version *************************************************************
                <div class='text-white  bg-id-services  bg-cover  w-full '>
                    <div className='flex flex-col bg-black/40'>
                        <div id='pre-reg' className='flex px-10 pt-10'>
                            <div className='flex flex-col items-center justify-center py-0 '>
                                <h1 className='text-4xl font-semibold '>
                                    {t("services-pre-reg-text")}
                                </h1>
                                <hr className='w-[20%] border-b-2 my-4 ml-0.5 border-green' />
                                {/* <div className="w-[15%] h-1 mr-1  my-3 rounded-md mb-10 "></div> */}
                                <div className='text-lg font-normal text-justify  '>
                                    {/* <ShowMoreText
            
            lines={5}
            more="Show more"
            less="Show less"
            className="w-full text-justify  trackin"
            anchorClass="show-more-less-clickable"       
            expanded={false}
            width={300}
            truncatedEndingComponent={"..."}
        >  
            {t("services-pre-reg-text2")}{" "}
        </ShowMoreText> */}
                                    {t("services-pre-reg-text2")}{" "}
                                </div>

                                <div className='flex flex-col pt-5 text-xl'>
                                    <button
                                        onClick={openModal}
                                        className='my-2 text-lg w-full text-center px-10 md:px-20 font-semibold text-white/90 shadow-md border-2  border-white/70 h-12 bg-transparent  rounded-3xl  hover:bg-[#083247] transition-all transform duration-300'
                                    >
                                        {t("services-pre-reg-button")}
                                    </button>

                                    <button
                                        onClick={() => navigate("/dataset")}
                                        className='my-2 w-full text-lg px-20 font-semibold text-white/90 shadow-md border-2 border-white/70 h-12 bg-transparent  rounded-3xl  hover:bg-[#083247] transition-all transform duration-300'
                                    >
                                        {t("services-required-fields-button")}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className='flex items-center justify-center w-full'>
                            <Parallax
                                easing={"easeOutQuad"}
                                translateX={[-85, 0]}
                                speed={-10}
                            >
                                <img
                                    src={HandImg}
                                    alt='Logo'
                                    className='items-start align-top'
                                />
                            </Parallax>
                        </div>

                        <div
                            id='mobile-app'
                            className='z-0 flex flex-col w-full px-10 bg-dark'
                        >
                            <div className='flex pb-5 '>
                                <div
                                    id='mobile-app'
                                    className='flex flex-col w-full '
                                >
                                    <div className='flex flex-col items-center'>
                                        <h1 className='text-4xl font-semibold'>
                                            {t("services-mobile-app")}
                                        </h1>
                                        <div className='w-[15%] h-1 mr-1 bg-green my-3 rounded-md mb-4 '></div>
                                        <p className='text-lg font-normal text-justify  '>
                                            {t("service-mobile-app-text")}{" "}
                                            {/* 
                      <ShowMoreText
            
            lines={5}
            more="Show more"
            less="Show less"
            className="bg-lightblue"
            anchorClass="bg-green"       
            expanded={false}
            width={300}
            truncatedEndingComponent={"..."}
        >  
          <span className="text-lg font-normal text-justify  ">
          {t("service-mobile-app-text")}
          </span>
        </ShowMoreText> */}
                                        </p>
                                    </div>

                                    <div className='flex items-center justify-center gap-2 pt-4 pb-4 '>
                                        <div className='shadow-sm gap-1 w-full shadow-white/80  justify-center flex py-0.5 align-middle bg-transparent border-2 border-white rounded-lg  place-items-center  hover:bg-[#083247] transition-all  duration-300 hover:scale-105'>
                                            <div className=''>
                                                <AppleLogo className='h-9 ' />
                                            </div>

                                            <div className='flex flex-col items-center justify-center leading-tight rounded-lg cursor-pointer '>
                                                <h1 className='text-xs font-extralight text-white/90'>
                                                    Download on the
                                                </h1>
                                                <h1 className='text-xl font-bold '>
                                                    App Store
                                                </h1>
                                            </div>
                                        </div>

                                        <div className='  shadow-sm gap-1 w-full shadow-white/80  justify-center flex py-0.5 align-middle bg-transparent border-2 border-white rounded-lg place-items-center  hover:bg-[#083247] transition-all  duration-300 hover:scale-105'>
                                            <div className='flex '>
                                                <Googleplaylogo className='h-8' />
                                            </div>

                                            <div className='flex flex-col items-center justify-center leading-tight rounded-lg cursor-pointer  '>
                                                <h1 className='text-xs font-extralight text-white/80'>
                                                    Get it on
                                                </h1>
                                                <h1 className='text-xl font-bold '>
                                                    Google Play
                                                </h1>
                                            </div>
                                        </div>

                                        {/* <div className="flex items-center justify-center transition-all duration-300 cursor-pointer rounded-xl hover:shadow-xl">
                      <img
                        src={Qrcode}
                        className="h-14 rounded-xl"
                        alt="Icon_checklist"
                      ></img>
                    </div> */}
                                    </div>
                                </div>
                                {/* 
                <div className="flex justify-center align-top  h-[40%] -mt-96 w-[40%] z-20 ">
                  <Parallax
                    easing={"easeOutQuad"}
                    translateY={[30, 0]}
                    speed={-10}
                  >
                    <img
                      src={MobileImg}
                      alt="Logo"
                      className="items-start align-top "
                    />
                  </Parallax>
                </div> */}
                            </div>

                            {/* <div className="z-10 h-16 -mt-16 bg-grey/80"></div> */}
                        </div>
                    </div>
                </div>
            )}
            {isOpen && (
                <div
                    className='fixed inset-0 flex items-center justify-center backdrop-blur-[2px] bg-black/75 bg-opacity-50 z-50 transition-all duration-500'
                    onClick={closeModal}
                >
                    <div
                        className='fixed inset-0 flex items-center justify-center bg-black/75 bg-opacity-50 z-50 transition-all duration-500'
                        onClick={closeModal}
                    >
                        <div
                            className='bg-white rounded-lg p-4 w-full max-w-2xl  lg:p-8 mx-3 flex flex-col justify-between' // Added flex flex-col justify-between here
                            onClick={(e) => e.stopPropagation()}
                        >
                            {/* Content */}
                            <div className='flex flex-col lg:flex-row w-full h-full text-2xl text-white'>
                                <div className='flex flex-col w-full lg:w-1/2 h-full items-center px-2 lg:px-5 py-4 flex-grow'>
                                    {" "}
                                    {/* Added flex-grow here */}
                                    <div className='text-MetallicBlue text-lg lg:text-xl text-center mb-4 lg:mb-10'>
                                        {t("landing-locations")}
                                    </div>
                                </div>
                                {/* <div className='hidden lg:block border h-40 border-MetallicBlue mx-4'></div>
                                <div className='flex flex-col w-full lg:w-1/2 h-full items-center px-2 lg:px-5 py-4 flex-grow'>
                                    {" "}
                                    
                                    <div className='text-MetallicBlue text-lg lg:text-xl text-center mb-4 lg:mb-10'>
                                        {t("landing-preregistration")}
                                    </div>
                                </div> */}
                            </div>

                            {/* Buttons */}
                            <div className='flex flex-col lg:flex-row w-full h-full text-2xl text-white mt-4'>
                                <div className='flex flex-col w-full lg:w-1/2 h-full items-center lg:ml-36 px-2 lg:px-5 py-4'>
                                    <a href='/locations'>
                                        <button className='bg-MetallicBlue px-4 lg:px-8 py-2 rounded-lg hover:bg-green hover:text-MetallicBlue'>
                                            {t("landing-locations-button")}
                                        </button>
                                    </a>
                                </div>
                                {/* <a
                                    href='https://register.fayda.et'
                                    target='_blank'
                                    rel='noreferrer'
                                    className='flex flex-col w-full lg:w-1/2 h-full items-center px-2 lg:px-5 py-4'
                                >
                                    <div>
                                        <div className='bg-MetallicBlue px-4 lg:px-8 py-2 rounded-lg hover:bg-green hover:text-MetallicBlue'>
                                            {t(
                                                "landing-preregistration-button"
                                            )}
                                        </div>
                                    </div>
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Idandmobile;
