import React from "react";
import { useNavigate } from "react-router-dom";

import { useMediaQuery } from "react-responsive";
import GifDesktop from "../../assets/newgifCord.gif";
import GifMobile from "../../assets/newgifCordMobile.gif";
const Page404 = () => {
    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    return (
        <>
            {isDesktopOrLaptop ? (
                <div className=' flex h-screen  items-center justify-center bg-404-backgroun bg-center  bg-cover overflow-y-clip '>
                    <div className=' flex w-[65%]  items-center justify-center bg-darkble  bg-lightblue rounded-r-full overflow-hidden h-[100%] py-[900px] '>
                        <div className='bg-gre flex items-center justify-center w-full '>
                            <img
                                src={GifDesktop}
                                alt='404-gif'
                                className='z-20 object-cover '
                            />
                        </div>
                    </div>

                    {/* <img className="absolute bg-darkred h-96 left-80 top-40" src={gif}></img> */}

                    <div className='flex  w-[39%] h-full items-center justify-center bg-'>
                        <div className='flex flex-col items-center justify-center  h-full w-full bg-darkorang bg-white'>
                            <div className=' bg-gry flex items-start flex-col justify-center'>
                                <p className='text-9xl text-MetallicBlue font-semibold'>
                                    404
                                </p>
                                <p className='text-3xl text-MetallicBlue font-semibold opacity-40 mb-10'>
                                    The page you are looking for doesn't exist
                                </p>

                                <button
                                    className='bg-MetallicBlue text-xl font-semibold text-white py-2 w-[45%] rounded-full hover:bg-green hover:text-MetallicBlue hover:shadow-md hover:scale-105 transition-all duration-300'
                                    onClick={() => navigate("/")}
                                >
                                    GO BACK
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            ) : (
                // MOBILE VERSION**********************************************
                <>
                    <div className=' flex flex-col h-screen bg-lightblu  items-center justify-center bg-404-backgroun bg-center  bg-cover  overflow-clip  '>
                        <div className='flex  w-full h-full items-center justify-center bg-bg-lightblue'>
                            <div className='flex flex-col items-center justify-center text-center  h-full w-full bg-darkorang'>
                                <div className=' bg-gry flex items-center flex-col justify-center  text-center'>
                                    <p className='text-8xl text-MetallicBlue font-semibold'>
                                        404
                                    </p>
                                    <p className='text-xl text-MetallicBlue font-semibold opacity-40 mb-4 mx-16 bg-gree'>
                                        The page you are looking for doesn't
                                        exist
                                    </p>

                                    <button
                                        className='bg-MetallicBlue text-xl font-semibold text-white py-1 w-[40%] rounded-full hover:bg-green hover:text-MetallicBlue hover:shadow-md hover:scale-105 transition-all duration-300'
                                        onClick={() => navigate("/")}
                                    >
                                        GO BACK
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className=' flex w-[120%] items-center justify-center bg-lightblue h-full rounded-t-full'>
                            {/* rounded-r-full */}
                            {/* <div className="bg-darkorange brightness-90 bg-404-backgroun  w-full  z-10 flex flex-col items-center justify-center h-[100%]"> */}
                            {/* <div className="bg-darkred w-full h- flex items-center justify-center z-40  bg-viole bg-4 h-full "> */}

                            <div className='bg-gree mr-[25%] flex items-start justify-center w-[50%] shadow-m shadow-whit '>
                                <img
                                    src={GifMobile}
                                    alt='404-gif'
                                    className='z-20 object-cover '
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Page404;
