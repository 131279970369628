import React from "react";
import { useMediaQuery } from "react-responsive";
import eFaydamob from "../../../../src/assets/hand_new.png";
import HandImg from "../../../../src/assets/idServiceshand.png";

import { ReactComponent as AppleLogo } from "../../../../src/assets/applelogo.svg";
import { ReactComponent as Googleplaylogo } from "../../../../src/assets/googleplaylogo.svg";
import Mobile from "../../../assets/hand_new.png";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Parallax } from "react-scroll-parallax";

const Faydamobile = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    return (
        //
        <>
            {isDesktopOrLaptop ? (
                <div>
                    <div class=' mt-36 bg-Fayda-on-digital-copy1 bg-left-top bg-cover h-fit w-full'>
                        <div
                            className='flex flex-col pb-12'
                            style={{
                                background:
                                    "linear-gradient(rgba(0, 75, 90, 0.5), rgba(0, 75, 90, 0.5))",
                            }}
                        >
                            <div
                                className='flex'
                                style={{
                                    marginBottom: "-6%",
                                }}
                            >
                                <div className='pl-60 justify-center w-[70%] pt-10'>
                                    <h1 className='text-white text-6xl pb-6'>
                                        <span className='text-green'>
                                            {t("credentials-body-title-2")}
                                        </span>{" "}
                                        {t("credentials-body-title-3")}
                                    </h1>
                                    <p className='text-white text-3xl w-[90%] pl-12'>
                                        {t("credentials-body-2")}
                                    </p>
                                    <div className='flex items-center grid-cols-3 gap-5 mt-8 pl-12'>
                                        <a
                                            href='https://apps.apple.com/us/app/telebirr/id1553601084'
                                            className='w-[26%] shadow-sm gap-2 shadow-white/80 h-full justify-center flex py-1 align-middle bg-transparent border-2 border-white rounded-lg  place-items-center  hover:bg-[#083247] transition-all  duration-300 hover:scale-105'
                                        >
                                            <div className=''>
                                                <AppleLogo className='h-16 ' />
                                            </div>

                                            <div className='flex-col items-center justify-center leading-tight rounded-lg cursor-pointer  '>
                                                <h1 className='lg:text-lg md:text-lg font-extralight text-white'>
                                                    Download on the
                                                </h1>
                                                <h1 className='font-bold lg:text-2xl md:text-xl sm:text-lg text-white'>
                                                    App Store
                                                </h1>
                                            </div>
                                        </a>

                                        <a
                                            href='https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://play.google.com/store/apps/details%3Fid%3Dcn.tydic.ethiopay%26hl%3Den%26gl%3DUS%26referrer%3Dutm_source%253Dgoogle%2526utm_medium%253Dorganic%2526utm_term%253Dtelebirr%2Bapp%26pcampaignid%3DAPPU_1_ioTsZJzIJ6zixc8P57urkA0&ved=2ahUKEwictbLLn_-AAxUscfEDHefdCtIQ8oQBegQIHxAB&usg=AOvVaw2jXnc4YH8WWcmKCcg_BO77'
                                            className='w-[26%] shadow-sm gap-2 shadow-white/80 h-full justify-center flex py-1 align-middle bg-transparent border-2 border-white rounded-lg  place-items-center  hover:bg-[#083247] transition-all  duration-300 hover:scale-105'
                                        >
                                            <div className='flex '>
                                                <Googleplaylogo className='h-16' />
                                            </div>

                                            <div className='flex flex-col items-center justify-center leading-tight rounded-lg cursor-pointer  '>
                                                <h1 className='lg:text-lg md:text-lg font-extralight text-white'>
                                                    Get it on
                                                </h1>
                                                <h1 className='font-bold lg:text-2xl md:text-xl sm:text-lg text-white'>
                                                    Google Play
                                                </h1>
                                            </div>
                                        </a>
                                    </div>
                                </div>

                                <div className='relative w-[25%] -top-40  lg:-left-20'>
                                    <img src={Mobile} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=' bg-Fayda-id-card bg-left-top bg-cover h-fit w-full'>
                        <div className='flex-left pb-16'>
                            <div className='flex gap-20 '>
                                <div className='flex items-center w-[70%]'>
                                    <Parallax
                                        // easing={"easeOutQuad"}
                                        easing={"easeInOut"}
                                        translateX={[-15, 0]}
                                        speed={-10}
                                    >
                                        <img
                                            src={HandImg}
                                            alt='Logo'
                                            className='items-start align-top'
                                        />
                                    </Parallax>
                                </div>

                                <div className='flex flex-col items-start mt-16 pr-20 w-[80%]'>
                                    <h1 className='text-MetallicBlue text-6xl pb-6'>
                                        <span className='text-green'>
                                            {" "}
                                            {t("credentials-body-title-4")}
                                        </span>{" "}
                                        {t("credentials-body-title-5")}
                                    </h1>
                                    <p className='text-MetallicBlue text-3xl mb-4 pl-12'>
                                        {t("credentials-body-3")}
                                    </p>
                                    <button
                                        onClick={() =>
                                            (window.location.href =
                                                "https://Id.et/cardprint")
                                        }
                                        className='inline-block ml-12 mt-2 px-2 py-2 rounded-md w-[25%] lg:w-[20%] bg-[#083247] text-white font-normal text-lg leading-tight  rounded-base shadow-md hover:bg-[#21cfac]  hover:shadow-lg focus:bg-[#3a6c7d] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#304f55] active:shadow-lg transition duration-150 ease-in-out'
                                    >
                                        {t("credentials-button-2")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                //************************************************************* Mobile Version *************************************************************
                <div className='text-white '>
                    <div className='flex flex-col'>
                        <div className='flex '>
                            <div className='flex flex-col items-center justify-center '>
                                <div className='flex flex-col  text-xl'>
                                    <img src={eFaydamob} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=' flex flex-col h-92 items-center bg-white mx-6  my-2 gap-0 justify-center px-8  pb-8'>
                        <div className='flex flex-col items-left justify-center w-full h-full pt-5 text-left'>
                            <h1 className='mt-3 mb-2 text-4xl font-medium text-green '>
                                {t("credentials-body-title-1")}
                            </h1>

                            <p className='text-xl text-MetallicBlue  opacity-80 mb-10'>
                                {t("credentials-body-1")}
                            </p>
                        </div>
                    </div>
                    <div className='bg-footer bg-cover flex flex-col items-center justify-center'>
                        <div className='w-[70%]'>
                            <img src={Mobile} alt='' />
                        </div>
                        <div className=' items-center px-8 mx-6'>
                            <h1 className='text-white text-3xl pb-3'>
                                <span className='text-green'>
                                    {t("credentials-body-title-2")}
                                </span>{" "}
                                {t("credentials-body-title-3")}
                            </h1>

                            <p className='text-xl pb-6'>
                                {t("credentials-body-2")}
                            </p>
                        </div>

                        <div className='flex justify-center space-x-4 pb-12'>
                            <a
                                href='https://apps.apple.com/us/app/telebirr/id1553601084'
                                className='flex items-center justify-center gap-2 px-4 py-2 bg-transparent border-2 border-white rounded-lg hover:bg-[#083247] transition-all duration-300 hover:scale-105'
                            >
                                <div className=''>
                                    <AppleLogo className='h-9' />
                                </div>

                                <div className='flex flex-col items-center justify-center leading-tight rounded-lg cursor-pointer '>
                                    <h1 className='text-xs font-extralight text-white/90'>
                                        Download on the
                                    </h1>
                                    <h1 className='text-xl font-bold'>
                                        App Store
                                    </h1>
                                </div>
                            </a>

                            <a
                                href='https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://play.google.com/store/apps/details%3Fid%3Dcn.tydic.ethiopay%26hl%3Den%26gl%3DUS%26referrer%3Dutm_source%253Dgoogle%2526utm_medium%253Dorganic%2526utm_term%253Dtelebirr%2Bapp%26pcampaignid%3DAPPU_1_ioTsZJzIJ6zixc8P57urkA0&ved=2ahUKEwictbLLn_-AAxUscfEDHefdCtIQ8oQBegQIHxAB&usg=AOvVaw2jXnc4YH8WWcmKCcg_BO77'
                                className='flex items-center justify-center gap-2 px-4 py-2 border-2 border-white rounded-lg hover:bg-[#083247] transition-all duration-300 hover:scale-105'
                            >
                                <div className='flex'>
                                    <Googleplaylogo className='h-8' />
                                </div>

                                <div className='flex flex-col items-center justify-center leading-tight rounded-lg cursor-pointer '>
                                    <h1 className='text-xs font-extralight text-white/80'>
                                        Get it on
                                    </h1>
                                    <h1 className='text-xl font-bold'>
                                        Google Play
                                    </h1>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div className='bg-white flex items-center justify-center w-[85%] pt-10'>
                            <img src={HandImg} alt='Logo' className='' />
                        </div>
                        <div className=' flex flex-col h-92 items-center bg-white mx-6 mb-4 gap-0 justify-center px-8  pb-8'>
                            <div className='flex flex-col items-left justify-center w-full h-full pt-5 text-left'>
                                <h1 className='mt-3 mb-2 text-4xl font-medium text-MetallicBlue '>
                                    <span className='text-green'>
                                        {t("credentials-body-title-4")}
                                    </span>{" "}
                                    {t("credentials-body-title-5")}
                                </h1>

                                <p className='text-xl text-MetallicBlue text-justify  opacity-80 mb-4'>
                                    {t("credentials-body-3")}
                                </p>
                                <button
                                    onClick={() =>
                                        (window.location.href =
                                            "https://Id.et/cardprint")
                                    }
                                    className='inline-block mt-2 px-2 py-2 rounded-md w-[40%] lg:w-[15%] bg-[#083247] text-white font-normal text-lg leading-tight  rounded-base shadow-md hover:bg-[#21cfac]  hover:shadow-lg focus:bg-[#3a6c7d] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#304f55] active:shadow-lg transition duration-150 ease-in-out'
                                >
                                    {t("credentials-button-2")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Faydamobile;
