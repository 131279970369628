import { useMediaQuery } from "react-responsive";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Hero({ citizen }) {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    const { t } = useTranslation();

    return (
        <>
            {isDesktopOrLaptop ? (
                <div className='bg-career-background bg-cover bg-center h-[550px] items-center '>
                    <div className='bg-hero-gradient h-[550px]'>
                        <div className='flex justify-start mx-32 pt-[13%] pb-16 text-8xl' />
                        <div className='flex flex-row items-center ml-[10%]'>
                            <h1 className='text-white text-8xl'>{t("nid")} </h1>
                            &nbsp;&nbsp;&nbsp;
                            <h1 className='text-8xl text-green'>
                                {t("career-title")}
                            </h1>
                        </div>
                        <h1 className='text-6xl ml-[10%] text-white'>
                            {t("career-title-text")}
                        </h1>
                        <div className='flex flex-col justify-start pb-32 mx-32 text-justify  mr-96 ' />
                    </div>
                </div>
            ) : (
                <div className='items-center bg-center bg-cover bg-career-background '>
                    <div className='bg-hero-gradient'>
                        <div className='pt-20 pb-5'>
                            <div className='flex flex-row items-center ml-[10%]'>
                                <h1 className='text-4xl text-white'>
                                    {t("nid")}{" "}
                                </h1>
                                &nbsp;&nbsp;&nbsp;
                                <h1 className='text-4xl text-green'>
                                    {t("career-title")}
                                </h1>
                            </div>
                            <h1 className='text-2xl ml-[10%] text-white'>
                                {t("career-title-text")}
                            </h1>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
