import React, { useState } from "react";

import i18next from "i18next";

import { useTranslation } from "react-i18next";

const NewsMobile = ({ news }) => {
    function modifyImageSource(html, extraUrl) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        const images = doc.getElementsByTagName("img");

        for (let i = 0; i < images.length; i++) {
            const image = images[i];
            let src = image.getAttribute("src");

            if (src && src.startsWith("data:image")) {
                // If the src attribute starts with "data:image", it is a base64 encoded image
                const base64DataIndex = src.indexOf("base64,"); // Find the index of base64 encoded data
                if (base64DataIndex !== -1) {
                    const base64Data = src.substring(base64DataIndex + 7); // Extract base64 encoded data
                    // Modify src attribute to use the extraUrl
                    src = `data:image;base64,${base64Data}`;
                    image.setAttribute("src", src);
                }
            }
        }

        return doc.documentElement.innerHTML;
    }

    const { t } = useTranslation();

    return (
        <div className='flex flex-col  '>
            <div className='flex flex-col items-center w-full pt-10 pb-16 mb-10 text-2xl md:text-3xl md:px-28 '>
                <div className='flex w-full px-8'>
                    <div className='flex items-start justify-center px-4 py-1 text-xl text-white w- bg-MetallicBlue'>
                        {" "}
                        {t("news-body-featured")}
                    </div>

                    <div className='w-[85%] flex items-center md:pl-5  border-b-2  text-darkblue border-MetallicBlue'>
                        {" "}
                    </div>
                </div>

                <div className='self-start w-full px-8 '>
                    <p className='w-full text-xl font-semibold text-MetallicBlue '>
                        {" "}
                        {i18next.language === "en" && news.title}
                        {i18next.language === "amh" && news.title_amh}
                    </p>
                    {news.type == "Tender" && (
                        <div className='absolute ml-auto flex group-hover:scale-105 z-10 duration-300'>
                            <h2
                                className={`${
                                    news.status === "Closed"
                                        ? ` bg-darkred`
                                        : // : news.status === "Open"
                                          // ? ` bg-green`
                                          "hidden"
                                } text-white justify-center text-xl w-20 py-1 flex`}
                            >
                                {news.status}
                            </h2>
                        </div>
                    )}
                </div>
            </div>

            <div className='flex justify-center w-full h-full px-12'>
                <img
                    src={
                        i18next.language === "amh"
                            ? process.env.REACT_APP_BASE_URL + news.image_amh
                            : process.env.REACT_APP_BASE_URL + news.image
                    }
                    className='h-[30%] '
                    alt={news.image}
                ></img>
            </div>

            <div class='flex px-10 mt-10 text-justify mb-20'>
                {/*News Paragraph */}
                <div className='col-span-2 flex flex-col gap-8 items-center'>
                    <div className='text-base '>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: modifyImageSource(
                                    i18next.language === "en"
                                        ? news.description
                                        : i18next.language === "amh"
                                        ? news.description_amh
                                        : "x",
                                    process.env.REACT_APP_BASE_URL // Replace with your extra URL
                                ),
                            }}
                        />
                    </div>{" "}
                    {/* <div className="text-xl">{news.description}</div> */}
                    {news.type == "Tender" && (
                        <button
                            className='h-10 mb-20 text-2xl text-white px-10 bg-MetallicBlue rounded-2xl hover:bg-[#083247]'
                            onClick={(e) => {
                                if (news.document) {
                                    if (
                                        news.document
                                            .toLowerCase()
                                            .startsWith("https")
                                    ) {
                                        window.open(news.document, "_blank");
                                    } else {
                                        window.open(
                                            process.env.REACT_APP_BASE_URL +
                                                news.document,
                                            "_blank"
                                        );
                                    }
                                }
                            }}
                        >
                            {t("Download")}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};
export default NewsMobile;
