import React from "react";
import { useNavigate } from "react-router";

const UserProfile = ({ img, name, fcn }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/", { replace: true });
    };
    return (
        <div className='flex w-full '>
            <img
                className='h-40 rounded-xl'
                src={`data:image/png;base64,${img}`}
            />
            <div className='my-9'>
                <div className='flex justify-end'>
                    <button
                        onClick={handleClick}
                        className=' bg-darkgreen  hover:bg-darkgreen text-white font-bold py-2 px-4 rounded'
                    >
                        Sign Out
                    </button>
                </div>
                <div className='w-full pl-4'>
                    <p className=' text-lg text-MetallicBlue font-semibold text-left'>
                        {name}
                    </p>
                    {/* <div>
    <p className=" text-lg text-MetallicBlue italic text-left">{fcn}</p>
    </div> */}
                </div>
            </div>
        </div>
    );
};
export default UserProfile;
