import React from "react";
import { useMediaQuery } from 'react-responsive';


import TopBar from "../../../../components/navbar/navbar";
import Sidebar from "../../../../components/sidebar/sidebar";
import Hero from "./hero";


const HeroLandingPage = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  return (
      <>
      {isDesktopOrLaptop?<TopBar/>:<Sidebar/> } 
      <Hero/>
      </>
  );
};

export default HeroLandingPage;