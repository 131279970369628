import React from "react";
import { useTranslation } from "react-i18next";

function Hero() {
    const { t } = useTranslation();
    return (
        <>
            <div class='bg-faq bg-center bg-cover flex '>
                <div className='bg-hero-gradient flex h-full w-full '>
                    <div className='flex justify-start text-4xl md:text-8xl  w-full md:pt-56 pt-32 md:pl-40 pl-10'>
                        <h1 className='pr-2 pb-10 font-bold  text-white w-full'>
                            {t("strategies-hero-text")}
                            {/* Under Maintenance  */}
                        </h1>
                    </div>
                </div>
            </div>
            {/* <div className=''>
                <header class='relative bg-faq bg-cover pt-96 pb-24 scale- -top-44 bg-top brightness-50'>
                    {" "}
                </header>
                <div className='absolute flex justify-start pl-24 text-8xl top-48 z-10 brightness-100'>
                    <h1 className='pr-4  text-white italic font-semibold'>
                        {" "}
                        {t("strategies-hero-text")}{" "}
                    </h1>
                </div>
                <div className='absolute bg-news-gradient pb-72 top-4 w-full'>
                    {" "}
                </div>
                <div className='absolute bg-strategies-gradient pb-52 top-0 w-full'>
                    {" "}
                </div>
            </div> */}
        </>
    );
}

export default Hero;
