import React from "react";
import { useTranslation } from "react-i18next";
// import { HashLink } from 'react-router-hash-link';
import PreRegIcon from "../../../../assets/pre-register-icon.svg";
import GetFaydaIcon from "../../../../assets/get-fayda-icon.svg";
import UpdateFaydaIcon from "../../../../assets/update-fayda-icon.svg";
import FaydaAppIcon from "../../../../assets/get-fayda-app-icon.svg";
import { useMediaQuery } from "react-responsive";
const Hero = () => {
    const { t } = useTranslation();
    const handleClickScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: "smooth", block: "end" });
        }
    };

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    return (
        <>
            <div class=' bg-id-services-hero  bg-cover h-full w-full  '>
                <div class=' bg-top-bot-gradient'>
                    {/************************** HERO TITLE ************************* */}
                    <div className='flex flex-col items-center justify-center md:justify-start md:items-start'>
                        <div className='flex  justify-center  md:justify-start mx-5 md:mx-32 md:pt-[16%] pt-[30%] text-4xl md:text-8xl'>
                            <h1 className='pr-2 mb-0 font-extrabold text-white '>
                                {t("services-title1")}
                            </h1>
                            <h1 className='mb-0 ml-2 font text-green '>
                                {t("services-title2")}{" "}
                            </h1>
                        </div>
                        <div className='mt-5 flex h-1 bg-green w-[15%] md:hidden'></div>
                    </div>

                    {isDesktopOrLaptop ? (
                        <div className='grid w-full h-full grid-cols-4 gap-1 px-5 pb-10 md:px-16 md:align-middle md:gap-8 '>
                            <div
                                className='flex items-center justify-center h-full '
                                onClick={() => handleClickScroll("get-no")}
                            >
                                <div className=' cursor-pointer hover:scale-110 transition-all h-[50%] duration-300 shadow-lg shadow-lightblue f flex flex-col justify-center  mx-16 my-2 text-center bg-white w-full md:w-[90%] rounded-lg items-center hover:bg-green font-semibold text-MetallicBlue'>
                                    <img
                                        src={GetFaydaIcon}
                                        alt='GetFaydaIcon '
                                        className='h-[40%]'
                                    />
                                    <div className=' w-[70%] border-b-2 border-lightblue mr-1 md:my-2'></div>
                                    <h1 className='px-10 text-xs font-bold leading-tight md:text-xl'>
                                        {t("services-get-no")}
                                    </h1>
                                </div>
                                <div className='w-0.5 h-16 ml-1 bg-lightblue/50'></div>
                            </div>

                            <div
                                className='flex items-center justify-center h-full '
                                onClick={() => handleClickScroll("pre-reg")}
                            >
                                <div className='cursor-pointer hover:scale-110 transition-all h-[50%] duration-300 shadow-lg shadow-lightblue f flex flex-col justify-center  mx-16 my-2 text-center bg-white w-full md:w-[90%] rounded-lg items-center hover:bg-green font-semibold text-MetallicBlue'>
                                    {/* <PreRegIcon className='h-[40%] ' /> */}
                                    <img
                                        src={PreRegIcon}
                                        alt='PreregIcon '
                                        className='h-[40%]'
                                    />

                                    <div className='w-[70%] border-b-2 border-lightblue mr-1 md:my-2'></div>
                                    <h1 className='px-10 text-xs font-bold leading-tight md:text-xl'>
                                        {t("services-pre-reg")}
                                    </h1>
                                </div>
                                <div className='w-0.5 h-16 ml-1 bg-lightblue/50'></div>
                            </div>

                            <div
                                className='flex items-center justify-center h-full '
                                onClick={() => handleClickScroll("mobile-app")}
                            >
                                <div className='cursor-pointer hover:scale-110 transition-all h-[50%] duration-300 shadow-lg shadow-lightblue f flex flex-col justify-center  mx-16 my-2 text-center bg-white w-full md:w-[90%] rounded-lg items-center hover:bg-green font-semibold text-MetallicBlue'>
                                    <img
                                        src={FaydaAppIcon}
                                        alt='FaydaAppIcon '
                                        className='h-[40%]'
                                    />
                                    <div className='w-[70%] border-b-2 border-lightblue mr-1 md:my-2'></div>
                                    <h1 className='px-10 text-xs font-bold leading-tight md:text-xl'>
                                        {t("services-get-app")}
                                    </h1>
                                </div>
                                <div className='w-1 h-16 ml-1 bg-lightblue/50'></div>
                            </div>

                            <div
                                className='flex items-center justify-center h-full '
                                onClick={() => handleClickScroll("update-no")}
                            >
                                <div className=' cursor-pointer hover:scale-110 transition-all h-[50%] duration-300 shadow-lg shadow-lightblue f flex flex-col justify-center  mx-16 my-2 text-center bg-white w-full md:w-[90%] rounded-lg items-center hover:bg-green font-semibold text-MetallicBlue'>
                                    <img
                                        src={UpdateFaydaIcon}
                                        alt='UpdateFaydaIcon '
                                        className='h-[40%]'
                                    />
                                    <div className='w-[70%] border-b-2 border-lightblue mr-1 md:my-2'></div>
                                    <h1 className='px-10 text-xs font-bold leading-tight md:text-xl'>
                                        {t("services-update-no")}
                                    </h1>
                                </div>
                            </div>

                            {/* card */}
                        </div>
                    ) : (
                        <>
                            <div className='grid justify-center w-full h-full grid-cols-2 grid-rows-2 gap-5 px-10 pb-20 mt-10 place-items-center  '>
                                {/*          
         <div className="flex items-center justify-center gap-4 mx-0 bg-darkorange"> */}

                                <div className='flex justify-center w-full h-full px-[5%] py-[5%]'>
                                    <div
                                        className='flex items-center justify-center w-full h-full '
                                        onClick={() =>
                                            handleClickScroll("get-no")
                                        }
                                    >
                                        <div className='flex flex-col items-center justify-center w-full py-2 font-semibold text-center bg-white rounded-sm shadow-lg cursor-pointer h-36 0 shadow-lightblue hover:bg-green text-MetallicBlue'>
                                            <div className='flex items-center justify-center h-[50%] w-full'>
                                                <img
                                                    src={GetFaydaIcon}
                                                    alt='GetFaydaIcon '
                                                    className='h-full'
                                                />
                                            </div>
                                            <div className='h-[50%]  flex flex-col items-center'>
                                                <div className=' w-[70%] border-b-2 border-lightblue mr-1 md:my-2'></div>
                                                <h1 className='w-full px-[1%] my-3 text-lg font-bold leading-tight md:text-xl'>
                                                    {t("services-get-no")}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-center w-full h-full px-[5%] py-[5%]'>
                                    <div
                                        className='flex items-center justify-center w-full h-full '
                                        onClick={() =>
                                            handleClickScroll("pre-reg")
                                        }
                                    >
                                        <div className='flex flex-col items-center justify-center w-full py-2 font-semibold text-center bg-white rounded-sm shadow-lg cursor-pointer h-36 0 shadow-lightblue hover:bg-green text-MetallicBlue'>
                                            <div className='flex items-center justify-center h-[50%] w-full'>
                                                <img
                                                    src={PreRegIcon}
                                                    alt='PreRegIcon '
                                                    className='h-full'
                                                />
                                            </div>
                                            <div className='h-[50%]  flex flex-col items-center'>
                                                <div className=' w-[70%] border-b-2 border-lightblue mr-1 md:my-2'></div>
                                                <h1 className='w-full px-[1%] my-3 text-lg font-bold leading-tight md:text-xl'>
                                                    {t("services-pre-reg")}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-center w-full h-full px-[5%] py-[5%]'>
                                    <div
                                        className='flex items-center justify-center w-full h-full '
                                        onClick={() =>
                                            handleClickScroll("mobile-app")
                                        }
                                    >
                                        <div className='flex flex-col items-center justify-center w-full py-2 font-semibold text-center bg-white rounded-sm shadow-lg cursor-pointer h-36 0 shadow-lightblue hover:bg-green text-MetallicBlue'>
                                            <div className='flex items-center justify-center h-[50%] w-full'>
                                                <img
                                                    src={FaydaAppIcon}
                                                    alt='FaydaAppIcon '
                                                    className='h-full'
                                                />
                                            </div>
                                            <div className='h-[50%]  flex flex-col items-center'>
                                                <div className=' w-[70%] border-b-2 border-lightblue mr-1 md:my-2'></div>
                                                <h1 className='w-full px-5 my-3 text-lg font-bold leading-tight md:text-xl'>
                                                    {t("services-get-fayda")}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-center w-full h-full px-[5%] py-[5%]'>
                                    <div
                                        className='flex items-center justify-center w-full h-full '
                                        onClick={() =>
                                            handleClickScroll("update-no")
                                        }
                                    >
                                        <div className='flex flex-col items-center justify-center w-full py-2 font-semibold text-center bg-white rounded-sm shadow-lg cursor-pointer h-36 0 shadow-lightblue hover:bg-green text-MetallicBlue'>
                                            <div className='flex items-center justify-center h-[50%] w-full'>
                                                <img
                                                    src={UpdateFaydaIcon}
                                                    alt='UpdateFaydaIcon '
                                                    className='h-full w-[50%]'
                                                />
                                            </div>

                                            <div className='h-[50%]  flex flex-col items-center w-full '>
                                                <div className=' w-[70%] border-b-2 border-lightblue mr-1 md:my-2'></div>
                                                <h1 className='w-full px-[1%] my-3 text-lg font-bold leading-tight md:text-xl'>
                                                    {t("services-update-no")}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Hero;
