import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


import {  Animate } from "../../../../hooks/animations";


const PrincipleMobile = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
 

  return (
    <>
      <div className="flex flex-col w-full h-full pt-0 text-center text-white bg-cover bg-bottom-top bg-principle ">

      <div className="w-full h-full pb-10 bg-MetallicBlue/90">
        <div className="flex flex-col px-5 pt-0 mx-6 ">
          <div className="mt-10 text-center">
            <Animate.FadeIn>
          

              <div className="flex justify-center text-5xl text-center ">
                <h1 className="pr-2 mb-0 font-semibold text-green">
                {t("nid")}
                </h1>
                <h1 className="pb-7">{t("principles")}</h1>
              </div>
            </Animate.FadeIn>

            <Animate.FadeIn>
              <div className="mt-0">
                {" "}
                <p className="mt-0 text-base text-justify text-white">
                {t("principles-text")}
                </p>
             </div>
            </Animate.FadeIn>
            {
              <Animate.FadeIn>
                <button  onClick={() => navigate("/principles")} className="text-white/80 px-16 text-xl mt-5 self-center shadow-xl font-semibold scale-90 h-9 btnnid rounded-2xl md:scale-100 hover:bg-[#083247]">
                  {t("learn-more")}
                </button>
              </Animate.FadeIn>
            }
          </div>
        </div>

   </div>
      </div>
    </>
  );
};
export default PrincipleMobile;
