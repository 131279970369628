import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import image from "../assets/event.jpg";
import { Link } from "react-router-dom";

function TwitterBox() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const carouselRef = useRef(null);
    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "nid-website",
    };

    useEffect(() => {
        fetch(process.env.REACT_APP_BASE_URL + `/News/EventsNews`, {
            headers: headers,
        })
            .then((res) => res.json())
            .then((data) => {
                setPosts(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, []);

    const handleToggleDescription = (index) => {
        const updatedPosts = [...posts];
        updatedPosts[index].showFullDescription =
            !updatedPosts[index].showFullDescription;
        setPosts(updatedPosts);
    };

    const handleAutoScroll = () => {
        if (carouselRef.current) {
            carouselRef.current.slideNext();
        }
    };

    return (
        <div className=' '>
            <Link to={`/events/`}>
                <h1 className='text-5xl  text-white p-1 text-center font-bold bg-[#083247] rounded-t-xl w-[481px] shadow-lg border-0  hover:cursor-pointer hover:transition-all duration-300 ease-in-out hover:bg-[#47F4C7]'>
                    {t("Events")}
                </h1>
            </Link>
            {loading ? (
                <p>Loading ... </p>
            ) : (
                <div className='bg-[#eeee] bg-center bg-opacity-100 rounded-b-xl shadow-lg border-t-1 border-gray-200 w-[481px] h-[250px] space-y-4 -semi-condensed overflow-auto scrollbar-hide'>
                    <AliceCarousel
                        mouseTracking={true}
                        animationDuration={3500}
                        infinite={true}
                        autoPlay={true}
                        disableDotsControls
                        disableButtonsControls
                        autoHeight={true}
                        items={posts.map((post, index) => (
                            <div key={index} className='relative z-20'>
                                <div className='ml-[15px] rounded-xl border-gray-200 shadow-2xl mb-8 ]'>
                                    <div className='flex justify-center items-center'>
                                        {post.image ? (
                                            <Link
                                                to={`/updates/${
                                                    post.type
                                                }/${post.title.replace(
                                                    / /g,
                                                    "-"
                                                )} `}
                                                state={{ news: post }}
                                            >
                                                <img
                                                    className='p-1 rounded-xl w-[450px] h-[250px] hover:rounded transition-all duration-300 ease-in-out'
                                                    src={
                                                        process.env
                                                            .REACT_APP_BASE_URL +
                                                        post.image
                                                    }
                                                    alt='Event'
                                                />
                                            </Link>
                                        ) : (
                                            <Link
                                                to={`/updates/${
                                                    post.type
                                                }/${post.title.replace(
                                                    / /g,
                                                    "-"
                                                )} `}
                                                state={{ news: post }}
                                            >
                                                <img
                                                    className='p-1 mt-[2px] rounded-xl w-[385px] h-[200px] hover:rounded-none transition-all duration-300 ease-in-out'
                                                    src={image}
                                                    alt='Fallback Event'
                                                />
                                            </Link>
                                        )}
                                    </div>
                                    <h2
                                        className='px-2 ml-[8px] mt-1 text-md text-black font-bold'
                                        dangerouslySetInnerHTML={{
                                            __html: post.title,
                                        }}
                                    />
                                    <p
                                        className='px-2 ml-[3px] text-cyan-700 text-md text-left font-medium'
                                        dangerouslySetInnerHTML={{
                                            __html: post.date,
                                        }}
                                    />
                                    <p className='px-2 ml-[3px] text-[14px] text-left'>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: `${post.description.slice(
                                                    0,
                                                    200
                                                )}...`,
                                            }}
                                        />
                                        <Link
                                            to={`/updates/${
                                                post.type
                                            }/${post.title.replace(
                                                / /g,
                                                "-"
                                            )} `}
                                            state={{ news: post }}
                                            className='text-sky-800 text-md font-medium ml-2'
                                        >
                                            Read more....
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        ))}
                        ref={carouselRef}
                    />
                </div>
            )}
        </div>
    );
}
export default TwitterBox;
