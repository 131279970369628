import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import TopBar from "../../../components/navbar/navbar";
import Sidebar from "../../../components/sidebar/sidebar";

const Hero = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    const { t } = useTranslation();

    return (
        <div className='bg-cover bg-right md:bg-center  text-white bg-partners-bg'>
            <div className=' bg-top-bot-gradient  pb-5'>
                {isDesktopOrLaptop ? <TopBar /> : <Sidebar />}

                <div className='px-5 md:px-10 xl:px-0 md:pt-20 xl:pt-32 xl:pl-28 xl:pr-40 pb-10 flex flex-col items-center text-center pt-24'>
                    <p className='text-7xl font-[400] pb-10'>
                        {t("partners-hero-title")}
                    </p>
                    <p className='max-w-[1200px] font-[500] text-2xl'>
                        <p>{t("partners-hero-description")}</p>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Hero;
