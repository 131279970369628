import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Vacancy = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    return (
        <>
            {isDesktopOrLaptop ? (
                <div>
                    <div className='relative flex flex-row text-white bg-cover bg-vacancy-career bg-no-repeat h-72 '>
                        <div className='flex w-full h-full bg-MetallicBlue/80'></div>
                    </div>

                    <div className='relative flex flex-row items-center px-20 text-white bottom-56 '>
                        <div className='flex flex-col w-[50%] mb-5 text-6xl'>
                            <h2 className='font-semibold'>
                                {t("about-vacancy")}
                            </h2>
                            <h2 className='font-thin'>
                                {t("about-vacancy-text1")}
                            </h2>
                        </div>
                        <div className='flex flex-col w-[50%] pt-14 text-2xl'>
                            <p>{t("about-vacancy-text2")}</p>

                            <div className='flex flex-row items-center justify-start pt-5 text-xl space-x-9'>
                                <button
                                    onClick={() => navigate("/career")}
                                    className='py-1 transition-all duration-300 rounded-full bg-green px-14 hover:bg-MetallicBlue hover:text-white hover:scale-105'
                                >
                                    {t("Apply Now")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='w-full  bg-vacancy bg-cover bg-center'>
                    <div className='flex flex-col bg-about-pilot h-full w-full px-10 pt-4 pb-8 items-center'>
                        <div className='flex flex-col mb-5 text-4xl text-white md:text-5xl'>
                            <div className='flex flex-col mb-5'>
                                <h2 className='font-semibold'>
                                    {t("about-vacancy")}
                                </h2>
                            </div>
                            <hr className='bg-green border-darkgreen border-2 w-[15%] self-center' />
                        </div>

                        <div className='w-full text-center text-white font-extralight '>
                            <h2 className='text-4xl font-thin'>
                                {t("about-vacancy-text1-mobile")}
                            </h2>
                            <h2 className='text-4xl font-semibold text-green'>
                                {t("about-vacancy-text1.1-mobile")}
                            </h2>
                        </div>
                        <button
                            className='mt-6 text-white border rounded-full px-7 hover:bg-darkgreen'
                            onClick={() => navigate("/career")}
                        >
                            {t("Apply Now")}
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default Vacancy;
