import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import Body from "./components/body/body";

import { useTranslation } from "react-i18next";

const GetId = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh' }}>
    //   <iframe
    //     src="https://docs.google.com/forms/d/e/1FAIpQLSd9hOMYK__VoQa0-LI8umj-mtfsMOgLe5RJ31f8BU254PUWOQ/viewform?embedded=true"
    //     width="640"
    //     height="800"
    //     frameborder="0"
    //     marginheight="0"
    //     marginwidth="0"
    //   >
    //     Loading...
    //   </iframe>
    // </div>

    <div>
      <Body /> :
      {/* <div className="text-center bg-MetallicBlue fixed bottom-0 left-0 w-full h-20 ">
        <span class="text-xs font-medium text-white dark:text-white">
          {new Date().getFullYear()} {t("national-id")} {t("all-rights")}
        </span>
      </div> */}
    </div>
  );
};

export default GetId;
