import React from "react";
import { useTranslation } from "react-i18next";
import copyImg from "../../../../assets/copy-icon.png";
import { useLocation } from "react-router-dom";

const menuItems = [
    {
        name: "resource-title-All",
        pathname: "/resources",
    },
    {
        name: "resource-title-MoU",
        pathname: "/mou",
    },
    {
        name: "resource-title-Decks",
        pathname: "/decks",
    },
    {
        name: "resource-title-Policies",
        pathname: "/policies",
    },

    {
        name: "resource-title-Publications",
        pathname: "/documents",
    },
    {
        name: "resource-title-Specifications",
        pathname: "/nidspecifications",
    },
    {
        name: "resource-title-Procurement",
        pathname: "/procurement",
    },
    {
        name: "resource-title-Law",
        pathname: "/law",
    },
];

const Hero = () => {
    const { t } = useTranslation();
    const subPath = useLocation();
    return (
        <div class=' bg-resources-hero  bg-cover h-fit text-white bg-bottom'>
            <div class=' bg-news-gradient'>
                <div className='flex  md:justify-start md:px-0 px-8 md:mx-32 pt-[50%] md:pt-[16%] md:pb-16 text-5xl md:text-8xl items-center  pb-8'>
                    <h1 className='mb-0 font-extrabold text-white md:pr-2 '>
                        {t("nid")}
                    </h1>
                    <h1 className='mb-0 ml-2 font text-green '>
                        {t("resources-title")}{" "}
                    </h1>
                </div>

                <div className='flex flex-col justify-start w-full px-8 pb-16 text-center md:pl-32 md:px-0 md:text-justify  '>
                    <div className='flex '>
                        <img
                            src={copyImg}
                            className='h-6 pr-2 bg-cover md:h-12 md:pr-4'
                            alt='resource_title'
                        ></img>
                        <h1 className='pb-2 text-xl md:pb-5 md:text-5xl'>
                            {t("resource-explore")}
                        </h1>
                    </div>

                    <p className='text-base leading-snug text-justify md:text-2xl md:mr-96 '>
                        {menuItems?.map((item) =>
                            item.pathname == subPath.pathname
                                ? t(item.name)
                                : null
                        )}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Hero;
