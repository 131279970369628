import React from "react";
import { useMediaQuery } from "react-responsive";
import { useState, useEffect } from "react";
import ExpandableContent from "react-expandable-content";

const PrinciplesCard = ({ card1, card2, card3, list }) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    const [card1Visible, setCard1Visible] = useState(false);
    const [card2Visible, setCard2Visible] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            setScrollPosition(scrollY);

            if (card1 < window.innerHeight && !card1Visible) {
                setCard1Visible(true);
            }
            if (card2 < window.innerHeight && !card2Visible) {
                setCard2Visible(true);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [card1Visible, card2Visible]);

    return (
        <>
            {isDesktopOrLaptop ? (
                <div className='flex flex-col'>
                    <div className='bg-white flex justify-center items-center pb-6 my-5'>
                        <div class='p-4 w-[45%]'>
                            <h2 class='text-6xl text-green font-black  mb-4 text-right'>
                                {card1.key}
                            </h2>
                            <h3 class='text-2xl text-MetallicBlue font-bold  relative pb-2 mb-4 text-right'>
                                {card1.title}
                                <span className='absolute bottom-0 right-0 w-[13.5%] h-1 bg-MetallicBlue/50'></span>
                                <span className='absolute bottom-0 right-0 w-[30%] h-0.5 bg-MetallicBlue/20'></span>
                            </h3>
                            <p class='text-right text-MetallicBlue  ml-20'>
                                <ExpandableContent
                                    maxHeight={100}
                                    collapseText={"Read less"}
                                    expandText={"Read more"}
                                    className='bg-MetallicBlue text-white absolute right-0 hover:scale-105'
                                    showIcon={false}
                                >
                                    {card1.content}
                                </ExpandableContent>
                                {/* {card1.content}.{" "} */}
                            </p>
                        </div>

                        <div class='border h-40 opacity-30 mx-4 '></div>
                        <div class='p-4 w-[45%]'>
                            <h2
                                class={`${
                                    card2.key === "" ? "hidden" : ""
                                } text-6xl text-green font-black  mb-4`}
                            >
                                {card2.key}
                            </h2>
                            <h3
                                class={`${
                                    card2.title === "" ? "hidden" : ""
                                } text-2xl text-MetallicBlue font-bold  relative pb-2 mb-4`}
                            >
                                {card2.title}
                                <span class='absolute bottom-0 left-0 w-[13.5%] h-1 bg-MetallicBlue/50'></span>
                                <span class='absolute bottom-0 left-0 w-[30%] h-0.5 bg-MetallicBlue/20'></span>
                            </h3>
                            <p class='text-justify text-MetallicBlue  mr-20'>
                                {card2.content && (
                                    <ExpandableContent
                                        maxHeight={100}
                                        collapseText={"Read less"}
                                        expandText={"Read more"}
                                        className='bg-MetallicBlue text-white absolute left-0 hover:scale-105'
                                        showIcon={false}
                                    >
                                        {card2.content}
                                    </ExpandableContent>
                                )}
                            </p>
                        </div>
                    </div>
                    <hr class='my-5 w-full border-gray-300' />
                </div>
            ) : (
                <div className='flex flex-col justify-center items-center pb-6 mx-4 my-2 text-MetallicBlue'>
                    <div
                        id='card1'
                        className={`card p-4 pb-12 mb-10 bg-grey/70 rounded-md ${
                            card1Visible ? "card--visible" : ""
                        }`}
                    >
                        <h2 className='text-6xl text-green font-black  mb-2 text-right'>
                            {card1.key}
                        </h2>
                        <h3 className='text-2xl text-MetallicBlue font-bold  relative pb-2 mb-2 text-right'>
                            {card1.title}
                            <span className='absolute bottom-0 right-0 w-1/4 h-1 bg-MetallicBlue/50'></span>
                            <span className='absolute bottom-0 right-1/4 w-[30%] h-px bg-MetallicBlue/20'></span>
                        </h3>
                        <p className='text-justify text-darkblue  font-normal text-lg tracking-tight leading-snug'>
                            <ExpandableContent
                                maxHeight={150}
                                collapseText={"Read less"}
                                expandText={"Read more"}
                                className='bg-MetallicBlue text-white absolute left-0 hover:scale-105'
                                showIcon={false}
                            >
                                {card1.content}
                            </ExpandableContent>
                        </p>
                    </div>
                    <div
                        id='card2'
                        className={`card p-4 pb-12 bg-grey/70 rounded-md ${
                            card2Visible ? "card--visible" : ""
                        }`}
                    >
                        <h2 className='text-6xl text-green font-black  mb-2'>
                            {card2.key}
                        </h2>
                        <h3 className='text-2xl text-MetallicBlue font-bold  relative pb-2 mb-2'>
                            {card2.title}
                            <span className='absolute bottom-0 left-0 w-1/4 h-1 bg-MetallicBlue/50'></span>
                            <span className='absolute bottom-0 left-1/4 w-[30%] h-px bg-MetallicBlue/20'></span>
                        </h3>
                        <p className='text-justify text-darkblue  font-normal text-lg tracking-tight leading-snug'>
                            <ExpandableContent
                                maxHeight={150}
                                collapseText={"Read less"}
                                expandText={"Read more"}
                                className='bg-MetallicBlue text-white absolute left-0 hover:scale-105'
                                showIcon={false}
                            >
                                {card2.content}
                            </ExpandableContent>
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};
export default PrinciplesCard;
