import React, {useState, useEffect} from "react";
import HeroLandingPage from "./hero/heroLandingPage";
import SmallFooter from "../../../components/footer/smallFooter";
import { useMediaQuery } from "react-responsive";
import Body from "./body/body";
import { useDispatch, useSelector } from "react-redux";
import { getGalleryList } from "../../../redux/actions";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";

const Podcast = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    // const timer = setTimeout(() => {
    //   setIsLoading(false);
    // }, 10000);

    // Group the dispatch calls based on their functionality
    const dataDispatches = [
      dispatch(getGalleryList()),
    ];

    // Execute the dispatch calls in parallel
    Promise.all(dataDispatches)
      .then(() => {
        setIsLoading(false)
        // Do something after all the dispatch calls have completed
      })
      .catch((error) => {
        // Handle any errors that occur during the dispatch calls
      });

    // return () => {
    //   clearTimeout(timer);
    // };
  }, [dispatch]);


  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <HeroLandingPage />
          <Body />
          <SmallFooter />
        </>
      )}
    </>
  );
};

export default Podcast;
