import React from "react";
import { useMediaQuery } from "react-responsive";

function Hero() {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    return (
        <>
            {isDesktopOrLaptop ? (
                <>
                    <div class='bg-benefits-hero bg-center bg-cover flex'>
                        <div className='bg-hero-gradient flex h-full w-full '>
                            <div className='flex w-full pt-52'></div>
                        </div>
                    </div>
                    {/* <div className=''>
            <header class="relative bg-faq bg-cover pt-96 pb-24 scale- -top-44 bg-top brightness-50"> </header>
            <div className="absolute flex justify-start pl-24 text-8xl top-48 z-10 brightness-100">
                <h1 className="pr-4  text-white italic font-semibold"> {t('strategies-hero-text')} </h1>
            </div>
            <div className="absolute bg-news-gradient pb-72 top-4 w-full">  </div>
            <div className="absolute bg-strategies-gradient pb-52 top-0 w-full">  </div>
        </div> */}
                </>
            ) : (
                <div class='bg-benefits-hero bg-center bg-cover flex'>
                    <div className='bg-hero-gradient flex h-full w-full '>
                        <div className='flex w-full pt-20'></div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Hero;
