import React from "react";
//import './news.css';

import i18next from "i18next";

import webinar from "../../../../assets/webinar.jpg";
import tender from "../../../../assets/tender.jpg";
import press from "../../../../assets/press.jpg";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { getNews } from "../../../../redux/selectors";

const News = () => {
    const list = useSelector(getNews);
    const navigate = useNavigate();

    return (
        <>
            {/* flex flex-col items-center w-full h-full pb-20 scale-90 pt-14 md:scale-100  */}
            {list.length > 0 ? (
                <div className='w-full  h-[20%] pt-16 mb-20 px-28'>
                    <div className='flex gap-3 '>
                        <div
                            onClick={() =>
                                navigate(
                                    `/updates/${
                                        list[0].type
                                    }/${list[0].title.replace(/ /g, "-")}/${0}`,
                                    { state: { news: list[0] } }
                                )
                            }
                            className='h-[600px] cursor-pointer relative flex items-center justify-center basis-[50%]  transition-all duration-700 ease-in-out transform scale-100 hover:scale-95 rounded-md  '
                        >
                            <img
                                className='absolute object-cover object-left w-full h-full transition-all duration-500 ease-in-out transform scale-100 rounded-md hover:scale-100 hover:cursor-pointer'
                                src={
                                    process.env.REACT_APP_BASE_URL +
                                        list[0].image !==
                                    ""
                                        ? process.env.REACT_APP_BASE_URL +
                                          list[0].image
                                        : list[0].type === "Webinar"
                                        ? webinar
                                        : list[0].type === "Press"
                                        ? press
                                        : list[0].type === "Tender"
                                        ? tender
                                        : i18next.language === "amh" &&
                                          process.env.REACT_APP_BASE_URL +
                                              list[0].image_amh !=
                                              ""
                                        ? process.env.REACT_APP_BASE_URL +
                                          list[0].image_amh
                                        : process.env.REACT_APP_BASE_URL +
                                          list[0].image
                                }
                                alt={list[0].image}
                            />

                            <div className='absolute bottom-0 left-0 right-0 h-full px-10 text-base'></div>
                            <div className='absolute bottom-0 left-0 right-0 px-10 py-10 text-base bg-news-gradient '>
                                <h1 className='pl-4 text-lg text-white whitespace-nowrap'>
                                    <span className='px-5 py-1 border-t-2 border-b-2 border-l-2 border-r-2 border-white rounded-sm'>
                                        Trending
                                    </span>
                                </h1>
                                <div className='flex p-4'>
                                    <div class=' h-8  bg-[#21cfac] w-1 mr-2 my-3'></div>
                                    <p class=' mt-2 text-2xl  text-white font-medium  '>
                                        {i18next.language === "en" &&
                                            list[0].title.slice(0, 150) + "..."}
                                        {i18next.language === "amh" &&
                                            list[0].title_amh.slice(0, 150) +
                                                "..."}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-col gap-2 basis-[50%]  h-[600px]'>
                            {/* one slightly bigger news */}
                            <div
                                onClick={() =>
                                    navigate(
                                        `/updates/${
                                            list[1].type
                                        }/${list[1].title.replace(
                                            / /g,
                                            "-"
                                        )}/${1}`,
                                        { state: { news: list[1] } }
                                    )
                                }
                                className='h-[380px] relative transition-all duration-700 ease-in-out transform scale-100 rounded-md cursor-pointer r hover:scale-95 '
                            >
                                <img
                                    className='object-cover w-full h-full rounded-md hover:cursor-pointer'
                                    src={
                                        process.env.REACT_APP_BASE_URL +
                                            list[1].image !==
                                        ""
                                            ? process.env.REACT_APP_BASE_URL +
                                              list[1].image
                                            : list[1].type === "Webinar"
                                            ? webinar
                                            : list[1].type === "Press"
                                            ? press
                                            : list[1].type === "Tender"
                                            ? tender
                                            : i18next.language === "amh" &&
                                              process.env.REACT_APP_BASE_URL +
                                                  list[1].image_amh !=
                                                  ""
                                            ? process.env.REACT_APP_BASE_URL +
                                              list[1].image_amh
                                            : process.env.REACT_APP_BASE_URL +
                                              list[1].image
                                    }
                                    alt={list[1].image}
                                />
                                <div className='absolute bottom-0 left-0 right-0 px-10 h-[90%] text-base '></div>
                                <div className='absolute bottom-0 left-0 right-0 px-4 py-2 bg-news-gradient'>
                                    <div className='flex p-4'>
                                        <div class='h-8  bg-[#21cfac] w-1 mr-2 mt-3'></div>
                                        <p class='mt-2 text-2xl text-white '>
                                            {i18next.language === "en" &&
                                                list[1].title.slice(0, 200) +
                                                    "..."}
                                            {i18next.language === "amh" &&
                                                list[1].title_amh.slice(
                                                    0,
                                                    200
                                                ) + "..."}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* Two small news */}
                            <div className='flex gap-2 h-[212px]'>
                                <div
                                    onClick={() =>
                                        navigate(
                                            `/updates/${
                                                list[2].type
                                            }/${list[2].title.replace(
                                                / /g,
                                                "-"
                                            )}/${2}`,
                                            { state: { news: list[2] } }
                                        )
                                    }
                                    className=' cursor-pointer r relative basis-[50%] transition-all duration-700 ease-in-out transform scale-100 hover:scale-95'
                                >
                                    <img
                                        className='object-cover w-full h-full rounded-md hover:cursor-pointer'
                                        src={
                                            process.env.REACT_APP_BASE_URL +
                                                list[2].image !==
                                            ""
                                                ? process.env
                                                      .REACT_APP_BASE_URL +
                                                  list[2].image
                                                : list[2].type === "Webinar"
                                                ? webinar
                                                : list[2].type === "Press"
                                                ? press
                                                : list[2].type === "Tender"
                                                ? tender
                                                : i18next.language === "amh" &&
                                                  process.env
                                                      .REACT_APP_BASE_URL +
                                                      list[2].image_amh !=
                                                      ""
                                                ? process.env
                                                      .REACT_APP_BASE_URL +
                                                  list[2].image_amh
                                                : process.env
                                                      .REACT_APP_BASE_URL +
                                                  list[2].image
                                        }
                                        alt={list[2].image}
                                    />
                                    <div className='absolute bottom-0 left-0 right-0 px-10 h-[90%] text-base'></div>
                                    <div className='absolute bottom-0 left-0 right-0 px-4 py-2 bg-news-gradient'>
                                        <div className='flex p-4'>
                                            <div class='h-8  bg-[#21cfac] w-1 mr-2 mt-3'></div>

                                            <p class='mt-2 text-2xl text-white '>
                                                {i18next.language === "en" &&
                                                    list[2].title.slice(0, 60) +
                                                        "..."}{" "}
                                                {i18next.language === "amh" &&
                                                    list[2].title_amh.slice(
                                                        0,
                                                        60
                                                    ) + "..."}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    onClick={() =>
                                        navigate(
                                            `/updates/${
                                                list[3].type
                                            }/${list[3].title.replace(
                                                / /g,
                                                "-"
                                            )}/${3}`,
                                            { state: { news: list[3] } }
                                        )
                                    }
                                    className='cursor-pointer r relative basis-[50%] transition-all duration-700 ease-in-out transform scale-100 hover:scale-95'
                                >
                                    <img
                                        className='object-cover w-full h-full rounded-md hover:cursor-pointer'
                                        src={
                                            process.env.REACT_APP_BASE_URL +
                                                list[3].image !==
                                            ""
                                                ? process.env
                                                      .REACT_APP_BASE_URL +
                                                  list[3].image
                                                : list[3].type === "Webinar"
                                                ? webinar
                                                : list[3].type === "Press"
                                                ? press
                                                : list[3].type === "Tender"
                                                ? tender
                                                : i18next.language === "amh" &&
                                                  process.env
                                                      .REACT_APP_BASE_URL +
                                                      list[3].image_amh !=
                                                      ""
                                                ? process.env
                                                      .REACT_APP_BASE_URL +
                                                  list[3].image_amh
                                                : process.env
                                                      .REACT_APP_BASE_URL +
                                                  list[3].image
                                        }
                                        alt={list[3].image}
                                    />
                                    <div className='absolute bottom-0 left-0 right-0 px-10 h-[90%] text-base'></div>
                                    <div className='absolute bottom-0 left-0 right-0 px-4 py-2 bg-news-gradient'>
                                        <div className='flex p-4'>
                                            <div class='h-8  bg-[#21cfac] w-1 mr-2 mt-3'></div>

                                            <p class='mt-2 text-2xl text-white '>
                                                {i18next.language === "en" &&
                                                    list[3].title.slice(0, 60) +
                                                        "..."}
                                                {i18next.language === "amh" &&
                                                    list[3].title_amh.slice(
                                                        0,
                                                        60
                                                    ) + "..."}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
export default News;
