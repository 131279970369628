
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Img1 from "../../../../../src/assets/photo_2022-05-11_18-21-08 copy.jpg"
import Img2 from "../../../../../src/assets/Group 74 copy.jpg"
import Img3 from "../../../../../src/assets/photo_2022-05-11_18-21-08 copy.jpg"
import Img4 from "../../../../../src/assets/photo_2022-05-11_18-21-08 copy.jpg"
import Img5 from "../../../../../src/assets/photo_2022-05-11_18-21-08 copy.jpg"

  const Carousel = () => {
    const slideImages = [
      { id: 1, url: `${process.env.REACT_APP_BASE_URL}/Images/Images/1-011.jpg` },
      { id: 2, url: `${process.env.REACT_APP_BASE_URL}/Images/Images/2-011.jpg` },
      { id: 3, url: `${process.env.REACT_APP_BASE_URL}/Images/Images/3-011.jpg` },
      { id: 4, url: `${process.env.REACT_APP_BASE_URL}/Images/Images/4-011.jpg` },
      { id: 5, url: `${process.env.REACT_APP_BASE_URL}/Images/Images/6-011.jpg` },
    ];
  
    const settings = {
      dots: false,
      infinite: true,
      speed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay:true,
      autoplaySpeed:1000,
      fade:true,
    };
  return (
   
    <div className="relative">
      <Slider {...settings}>
        {slideImages.map((slideImage) => (
          <div key={slideImage.id}>
            <div
              className="w-full h-screen bg-cover bg-center"
              style={{ backgroundImage: `url(${slideImage.url})` }}
            ></div>
          </div>
        ))}
      </Slider>
    </div>

  );
};

export default Carousel;