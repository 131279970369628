import axios from "axios";

export const GET_BLOG_LIST = "GET_BLOG_LIST";

export const getBlogList = () => async (dispatch) => {
  // const cachedList = localStorage.getItem("careerList");
  // if (cachedList) {
  //   dispatch({
  //     type: GET_CAREER_LIST,
  //     payload: JSON.parse(cachedList),
  //   });
  // } else {

  try {
    const response = await axios.get(
      
      process.env.REACT_APP_BASE_URL +`/Blogs/`
    );

    if (response && response.data) {
      dispatch({
        type: GET_BLOG_LIST,
        payload: response.data,
      });
      localStorage.setItem("blogList", JSON.stringify(response.data));
    } else {
      const cachedBlogList = localStorage.getItem("blogList");
      if (cachedBlogList) {
        dispatch({
          type: GET_BLOG_LIST,
          payload: JSON.parse(cachedBlogList),
        });
      }
    }
  } catch (err) {
    const cachedBlogList = localStorage.getItem("blogList");
    if (cachedBlogList) {
      dispatch({
        type: GET_BLOG_LIST,
        payload: JSON.parse(cachedBlogList),
      });
    }
  }
};