import React from "react";
import Body from "./components/body/body";
import SmallFooter from "../../components/footer/smallFooter";
import HeroLandingPage from "./components/hero/heroLandingPage";
const PartyRegistrationForm = () => {
    return (
        <div>
            <HeroLandingPage />
            <Body />
            <SmallFooter />
        </div>
    );
};

export default PartyRegistrationForm;
