import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import PrinciplesCard from "./principlesCard";
import ReactPaginate from "react-paginate";

const PrinciplesContainer = ({ list }) => {
  const cardData = [
    {
      id: 1,
      cards: [
        {
          key: "01",
          title: "1. Privacy and Minimal Data Collection",
          content: "This entire program aims to design and implement a foundational ID system for Ethiopia at the national level. For all intents and purposes, “National ID” shall mean Foundational Digital ID. From this program’s perspective, the term “National ID” is synonymous with the term “Foundational ID” for communication and reporting purposes.Only the data necessary for establishing uniqueness will be collected. All data collected, stored in the NID database, and/or published in the ID credential focuses on minimal data required to identify an individual, namely “Full Name”, “Gender”, “Current Address” and “Date of Birth”, all other data is optional, including citizenship information.Moreover, sensitive or unnecessary data such as the occupation of the individual, his/her religion or ethnicity, birthplace, or other similar data will NOT be collected.Other optional fields including phone number and email are taken for further interaction and notification purposes. Consent from individuals to participate in the Pilot will be obtained by using the following statement:“You are hereby informed that your personal information is being collected for the purpose of being included in a national ID database for issuing a personal digital identification. This information may be shared or otherwise automatically processed only in connection with that purpose. Third parties will not have access to this data without your consent. You have the right to inspect your data, audit its history and correct any errors by contacting us at www.id.et”A log of all individuals who are so informed will be maintained where the register of other data collected in connection with this program is maintained.",
        },
        {
          key: "02",
          title: "2. Inclusion",
          content:
            "To remove barriers to inclusion and following the international manuals, the National Foundational ID service will be available to all citizens and non-citizens (legal residents) who can provide any type of acceptable evidence including an appropriate witness/es that can attest on the individual’s behalf called Introducer. The introducer practice has already been in use for the Kebele-ID although it has mainly been practiced by citizens. ●	Inclusion Principles:  ●	Leave no one behind ●	Ensure universal access for individuals, free from discrimination ●	Remove barriers to access",
        },
        {
          key: "03",
          title: "3. Authentication Mechanisms and Standards",
          content:
            "The owner of the identity data is the individual and will have the mandate to manage how it should be used at the individual level. Accordingly, all rules set forth on the annexed personal data protection proclamation will be complied with during the pilot. All tests will be performed by using a formal consent from every registrant that participates during the pilot period.",
        },
      ],
    },
    {
      id: 2,
      cards: [
        {
          key: "03",
          title: "3. Authentication Mechanisms and Standards",
          content:
            "The owner of the identity data is the individual and will have the mandate to manage how it should be used at the individual level. Accordingly, all rules set forth on the annexed personal data protection proclamation will be complied with during the pilot. All tests will be performed by using a formal consent from every registrant that participates during the pilot period.",
        },
        {
          key: "04",
          title: "4. Usage of Credentials",
          content:
            "Different forms of credentials may be issued for registered individuals that can allow both online and offline authentication. Until such time as defined by proclamation, the ID credentials issued by NIDP shall only be applicable to the purposes for which the ID is issued and its scope will be limited to the provision of services for the particular relying party that has signed an agreement with the program. Hence, all identity credentials issued by NID while this Protocol Document is valid, i.e. until such time as ID Law comes into force to establish an ID authority, will not have a legal role to play as a foundational ID but a functional one to serve the aforementioned list of use-cases. Accordingly, the credentials are not mandatory and are a prerequisite for different types of functional services currently provided using the Kebele ID.",
        },
        {
          key: "05",
          title: "5. Vendor Neutrality and Open Standards-Based",
          content:
            "The program focuses on vendor neutrality and remaining in technology ownership of the core ID platform. This arrangement ensures individual data remains secure under NIDP, also known as the “Data Controller” and not the vendors or other third parties. The hardware vendors as well as ABIS and other software vendors should be interchangeable and the platform will remain independent of a specific type of hardware technology or software license.",
        },
      ],
    },
    {
      id: 3,
      cards: [
        {
          key: "05",
          title: "5. Vendor Neutrality and Open Standards-Based",
          content:
            "The program focuses on vendor neutrality and remaining in technology ownership of the core ID platform. This arrangement ensures individual data remains secure under NIDP, also known as the “Data Controller” and not the vendors or other third parties. The hardware vendors as well as ABIS and other software vendors should be interchangeable and the platform will remain independent of a specific type of hardware technology or software license.",
        },
        {
          key: "06",
          title: "6. Security in Design",
          content:
            "This platform gives the highest priority for security as a platform that holds sensitive data and it provides the basis for many other functional services across the nation. Communication between any end-points, including enrollment stations, supervisor and admin portals, authentication stations, resident service portals, and backup sites are all end-to-end encrypted. This offers individuals, service providers, and authentication parties the trust and confidence that their personal data and interaction are secure, tamper-free, and not accessible without their knowledge and active consent. ●	Design Principles: ●	Establish a trusted – unique, secure, and accurate – identity ●	Create and responsive and interoperable platform (flexible and scalable) ●	Use open standards and prevent vendor technology lock-in. ●	Protect privacy and agency through system design ●	Plan for financial and operational sustainability ",
        },
        {
          key: "07",
          title: "7. Grievance redressal and management",
          content:
            "●	All individuals enrolled in NID system will have the right to inspect their personal data and audit its history on the platform provided by NID (www.id.gov.et) ●	An internal grievance redressal committee will be established to address grievances based on internal procedures, ID draft laws, this protocol document, and internal national legislation. ●	If individuals encounter any errors or problems in their personal data, they can contact NID through digital means at www.id.gov.et, via a toll-free call center on 9188, or by personally going to any of the designated enrollment centers. ●	Any other grievances or complaints can be reported on-site during the enrollment processes or directly to NID via the above-mentioned mechanisms. A complaints officer will log the grievance report and a redressal workflow will be initiated.",
        },
      ],
    },
    {
      id: 4,
      cards: [
        {
          key: "07",
          title: "7. Grievance redressal and management",
          content:
            "●	All individuals enrolled in NID system will have the right to inspect their personal data and audit its history on the platform provided by NID (www.id.gov.et) ●	An internal grievance redressal committee will be established to address grievances based on internal procedures, ID draft laws, this protocol document, and internal national legislation. ●	If individuals encounter any errors or problems in their personal data, they can contact NID through digital means at www.id.gov.et, via a toll-free call center on 9188, or by personally going to any of the designated enrollment centers. ●	Any other grievances or complaints can be reported on-site during the enrollment processes or directly to NID via the above-mentioned mechanisms. A complaints officer will log the grievance report and a redressal workflow will be initiated.",
        },
        {
          key: "08",
          title: "8. Communications",
          content:
            "NID prioritizes transparency and as such all non-personal or non-sensitive data, including activities of the program, anonymous statistical data, audit reports, and other findings will be publicly published.",
        },
        {
          key: "09",
          title: "9. Governance",
          content:
            "●	Governance principles focus on: ●	Oversight, Security, and Accountability ●	Safeguard data privacy, security, and user rights through a comprehensive legal and regulatory framework. ●	Establish clear institutional mandates with transparency and accountability.  ●	Enforce legal and trust frameworks through independent oversight and compliance with UN and international laws.",
        },
      ],
    },
    {
      id: 5,
      cards: [
        {
          key: "09",
          title: "9. Governance",
          content:
            "●	Governance principles focus on: ●	Oversight, Security, and Accountability ●	Safeguard data privacy, security, and user rights through a comprehensive legal and regulatory framework. ●	Establish clear institutional mandates with transparency and accountability.  ●	Enforce legal and trust frameworks through independent oversight and compliance with UN and international laws.",
        },
        {
          key: "10",
          title: "10. Relationship with Relying Parties",
          content:
            "●	The pilot and the subsequent country rollout will depend on relying parties to scale its reach. The strategy that will be followed towards the successful completion of the batch registration will primarily focus on functional use cases that offer the individual the benefit of ID-supported trustworthy services. ●	The pilot will be operational and will involve different organizations which include local authorities (regions, zones/sub-cities, and woredas). Furthermore, a selected use case can imply the direct participation of different organizations. As an example, the selected PSNP (Productive Safety Net Program) use case will require the direct involvement of three other ministries (Ministry of Agriculture, Ministry of Urban Development and Construction, and Ministry of Labor and Social Affairs) as well as the Federal Urban Job Creation and Food Security Agency which the program has already begun contacting.",
        },
        {
          key: "11",
          title: "11. Permanent Independent Entity",
          content:
            "●	As depicted in the program’s component and progress model (section 2.1 and figure 2), it should be noted that the government, and the NIDP thereof, will work towards passing of the “ID Law” that establishes a permanent independent ID authority. This Protocol Document shall serve as a guiding principle for procedural decision and adjudication until an ID Authority is formally established.",
        },
      ],
    },
    {
      id: 6,
      cards: [
        {
          key: "11",
          title: "11. Permanent Independent Entity",
          content:
            "●	As depicted in the program’s component and progress model (section 2.1 and figure 2), it should be noted that the government, and the NIDP thereof, will work towards passing of the “ID Law” that establishes a permanent independent ID authority. This Protocol Document shall serve as a guiding principle for procedural decision and adjudication until an ID Authority is formally established.",
        },
        {
          key: "12",
          title: "12. Supervision",
          content:
            "●	Governance principles focus on: ●	Oversight, Security, and Accountability ●	Safeguard data privacy, security, and user rights through a comprehensive legal and regulatory framework. ●	Establish clear institutional mandates with transparency and accountability.  ●	Enforce legal and trust frameworks through independent oversight and compliance with UN and international laws.",
        },
        {
          key: "13",
          title: "13. Procedure for penalty",
          content:
            "●	The pilot and the subsequent country rollout will depend on relying parties to scale its reach. The strategy that will be followed towards the successful completion of the batch registration will primarily focus on functional use cases that offer the individual the benefit of ID-supported trustworthy services. ●	The pilot will be operational and will involve different organizations which include local authorities (regions, zones/sub-cities, and woredas). Furthermore, a selected use case can imply the direct participation of different organizations. As an example, the selected PSNP (Productive Safety Net Program) use case will require the direct involvement of three other ministries (Ministry of Agriculture, Ministry of Urban Development and Construction, and Ministry of Labor and Social Affairs) as well as the Federal Urban Job Creation and Food Security Agency which the program has already begun contacting.",
        },
      ],
    },
    {
      id: 7,
      cards: [
        {
          key: "13",
          title: "13. Procedure for penalty",
          content:
            "●	The pilot and the subsequent country rollout will depend on relying parties to scale its reach. The strategy that will be followed towards the successful completion of the batch registration will primarily focus on functional use cases that offer the individual the benefit of ID-supported trustworthy services. ●	The pilot will be operational and will involve different organizations which include local authorities (regions, zones/sub-cities, and woredas). Furthermore, a selected use case can imply the direct participation of different organizations. As an example, the selected PSNP (Productive Safety Net Program) use case will require the direct involvement of three other ministries (Ministry of Agriculture, Ministry of Urban Development and Construction, and Ministry of Labor and Social Affairs) as well as the Federal Urban Job Creation and Food Security Agency which the program has already begun contacting.",
        },
        {
          key: "",
          title: "",
          content: "",
        },
        {
          key: "",
          title: "",
          content: "",
        },
      ],
    },
  ];

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const cardsPerPage = isDesktopOrLaptop ? 3 : 2;

  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const startIndex = currentPage * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;
  const cardsToShow = cardData.slice(startIndex, endIndex);
  const totalPages = Math.ceil(cardData.length / cardsPerPage);

  return (
    <>
      <div className="flex flex-col bg-white">
        {cardsToShow.map((cardItem) => (
          <PrinciplesCard
            card1={cardItem.cards[0]}
            card2={cardItem.cards[1]}
            card3={cardItem.cards[2]}
          />
        ))}
      </div>
      <div className="gap-6 px-5 font-medium">
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          previousClassName="border border-opacity-40 border-MetallicBlue rounded-md hover:bg-MetallicBlue hover:text-green mr-5 px-14 py-1"
          nextClassName="border border-opacity-40 border-MetallicBlue rounded-md hover:bg-MetallicBlue hover:text-green px-14 py-1"
          pageClassName="border border-opacity-20 rounded-sm hover:bg-green px-3 py-1 hidden"
          containerClassName={
            "pagination flex justify-center text-MetallicBlue mt-5 gap-1 mb-14 hover:cursor-pointer"
          }
          activeClassName={"active bg-green"}
        />
      </div>
    </>
  );
};

export default PrinciplesContainer;
