import React from "react";

const Hero = () => {
    return (
        <div class='bg-principle-hero bg-[cent_bottom_-26rem] bg-cover mb-5'>
            <div className='md:px-5 px-2 pb-5 bg-hero-gradient'>
                <div class='grid grid-cols-1 md:gap-16 md:px-6 md:py-8'></div>
                <div className='flex justify-start text-4xl pt-24 md:pt-24 md:p-0 md:pr-40 md:text-6xl  md:pl-28 xl:pt-60 xl:pb-10'>
                    <h1 className='pr-2 mb-0 font-bold'>
                        <span className='text-white'>
                            EthioPost All Locations
                        </span>
                    </h1>
                </div>

                {/* <div className='pb-16 text-base text-white md:text-xl md:pl-28 '>
                    <p className=''>
                        National ID Program of Ethiopia principles are based on
                        the endorsed 10 global principles of Identity for
                        Development (ID4D) which are stated as the principles of
                        identification for sustainable development in the
                        digital age. Explore each principle for more
                        information.
                    </p>
                </div> */}
            </div>
        </div>
    );
};

export default Hero;
