import React from "react";
import { useTranslation } from "react-i18next";
import copyImg from "../../../../assets/copy-icon.png";
import { useLocation } from "react-router-dom";

const Hero = () => {
    const { t } = useTranslation();
    const subPath = useLocation();
    return (
        <div class=' bg-resources-hero  bg-cover h-fit text-white bg-bottom'>
            <div class=' bg-news-gradient'>
                <div className='flex  md:justify-start md:px-0 px-8 md:mx-32 pt-[50%] md:pt-[16%] md:pb-16 text-5xl md:text-8xl items-center  pb-8'>
                    <h1 className='mb-0 font-extrabold text-white md:pr-2 '>
                        {t("nid")}
                    </h1>
                    <h1 className='mb-0 ml-2 font text-green '>{t("temp")} </h1>
                </div>
            </div>
        </div>
    );
};

export default Hero;
