import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { send } from "emailjs-com";

const Body = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    const { t } = useTranslation();
    const [toSend, setToSend] = useState({
        name: "",
        email: "",
        comments: "",
    });
    const [emailSent, setEmailSent] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();

        // axios.post('http://localhost:5000/send-email', {from,to,subject,message})
        //   .then(response => {
        //     console.log(response.data);
        //   })
        //   .catch(error => {
        //     console.log(error);
        //   });
        send("service_m23zees", "template_95bm4jn", toSend, "Ul0geNHTaIto046vz")
            .then((response) => {
                setEmailSent(true);
            })
            .catch((err) => {});
    };
    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };

    return (
        <>
            {isDesktopOrLaptop ? (
                <div className='flex flex-row justify-center gap-10 mx-36 '>
                    <div className=''>{/* put locations map here */}</div>
                    <div className='flex flex-col w-[50%] mt-20 rounded-md gap-10'>
                        <div className=''>
                            <h2 className='bg-grey text-xl rounded-t-md px-16 text-MetallicBlue font-bold'>
                                {t("contactus-body-title1")}
                            </h2>
                            <div className='rounded-md px-16 bg-grey brightness-105 text-MetallicBlue rounded-b-md space-y-3 py-3'>
                                <div className='flex'>
                                    <h2 className='font-bold mr-2'>
                                        {t("footer-Address")}:
                                    </h2>
                                    {t("address")}
                                </div>
                                <div className='flex'>
                                    <h2 className='font-bold mr-4'>
                                        {" "}
                                        {t("contactus-body-labelPhone")}:{" "}
                                    </h2>
                                    {t("Phone")}
                                </div>
                                <div className='flex'>
                                    <h2 className='font-bold mr-6'>
                                        {" "}
                                        {t("email")}:{" "}
                                    </h2>
                                    {t("Email")}
                                </div>
                            </div>
                        </div>

                        <div className='bg-grey text-MetallicBlue rounded-md font-medium'>
                            <h2 className='px-16 text-xl font-bold py-2'>
                                {t("contactus-body-workinghours")}
                            </h2>
                            <div className='bg-grey brightness-105 pt-4 pb-10 rounded-b-md'>
                                <h2 className='flex justify-between px-16'>
                                    <h2>{t("contactus-body-day1")}:</h2>{" "}
                                    {t("contactus-body-workinghours-time")}
                                </h2>
                                <div class='border-b-2 border-dark-grey opacity-10 mx-16'></div>
                                <h2 className='flex justify-between px-16'>
                                    <h2>{t("contactus-body-day2")}:</h2>{" "}
                                    {t("contactus-body-workinghours-time")}
                                </h2>
                                <div class='border-b-2 border-dark-grey opacity-10 mx-16'></div>
                                <h2 className='flex justify-between px-16'>
                                    <h2>{t("contactus-body-day3")}:</h2>
                                    {t("contactus-body-workinghours-time")}
                                </h2>
                                <div class='border-b-2 border-dark-grey opacity-10 mx-16'></div>
                                <h2 className='flex justify-between px-16'>
                                    <h2>{t("contactus-body-day4")}:</h2>{" "}
                                    {t("contactus-body-workinghours-time")}
                                </h2>
                                <div class='border-b-2 border-dark-grey opacity-10 mx-16'></div>
                                <h2 className='flex justify-between px-16'>
                                    <h2>{t("contactus-body-day5")}:</h2>{" "}
                                    {t("contactus-body-workinghours-time")}
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col w-[50%] gap-4 mt-14 mb-20'>
                        <div className='text-MetallicBlue'>
                            <h2 className='text-4xl font-bold'>
                                {t("contactus-form-title")}
                            </h2>
                            <h2 className='text-xl'>
                                {t("contactus-form-subtitle")}
                            </h2>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className='flex flex-col brightness-105 gap-2'>
                                <h2 className='opacity-60 font-medium'>
                                    {t("contactus-body-label1")}
                                </h2>
                                <input
                                    className='bg-grey py-2 rounded-md pl-3'
                                    type='text'
                                    name='name'
                                    value={toSend.name}
                                    onChange={handleChange}
                                />
                                <h2 className='flex'>
                                    <h2 className='opacity-60 font-medium'>
                                        {t("contactus-body-label2")}
                                    </h2>{" "}
                                    <h3 className='text-darkred'>*</h3>
                                </h2>
                                <input
                                    className='bg-grey py-2 rounded-md pl-3'
                                    type='email'
                                    name='email'
                                    required
                                    value={toSend.email}
                                    onChange={handleChange}
                                />
                                <h2 className='flex'>
                                    <h2 className='opacity-60 font-medium'>
                                        {t("contactus-body-label3")}
                                    </h2>{" "}
                                    <h3 className='text-darkred'>*</h3>
                                </h2>
                                <textarea
                                    rows='2'
                                    cols='50'
                                    className='bg-grey pb-16 pt-2 rounded-md pl-3 text-start'
                                    type='textarea'
                                    name='comments'
                                    required
                                    value={toSend.comments}
                                    onChange={handleChange}
                                />
                            </div>
                            <button
                                type='submit'
                                class='inline-block mt-2 px-2 py-2 rounded-md w-[50%] lg:w-[27%] bg-[#21cfac] text-white font-normal text-lg leading-tight  rounded-base shadow-md hover:bg-[#083247]  hover:shadow-lg focus:bg-[#3a6c7d] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#304f55] active:shadow-lg transition duration-150 ease-in-out'
                            >
                                {t("footer-send-message")}
                            </button>
                        </form>
                        {emailSent && (
                            <div className='fixed z-50 inset-0 overflow-y-auto'>
                                <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                                    {/* background overlay */}
                                    <div className='fixed inset-0 transition-opacity'>
                                        <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
                                    </div>

                                    {/* popup */}
                                    <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
                                        <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                                            <div className='sm:flex sm:items-start'>
                                                <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10'>
                                                    <svg
                                                        className='h-6 w-6 text-green-600'
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        fill='none'
                                                        viewBox='0 0 24 24'
                                                        stroke='currentColor'
                                                        aria-hidden='true'
                                                    >
                                                        <path
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            strokeWidth={2}
                                                            d='M5 13l4 4L19 7'
                                                        />
                                                    </svg>
                                                </div>
                                                <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                                                    <div className='mt-2'>
                                                        <p className='text-xl text-gray-500 '>
                                                            {t(
                                                                "footer-notification-message"
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
                                            <button
                                                type='button'
                                                className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium bg-green text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm '
                                                onClick={() =>
                                                    setEmailSent(false)
                                                }
                                            >
                                                {t("footer-notification-close")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className='flex flex-col justify-center mx-10 '>
                    <div className=''>{/* put locations map here */}</div>
                    <div className='flex flex-col w-[100%] mt-10 rounded-md gap-8'>
                        <div className=''>
                            <h2 className='bg-grey text-xl rounded-t-md px-5 text-MetallicBlue font-bold'>
                                {t("contactus-body-title1")}
                            </h2>
                            <div className='rounded-md px-5 bg-grey brightness-105 text-MetallicBlue rounded-b-md space-y-3 py-3'>
                                <div className='flex'>
                                    <h2 className='font-bold mr-2'>
                                        {" "}
                                        {t("footer-Address")}:
                                    </h2>
                                    {t("address")}
                                </div>
                                <div className='flex'>
                                    <h2 className='font-bold mr-4'>
                                        {" "}
                                        {t("contactus-body-labelPhone")}:{" "}
                                    </h2>
                                    {t("Phone")}
                                </div>
                                <div className='flex'>
                                    <h2 className='font-bold mr-6'>
                                        {" "}
                                        {t("email")}:{" "}
                                    </h2>
                                    {t("Email")}
                                </div>
                            </div>
                        </div>

                        <div className='bg-grey text-MetallicBlue rounded-md'>
                            <h2 className='px-5 text-xl font-bold py-2'>
                                {t("contactus-body-workinghours")}
                            </h2>
                            <div className='bg-grey brightness-105 pt-4 pb-10 rounded-b-md'>
                                <h2 className='flex justify-between px-5'>
                                    <h2>{t("contactus-body-day1")}:</h2>{" "}
                                    {t("contactus-body-workinghours-time")}
                                </h2>
                                <div class='border-b border-dark-grey mx-5'></div>
                                <h2 className='flex justify-between px-5'>
                                    <h2>{t("contactus-body-day2")}:</h2>{" "}
                                    {t("contactus-body-workinghours-time")}
                                </h2>
                                <div class='border-b border-dark-grey mx-5'></div>
                                <h2 className='flex justify-between px-5'>
                                    <h2>{t("contactus-body-day3")}:</h2>
                                    {t("contactus-body-workinghours-time")}
                                </h2>
                                <div class='border-b border-dark-grey mx-5'></div>
                                <h2 className='flex justify-between px-5'>
                                    <h2>{t("contactus-body-day4")}:</h2>{" "}
                                    {t("contactus-body-workinghours-time")}
                                </h2>
                                <div class='border-b border-dark-grey mx-5'></div>
                                <h2 className='flex justify-between px-5'>
                                    <h2>{t("contactus-body-day5")}:</h2>{" "}
                                    {t("contactus-body-workinghours-time")}
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col w-[100%] gap-4 mt-14 mb-10'>
                        <div className='text-MetallicBlue'>
                            <h2 className='text-4xl font-bold'>
                                {t("contactus-form-title")}
                            </h2>
                            <h2 className='text-xl'>
                                {t("contactus-form-subtitle")}
                            </h2>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className='flex flex-col brightness-105 gap-2'>
                                <h2 className='opacity-60 font-medium'>
                                    {t("contactus-body-label1")}
                                </h2>
                                <input
                                    className='bg-grey py-2 rounded-md pl-2'
                                    type='text'
                                    value={toSend.name}
                                    onChange={handleChange}
                                />
                                <h2 className='flex'>
                                    <h2 className='opacity-60 font-medium'>
                                        {t("contactus-body-label2")}
                                    </h2>{" "}
                                    <h3 className='text-darkred'>*</h3>
                                </h2>
                                <input
                                    className='bg-grey py-2 rounded-md pl-2'
                                    type='email'
                                    value={toSend.email}
                                    onChange={handleChange}
                                />
                                <h2 className='flex'>
                                    <h2 className='opacity-60 font-medium'>
                                        {t("contactus-body-label3")}
                                    </h2>{" "}
                                    <h3 className='text-darkred'>*</h3>
                                </h2>
                                <textarea
                                    rows='2'
                                    cols='50'
                                    className='bg-grey pb-10 pt-2 rounded-md pl-2'
                                    type='text'
                                    value={toSend.comments}
                                    onChange={handleChange}
                                />
                            </div>
                            <button
                                type='submit'
                                class='inline-block mt-2 px-2 py-2 rounded-sm  w-[50%] lg:w-[20%] bg-[#21cfac] text-white font-normal text-md leading-tight  rounded-base shadow-md hover:bg-[#083247]  hover:shadow-lg focus:bg-[#3a6c7d] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#304f55] active:shadow-lg transition duration-150 ease-in-out'
                            >
                                {t("footer-send-message")}
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default Body;
