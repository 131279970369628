import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import eFayda from "../../../../assets/Efayda_new.jpg";

const Hero = () => {
    const { t } = useTranslation();

    const one = useMediaQuery({
        query: "(max-height: 300px)",
    });

    const TwoFiveZeroPercent = useMediaQuery({
        query: "(max-height: 400px)",
    });

    const TwoZeroZeroPercent = useMediaQuery({
        query: "(max-height: 500px)",
    });

    const OneSevenFivePercent = useMediaQuery({
        query: "(max-height: 600px)",
    });

    const OneFivePercent = useMediaQuery({
        query: "(max-height: 700px)",
    });

    const OneTwoFivePercent = useMediaQuery({
        query: "(max-height: 800px)",
    });

    const OneOneTenPercent = useMediaQuery({
        query: "(max-height: 900px)",
    });
    const OneOnezeroPercent = useMediaQuery({
        query: "(max-height: 1000px)",
    });
    const NinezeroPercent = useMediaQuery({
        query: "(max-height: 1100px)",
    });

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    return (
        <>
            {isDesktopOrLaptop ? (
                <>
                    <div className='relative w-full h'>
                        <div
                            className={`${
                                one
                                    ? "absolute top-[50%] left-[13%] transform translate[-50%, -50%] w-[20%] "
                                    : TwoFiveZeroPercent
                                    ? "absolute top-[100%] left-[13%] transform translate[-50%, -50%] w-[20%] "
                                    : TwoZeroZeroPercent
                                    ? "absolute top-[90%] left-[13%] transform translate[-50%, -50%] w-[20%] "
                                    : OneSevenFivePercent
                                    ? "absolute top-[80%] left-[13%] transform translate[-50%, -50%] w-[23%] "
                                    : OneFivePercent
                                    ? "absolute top-[70%] left-[13%] w-[23%] transform translate[-50%, -50%]"
                                    : OneTwoFivePercent
                                    ? "absolute w-[22%] top-[60%] left-[13%] transform translate[-50%, -50%]"
                                    : OneOneTenPercent
                                    ? "absolute w-[20%] top-[47%] left-[13%] transform translate[-50%, -50%] "
                                    : OneOnezeroPercent
                                    ? "absolute top-[47%] left-[13%] transform translate[-50%, -50%] w-[18%] "
                                    : NinezeroPercent
                                    ? "absolute top-[47%] left-[13%] transform translate[-50%, -50%] w-[16%] "
                                    : "absolute top-[45%] left-[13%] transform translate[-50%, -50%] w-[12%] "
                            }}`}
                        >
                            <img
                                className='flex w-full h-full object-cover  rounded-xl'
                                src={eFayda}
                                alt=''
                            />
                        </div>

                        {/* absolute flex w-[19%] top-[60%] left-[13%] */}

                        <header className='bg-qrscanner-hero bg-right-top bg-cover '>
                            <div className='h-full w-full'>
                                <div className='grid grid-cols-1 gap-16 px-6 py-40'></div>
                                <div
                                    className='flex justify-center pb-10 text-8xl'
                                    style={{ paddingLeft: "20%" }}
                                >
                                    <div className='flex pr-2 mb-0 text-MetallicBlue  space-x-4'>
                                        <div>
                                            {t("credentials-main-title-1")}
                                        </div>
                                        <div className='font-bold'>
                                            {" "}
                                            {t("credentials-main-title-2")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                    </div>
                    <div className='mx-auto fo ' style={{ paddingLeft: "43%" }}>
                        <h1 className='text-green pb-6 mt-10 text-6xl'>
                            {" "}
                            {t("credentials-body-title-1")}
                        </h1>
                        <p className='text-MetallicBlue text-3xl w-[80%] mb-4 pl-12'>
                            {t("credentials-body-1")}
                        </p>
                    </div>
                </>
            ) : (
                <header class='bg-qrscanner-hero bg-right-top bg-cover fo '>
                    {" "}
                    {/*enter and check --> scale-125*/}
                    <div className='bg-qrscanner-hero pt-6 h-full w-full'>
                        <div className='flex flex-col mx-6'>
                            <div className='flex flex-row justify-center pt-20 pb-16 font-medium'>
                                <h1 className='text-5xl pr-2  text-white'>
                                    {t("credentials-main-title-1")}
                                </h1>
                                <h1 className='text-5xl pr-2  text-white font-bold'>
                                    {t("credentials-main-title-2")}
                                </h1>
                            </div>
                        </div>
                    </div>
                </header>
            )}
        </>
    );
};

export default Hero;
