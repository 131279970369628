import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const languageInSession = localStorage.getItem("LanguageState")
const initialLanguage = languageInSession === null ? "en" : JSON.parse(languageInSession) || "en"
i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: initialLanguage,
  resources: {
    en: {
      translations: require('./locales/eng/translations.json')
    },
    amh: {
      translations: require('./locales/amh/translations.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'amh'];

export default i18n;
