import React, {useState} from "react";
import HeroLandingPage from "./components/hero/heroLandingPage";
import { useMediaQuery } from "react-responsive";
import Body from "./components/body/body";
import BodyFaqs from "./components/body/bodyMobile";
import SmallFooter from "../../components/footer/smallFooter";
import { useEffect } from "react";

import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFaqList } from "../../redux/actions";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";

// import { Card } from "react-bootstrap";

const Singlefaq = ({}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const location = useLocation();
  const url = location.pathname;

  // const { faqs } = location.state;
  // const faqList = useSelector(getFaq);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // const timer = setTimeout(() => {
    //   setIsLoading(false);
    // }, 10000);

    // Group the dispatch calls based on their functionality
    const dataDispatches = [dispatch(getFaqList())];

    // Execute the dispatch calls in parallel
    Promise.all(dataDispatches)
      .then(() => {
        setIsLoading(false);
        // Do something after all the dispatch calls have completed
      })
      .catch((error) => {
        // Handle any errors that occur during the dispatch calls
      });

    // return () => {
    //   clearTimeout(timer);
    // };
  }, [dispatch]);

  function faq_Number() {
    const number = parseInt(url.slice(4));

    return number;
  }

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <HeroLandingPage index={faq_Number()} />

          {isDesktopOrLaptop ? (
            <Body index={faq_Number() } />
          ) : (
            <BodyFaqs index={faq_Number() } />
          )}

          <SmallFooter />
        </>
      )}
    </>
  );
};
export default Singlefaq;
