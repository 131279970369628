import { allLocationList } from "./allLocationConfig";
const ProcurementTable = () => {
    const tableHead = [
        "no",
        "Location in Amharic",
        "Location in English",
        "Region",
        // "Category",
        // "Estimated RFP Publishing Time",
        // "Procurement Approach/Competition",
        // "Selection Methods",
        // "Evaluation",
    ];
    return (
        <table>
            <thead className='w-full font-semibold text-lg border border-black'>
                <tr>
                    {tableHead.map((head) => (
                        <td className='border px-2 border-black'>{head}</td>
                    ))}
                </tr>
            </thead>
            <tbody className='border border-black text-center sm:text-left'>
                {allLocationList.map((row, index) => (
                    <tr>
                        {/* <td className='border px-2 border-black'>{row.S_No}</td> */}
                        <td className='border px-2 border-black'>
                            {index + 1}
                        </td>
                        <td className='border px-2 border-black'>
                            {row.branch_name_amahric}
                        </td>
                        <td className='border px-2 border-black'>
                            {row.branch_name_english}
                        </td>
                        <td className='border px-2 border-black'>
                            {row.region}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default ProcurementTable;
