import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

const DownloadLogos = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Construct the URL to the static file
    const staticBaseUrl = window.location.origin + '/static/';
    const fileName = 'logos.zip';
    const fileUrl = `${staticBaseUrl}${fileName}`;

    // Trigger the download
    window.location.href = fileUrl;

    // Optional: navigate away after initiating the download
    // navigate('/'); // Redirect to home page or any other page
  }, [navigate]);

  return null; // This component does not render anything
};

export default DownloadLogos;
