import React from "react";
import { useMediaQuery } from "react-responsive";

import TopBar from "../../../../components/navbar/navbar";
import Sidebar from "../../../../components/sidebar/sidebar";
import Hero from "./hero";
import Heromobile from "./heromoblie";

const HeroLandingPage = ({ citizen, forwardRef }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  return (
    <>
      <div ref={forwardRef}>
        {isDesktopOrLaptop ? (
          <TopBar viewState={citizen ? "citizen" : "stakeholder"} />
        ) : (
          <Sidebar />
        )}
        {isDesktopOrLaptop ? <Hero citizen={citizen} /> : <Heromobile />}
      </div>
    </>
  );
};

export default HeroLandingPage;
