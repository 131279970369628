import React from "react";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import { useState } from "react";
import HeroLandingPage from "./components/hero/heroLandingPage";
import SmallFooter from "../../components/footer/smallFooter";
import Faydamobile from "./components/faydamobile";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getPartnersList } from "../../redux/actions";

function Credentials() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const [citizen, setCitizen] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // const timer = setTimeout(() => {
    //   setIsLoading(false);
    // }, 10000);

    // Group the dispatch calls based on their functionality
    const dataDispatches = [dispatch(getPartnersList())];

    // Execute the dispatch calls in parallel
    Promise.all(dataDispatches)
      .then(() => {
        setIsLoading(false);
        // Do something after all the dispatch calls have completed
      })
      .catch((error) => {
        // Handle any errors that occur during the dispatch calls
      });

    // return () => {
    //   clearTimeout(timer);
    // };
  }, [dispatch]);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <HeroLandingPage citizen={citizen} />
          <Faydamobile />
          <SmallFooter />
        </>
      )}
    </>
  );
}

export default Credentials;
