import { useState, createContext } from "react";
export const ViewContext = createContext();

export const ViewContextProvider = ({children})=> {
    const[view, setView] = useState('Citizen');

    return(
        <ViewContext.Provider value={{view, setView}}>

            {children}
        </ViewContext.Provider> 

    )
    }