import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import React from "react";
import i18next from "i18next";
import { FaChevronRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import { getCareer } from "../../../../redux/selectors";
import TextTruncate from "react-text-truncate";
// const categories = [
//   "Technical Department",
//   "Planning and Evaluation",
//   "Program Department",
//   "Communications & Knowledge Management",
//   "Final, Procurement and Operations",
//   "Specifications",
// ];
const categories = [
    {
        en: "Technical Department",
        amh: "የቴክኒክ ክፍል",
    },
    {
        en: "Planning and Evaluation",
        amh: "እቅድ እና ግምገማ",
    },
    {
        en: "Program Department",
        amh: "ፕሮግራም ክፍል",
    },
    {
        en: "Communications & Knowledge Management",
        amh: "ኮሚኒኬሽን (ዕውቀት አስተዳደር)",
    },
    {
        en: "Final, Procurement and Operations",
        amh: "ግዥ እና አሰራር አንዳንድ ዝርዝር ጉዳዮች",
    },
];

export default function Main({ mobileEvent, sortBy, searchBy }) {
    const list = useSelector(getCareer);
    const [selectedJob, setSelectedJob] = useState(null);
    const [sortedList, setSortedList] = useState(null);
    const { t } = useTranslation();
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    const abc = [{ title: "asd" }, { title: "hfg" }];
    const [Event, setEvent] = useState("All");
    useEffect(() => {
        const modifiedList = [...list];
        sortBy === "latest" ? modifiedList : modifiedList.reverse(); // this sorts the list latest and oldest
        setSortedList(modifiedList);
    }, [sortBy, searchBy]);

    useEffect(() => {
        searchSortedList();
    }, [searchBy]);
    const searchSortedList = useCallback(() => {
        setSortedList(
            list?.filter(
                (item) =>
                    item.title.toLowerCase().includes(searchBy) ||
                    item.title_amh.toLowerCase().includes(searchBy)
            )
        );
    }, [searchBy]);
    console.log("modifiedList", sortedList);

    const [activeButtonIndex, setActiveButtonIndex] = useState(0);
    const mailtoHref =
        "mailto:communications@id.et?subject=SendMail&body=Description";
    return (
        <>
            {isDesktopOrLaptop ? (
                <div className='grid grid-cols-5 gap-3 mx-24 '>
                    <div className='border-r pr-10 my-10 mr-5 space-y-3'>
                        <div className='flex content-center px-3 py-2 justify-center text-2xl font-semibold'>
                            <h3>{t("career-main-categories")}</h3>
                            <div className='ml-auto mt-2'></div>
                        </div>

                        <div
                            onClick={() => {
                                setActiveButtonIndex(-1);
                                setEvent("All");
                            }}
                            className={`${
                                activeButtonIndex === -1 ? "bg-darkgreen" : ""
                            } 
                flex content-center px-3 py-2 justify-center text-lg hover:bg-darkgreen rounded-md hover:cursor-pointer group`}
                        >
                            <h3>{t("career-main-all")}</h3>
                            <div className=' ml-auto mt-2'>
                                <FaChevronRight
                                    className={`${
                                        activeButtonIndex === -1
                                            ? "text-white"
                                            : "text-transparent"
                                    } w-4 h-4 group-hover:text-white`}
                                />
                            </div>
                        </div>
                        {categories.map((category, index) => (
                            <div
                                onClick={() => {
                                    setActiveButtonIndex(index);
                                    setEvent(category.en);
                                }}
                                className={`${
                                    activeButtonIndex === index
                                        ? "bg-darkgreen"
                                        : ""
                                } 
                    flex content-center px-3 py-2 justify-center text-lg hover:bg-darkgreen rounded-md hover:cursor-pointer group`}
                            >
                                <h3>
                                    {i18next.language === "en" && category.en}{" "}
                                    {i18next.language === "amh" && category.amh}
                                </h3>
                                <div className=' ml-auto mt-2'>
                                    <FaChevronRight
                                        className={`${
                                            activeButtonIndex === 1
                                                ? "text-white"
                                                : "text-transparent"
                                        } w-4 h-4 group-hover:text-white`}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='py-10 col-span-2 cursor-pointer'>
                        {sortedList?.reduce((acc, listItem, key) => {
                            if (
                                Event === listItem.department ||
                                Event === "All"
                            ) {
                                acc.push(
                                    <>
                                        <div
                                            className='flex flex-col border border-red-500 my-4 p-5 rounded-lg'
                                            onClick={() =>
                                                setSelectedJob(listItem)
                                            }
                                        >
                                            <div className='flex'>
                                                <div
                                                    className='flex w-[100%] font-bold text-xl border-b-2 pb-2'
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            i18next.language ===
                                                            "en"
                                                                ? listItem.title
                                                                : i18next.language ===
                                                                  "amh"
                                                                ? listItem.title_amh
                                                                : "",
                                                    }}
                                                />

                                                <div className='flex justify-end'>
                                                    <h2
                                                        className={`${
                                                            listItem.status ===
                                                            "open"
                                                                ? ` bg-green`
                                                                : "bg-darkred"
                                                        } text-white p-2 px-4 mt-2 flex justify-end`}
                                                    >
                                                        {listItem.status
                                                            .split(" ")
                                                            .map(
                                                                (word) =>
                                                                    word
                                                                        .charAt(
                                                                            0
                                                                        )
                                                                        .toUpperCase() +
                                                                    word.slice(
                                                                        1
                                                                    )
                                                            )
                                                            .join(" ")}
                                                    </h2>
                                                </div>
                                            </div>
                                            <div className='py-5'>
                                                <p>
                                                    <div
                                                        className='line-clamp-3'
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                i18next.language ===
                                                                "en"
                                                                    ? listItem?.description
                                                                    : i18next.language ===
                                                                      "amh"
                                                                    ? listItem?.description_amh
                                                                    : "",
                                                        }}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            return acc;
                        }, [])}
                        {sortedList?.length === 0 && (
                            <p>No job openings found</p>
                        )}
                    </div>
                    {(selectedJob != null &&
                        selectedJob?.department === Event) ||
                    (selectedJob != null && Event == "All") ? (
                        <div className='my-14 border border-red-500 col-span-2 px-14 py-6 rounded-md'>
                            <h1 className='border-b pb-3 font-bold text-xl  '>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            i18next.language === "en" &&
                                            selectedJob?.title
                                                ? selectedJob.title
                                                : i18next.language === "amh" &&
                                                  selectedJob?.title_amh
                                                ? selectedJob.title_amh
                                                : "",
                                    }}
                                />
                            </h1>
                            <div className='flex flex-row py-4'>
                                <p>{t("career-main-category")}</p>

                                <p className='pl-12'>
                                    {" "}
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                i18next.language === "en" &&
                                                selectedJob?.category
                                                    ? selectedJob.category
                                                    : i18next.language ===
                                                          "amh" &&
                                                      selectedJob?.category_amh
                                                    ? selectedJob.category_amh
                                                    : "",
                                        }}
                                    />
                                </p>
                            </div>

                            <div className='flex flex-row py-4'>
                                <p>{t("career-main-qualification")} </p>
                                <p className='pl-7'>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                i18next.language === "en" &&
                                                selectedJob?.level
                                                    ? selectedJob.level
                                                    : i18next.language ===
                                                          "amh" &&
                                                      selectedJob?.level_amh
                                                    ? selectedJob.level_amh
                                                    : "",
                                        }}
                                    />
                                </p>
                            </div>

                            <div className='flex flex-row py-4'>
                                <p>{t("career-main-quantity")} </p>
                                <p className='pl-16'>
                                    {(i18next.language === "en" &&
                                        selectedJob?.quantity) ||
                                        (i18next.language === "amh" &&
                                            selectedJob?.quantity_amh)}
                                </p>
                            </div>
                            <p className='py-4'>
                                {" "}
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            i18next.language === "en"
                                                ? selectedJob?.description
                                                : i18next.language === "amh"
                                                ? selectedJob?.description_amh
                                                : "",
                                    }}
                                />
                            </p>
                            {/* <button
                                className='w-full bg-MetallicBlue p-2 rounded-md text-white hover:bg-lightblue active:bg-green disabled:opacity-50 disabled:cursor-not-allowed'
                                disabled={
                                    selectedJob != null &&
                                    selectedJob.status === "Closed"
                                }
                                onClick={() =>
                                    (window.location.href = mailtoHref)
                                }
                            >
                                {t("career-main-apply")}
                            </button> */}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <div className='grid grid-cols-2 mx-5 gap-3 '>
                    <div className='col-span-2 cursor-pointer'>
                        {sortedList?.reduce((acc, listItem, key) => {
                            if (
                                (mobileEvent === listItem.department ||
                                    mobileEvent === "All") &&
                                acc.length < 3
                            ) {
                                acc.push(
                                    <>
                                        <div
                                            className='border border-red-500 my-4 p-5 rounded-lg'
                                            onClick={() =>
                                                setSelectedJob(listItem)
                                            }
                                        >
                                            <div className='flex w-full'>
                                                <h1 className='flex font-bold text-xl border-b-2 pb-2 pr-5'>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                i18next.language ===
                                                                "en"
                                                                    ? listItem.title
                                                                    : i18next.language ===
                                                                      "amh"
                                                                    ? listItem.title_amh
                                                                    : "",
                                                        }}
                                                    />
                                                </h1>
                                                <h2
                                                    className={`${
                                                        listItem.status ===
                                                        "open"
                                                            ? ` bg-green`
                                                            : "bg-darkred"
                                                    } text-white p-2 px-4 mt-2 flex justify-end`}
                                                >
                                                    {listItem.status
                                                        .split(" ")
                                                        .map(
                                                            (word) =>
                                                                word
                                                                    .charAt(0)
                                                                    .toUpperCase() +
                                                                word.slice(1)
                                                        )
                                                        .join(" ")}
                                                </h2>
                                            </div>

                                            <p className='py-5 text-justify leading-snug'>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            i18next.language ===
                                                            "en"
                                                                ? listItem.description
                                                                : i18next.language ===
                                                                  "amh"
                                                                ? listItem.description_amh
                                                                : "",
                                                    }}
                                                />
                                            </p>
                                            {/* <button
                                                className='w-full bg-MetallicBlue text-white p-2 rounded-md'
                                                onClick={() =>
                                                    (window.location.href =
                                                        mailtoHref)
                                                }
                                            >
                                                {t("career-main-apply")}
                                            </button> */}
                                        </div>
                                    </>
                                );
                            }
                            return acc;
                        }, [])}

                        {sortedList?.length === 0 && (
                            <p>No job openings found</p>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
