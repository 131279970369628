import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const Hero = () => {
    const { t } = useTranslation();

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    return (
        <>
            {isDesktopOrLaptop ? (
                <header class='bg-principle bg-right-top bg-cover '>
                    <div className='w-full h-full bg-about-hero'>
                        <div class='grid grid-cols-1 gap-16 px-6 py-24 bg-geen '></div>
                        <div className='flex justify-start pb-60 text-8xl pl-28 bg-darkorane '>
                            <div className='flex pr-2 mb-0 space-x-4 font-bold text-white '>
                                <div>{t("about-title")}</div>
                                <div className='text-green'>
                                    {t("about-title2")}
                                </div>
                                <div>{t("about-title3")}</div>
                            </div>
                            {/* <h3 className="font-medium pb-7  text-green"> We </h3>
              <h1 className='mb-0 ml-2 text-white ' > are </h1> */}
                        </div>
                    </div>
                </header>
            ) : (
                <header class='bg-principle bg-right-top bg-cover'>
                    {" "}
                    {/*enter and check --> scale-125*/}
                    <div className='w-full h-full bg-about-hero'>
                        <div className='flex flex-col'>
                            <div className='flex flex-row justify-center pt-20 pb-2 text-3xl font-medium'>
                                <h1 className='pr-2 text-white  '>
                                    {t("nid")}
                                </h1>
                                <h1 className='text-white '>
                                    {t("about-history")}
                                </h1>
                            </div>
                            <hr className='w-[10%] bg-green border-green border-2 self-center mb-5' />
                        </div>
                    </div>
                </header>
            )}
        </>
    );
};

export default Hero;
