import React, { useState, useEffect } from "react";
//import './news.css';
import { useMediaQuery } from "react-responsive";
import i18next from "i18next";
import TextTruncate from "react-text-truncate";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { FaFacebookF, FaTiktok } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { RiLinkedinFill, RiTelegramFill, RiYoutubeFill } from "react-icons/ri";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import webinar from "../../../../assets/webinar.jpg";
import tender from "../../../../assets/tender.jpg";
import press from "../../../../assets/press.jpg";
import { useSelector } from "react-redux";
import { getNews } from "../../../../redux/selectors";
import TwitterBox from "../../../../components/twitterBox";

function isEnglish(str) {
    // Regular expression to match English characters
    const regex = /^[ -~\u2018\u2019\u201c\u201d]+$/;
    console.log(
        regex.test(
            "Ethiopia’s Digital ID Proclamation No.1284/2023 made into law by the House of Peoples’ Representatives."
        )
    );

    // Test if the string matches the regex
    return regex.test(str);
}

function isHTML(str) {
    const htmlRegex = /<[a-z][\s\S]*>/i;
    return htmlRegex.test(str);
}

function TruncatedHTML(prop) {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(prop, "text/html");
    const paragraphs = htmlDoc.querySelectorAll("p");

    // Get the first <p> element from the DOM tree

    const secondParagraphText = paragraphs[0]?.textContent.trim();

    // Get the text content of the first <p> element

    // Output the text content of the first <p> element
    return secondParagraphText;
}

const Updates = () => {
    const newslist = useSelector(getNews);

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    const newsItem = newslist.find(
        (newslistItem) =>
            (i18next.language === "en" &&
                newslistItem.title !== "" &&
                isEnglish(newslistItem.title)) ||
            (i18next.language === "amh" &&
                newslistItem.title_amh !== "" &&
                !isEnglish(newslistItem.title_amh))
    );

    const [page, setPage] = useState("All");
    const [index, setIndex] = useState(newslist.indexOf(newsItem));
    const [currentPage, setCurrentPage] = useState(0);
    let itemsPerPage;
    if (isDesktopOrLaptop) {
        itemsPerPage = 4;
    } else {
        itemsPerPage = 5;
    }

    function handlePageClick({ selected }) {
        setCurrentPage(selected);
    }

    const offset = currentPage * itemsPerPage;
    const sortedData = newslist
        .filter((newslistItem) => {
            return page === newslistItem.type || page === "All";
        })
        .sort((a, b) => {
            // your custom sorting logic goes here
        });
    const pageCount = Math.ceil(sortedData.length / itemsPerPage);
    const paginatedItems = sortedData.slice(offset, offset + itemsPerPage);
    const { t } = useTranslation();

    const navigate = useNavigate();

    const handlePageChange = (page) => {
        setPage(page);
        if (page !== "All") {
            navigate(`/${page.toLowerCase()}`);
        }

        for (let i = 0; i < newslist.length; i++) {
            if ((page === "All") & (newslist[i].type === "News")) {
                setIndex(i);
                break;
            } else if (newslist[i].type === page) {
                setIndex(i);
                break;
            } else {
                setIndex(-1);
                continue;
            }
        }
    };
    useEffect(() => {
        if (window.location.pathname === "/press") {
            setPage("Press");
            setTimeout(() => {
                const pressElement = document.getElementById("updates");
                if (pressElement) {
                    pressElement.scrollIntoView({ behavior: "smooth" });
                }
            }, 100);
        }
    }, []);
    return (
        <>
            {newslist.length > 0 ? (
                <>
                    {isDesktopOrLaptop ? (
                        <div className='xl:inline  h-1/6'>
                            <div
                                class={`grid gap-10 grid-cols-4 mx-28 mt-12 mb-6 h-1/6`}
                            >
                                {/*  Title for news /Press  container  */}
                                <div className='w-full col-span-3'>
                                    <div id='updates'>
                                        <div className='flex'>
                                            <h1 className='px-16 text-2xl text-center text-white whitespace-nowrap bg-MetallicBlue rounded-t-md'>
                                                <span className='flex items-center justify-center h-full rounded-sm '>
                                                    {t("media-trending")}
                                                </span>
                                            </h1>
                                            <ul className='flex items-center w-full px-8 text-2xl '>
                                                <div className='grid w-full place-items-center '>
                                                    <button
                                                        className={`${
                                                            page === "All"
                                                                ? "underline decoration-[#00f7c7] decoration-4 decoration-offset-10"
                                                                : ""
                                                        }content-center px-6 hover:cursor-pointer hover:underline  `}
                                                        onClick={() =>
                                                            handlePageChange(
                                                                "All"
                                                            )
                                                        }
                                                    >
                                                        {t("media-all")}
                                                    </button>
                                                </div>

                                                <div className='grid w-full place-items-center'>
                                                    <button
                                                        className={` ${
                                                            page === "News"
                                                                ? "underline decoration-[#00f7c7] decoration-4 decoration-offset-10"
                                                                : ""
                                                        }content-center px-6 hover:cursor-pointer hover:underline  `}
                                                        onClick={() =>
                                                            handlePageChange(
                                                                "News"
                                                            )
                                                        }
                                                    >
                                                        {t("media-news")}
                                                    </button>
                                                </div>

                                                <div className='grid w-full place-items-center '>
                                                    <button
                                                        className={` ${
                                                            page === "Press"
                                                                ? "underline decoration-[#00f7c7] decoration-4 decoration-offset-10"
                                                                : ""
                                                        }content-center px-6 hover:cursor-pointer hover:underline `}
                                                        onClick={() =>
                                                            handlePageChange(
                                                                "Press"
                                                            )
                                                        }
                                                    >
                                                        {t("media-press")}
                                                    </button>
                                                </div>

                                                <div className='grid w-full place-items-center'>
                                                    <button
                                                        className={`${
                                                            page === "Tender"
                                                                ? "underline decoration-[#00f7c7] decoration-4 decoration-offset-10"
                                                                : ""
                                                        } content-center px-6 hover:cursor-pointer hover:underline `}
                                                        onClick={() =>
                                                            handlePageChange(
                                                                "Tender"
                                                            )
                                                        }
                                                    >
                                                        {t("media-tender")}
                                                    </button>
                                                </div>
                                                <div className='grid w-full place-items-center'>
                                                    <button
                                                        className={`${
                                                            page === "Webinar"
                                                                ? "underline decoration-[#00f7c7] decoration-4 decoration-offset-10"
                                                                : ""
                                                        } content-center px-6 hover:cursor-pointer hover:underline `}
                                                        onClick={() =>
                                                            handlePageChange(
                                                                "Webinar"
                                                            )
                                                        }
                                                    >
                                                        {t("media-webinar")}
                                                    </button>
                                                </div>
                                            </ul>
                                        </div>

                                        <div>
                                            <hr className='w-[100%] border-b-1 border-MetallicBlue font-light' />
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-2 gap-5 pt-10'>
                                        {index != -1 ? (
                                            <div
                                                onClick={() =>
                                                    navigate(
                                                        `/updates/${
                                                            newslist[index].type
                                                        }/${newslist[
                                                            index
                                                        ].title.replace(
                                                            / /g,
                                                            "-"
                                                        )}`,
                                                        {
                                                            state: {
                                                                news: newslist[
                                                                    index
                                                                ],
                                                            },
                                                        }
                                                    )
                                                }
                                                className=' cursor-pointer text-darkblue font-medium'
                                            >
                                                <div className='w-full h-96'>
                                                    {page === "Tender" && (
                                                        <div className='absolute -mt-[53px] flex group-hover:scale-105 z-10 duration-300'>
                                                            <h2
                                                                className={`${
                                                                    newslist[
                                                                        index
                                                                    ].status ===
                                                                    "Closed"
                                                                        ? ` bg-darkred`
                                                                        : // : newslist.status === "Open"
                                                                          // ? ` bg-green`
                                                                          "hidden"
                                                                } text-white justify-center text-2xl w-28 p-2 px-4 mt-2 flex`}
                                                            >
                                                                {
                                                                    newslist[
                                                                        index
                                                                    ].status
                                                                }
                                                            </h2>
                                                        </div>
                                                    )}
                                                    <img
                                                        className='object-cover w-full h-full duration-300 rounded-lg group-hover:scale-105 transform-all'
                                                        src={
                                                            process.env
                                                                .REACT_APP_BASE_URL +
                                                                newslist[index]
                                                                    .image !==
                                                            ""
                                                                ? process.env
                                                                      .REACT_APP_BASE_URL +
                                                                  newslist[
                                                                      index
                                                                  ].image
                                                                : newslist[
                                                                      index
                                                                  ].type ===
                                                                  "Webinar"
                                                                ? webinar
                                                                : newslist[
                                                                      index
                                                                  ].type ===
                                                                  "Press"
                                                                ? press
                                                                : newslist[
                                                                      index
                                                                  ].type ===
                                                                  "Tender"
                                                                ? tender
                                                                : i18next.language ===
                                                                  "amh"
                                                                ? process.env
                                                                      .REACT_APP_BASE_URL +
                                                                  newslist[
                                                                      index
                                                                  ].image_amh
                                                                : process.env
                                                                      .REACT_APP_BASE_URL +
                                                                  newslist[
                                                                      index
                                                                  ].image
                                                        }
                                                        alt='news1'
                                                    />
                                                </div>

                                                <div className='mt-4 overflow-auto text-2xl font-semibold '>
                                                    <h1>
                                                        {i18next.language ===
                                                            "en" &&
                                                            newslist[index]
                                                                .title}{" "}
                                                        {i18next.language ===
                                                            "amh" &&
                                                            newslist[index]
                                                                .title_amh}{" "}
                                                    </h1>
                                                </div>
                                                <div className='mt-3 text-justify  h-[20%]  pr-3 shadow-sm'>
                                                    <p>
                                                        <TextTruncate
                                                            line={7}
                                                            element='span'
                                                            truncateText='…'
                                                            text={
                                                                isHTML(
                                                                    (i18next.language ===
                                                                        "en" &&
                                                                        newslist[
                                                                            index
                                                                        ]
                                                                            .description) ||
                                                                        (i18next.language ===
                                                                            "amh" &&
                                                                            newslist[
                                                                                index
                                                                            ]
                                                                                .description_amh)
                                                                )
                                                                    ? TruncatedHTML(
                                                                          (i18next.language ===
                                                                              "en" &&
                                                                              newslist[
                                                                                  index
                                                                              ]
                                                                                  .description) ||
                                                                              (i18next.language ===
                                                                                  "amh" &&
                                                                                  newslist[
                                                                                      index
                                                                                  ]
                                                                                      .description_amh)
                                                                      )
                                                                    : (i18next.language ===
                                                                          "en" &&
                                                                          newslist[
                                                                              index
                                                                          ]
                                                                              .description) ||
                                                                      (i18next.language ===
                                                                          "amh" &&
                                                                          newslist[
                                                                              index
                                                                          ]
                                                                              .description_amh)
                                                            }
                                                            textTruncateChild={
                                                                <Link
                                                                    to={`/updates/${
                                                                        newslist[
                                                                            index
                                                                        ].type
                                                                    }/${newslist[
                                                                        index
                                                                    ].title.replace(
                                                                        / /g,
                                                                        "-"
                                                                    )}`}
                                                                    state={{
                                                                        news: newslist[
                                                                            index
                                                                        ],
                                                                    }}
                                                                    className='font-bold text-MetallicBlue italic '
                                                                >
                                                                    {" "}
                                                                    Read More
                                                                    ...{" "}
                                                                </Link>
                                                            }
                                                        />
                                                    </p>
                                                </div>

                                                {/* <div className="bottom-0 flex mt-4 font-light ">
                <h3>{newslist[index].date}</h3>
              </div> */}
                                            </div>
                                        ) : (
                                            <div className=' cursor-pointer h-[50%]   text-darkblue font-medium '>
                                                <div className='w-full h-full '></div>
                                            </div>
                                        )}
                                        {/* News Update holder in mini form container */}
                                        <div
                                            class={`grid grid-rows-7 grid-cols-3 gap-y-6 gap-x-3  rounded-md h-[560px]  overflow-y-auto`}
                                        >
                                            {newslist.reduce(
                                                (acc, newslistItem, key) => {
                                                    if (
                                                        (page ===
                                                            newslistItem.type ||
                                                            page === "All") &&
                                                        ((i18next.language ===
                                                            "en" &&
                                                            newslistItem.title !==
                                                                "" &&
                                                            isEnglish(
                                                                newslistItem.title
                                                            )) ||
                                                            (i18next.language ===
                                                                "amh" &&
                                                                newslistItem.title_amh !==
                                                                    "" &&
                                                                !isEnglish(
                                                                    newslistItem.title_amh
                                                                )))
                                                    ) {
                                                        acc.push(
                                                            <>
                                                                <div
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/updates/${
                                                                                newslistItem.type
                                                                            }/${newslistItem.title.replace(
                                                                                / /g,
                                                                                "-"
                                                                            )}`,
                                                                            {
                                                                                state: {
                                                                                    news: newslistItem,
                                                                                },
                                                                            }
                                                                        )
                                                                    }
                                                                    class='cursor-pointer col-start-1 flex  justify-center  items-center '
                                                                >
                                                                    <img
                                                                        className='object-cover w-full h-[90%] duration-300 rounded-lg hover:scale-105 transform-all'
                                                                        src={
                                                                            process
                                                                                .env
                                                                                .REACT_APP_BASE_URL +
                                                                                newslistItem.image !==
                                                                            ""
                                                                                ? process
                                                                                      .env
                                                                                      .REACT_APP_BASE_URL +
                                                                                  newslistItem.image
                                                                                : newslistItem.type ===
                                                                                  "Webinar"
                                                                                ? webinar
                                                                                : newslistItem.type ===
                                                                                  "Press"
                                                                                ? press
                                                                                : newslistItem.type ===
                                                                                  "Tender"
                                                                                ? tender
                                                                                : i18next.language ===
                                                                                  "amh"
                                                                                ? process
                                                                                      .env
                                                                                      .REACT_APP_BASE_URL +
                                                                                  newslistItem.image_amh
                                                                                : process
                                                                                      .env
                                                                                      .REACT_APP_BASE_URL +
                                                                                  newslistItem.image
                                                                        }
                                                                        alt='news1'
                                                                    />
                                                                </div>
                                                                <div
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/updates/${
                                                                                newslistItem.type
                                                                            }/${newslistItem.title.replace(
                                                                                / /g,
                                                                                "-"
                                                                            )}`,
                                                                            {
                                                                                state: {
                                                                                    news: newslistItem,
                                                                                },
                                                                            }
                                                                        )
                                                                    }
                                                                    class='cursor-pointer relative col-span-2 '
                                                                >
                                                                    <div className='text-xl font-semibold leading-tight'>
                                                                        <h1>
                                                                            <TextTruncate
                                                                                line={
                                                                                    7
                                                                                }
                                                                                element='span'
                                                                                truncateText='…'
                                                                                text={
                                                                                    i18next.language ===
                                                                                    "en"
                                                                                        ? newslistItem.title
                                                                                        : i18next.language ===
                                                                                          "amh"
                                                                                        ? newslistItem.title_amh
                                                                                        : ""
                                                                                }

                                                                                // {i18next.language === "en" ? list[0].title:
                                                                                //       i18next.language === "amh" ? list[0].title_amh:""}
                                                                            />
                                                                        </h1>
                                                                    </div>
                                                                    <div className=' h-[50%] leading-tight text-justify  pr-3'>
                                                                        <p>
                                                                            <span className='font-bold text-lightblue'>
                                                                                {
                                                                                    newslistItem.date
                                                                                }
                                                                            </span>{" "}
                                                                            -{" "}
                                                                            <TextTruncate
                                                                                line={
                                                                                    2
                                                                                }
                                                                                element='span'
                                                                                truncateText='…'
                                                                                text={
                                                                                    isHTML(
                                                                                        (i18next.language ===
                                                                                            "en" &&
                                                                                            newslistItem.description) ||
                                                                                            (i18next.language ===
                                                                                                "amh" &&
                                                                                                newslistItem.description_amh)
                                                                                    )
                                                                                        ? TruncatedHTML(
                                                                                              (i18next.language ===
                                                                                                  "en" &&
                                                                                                  newslistItem.description) ||
                                                                                                  (i18next.language ===
                                                                                                      "amh" &&
                                                                                                      newslistItem.description_amh)
                                                                                          )
                                                                                        : (i18next.language ===
                                                                                              "en" &&
                                                                                              newslistItem.description) ||
                                                                                          (i18next.language ===
                                                                                              "amh" &&
                                                                                              newslistItem.description_amh)
                                                                                }
                                                                                textTruncateChild={
                                                                                    <Link
                                                                                        to={`/updates/${
                                                                                            newslistItem.type
                                                                                        }/${newslistItem.title.replace(
                                                                                            / /g,
                                                                                            "-"
                                                                                        )}`}
                                                                                        state={{
                                                                                            news: newslistItem,
                                                                                        }}
                                                                                        className='font-bold text-MetallicBlue italic'
                                                                                    >
                                                                                        {" "}
                                                                                        Read
                                                                                        More
                                                                                        ...{" "}
                                                                                    </Link>
                                                                                }
                                                                            />
                                                                        </p>
                                                                    </div>

                                                                    {/* <div className="absolute bottom-0 ">
                      <h3>{newslistItem.date}</h3>
                    </div> */}
                                                                </div>
                                                            </>
                                                        );
                                                    }
                                                    return acc;
                                                },
                                                []
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/*  Title for Staying connected  */}
                                <div className='w-full'>
                                    <div className='w-full'>
                                        <h1 className=' text-2xl text-white whitespace-nowrap bg-MetallicBlue text-center h-full w-fit px-2 flex justify-center items-center rounded-t-md'>
                                            {t("media-connected")}
                                        </h1>
                                        <hr className='w-full border-b-1 border-MetallicBlue' />
                                    </div>
                                    <div className='flex flex-col pt-10'>
                                        <div className='grid grid-cols-3 items-center justify-center py-1'>
                                            <div className='flex col-span-1'>
                                                <div className='p-1 text-3xl text-white rounded-lg bg-dodgerblue'>
                                                    <a
                                                        target='_blank'
                                                        href='https://www.facebook.com/IDethiopia/?ref=pages_you_manage'
                                                    >
                                                        <FaFacebookF />
                                                    </a>
                                                </div>
                                                <div className='px-2 text-2xl hidden xl:block'>
                                                    <p>Facebook</p>
                                                </div>
                                            </div>
                                            <div className='w-full h-full text-center col-span-1 col-start-3'>
                                                <a
                                                    target='_blank'
                                                    href='https://www.facebook.com/IDethiopia/?ref=pages_you_manage'
                                                >
                                                    <div className='border-2 text-center rounded-full hover:bg-MetallicBlue hover:text-white transform-all duration-200 '>
                                                        <span className=''>
                                                            Like{" "}
                                                        </span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>

                                        <div className='grid grid-cols-3 items-center justify-center py-1'>
                                            <div className='flex col-span-1'>
                                                <div className='p-1 text-3xl text-white rounded-lg bg-[black]'>
                                                    <a
                                                        target='_blank'
                                                        href='https://twitter.com/IDethiopia'
                                                    >
                                                        <FaXTwitter />
                                                    </a>
                                                </div>
                                                <div className='px-2 text-2xl hidden xl:block'>
                                                    <p>Twitter</p>
                                                </div>
                                            </div>
                                            <div className='w-full h-full text-center col-span-1 col-start-3'>
                                                <a
                                                    target='_blank'
                                                    href='https://twitter.com/IDethiopia'
                                                >
                                                    <div className='border-2 text-center rounded-full hover:bg-MetallicBlue hover:text-white transform-all duration-200 '>
                                                        <span className=''>
                                                            Follow{" "}
                                                        </span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>

                                        <div className='grid grid-cols-3 items-center justify-center py-1'>
                                            <div className='flex col-span-1'>
                                                <div className='p-1 text-3xl text-white rounded-lg bg-darkred'>
                                                    <a
                                                        target='_blank'
                                                        href='https://www.youtube.com/channel/UCf3SnLcgf5jzxhkeMTf2fRA'
                                                    >
                                                        <RiYoutubeFill />
                                                    </a>
                                                </div>
                                                <div className='px-2 text-2xl hidden xl:block'>
                                                    <p>Youtube</p>
                                                </div>
                                            </div>
                                            <div className='w-full h-full text-center col-span-1 col-start-3'>
                                                <a
                                                    target='_blank'
                                                    href='https://www.youtube.com/@IDethiopia'
                                                >
                                                    <div className='border-2 text-center rounded-full hover:bg-MetallicBlue hover:text-white transform-all duration-200 '>
                                                        <span className=''>
                                                            Subscribe{" "}
                                                        </span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>

                                        <div className='grid grid-cols-3 items-center justify-center py-1'>
                                            <div className='flex col-span-1'>
                                                <div className='p-1 text-3xl text-white rounded-lg bg-[#1f8bc1]'>
                                                    <a
                                                        target='_blank'
                                                        href='https://t.me/IDethiopia'
                                                    >
                                                        <RiTelegramFill />
                                                    </a>
                                                </div>
                                                <div className='px-2 text-2xl hidden xl:block'>
                                                    <p>Telegram</p>
                                                </div>
                                            </div>
                                            <div className='w-full h-full text-center col-span-1 col-start-3'>
                                                <a
                                                    target='_blank'
                                                    href='https://t.me/IDethiopia'
                                                >
                                                    <div className='border-2 text-center rounded-full hover:bg-MetallicBlue hover:text-white transform-all duration-200 '>
                                                        <span className=''>
                                                            Join{" "}
                                                        </span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>

                                        <div className='grid grid-cols-3 items-center justify-center py-1'>
                                            <div className='flex col-span-1'>
                                                <div className='p-1 text-3xl text-white rounded-lg bg-[#3464c3]'>
                                                    <a
                                                        target='_blank'
                                                        href='https://www.linkedin.com/company/idethiopia/'
                                                    >
                                                        <RiLinkedinFill />
                                                    </a>
                                                </div>
                                                <div className='px-2 text-2xl hidden xl:block'>
                                                    <p>LinkedIn</p>
                                                </div>
                                            </div>
                                            <div className='w-full h-full text-center col-span-1 col-start-3'>
                                                <a
                                                    target='_blank'
                                                    href='https://www.linkedin.com/company/idethiopia/'
                                                >
                                                    <div className='border-2 text-center rounded-full hover:bg-MetallicBlue hover:text-white transform-all duration-200 '>
                                                        <span className=''>
                                                            Connect{" "}
                                                        </span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className='grid grid-cols-3 items-center justify-center py-1'>
                                            <div className='flex col-span-1'>
                                                <div className='p-1 text-3xl text-white rounded-lg bg-[black]'>
                                                    <a
                                                        target='_blank'
                                                        href='https://www.tiktok.com/@idethiopia'
                                                    >
                                                        <FaTiktok />
                                                    </a>
                                                </div>
                                                <div className='px-2 text-2xl hidden xl:block'>
                                                    <p>Tiktok </p>
                                                </div>
                                            </div>
                                            <div className='w-full h-full text-center col-span-1 col-start-3'>
                                                <a
                                                    target='_blank'
                                                    href='https://www.tiktok.com/@idethiopia'
                                                >
                                                    <div className='border-2 text-center rounded-full hover:bg-MetallicBlue hover:text-white transform-all duration-200 '>
                                                        <span className=''>
                                                            Follow
                                                        </span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>{" "}
                                {/*  Single Major News container  */}
                                {/* Social Media and Tweet Embed container */}
                            </div>
                        </div>
                    ) : (
                        //******************** */ Mobie VERSION ******************
                        <>
                            <div className='pb-5 '>
                                <div class='flex px-4  bg-grey pt-5 justify-center items-end '>
                                    <ul className=' flex items-end  justify-center w-full h-full gap-2 text-[120%] place-items-end  '>
                                        <div className=''>
                                            <button
                                                className={`${
                                                    page === "All"
                                                        ? " bg-white  px-4 "
                                                        : ""
                                                }content-center hover:cursor-pointer px-4  rounded-t-sm`}
                                                onClick={() =>
                                                    handlePageChange("All")
                                                }
                                            >
                                                {t("media-all")}
                                            </button>
                                        </div>

                                        <div className=''>
                                            <button
                                                className={` ${
                                                    page === "News"
                                                        ? " bg-white "
                                                        : ""
                                                }content-center px-2 hover:cursor-pointer rounded-t-sm `}
                                                onClick={() =>
                                                    handlePageChange("News")
                                                }
                                            >
                                                {t("media-news")}
                                            </button>
                                        </div>

                                        <div className=''>
                                            <button
                                                className={` ${
                                                    page === "Press"
                                                        ? " bg-white "
                                                        : ""
                                                }content-center px-2 hover:cursor-pointer  rounded-t-sm`}
                                                onClick={() =>
                                                    handlePageChange("Press")
                                                }
                                            >
                                                {t("media-press")}
                                            </button>
                                        </div>

                                        <div className=''>
                                            <button
                                                className={`${
                                                    page === "Tender"
                                                        ? " bg-white "
                                                        : ""
                                                } content-center px-3  rounded-t-sm`}
                                                onClick={() =>
                                                    handlePageChange("Tender")
                                                }
                                            >
                                                {t("media-tender")}
                                            </button>
                                        </div>

                                        <div className=''>
                                            <button
                                                className={`${
                                                    page === "Webinar"
                                                        ? " bg-white "
                                                        : ""
                                                } content-center px-3 hover:cursor-pointer  rounded-t-sm`}
                                                onClick={() =>
                                                    handlePageChange("Webinar")
                                                }
                                            >
                                                {t("media-webinar")}
                                            </button>
                                        </div>
                                    </ul>
                                </div>

                                <div className='mx-5 my-5 overflow-auto h-15'>
                                    {paginatedItems.reduce(
                                        (acc, newslistItem, key) => {
                                            if (
                                                (page === newslistItem.type ||
                                                    page === "All") &&
                                                ((i18next.language === "en" &&
                                                    newslistItem.title !== "" &&
                                                    isEnglish(
                                                        newslistItem.title
                                                    )) ||
                                                    (i18next.language ===
                                                        "amh" &&
                                                        newslistItem.title_amh !==
                                                            "" &&
                                                        !isEnglish(
                                                            newslistItem.title_amh
                                                        )))
                                            ) {
                                                acc.push(
                                                    <div
                                                        onClick={() =>
                                                            navigate(
                                                                `/updates/${
                                                                    newslistItem.type
                                                                }/${newslistItem.title.replace(
                                                                    / /g,
                                                                    "-"
                                                                )} `,
                                                                {
                                                                    state: {
                                                                        news: newslistItem,
                                                                    },
                                                                }
                                                            )
                                                        }
                                                        className='flex items-center gap-3 pb-5 font-medium cursor-pointer text-darkblue'
                                                    >
                                                        <div className='grid items-center grid-cols-3 gap-3'>
                                                            {/* IMAGE INPUT FOR THE UPDATES SECTION  */}
                                                            <div className='w-full h-full'>
                                                                {" "}
                                                                <img
                                                                    className='object-cover w-full h-full duration-300 rounded-sm hover:scale-105 transform-all'
                                                                    src={
                                                                        process
                                                                            .env
                                                                            .REACT_APP_BASE_URL +
                                                                            newslistItem.image !==
                                                                        ""
                                                                            ? process
                                                                                  .env
                                                                                  .REACT_APP_BASE_URL +
                                                                              newslistItem.image
                                                                            : newslistItem.type ===
                                                                              "Webinar"
                                                                            ? webinar
                                                                            : newslistItem.type ===
                                                                              "Press"
                                                                            ? press
                                                                            : newslistItem.type ===
                                                                              "Tender"
                                                                            ? tender
                                                                            : i18next.language ===
                                                                              "amh"
                                                                            ? process
                                                                                  .env
                                                                                  .REACT_APP_BASE_URL +
                                                                              newslistItem.image_amh
                                                                            : process
                                                                                  .env
                                                                                  .REACT_APP_BASE_URL +
                                                                              newslistItem.image
                                                                    }
                                                                    alt='news1'
                                                                />
                                                            </div>

                                                            {/* TITLE AND DESCRIPTION INPUT FOR THE UPDATES SECTION  */}
                                                            <div className='flex flex-col items-start w-full col-span-2 '>
                                                                {/* TITLE INPUT FOR THE UPDATES SECTION  */}
                                                                <div className=' overflow-auto text-sm font-semibold leading-tight  w-[] h-[] pb-2 '>
                                                                    <h1>
                                                                        <TextTruncate
                                                                            line={
                                                                                3
                                                                            }
                                                                            element='span'
                                                                            truncateText='…'
                                                                            text={
                                                                                i18next.language ===
                                                                                "en"
                                                                                    ? newslistItem.title
                                                                                    : i18next.language ===
                                                                                      "amh"
                                                                                    ? newslistItem.title_amh
                                                                                    : ""
                                                                            }
                                                                            // text={
                                                                            //   (i18next.language === "en" &&
                                                                            //     newslist[index].description) ||
                                                                            //   (i18next.language === "amh" &&
                                                                            //     newslist[index].description_amh)
                                                                            // }
                                                                            textTruncateChild={
                                                                                <Link
                                                                                    to={`/updates/${
                                                                                        newslistItem.type
                                                                                    }/${newslistItem.title.replace(
                                                                                        / /g,
                                                                                        "-"
                                                                                    )} `}
                                                                                    state={{
                                                                                        news: newslistItem,
                                                                                    }}
                                                                                    className='font-bold text-MetallicBlue '
                                                                                >
                                                                                    {" "}
                                                                                </Link>
                                                                            }
                                                                        />
                                                                    </h1>
                                                                </div>

                                                                {/* DESCRIPTION INPUT FOR THE UPDATES SECTION  */}
                                                                <div className='mt-1 text-xs leading-tight text-justify shadow-sm'>
                                                                    <p>
                                                                        <TextTruncate
                                                                            line={
                                                                                3
                                                                            }
                                                                            element='span'
                                                                            truncateText='…'
                                                                            text={
                                                                                isHTML(
                                                                                    (i18next.language ===
                                                                                        "en" &&
                                                                                        newslistItem.description) ||
                                                                                        (i18next.language ===
                                                                                            "amh" &&
                                                                                            newslistItem.description_amh)
                                                                                )
                                                                                    ? TruncatedHTML(
                                                                                          (i18next.language ===
                                                                                              "en" &&
                                                                                              newslistItem.description) ||
                                                                                              (i18next.language ===
                                                                                                  "amh" &&
                                                                                                  newslistItem.description_amh)
                                                                                      )
                                                                                    : (i18next.language ===
                                                                                          "en" &&
                                                                                          newslistItem.description) ||
                                                                                      (i18next.language ===
                                                                                          "amh" &&
                                                                                          newslistItem.description_amh)
                                                                            }
                                                                            // text={
                                                                            //   (i18next.language === "en" &&
                                                                            //     newslist[index].description) ||
                                                                            //   (i18next.language === "amh" &&
                                                                            //     newslist[index].description_amh)
                                                                            // }
                                                                            textTruncateChild={
                                                                                <Link
                                                                                    to={`/updates/${
                                                                                        newslistItem.type
                                                                                    }/${newslistItem.title.replace(
                                                                                        / /g,
                                                                                        "-"
                                                                                    )} `}
                                                                                    state={{
                                                                                        news: newslistItem,
                                                                                    }}
                                                                                    className='font-bold text-MetallicBlue '
                                                                                >
                                                                                    {" "}
                                                                                </Link>
                                                                            }
                                                                        />
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            return acc;
                                        },
                                        []
                                    )}
                                </div>
                                <div className='w-full gap-6 '>
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        previousClassName='border border-opacity-20 rounded-sm hover:bg-green px-3 py-1'
                                        nextClassName='border border-opacity-20 rounded-sm hover:bg-green px-3 py-1'
                                        pageClassName='border border-opacity-20 rounded-sm hover:bg-green px-3 py-1'
                                        containerClassName={
                                            "pagination flex justify-center text-MetallicBlue  gap-3"
                                        }
                                        activeClassName={"active bg-green"}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </>
            ) : (
                <></>
            )}
        </>
    );
};
export default Updates;
