export const procurementTableConfig = [
    {
        S_No: 1,
        Contract_Title: [
            "Enrollment service: to carry out digital ID registration across the country. (Four or above successful Super Agents, each of which will enroll 2.5M + residents) id.et / enrollment",
            "id.et/enrollment",
        ],
        Category: "Non-consulting services",
        EstimatedTime: "January 2024",
        ProcurementApproach: "Local",
        SelectionMethods: "RFP",
        Evaluation: "Rated Criteria",
    },
    {
        S_No: 2,
        Contract_Title: [
            "Supply and Installation of Datacenter Infrastructure and a Systems Integrator for Fayda and associated Services",
            "id.et/services ",
        ],
        Category: "Goods",
        EstimatedTime: "January 2024",
        ProcurementApproach: "Open/International",
        SelectionMethods: "RFP",
        Evaluation: "Rated Criteria",
    },
    {
        S_No: 3,
        Contract_Title: [
            "Supply and installation of ITS software and License, plus tech support,training",
            "id.et/services ",
        ],
        Category: "Information technology system",
        EstimatedTime: "February 2024 ",
        ProcurementApproach: "Open/International",
        SelectionMethods: "RFP",
        Evaluation: "Rated Criteria",
    },
    {
        S_No: 4,
        Contract_Title: ["Pre-personalized cards", "id.et/credentials"],
        Category: "Goods",
        EstimatedTime: "February 2024 ",
        ProcurementApproach: "Open/International",
        SelectionMethods: "RFP",
        Evaluation: "Rated Criteria",
    },
    {
        S_No: 5,
        Contract_Title: [
            "Biometrics Registration kits",
            "id.et/nidspecifications",
        ],
        Category: "Goods",
        EstimatedTime: "February 2024 ",
        ProcurementApproach: "Open/International",
        SelectionMethods: "RFP",
        Evaluation: "Rated Criteria",
    },
    {
        S_No: 6,
        Contract_Title: [
            "Consultancy services to provide technical assistance to NIDP",
            "id.gov.et/tender",
        ],
        Category: "Consulting services ",
        EstimatedTime: "June 2024  ",
        ProcurementApproach: "Open/International",
        SelectionMethods: "RFP",
        Evaluation: "Rated Criteria",
    },
    {
        S_No: 7,
        Contract_Title: [
            "Consultancy service for call center Management service",
            "id.gov.et/decks",
            "id.gov.et/media",
        ],
        Category: "Consultancy",
        EstimatedTime: "June 2024",
        ProcurementApproach: "Local",
        SelectionMethods: "QCBS",
        Evaluation: "Rated Criteria",
    },
    {
        S_No: 8,
        Contract_Title: [
            "Communications firm contract for market research, planning, and development of content for TV / Radio / Digital content communications",
        ],
        Category: "Consulting services ",
        EstimatedTime: "June 2024",
        ProcurementApproach: "Local",
        SelectionMethods: "QCBS",
        Evaluation: "Rated Criteria",
    },
];
