import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Imgen from "../../../../assets/registration process english and amharic-02.png";
import Imgam from "../../../../assets/registration process english and amharic-01.png";

import i18next from "i18next";

import ExpandableContent from "react-expandable-content";
import { useSelector } from "react-redux";
import { getEnrollment } from "../../../../redux/selectors";

const titleDesc = {
    en: "The demographic data and biometric information is collected, solely for the purpose of uniquely identifying the individual. With the principle of collecting minimal data, the following data have been identified as the crucial information needed. Demographic data required are Full name, Date of Birth, Gender, Nationality, and Current Address. Biometric data required are fingerprint, iris, and face Photo. Other fields such as Phone Number and email address are optionally used to deliver FIN  (Fayda Identification Number)",
    amh: "ሙሉ ስም",
};

const Registration_and_informed = () => {
    const [IsOpen, setIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState("");
    const [currentCaption, setCurrentCaption] = useState("");
    const imgRef = useRef(null);
    const list = useSelector(getEnrollment);
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    const { t } = useTranslation();

    function toggleModal(imageUrl, caption) {
        setIsOpen(!IsOpen);
        setCurrentImage(imageUrl);
        setCurrentCaption(caption);
    }

    return (
        <>
            {IsOpen && (
                <div
                    className='fixed top-0 left-0 w-full h-full py-20 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-all duration-500'
                    onClick={toggleModal}
                >
                    {currentCaption === "Data Collection Principles" ? (
                        <img
                            ref={imgRef}
                            src={currentImage}
                            className='h-full object-contain bg-white'
                            alt='Modal content'
                        />
                    ) : (
                        <img
                            ref={imgRef}
                            src={process.env.REACT_APP_BASE_URL + currentImage}
                            className='h-full object-contain'
                            alt='Modal content'
                        />
                    )}

                    <div
                        id='modal-content'
                        className='absolute bottom-20 py-20 px-5 '
                    >
                        <p className='text-white text-3xl z-50'>
                            {currentCaption}
                        </p>
                    </div>
                </div>
            )}
            {isDesktopOrLaptop ? (
                <div className='grid grid-cols-2 gap-2 mt-16 text-xl  md:mt-32'>
                    <div className='relative col-span-2 px-16 bottom-10'>
                        <h2 className='px-5 text-4xl text-white bg-MetallicBlue w-fit bottom-5'>
                            {t("enrollment-registration")}
                        </h2>
                        <hr className='mb-10' />
                        <div className='flex gap-2 ml-10'>
                            {i18next.language === "en" ? (
                                <img
                                    onClick={() => {
                                        toggleModal(
                                            Imgen,
                                            "Data Collection Principles"
                                        );
                                    }}
                                    src={Imgen}
                                    className='w-[820px] h-[440px] mx-4'
                                />
                            ) : i18next.language === "amh" ? (
                                <img
                                    src={Imgam}
                                    className='w-[820px] h-[440px]  mx-4'
                                />
                            ) : null}{" "}
                            {/* <ExpandableContent
                maxHeight={450}
                collapseText={"Read less"}
                expandText={"Read more"}
                className="bg-MetallicBlue text-white absolute left-0 hover:scale-105"
                showIcon={false}
              > */}
                            <div className='flex flex-col'>
                                <h1 className='text-4xl font-semibold  ml-5 text-MetallicBlue '>
                                    Data Collection Principles
                                </h1>
                                <hr className='w-[18%] border-b-4 mb-5 ml-5 border-green' />
                                <p
                                    className='ml-4 text-2xl text-justify'
                                    // dangerouslySetInnerHTML={{
                                    //   __html: t("enrollment-registration-text1"),
                                    // }}
                                >
                                    The demographic data and biometric
                                    information is collected, solely for the
                                    purpose of uniquely identifying the
                                    individual. With the principle of collecting
                                    minimal data, the following data have been
                                    identified as the crucial information
                                    needed. Demographic data required are Full
                                    name, Date of Birth, Gender, Nationality,
                                    and Current Address. Biometric data required
                                    are fingerprint, iris, and face Photo. Other
                                    fields such as Phone Number and email
                                    address are optionally used to deliver FIN
                                    (Fayda Identification Number)
                                </p>
                            </div>
                            {/* </ExpandableContent> */}
                        </div>
                    </div>

                    {list.map((listItem, index = 0) => (
                        <div
                            className={`${
                                index % 2
                                    ? ""
                                    : " bg-Fayda-on-digital-copy px-20 bg-cover w-full py-10"
                            } mb-16 col-span-2`}
                        >
                            <div className='grid w-full grid-cols-2 px-40 py-10'>
                                <div
                                    className={`${
                                        index % 2
                                            ? "col-start-1 row-start-1 pr-10"
                                            : "col-start-2 row-start-1"
                                    } w-[78%] px-12 h-96 overflow-hidden`}
                                >
                                    <img
                                        onClick={() => {
                                            toggleModal(
                                                listItem.image_url,
                                                listItem.title
                                            );
                                        }}
                                        src={
                                            process.env.REACT_APP_BASE_URL +
                                            listItem.image_url
                                        }
                                        alt='enrollment'
                                        className={`${
                                            index % 2
                                                ? "object-right-top"
                                                : !index % 2
                                                ? ""
                                                : ""
                                        } w-full h-full object-cover px-2 overflow-hidden scale-110`}
                                    />
                                </div>
                                <div
                                    className={`${
                                        index % 2
                                            ? "mx-7"
                                            : !index % 2
                                            ? "ml-7 mr-5"
                                            : ""
                                    } row-start-1`}
                                >
                                    <div className='mb-3'>
                                        <div className='pb-4'>
                                            <h2
                                                className={`${
                                                    index % 2
                                                        ? "text-MetallicBlue"
                                                        : "text-white"
                                                } relative text-4xl font-semibold mb-1`}
                                            >
                                                {i18next.language === "en"
                                                    ? listItem.title
                                                    : i18next.language === "amh"
                                                    ? listItem.title_amh
                                                    : ""}
                                            </h2>
                                            <hr className='w-[18%] border-b-4 mb-5 border-green' />
                                        </div>

                                        <p className='text-2xl mx-2'>
                                            <ExpandableContent
                                                maxHeight={280}
                                                collapseText={"Read less"}
                                                expandText={"Read more"}
                                                className='bg-MetallicBlue text-white hover:scale-105'
                                                showIcon={false}
                                            >
                                                <div
                                                    className={`${
                                                        index % 2
                                                            ? "text-MetallicBlue"
                                                            : "text-white"
                                                    } "bg-MetallicBlue  hover:scale-105"`}
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            i18next.language ===
                                                            "en"
                                                                ? listItem.text
                                                                : i18next.language ===
                                                                  "amh"
                                                                ? listItem.text_amh
                                                                : "",
                                                    }}
                                                />
                                            </ExpandableContent>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    {/************************* mobile view ************************ */}
                    <div className='grid grid-cols-1 gap-2 mt-16 text-xl  md:mt-32'>
                        <div className='relative col-span-2 px-5 text-justify md:px-20 bottom-10'>
                            <h2 className='px-5 text-base text-white bg-MetallicBlue w-fit bottom-5'>
                                {t("enrollment-registration")}
                            </h2>
                            <hr className='mb-10' />
                            <div className='px-1 text-lg mb-5'>
                                {i18next.language === "en" ? (
                                    <img src={Imgen} className='mb-4' />
                                ) : i18next.language === "amh" ? (
                                    <img src={Imgam} className='mb-4' />
                                ) : null}
                                <ExpandableContent
                                    maxHeight={280}
                                    collapseText={"Read less"}
                                    expandText={"Read more"}
                                    className='bg-MetallicBlue text-white hover:scale-105'
                                    showIcon={false}
                                >
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                "enrollment-registration-text1"
                                            ),
                                        }}
                                    ></div>
                                </ExpandableContent>
                            </div>
                        </div>

                        {list.map((listItem, index = 0) => (
                            <div
                                className={`${
                                    index % 2
                                        ? ""
                                        : "py-8 bg-Fayda-on-digital-copy"
                                } mb-16 text-justify col-span-2`}
                            >
                                <div className='flex flex-col px-5'>
                                    <div className='w-80 self-center'>
                                        <img
                                            src={
                                                process.env.REACT_APP_BASE_URL +
                                                listItem.image_url
                                            }
                                            alt='enrollment'
                                            className='flex px-2 overflow-hidden mb-5'
                                        />
                                    </div>

                                    <div className='row-start-2'>
                                        <div className='flex flex-col items-center justify-center '>
                                            <h2
                                                className={`${
                                                    index % 2
                                                        ? "text-MetallicBlue"
                                                        : "text-white"
                                                } flex justify-center text-xl font-semibold`}
                                            >
                                                <p>
                                                    {i18next.language ===
                                                        "en" && listItem.title}
                                                    {i18next.language ===
                                                        "amh" &&
                                                        listItem.title_amh}
                                                </p>
                                            </h2>
                                            <hr className='border-2 w-[18%] border-green mb-3' />
                                        </div>
                                        <p className='text-base px-5 text-justify'>
                                            <ExpandableContent
                                                darkMode={index % 2 === 0}
                                                maxHeight={200}
                                                collapseText={"Read less"}
                                                expandText={"Read more"}
                                                className='bg-MetallicBlue text-white hover:scale-105'
                                                showIcon={false}
                                            >
                                                <div
                                                    className={`${
                                                        index % 2 === 0
                                                            ? "text-white"
                                                            : "text-MetallicBlue"
                                                    } text-justify`}
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            i18next.language ===
                                                            "en"
                                                                ? listItem.text
                                                                : i18next.language ===
                                                                  "amh"
                                                                ? listItem.text_amh
                                                                : "",
                                                    }}
                                                />
                                            </ExpandableContent>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </>
    );
};

export default Registration_and_informed;
