import React from "react";
import { useMediaQuery } from "react-responsive";

import TopBar from "../../../../components/navbar/navbar";
import Sidebar from "../../../../components/sidebar/sidebar";
import Hero from "./hero";
import Heromobile from "./heromoblie";

const HeroLandingPage = ({ citizen, handleSearchFaq }) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    return (
        <>
            {isDesktopOrLaptop ? (
                <TopBar
                    viewState={citizen ? "citizen" : "stakeholder"}
                    handleSearchFaq={handleSearchFaq}
                />
            ) : (
                <Sidebar />
            )}
            {isDesktopOrLaptop ? (
                <Hero citizen={citizen} handleSearchFaq={handleSearchFaq} />
            ) : (
                <Heromobile handleSearchFaq={handleSearchFaq} />
            )}
        </>
    );
};

export default HeroLandingPage;
