import React from "react";
import { useMediaQuery } from 'react-responsive';


import TopBar from "../../../../components/navbar/navbar";
import Hero from "./hero";


const HeroLandingPage = ({citizen, title, news}) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  return (
   <>
  {isDesktopOrLaptop&&<TopBar viewState={citizen?"citizen":"stakeholder"}/> } 
   <Hero citizen={citizen} title={title} news={news}/>
   </>
  );
};

export default HeroLandingPage;