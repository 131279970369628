import React, { useEffect, useState } from "react";
import i18next from "i18next";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css"; // import the styles
const FaqAccordion = ({ faqs }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    return (
        <Accordion className='my-accordion' allowZeroExpanded>
            {faqs.map((faq, key) => (
                <AccordionItem key={key}>
                    <AccordionItemButton>
                        <div className='flex items-center justify-between'>
                            <div className='flex mr-5 pr-10'>
                                <p
                                    className='font-medium text-lg text-1xl '
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            i18next.language === "en"
                                                ? faq.question
                                                : i18next.language === "amh"
                                                ? faq.question_amh
                                                : "",
                                    }}
                                />
                            </div>

                            <div
                                className='w-6 h-6 rounded-full bg-gray-300 transform transition-transform flex items-center justify-center'
                                style={{ flexDirection: "row-reverse" }}
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 24 24'
                                    fill='currentColor'
                                    className={`w-4 h-4 mx-auto my-auto ${
                                        activeIndex === key ? "rotate-180" : ""
                                    }`}
                                >
                                    <path d='M7 10l5 5 5-5z' />
                                </svg>
                            </div>
                        </div>
                    </AccordionItemButton>
                    <AccordionItemPanel>
                        <p className='text-gray-600'>
                            {i18next.language === "en"
                                ? faq.answer
                                : i18next.language === "amh"
                                ? faq.answer_amh
                                : ""}
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
            ))}
        </Accordion>
    );
};

export default FaqAccordion;
