import React, { useState} from "react";
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import procPdf from '../../assets/DigitalIDproclamation1284.pdf'

import axios from "axios";

function ProcPdf() {
  const [numPages, setNumPages] = useState(null);
 

  const downloadPdf = () => {
    axios({
      url: bioPdf,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Biometrics-registration-kit-specification-March-1.pdf');
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <div>
      <div
        style={{
          border: '1px solid rgba(0, 0, 0, 0.3)',
          height: '200px',
         
        }}
      >
         <button   className="h-10  text-xl mt-4 text-white px-20  rounded-2xl " >Click Here to Download the PDF</button>
        <Viewer  fileUrl={procPdf} />
      </div>
     
    </div>
  );
}

export default ProcPdf;