import aboutReducer from "./aboutReducer";
import newsReducer from "./newsReducer";
import resourcesReducer from "./resourcesReducer";
import mediaReducer from "./mediaReducers";
import enrollmentReducer from "./enrollmentReducer";
import strategiesReducer from "./strategiesReducer"
import careerReducer from "./careerReducer";
import locationsReducer from "./locationReducer";
import principlesReducer from "./principlesReducer";
import blogReducer from "./blogReducer"


import { combineReducers } from "redux";

const rootReducer = combineReducers({
    aboutReducer,
    careerReducer,
    newsReducer,
    resourcesReducer,
    mediaReducer,
    enrollmentReducer,
    strategiesReducer,
    locationsReducer,
    principlesReducer,
    blogReducer

})

export default rootReducer;