import React from "react";
import { useMediaQuery } from 'react-responsive';

import TopBar from "../../../../components/navbar/navbar";
import Sidebar from "../../../../components/sidebar/sidebar";
import Hero from "./hero";
import IdDayHero from "./IdDayHero";



const HeroLandingPage = ({citizen, heroHome}) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  return (
      <>
          {/* <div className="relative "> */}

          {isDesktopOrLaptop ? (
              <TopBar viewState={citizen ? "citizen" : "stakeholder"} />
          ) : (
              <Sidebar />
          )}
          {/* <div className="absolute bottom-0 h-full"> */}
          <Hero citizen={citizen} heroHome={heroHome}/>
          {/* <IdDayHero heroHome={heroHome} /> */}

          {/* </div>
   </div> */}
      </>
  );
};

export default HeroLandingPage;