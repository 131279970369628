import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './i18n/config'
import '../src/i18n/config';
import { Provider } from 'react-redux';
import store from './redux/store';
import { ViewContextProvider } from './providers/viewContext';
import { ParallaxProvider } from 'react-scroll-parallax';

if (process.env.NODE_ENV === 'production') {

  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    
  <React.StrictMode>
  <ParallaxProvider>
   <ViewContextProvider >
  <Provider store={store}>
    
    <App />
    </Provider>
    </ViewContextProvider>
    </ParallaxProvider>
  </React.StrictMode>
  </BrowserRouter>
);
