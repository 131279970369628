import React from "react";
import { useTranslation } from "react-i18next";

function HeroSection() {
    const { t } = useTranslation();
    return (
        <div className=''>
            <header class='bg-footer bg-cover bg-center '>
                <div class='grid grid-cols-1 gap-16 px-6 py-14 '></div>
                <div className='p-6 items-center justify-center flex '>
                    <div className='flex flex-col mb-12'>
                        <h1 className='lg:px-24 py-2 text-5xl text-green  font-medium'>
                            <span className='text-white px-2 mr-1 font-medium'>
                                {t("auth-title")}
                            </span>
                        </h1>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default HeroSection;
