import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Iframe from "react-iframe";
import i18next from "i18next";
import title from "../../../../assets/full logo-fayda podcast-01.png";
import { useSelector } from "react-redux";
import { getGalleries } from "../../../../redux/selectors";
import play from "../../../../assets/play icon-01.png";
import play1 from "../../../../assets/play icon-01 copy.png";
import thumbnail from "../../../../assets/youthumb_kPxljHrJREs_maxres.png";
import { Link, NavLink, useNavigate } from "react-router-dom";

const Podcast = () => {
    const one = useMediaQuery({
        query: "(max-height: 300px)",
    });

    const TwoFiveZeroPercent = useMediaQuery({
        query: "(max-height: 400px)",
    });

    const TwoZeroZeroPercent = useMediaQuery({
        query: "(max-height: 500px)",
    });

    const OneSevenFivePercent = useMediaQuery({
        query: "(max-height: 600px)",
    });

    const OneFivePercent = useMediaQuery({
        query: "(max-height: 700px)",
    });

    const OneTwoFivePercent = useMediaQuery({
        query: "(max-height: 800px)",
    });

    const OneOneTenPercent = useMediaQuery({
        query: "(max-height: 900px)",
    });

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    const navigate = useNavigate();

    const { t } = useTranslation();

    // const list = useSelector(getGalleries);

    const [VidOpen, setVidOpen] = useState(false);
    const [vidModal, setVidModal] = useState("");

    function toggleVidModal(vidUrl) {
        setVidOpen(!VidOpen);
        setVidModal(vidUrl);
    }

    // const latestPodCast = list.filter((item) => item.type === "Podcast");

    return (
        <>
            {isDesktopOrLaptop ? (
                <>
                    {VidOpen && (
                        <div
                            className='fixed top-0 left-0 w-full h-full py-20 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-all duration-500'
                            onClick={toggleVidModal}
                        >
                            <div className='relative w-full h-full px-2 flex hover:cursor-pointer hover:scale-105 transition-all duration-500 items-center justify-center'>
                                <Iframe
                                    url={vidModal}
                                    id=''
                                    className='flex w-[80%] h-full'
                                    display='block'
                                    position='relative'
                                    frameborder='0'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                    allowfullscreen
                                />
                            </div>
                        </div>
                    )}

                    <div class='bg-landing-podcast-hero flex w-full h-full bg-center bg-cover'>
                        <div className='flex w-full h-full bg-landing-podcast-gradient pt-32 pb-12'>
                            <div className='flex flex-col w-[50%] h-full'>
                                <div className='flex w-full h-full text-8xl pl-32 mb-5 justify-start'>
                                    <p className='text-start text-white font-extralight'>
                                        {t("podcast-text")}
                                    </p>
                                    <p className='text-start text-[#D9C460] font-semibold pl-5'>
                                        {t("podcast-text2")}
                                    </p>
                                </div>
                                <div className='flex w-full h-full text-2xl pl-32 justify-start'>
                                    <p className='text-justify tracking-tight pl-3 pr-5 text-white font-extralight'>
                                        {t("podcast-subtext")}
                                    </p>
                                </div>
                                <div className='pl-28 flex w-full h-full pt-[3%] py-2'>
                                    <img
                                        src={title}
                                        alt=''
                                        className='w-[60%]'
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col w-[50%] h-full'>
                                <div className='flex items-center w-full h-full px-20 mb-10'>
                                    <div
                                        className='flex relative w-full h-full hover:scale-105 hover:cursor-pointer transition-all duration-500 ease-in-out'
                                        onClick={() =>
                                            toggleVidModal(
                                                "https://www.youtube.com/embed/kPxljHrJREs?si=VCnzGjr4HV3DN1KG"
                                            )
                                        }
                                    >
                                        <div className='rounded-t-lg flex justify-center w-full h-full'>
                                            <img
                                                src={thumbnail}
                                                alt='video'
                                                className={` ${
                                                    one
                                                        ? "object-[center_-2px] h-[250px]"
                                                        : TwoFiveZeroPercent
                                                        ? "object-[center_-2px] h-[250px]"
                                                        : TwoZeroZeroPercent
                                                        ? "object-[center_-2px] h-[250px]"
                                                        : OneSevenFivePercent
                                                        ? "object-[center_-25px] h-[250px]"
                                                        : OneFivePercent
                                                        ? "object-[center_-35px] h-[250px]"
                                                        : OneTwoFivePercent
                                                        ? "object-[center_-56px] h-[300px]"
                                                        : OneOneTenPercent
                                                        ? "object-[center_-62px] h-[380px]"
                                                        : "object-[center_-55px] h-[420px]"
                                                } flex w-[90%] rounded-lg object-cover`}
                                            />
                                        </div>
                                        <div className='absolute inset-0 flex items-center justify-center'>
                                            <img
                                                src={play}
                                                alt='play img'
                                                className='object-cover scale-[0.15]'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className='flex w-full h-full justify-center items-end hover:cursor-pointer hover:scale-105 transition-all duration-500 ease-in-out group'
                                    onClick={() => navigate("/podcast")}
                                >
                                    <div className='rounded-full bg-gray-500 relative'>
                                        <div className='absolute w-[80px] h-[80px] top-1/2 left-1/2 transform -translate-x-[49px] -translate-y-[64px]'>
                                            {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-[70px] h-[70px]"
                viewBox="0 0 24 24"
                fill="none"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="10"
                  fill="orange"
                  className="-z-20"
                />
                <polygon
                  points="10 8 16 12 10 16 10 8"
                  fill="#235D71"
                  className="z-20"
                />
              </svg> */}
                                            <img
                                                src={play1}
                                                alt='play1 img'
                                                className='object-cover'
                                            />
                                        </div>
                                    </div>
                                    <div className='bg-green group-hover:bg-MetallicBlue hover:text-green transition-all duration-500 ease-in-out py-2 px-12 text-center text-2xl font-semibold text-white rounded-full'>
                                        <p>{t("podcast-button")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    {VidOpen && (
                        <div
                            className='fixed top-0 left-0 w-full h-full py-20 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-all duration-500'
                            onClick={toggleVidModal}
                        >
                            <div className='relative w-full h-80 px-2 flex hover:cursor-pointer hover:scale-105 transition-all duration-500 '>
                                <Iframe
                                    url={vidModal}
                                    id=''
                                    className='flex w-[100%] h-full '
                                    display='block'
                                    position='relative'
                                    frameborder='0'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                    allowfullscreen
                                />
                            </div>
                        </div>
                    )}
                    <div class='bg-landing-podcast-hero flex w-full h-full bg-center bg-cover'>
                        <div className='flex flex-col w-full h-full bg-landing-podcast-gradient pt-10 pb-12'>
                            <div className='flex flex-col w-full h-full'>
                                <div className='flex w-full h-full text-6xl pl-5 mb-5 justify-start'>
                                    <p className='text-start text-white font-extralight'>
                                        {t("podcast-text")}
                                    </p>
                                    <p className='text-start text-[#D9C460] font-semibold pl-5'>
                                        {t("podcast-text2")}
                                    </p>
                                </div>
                                <div className='flex w-full h-full text-xl pl-5 justify-start'>
                                    <p className='text-justify tracking-tighter leading-tight pl-3 pr-5 text-white font-extralight'>
                                        {t("podcast-subtext")}
                                    </p>
                                </div>
                                <div className='pl-20 flex w-full h-full pt-[3%] py-2'>
                                    <img
                                        src={title}
                                        alt=''
                                        className='w-[70%]'
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col w-full h-full'>
                                <div className='flex items-center w-full h-full px-5 mb-5'>
                                    <div
                                        className='flex relative w-full h-full'
                                        onClick={() =>
                                            toggleVidModal(
                                                "https://www.youtube.com/embed/OpVu0XY4iDs?si=pDGbZbKH95J1t9M1"
                                            )
                                        }
                                    >
                                        <div className=' flex justify-center w-full h-full rounded-md'>
                                            <img
                                                src={thumbnail}
                                                alt='video'
                                                className={` ${
                                                    one
                                                        ? "object-[center_-2px] h-[250px]"
                                                        : TwoFiveZeroPercent
                                                        ? "object-[center_-2px] h-[250px]"
                                                        : TwoZeroZeroPercent
                                                        ? "object-[center_-2px] h-[250px]"
                                                        : OneSevenFivePercent
                                                        ? "object-[center_-25px] h-[250px]"
                                                        : OneFivePercent
                                                        ? "object-[center_-35px] h-[250px]"
                                                        : OneTwoFivePercent
                                                        ? "object-[center_-56px] h-[300px]"
                                                        : OneOneTenPercent
                                                        ? "object-[center_-32px] h-[180px]"
                                                        : "object-[center_-55px] h-[420px]"
                                                } flex w-[90%] rounded-lg object-cover`}
                                            />
                                        </div>
                                        <div className='absolute inset-0 flex items-center justify-center'>
                                            <img
                                                src={play}
                                                alt='play img'
                                                className='object-cover scale-[0.15]'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className='flex w-full h-full justify-center items-end hover:cursor-pointer hover:scale-105 transition-all duration-500 ease-in-out group'
                                    onClick={() => navigate("/podcast")}
                                >
                                    <div className='rounded-full bg-gray-500 relative'>
                                        <div className='absolute w-[80px] h-[80px] top-1/2 left-1/2 transform -translate-x-[40px] -translate-y-[54px]'>
                                            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-[70px] h-[70px]"
              viewBox="0 0 24 24"
              fill="none"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle
                cx="12"
                cy="12"
                r="10"
                fill="orange"
                className="-z-20"
              />
              <polygon
                points="10 8 16 12 10 16 10 8"
                fill="#235D71"
                className="z-20"
              />
            </svg> */}
                                            <img
                                                src={play1}
                                                alt='play1 img'
                                                className='object-cover w-[80%]'
                                            />
                                        </div>
                                    </div>
                                    <div className='bg-green group-hover:bg-MetallicBlue hover:text-green transition-all duration-500 ease-in-out py-2 px-6 text-center text-xl font-semibold text-white rounded-full'>
                                        <p>{t("podcast-button")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Podcast;
