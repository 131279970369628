import { procurementTableConfig } from "./procurementTableConfig";
const ProcurementTable = () => {
    const tableHead = [
        "S.No.",
        "Contract Title",
        "Category",
        "Estimated RFP Publishing Time",
        "Procurement Approach/Competition",
        "Selection Methods",
        "Evaluation",
    ];
    return (
        <table>
            <thead className='w-full font-semibold text-lg border border-black'>
                <tr>
                    {tableHead.map((head) => (
                        <td className='border px-2 border-black'>{head}</td>
                    ))}
                </tr>
            </thead>
            <tbody className='border border-black text-center sm:text-left'>
                {procurementTableConfig.map((row) => (
                    <tr>
                        <td className='border px-2 border-black'>{row.S_No}</td>
                        <td className='border px-2 border-black'>
                            {row.Contract_Title.map((parag) => (
                                <div key={parag}>{parag}</div>
                            ))}
                        </td>
                        <td className='border px-2 border-black'>
                            {row.Category}
                        </td>
                        <td className='border px-2 border-black'>
                            {row.EstimatedTime}
                        </td>
                        <td className='border px-2 border-black'>
                            {row.ProcurementApproach}
                        </td>
                        <td className='border px-2 border-black'>
                            {row.SelectionMethods}
                        </td>
                        <td className='border px-2 border-black'>
                            {row.Evaluation}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default ProcurementTable;
