import React, {useState, useEffect} from "react";
import HeroLandingPage from "../proof/components/hero/heroLandingPage";
import SmallFooter from "../../components/footer/smallFooter";
import Poi from "./components/poi/poi";



function Proof() {
  const [citizen, setCitizen] = useState(true);
;


  useEffect(() => {
    
    window.scrollTo(0, 0);
  
  }, [])
  return ( 
  <> 


<HeroLandingPage citizen={citizen} />
<Poi/>
<SmallFooter/>




  </>
  );
}

export default Proof;
