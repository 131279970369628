import React, { useEffect, useState } from "react";
import HeroLandingPage from "./components/hero/heroLandingPage";
import Registration_and_informed from "./components/registration_and_informed/registration_and_informed";
import SmallFooter from "../../components/footer/smallFooter";
import { getEnrollment } from "../../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { getEnrollmentList } from "../../redux/actions";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";

const Enrollment = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // const timer = setTimeout(() => {
    //   setIsLoading(false);
    // }, 10000);

    // Group the dispatch calls based on their functionality
    const dataDispatches = [dispatch(getEnrollmentList())];

    // Execute the dispatch calls in parallel
    Promise.all(dataDispatches)
      .then(() => {
        setIsLoading(false);
        // Do something after all the dispatch calls have completed
      })
      .catch((error) => {
        // Handle any errors that occur during the dispatch calls
      });

    // return () => {
    //   clearTimeout(timer);
    // };
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <HeroLandingPage />

          <Registration_and_informed  />

          <SmallFooter />
        </>
      )}
    </>
  );
};

export default Enrollment;
