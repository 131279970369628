import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import "./news.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getNews } from "../../../../redux/selectors";

const News = () => {
    const data = useSelector(getNews);
    const list = useMemo(
        () => data.sort((a, b) => new Date(b.date) - new Date(a.date)),
        [data]
    );
    const navigate = useNavigate();
    const { t } = useTranslation();
    console.log(list);
    return (
        <>
            {list.length > 0 ? (
                <div className='w-full px-20 pt-16 mb-20'>
                    <h1 className='mb-2 text-4xl font-bold text-MetallicBlue md:text-6xl'>
                        {t("latest-updates")}
                    </h1>

                    <div className='flex mb-10 '>
                        <hr className='w-[26%] border-b-4  border-green' />
                        <hr className='w-[75%] border-black/50 mb-12 mt-1' />
                    </div>

                    <div className='flex gap-3 '>
                        <div
                            onClick={() =>
                                navigate(
                                    `/updates/${
                                        list[0].type
                                    }/${list[0].title.replace(/ /g, "-")}`,
                                    { state: { news: list[0] } }
                                )
                            }
                            className=' h-[650px] relative flex items-center justify-center basis-[50%] transition-all duration-700 ease-in-out transform scale-100 hover:scale-95 '
                        >
                            <img
                                className='absolute object-cover w-full h-full transition-all duration-500 ease-in-out transform scale-100 hover:scale-100 hover:cursor-pointer'
                                src={
                                    i18next.language === "amh" &&
                                    process.env.REACT_APP_BASE_URL +
                                        list[0].image_amh !=
                                        ""
                                        ? process.env.REACT_APP_BASE_URL +
                                          list[0].image_amh
                                        : process.env.REACT_APP_BASE_URL +
                                          list[0].image
                                }
                                alt={list[0].image}
                            />
                            <div className='absolute bottom-0 left-0 right-0 px-10 text-base h-[90%] bg-news-gradient '></div>
                            <div className='absolute bottom-0 left-0 right-0 px-10 py-2 text-base bg-news-gradient from-green'>
                                <h1 className='mb-5 text-3xl font-semibold text-white whitespace-nowrap'>
                                    <span className='px-5 py-1 border-t-2 border-b-2 border-l-2 border-r-2 border-white rounded-sm'>
                                        Trending
                                    </span>
                                </h1>
                                <div className='flex items-center pb-5'>
                                    <div class=' h-8  bg-[#21cfac] w-1 mr-2 self-center'></div>
                                    <p class='  text-3xl text-white font-medium self-center line-clamp-1'>
                                        {i18next.language === "en" &&
                                            list[0].title}
                                        {i18next.language === "amh" &&
                                            list[0].title_amh}
                                    </p>
                                </div>
                            </div>
                            .
                        </div>

                        <div className='flex flex-col gap-2 basis-[50%]  '>
                            <div
                                onClick={() =>
                                    navigate(
                                        `/updates/${
                                            list[1].type
                                        }/${list[1].title.replace(/ /g, "-")}`,
                                        { state: { news: list[1] } }
                                    )
                                }
                                className='relative transition-all duration-700 ease-in-out transform scale-100 hover:scale-95 h-[320px]'
                            >
                                <img
                                    className='object-cover w-full h-full hover:cursor-pointer'
                                    src={
                                        i18next.language === "amh" &&
                                        process.env.REACT_APP_BASE_URL +
                                            list[1].image_amh !=
                                            ""
                                            ? process.env.REACT_APP_BASE_URL +
                                              list[1].image_amh
                                            : process.env.REACT_APP_BASE_URL +
                                              list[1].image
                                    }
                                    alt={list[1].image}
                                />
                                <div className='absolute bottom-0 left-0 right-0 px-10 text-xl h-[90%]  bg-news-gradient '></div>
                                <div className='absolute bottom-0 left-0 right-0 px-4 py-2 bg-news-gradient'>
                                    <div className='flex items-center mb-4'>
                                        <div class=' h-8  bg-[#21cfac] w-1 mr-2 self-center'></div>
                                        <p class='  text-3xl text-white font-medium self-center line-clamp-1'>
                                            {i18next.language === "en" &&
                                                list[1].title}
                                            {i18next.language === "amh" &&
                                                list[1].title_amh}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='flex gap-2 '>
                                <div
                                    onClick={() =>
                                        navigate(
                                            `/updates/${
                                                list[2].type
                                            }/${list[2].title.replace(
                                                / /g,
                                                "-"
                                            )}`,
                                            { state: { news: list[2] } }
                                        )
                                    }
                                    className='relative basis-[50%] transition-all duration-700 ease-in-out transform scale-100 hover:scale-95 h-[322px] '
                                >
                                    <img
                                        className='object-cover w-full h-full hover:cursor-pointer'
                                        src={
                                            i18next.language === "amh" &&
                                            process.env.REACT_APP_BASE_URL +
                                                list[2].image_amh !=
                                                ""
                                                ? process.env
                                                      .REACT_APP_BASE_URL +
                                                  list[2].image_amh
                                                : process.env
                                                      .REACT_APP_BASE_URL +
                                                  list[2].image
                                        }
                                        alt={list[2].image}
                                    />
                                    <div className='absolute bottom-0 left-0 right-0 px-10 h-[90%]  text-base bg-news-gradient '></div>
                                    <div className='absolute bottom-0 left-0 right-0 px-4 py-2 bg-news-gradient'>
                                        <div className='flex items-center mb-4'>
                                            <div class=' h-8  bg-[#21cfac] w-1 mr-2 self-center'></div>
                                            <p class='  text-xl text-white font-medium self-center line-clamp-1'>
                                                {i18next.language === "en" &&
                                                    list[2].title}{" "}
                                                {i18next.language === "amh" &&
                                                    list[2].title_amh}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='relative basis-[50%]  transition-all duration-700 ease-in-out transform scale-100 hover:scale-95 '>
                                    <div
                                        className='absolute top-0 bottom-0 left-0 right-0 px-4 py-2 bg-cover bg-blur-news '
                                        onClick={() => navigate("/news")}
                                    >
                                        {/* <div className="w-full h-full bg-cover bg-green/20 blur-sm "></div> */}
                                        {/* <div className="z-0 flex w-full h-full bg-news-load-more-gradient "></div> */}
                                        <p class=' flex text-center   justify-center h-full items-center w-full text-4xl text-white font-semibold hover:cursor-pointer hover:-translate-y-1 transform transition duration-500 hover:scale-110'>
                                            {t("learn-more")}{" "}
                                        </p>

                                        <div className='flex'>
                                            {/* <div class="h-[40px]  bg-[#21cfac] w-[5px] mr-2"></div> */}
                                            {/* <p class="mt-2 text-xl text-white">Load More</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
            {/* flex flex-col items-center w-full h-full pb-20 scale-90 pt-14 md:scale-100 */}
        </>
    );
};
export default News;
