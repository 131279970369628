import { useState, useEffect } from "react";
import HeroLandingPage from "../career/components/hero/heroLandingPage";
import Main from "./components/main/Main";
import SmallFooter from "../../components/footer/smallFooter";
import Search from "./components/search/Search";
import { useSelector } from "react-redux";
import { getCareer } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { getCareerList } from "../../redux/actions";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";

export default function Career() {
    const dispatch = useDispatch();
    const [mobileEvent, setMobileEvent] = useState("All");
    const [searchBy, setSearchBy] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [sortBy, setSortBy] = useState("latest");
    const handleSearch = (searchText) => {
        setSearchBy(searchText);
    };
    const handleSortBy = (sortyByString) => {
        setSortBy(sortyByString);
    };
    const setEvent = (event) => {
        setMobileEvent(event);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        // const timer = setTimeout(() => {
        //   setIsLoading(false);
        // }, 10000);

        // Group the dispatch calls based on their functionality
        const dataDispatches = [dispatch(getCareerList())];

        // Execute the dispatch calls in parallel
        Promise.all(dataDispatches)
            .then(() => {
                setIsLoading(false);
                // Do something after all the dispatch calls have completed
            })
            .catch((error) => {
                // Handle any errors that occur during the dispatch calls
            });

        // return () => {
        //   clearTimeout(timer);
        // };
    }, [dispatch]);
    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <HeroLandingPage />
                    <Search
                        setEvent={setEvent}
                        sortHandler={handleSortBy}
                        searchHandler={handleSearch}
                    />
                    <Main
                        mobileEvent={mobileEvent}
                        sortBy={sortBy}
                        searchBy={searchBy}
                    />
                    <SmallFooter />
                </>
            )}
        </>
    );
}
