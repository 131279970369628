import { useTranslation } from "react-i18next";
import { locationConfig } from "./locationsConfig";

const SideBar = () => {
    const { t } = useTranslation();
    return (
        <div className='hidden lg:flex flex-col gap-3 p-5 w-[30%] min-w-[250px] xl:w-[30%] xl:max-w-[350px] overflow-y-scroll'>
            <p className='font-bold text-3xl mb-8 xl:mb-14 mt-5'>
                {t("location_title")}
            </p>
            <p className='xl:mb-10'>{t("location_description")}</p>
            <div className=''>
                <div className='h-10 col-span-1 mb-5 mt-5'>
                    <h1 className=' text-2xl text-white whitespace-nowrap  bg-MetallicBlue text-center h-full  w-fit px-1 flex justify-center items-center rounded-t-md'>
                        <span className='flex items-center justify-center h-full rounded-sm'>
                            {t("locations-all")}
                        </span>
                    </h1>
                    <hr className='w-full border-b-1 border-MetallicBlue ' />
                </div>
                <div className='grid grid-cols-3 gap-5'>
                    {locationConfig.map((location) => (
                        <div
                            key={location.name}
                            className='flex flex-col items-center'
                        >
                            <img
                                src={location.img}
                                className='object-cover h-[75px]'
                            />
                            <p className='min-w-fit text-center w-fit py-0.5 px-2 border-2 border-MetallicBlue rounded-full'>
                                {location.name}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SideBar;
