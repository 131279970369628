import React, { useEffect } from "react";
import SmallFooter from "../../components/footer/smallFooter";
import HeroLandingPage from "./components/hero/heroLandingPage.jsx";
import Body from "./components/Body/body.jsx";

const Authentication = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <HeroLandingPage />
            <Body />
            <SmallFooter hotline={true} />
        </div>
    );
};

export default Authentication;
