import { FaXTwitter } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { RiLinkedinFill, RiTelegramFill, RiYoutubeFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { locationConfig } from "./locationsConfig";

const SideBarMobile = ({ handleCloseSideBar }) => {
    const { t } = useTranslation();
    return (
        <div className='absolute lg:hidden bg-black/40 top-0 bottom-0 left-0 right-0 w-full h-svh p-5 overflow-y-scroll z-40'>
            <div className='flex bg-white flex-col gap-3 p-5'>
                <div className='flex justify-between font-bold text-3xl mb-6 mt-3'>
                    <p className='font-bold text-3xl'>{t("location_title")}</p>

                    <button
                        onClick={handleCloseSideBar}
                        className='text-[#235D71]'
                    >
                        <IoMdClose />
                    </button>
                </div>
                <p className='xl:mb-10 text-justify'>
                    {t("location_description")}
                </p>
                <div className=''>
                    <div className='h-10 col-span-1 mb-5 mt-5'>
                        <h1 className=' text-2xl text-white whitespace-nowrap  bg-MetallicBlue text-center h-full  w-fit px-1 flex justify-center items-center rounded-t-md'>
                            <span className='flex items-center justify-center h-full rounded-sm'>
                                {t("locations-all")}
                            </span>
                        </h1>
                        <hr className='w-full border-b-1 border-MetallicBlue ' />
                    </div>
                    <div className='grid grid-cols-3 gap-5'>
                        {locationConfig.map((location) => (
                            <div
                                key={location.name}
                                className='flex flex-col items-center'
                            >
                                <img
                                    src={location.img}
                                    className='object-cover h-[75px]'
                                />
                                <p className='min-w-fit text-center w-fit py-0.5 px-2 border-2 border-MetallicBlue rounded-full'>
                                    {location.name}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SideBarMobile;
