import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { getLocations } from "../../redux/actions";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import NewLocations from "./locations";
//import './news.css';
import { useTranslation } from "react-i18next";
import SideBar from "./components/SideBar";
import SideBarMobile from "./components/SideBarMobile";
const Locations = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        // const timer = setTimeout(() => {
        //   setIsLoading(false);
        // }, 10000);

        // Group the dispatch calls based on their functionality
        const dataDispatches = [dispatch(getLocations())];

        // Execute the dispatch calls in parallel
        Promise.all(dataDispatches)
            .then(() => {
                setIsLoading(false);
                // Do something after all the dispatch calls have completed
            })
            .catch((error) => {
                // Handle any errors that occur during the dispatch calls
            });

        // return () => {
        //   clearTimeout(timer);
        // };
    }, [dispatch]);

    const [firstLoadSidebar, setFirstLoadSidebar] = useState(true);
    const handleCloseSideBar = useCallback(() => {
        setFirstLoadSidebar(false);
    }, []);
    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div className='relative w-full flex h-[100vh] '>
                    <div className='hidden lg:flex w-full'>
                        <SideBar />
                        <NewLocations />
                    </div>
                    <div className='w-full lg:hidden'>
                        {firstLoadSidebar ? (
                            <SideBarMobile
                                handleCloseSideBar={handleCloseSideBar}
                            />
                        ) : (
                            <NewLocations />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Locations;
