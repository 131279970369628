import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Modal from "./Modal";

const VideoMobile = ({ videosLists }) => {
    const [openModal, setOpenModal] = useState(false);
    const [showVideo, setShowVideo] = useState("");
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const handleOpenModal = (showVideo) => {
        setShowVideo(showVideo);
        setOpenModal(true);
    };

    return (
        <div className='w-full px-5 z-20'>
            <div className='w-full grid grid-cols-2 gap-5'>
                {videosLists?.map((videos) => (
                    <button
                        className='w-full relative'
                        onClick={() => handleOpenModal(videos.video_url)}
                        key={videos.video_url}
                    >
                        <div className='z-10 w-full min-h-[200px] h-full aspect-video'>
                            <ReactPlayer
                                url={videos.video_url}
                                width='100%'
                                height='100%'
                            />
                        </div>
                        <div className='absolute top-0 w-full h-full z-50'></div>
                    </button>
                ))}
            </div>
            {openModal ? (
                <Modal videoUrl={showVideo} closeModal={handleCloseModal} />
            ) : null}
        </div>
    );
};

export default VideoMobile;
