import React from "react";
import checkIcon from "../../../../assets/8-01-Checklist.svg";
import { useNavigate } from "react-router-dom";
import CardSlider from "../cardSlider/CardSlider";
import { useMediaQuery } from "react-responsive";

import TextTruncate from "react-text-truncate";
import { Link } from "react-router-dom";
import { Animate } from "../../../../hooks/animations";

import { useTranslation } from "react-i18next";
import Newpage from "../newpage/newpage";
// const useFetching = (someFetchActionCreator) => {
//   const dispatch = useDispatch();
//   ("Dispatching....")
//   useEffect(() => {
//     dispatch(someFetchActionCreator());
//   }, [dispatch])
// }

const About = ({ list }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });

    return (
        <>
            <div className='flex flex-col items-center justify-center w-full h-full pt-3 scale-90 md:pt-20 md:pb-0 md:scale-100'>
                <Animate.FadeUp>
                    <div className='flex w-full text-5xl md:text-6xl '>
                        <h1 className='pb-1 text-MetallicBlue '>
                            {t("About-your")}
                        </h1>
                        <h1 className='pl-2.5 font-extrabold text-green'>
                            {t("hero-fayda")}
                        </h1>
                    </div>
                </Animate.FadeUp>

                {isDesktopOrLaptop ? (
                    <Animate.FadeUp>
                        <div className='flex flex-col items-center justify-center pt-3 pb-0 md:pt-5 md:pb-3 mb-40 '>
                            <Animate.FadeUp>
                                <p className='p-3 px-10 text-sm leading-normal text-justify md:text-center md:px-72 md:text-xl '>
                                    {t("About-program")}{" "}
                                </p>
                            </Animate.FadeUp>
                        </div>
                    </Animate.FadeUp>
                ) : (
                    <Animate.FadeUp>
                        <div className='flex flex-col items-center justify-center pt-3 pb-0 md:pt-5 md:pb-3 '>
                            <Animate.FadeUp>
                                <p className='p-3 px-10 text-sm leading-normal text-center md:text-center md:px-72 md:text-xl '>
                                    <TextTruncate
                                        line={3}
                                        element='span'
                                        truncateText='…'
                                        text={t("About-program")}
                                        textTruncateChild={
                                            <Link
                                                // to={`/news/${newslistItem.title}`}
                                                // state={{ news: newslistItem }}
                                                className='font-bold text-MetallicBlue italic '
                                            >
                                                Read More
                                            </Link>
                                        }
                                    />
                                </p>
                            </Animate.FadeUp>
                        </div>
                    </Animate.FadeUp>
                )}
                <Newpage />
                {isDesktopOrLaptop ? (
                    <Animate.FadeUp>
                        <div className='grid justify-center grid-cols-3 gap-1 p-10 place-items-center mt-20 mb-20 '>
                            <div className='flex h-full '>
                                <div className='flex flex-col items-center justify-center mx-10 text-sm text-center md:px-18 place-items-center md:text-base  pr-20 '>
                                    <Animate.FadeUp>
                                        <div className='w-12 h-12 p-1.5 border-2 rounded-full border-green/10 bg-MetallicBlue/95'>
                                            <Animate.FadeUp>
                                                <img
                                                    src={checkIcon}
                                                    className='w-10 h-8 '
                                                    alt='Icon_checklist'
                                                ></img>
                                            </Animate.FadeUp>
                                        </div>
                                    </Animate.FadeUp>

                                    <Animate.FadeUp>
                                        <div className='flex items-center justify-center w-full '>
                                            <h1 className='pt-3 text-2xl font-bold md:text-4xl text-MetallicBlue'>
                                                {t("about-get-fayda")}
                                            </h1>
                                        </div>
                                    </Animate.FadeUp>

                                    <Animate.FadeUp>
                                        <div className='text-justify'>
                                            <p className='py-4 mx-auto text-xl text-center '>
                                                {t("about-get-fayda-text")}
                                            </p>
                                        </div>
                                    </Animate.FadeUp>
                                    <button
                                        className='h-10  text-xl mt-4 text-white px-20 bg-green rounded-2xl hover:bg-[#083247]'
                                        onClick={() => navigate("/benefits")}
                                    >
                                        {t("learn-more")}
                                    </button>
                                </div>

                                {/* <div className="bg-MetallicBlue/40 h-[90%] rounded-md w-1 flex self-center justify-center"></div> */}
                            </div>

                            <div className='flex h-full '>
                                <div className='flex flex-col content-center mx-10 text-sm text-center md:px-18 place-items-center md:text-base   backdrop-blur-sm pr-20'>
                                    <Animate.FadeUp>
                                        <div className='w-12 h-12 p-1.5 border-2 rounded-full border-green/10 bg-MetallicBlue/95'>
                                            <Animate.FadeUp>
                                                <img
                                                    src={checkIcon}
                                                    className='w-10 h-8 '
                                                    alt='Icon_checklist'
                                                ></img>
                                            </Animate.FadeUp>
                                        </div>
                                    </Animate.FadeUp>
                                    <Animate.FadeUp>
                                        <h1 className='pt-3 text-2xl font-bold md:text-4xl text-MetallicBlue'>
                                            {t("about-update-fayda")}
                                        </h1>
                                    </Animate.FadeUp>
                                    <Animate.FadeUp>
                                        <p className='py-5 text-xl'>
                                            {t("about-update-fayda-text")}
                                        </p>
                                    </Animate.FadeUp>
                                    <Animate.FadeUp>
                                        <button
                                            className='h-10  text-xl mt-14 text-white px-20 bg-green rounded-2xl hover:bg-[#083247]'
                                            onClick={() =>
                                                navigate("/locations")
                                            }
                                        >
                                            {t("current-locations")}
                                        </button>
                                    </Animate.FadeUp>
                                </div>
                            </div>

                            <div className='flex h-full '>
                                <div className='flex flex-col content-center mx-10 text-sm text-center md:px-18 place-items-center md:text-base'>
                                    <Animate.FadeUp>
                                        <div className='w-12 h-12 p-1.5 border-2 rounded-full border-green/10 bg-MetallicBlue/95'>
                                            <Animate.FadeUp>
                                                <img
                                                    src={checkIcon}
                                                    className='w-10 h-8 '
                                                    alt='Icon_checklist'
                                                ></img>
                                            </Animate.FadeUp>
                                        </div>
                                    </Animate.FadeUp>
                                    <Animate.FadeUp>
                                        <h1 className='pt-3 text-2xl font-bold md:text-4xl text-MetallicBlue'>
                                            {t("about-fayda-service")}
                                        </h1>
                                    </Animate.FadeUp>
                                    <Animate.FadeUp>
                                        <p className='py-4 text-xl'>
                                            {t("about-fayda-service-text")}
                                        </p>
                                    </Animate.FadeUp>
                                    <button
                                        className='h-10  text-xl mt-8 text-white px-20 bg-green rounded-2xl hover:bg-[#083247]'
                                        onClick={() => navigate("/services")}
                                    >
                                        {t("learn-more")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Animate.FadeUp>
                ) : (
                    <CardSlider />
                )}
            </div>
        </>
    );
};

export default About;
