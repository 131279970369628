import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import i18next from "i18next";
import title from "../../../../assets/full logo-fayda podcast-01.png";

const HeroMobile = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    const { t } = useTranslation();
    return (
        <div class='bg-podcast-hero flex w-full h-full bg-center bg-cover '>
            <div className='bg-podcast-gradient flex flex-col w-full h-full pb-12'>
                <div className='pl-5 flex w-full h-full pt-[25%] py-2'>
                    <img src={title} alt='' className='w-[50%]' />
                </div>
                <div className='flex w-full h-full px-5 justify-center text-base font-light text-white '>
                    <p className='text-start'>{t("podcast-subtitle")}</p>
                </div>
            </div>
        </div>
    );
};

export default HeroMobile;
