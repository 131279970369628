import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Hero = ({ news }) => {
    const { t } = useTranslation();
    return (
        <div class='bg-media-hero bg-center bg-cover '>
            <div className='bg-hero-gradient'>
                {/* <div class="grid grid-cols-1 gap-16 px-6 py-0">
    </div> */}
                <div className='flex justify-center md:justify-start  items-center pb-[5%] md:pr-40 md:text-8xl  md:px-28 px-10 text-2xl '>
                    <div className='w-full '>
                        {/* <h1 className="flex w-full pr-2 mt-32 mb-0 font-bold text-white">
              {
               
                i18next.language === "en"
                  ? news.title
                  : i18next.language === "amh"
                  ? news.title_amh
                  : ""
              }
            </h1> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
