import React, { useState, useEffect } from "react";

import HeroLandingPage from "../idServices/components/hero/heroLandingPage";
import FooterService from "../../components/footer/footer";
import Idandmobile from "./components/idandmobile/idandmobile";
import Getfayda from "./components/getfayda/getfayda";
import Updatenumber from "./components/updatenumber/updatenumber";

function IdServices() {
  const [citizen, setCitizen] = useState(true);
 
  useEffect(() => {
    
    window.scrollTo(0, 0);
  
  }, [])
  return (
    <>
      <HeroLandingPage citizen={citizen} />
     
      <Getfayda id={'get-no'}/>
      <Idandmobile />
      <Updatenumber id={'update-no'}/>
      <FooterService />
    </>
  );
}

export default IdServices;
