import axios from "axios";

export const GET_EVENTS_LIST = "GET_EVENTS_LIST";
export const GET_SOCIALS_LIST = "GET_SOCIALS_LIST";
export const GET_GALLERY_LIST = "GET_GALLERY_LIST";
// export const ADD_ABOUT = "ADD_ABOUT";
// export const DELETE_ABOUT = "DELETE_ABOUT";
// export const UPDATE_ABOUT = "UPDATE_ABOUT";

const headers = {
  "Accept": "application/json",
  "Content-Type": "application/json",
  "X-Requested-With": "nid-website",
};

export const getEventsList = () => async (dispatch) => {
  // const cachedList = localStorage.getItem("eventsList");
  // if (cachedList) {
  //   dispatch({
  //     type: GET_EVENTS_LIST,
  //     payload: JSON.parse(cachedList),
  //   });
  // } else {

    try {
      const response = await axios.get(
        
        process.env.REACT_APP_BASE_URL +`/News/EventsNews`,
        {
          headers: headers,
        }
      );
    
      if (response && response.data) {
        dispatch({
          type: GET_EVENTS_LIST,
          payload: response.data,
        });
        localStorage.setItem("eventsList", JSON.stringify(response.data));
      } else {
        const cachedEventsList = localStorage.getItem("eventsList");
        if (cachedEventsList) {
          dispatch({
            type: GET_EVENTS_LIST,
            payload: JSON.parse(cachedEventsList),
          });
        }
      }
    } catch (err) {
      const cachedEventsList = localStorage.getItem("eventsList");
      if (cachedEventsList) {
        dispatch({
          type: GET_EVENTS_LIST,
          payload: JSON.parse(cachedEventsList),
        });
      }
    }
};

// export const  getSocialsList = () => async (dispatch) => {
//   try{
//   await axios.get(`/api/resource/Socials?q=proxy&fields=["title", "image", "link","action"]`,
//   {
//     headers: headers
//   },

//   )

//   .then(response =>

//     dispatch(
//     {
//       type: GET_SOCIALS_LIST,
//       payload: response.data
//     }
//   ))

//   ("Sucess");

// }
// catch (err) {
//   (err)
// }
// };

export const getGalleryList = () => async (dispatch) => {
  // const cachedList = localStorage.getItem("galleryList");
  // if (cachedList) {
  //   dispatch({
  //     type: GET_GALLERY_LIST,
  //     payload: JSON.parse(cachedList),
  //   });
  // } else {
    try {
     
      const response = await axios.get(
         
        process.env.REACT_APP_BASE_URL +`/galleryy/`,
        {
          headers: headers,
        }
      );
    
      if (response && response.data) {
        dispatch({
          type: GET_GALLERY_LIST,
          payload: response.data,
        });
        localStorage.setItem("galleryList", JSON.stringify(response.data));
      } else {
        const cachedGalleryList = localStorage.getItem("galleryList");
        if (cachedGalleryList) {
          dispatch({
            type: GET_GALLERY_LIST,
            payload: JSON.parse(cachedGalleryList),
          });
        }
      }
    } catch (err) {
      const cachedGalleryList = localStorage.getItem("galleryList");
      if (cachedGalleryList) {
        dispatch({
          type: GET_GALLERY_LIST,
          payload: JSON.parse(cachedGalleryList),
        });
      }
    }
};