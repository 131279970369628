import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Update = () => {
    const {t}=useTranslation()
    return (
        <div className='p-4'>
            <div className='flex flex-col items-center justify-center gap-5 h-fit'>
                <div className='flex flex-col text-center gap-5'>
                    <h1 className='text-3xl md:text-4xl font-semibold text-MetallicBlue'>
                        {t("help-update-title")}{" "}
                    </h1>
                    <p className='font-semibold text-black/60 text-xl text-justify'>
                        {t("help-update-description")}
                    </p>
                </div>
                <div className='flex flex-col md:flex-row gap-3 items-center w-full justify-center'>
                    <a href='https://resident.fayda.et'>
                        <p className='text-xl text-white px-5 lg:px-16 bg-green hover:bg-[#083247] rounded-md py-2'>
                            {t("update-now")}{" "}
                        </p>
                    </a>
                    {/* <Link to='/proof'>
                        <p className='text-xl text-white px-5 lg:px-16 bg-green hover:bg-[#083247] rounded-md py-2'>
                            {t("required-docs")}{" "}
                        </p>
                    </Link> */}
                </div>
            </div>
        </div>
    );
};

export default Update;