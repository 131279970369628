import React from "react";
//import './news.css';

import i18next from "i18next";

import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { RiYoutubeFill } from "react-icons/ri";
import { TwitterTimelineEmbed } from "react-twitter-embed";

import { useTranslation } from "react-i18next";
import TwitterBox from "../../../../components/twitterBox";

function modifyImageSource(html, extraUrl) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const images = doc.getElementsByTagName("img");

    for (let i = 0; i < images.length; i++) {
        const image = images[i];
        let src = image.getAttribute("src");

        if (src && src.startsWith("data:image")) {
            // If the src attribute starts with "data:image", it is a base64 encoded image
            const base64DataIndex = src.indexOf("base64,"); // Find the index of base64 encoded data
            if (base64DataIndex !== -1) {
                const base64Data = src.substring(base64DataIndex + 7); // Extract base64 encoded data
                // Modify src attribute to use the extraUrl
                src = `data:image;base64,${base64Data}`;
                image.setAttribute("src", src);
            }
        }
    }

    return doc.documentElement.innerHTML;
}

const Body = ({ news }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className='flex flex-col  '>
                <div className='flex flex-col items-center pt-20 pb-16 mb-10 text-3xl px-28 '>
                    <div className='flex w-full '>
                        <div className='w-[15%] flex items-start py-1 justify-center text-white bg-MetallicBlue'>
                            {" "}
                            {t("news-body-featured")}
                        </div>

                        <div className='w-[85%] flex items-center pl-5  border-b-2  text-darkblue border-MetallicBlue'>
                            {" "}
                        </div>
                    </div>

                    <div className='flex flex-col w-full mt-7'>
                        <p className='w-full text-6xl font-semibold text-MetallicBlue'>
                            {i18next.language == "en" && news.title}
                            {i18next.language == "amh" && news.title_amh}
                        </p>
                        {news.type == "Tender" && (
                            <div className='relative mt-1 w-[34%] justify-end group-hover:scale-105 z-10 duration-300'>
                                <h2
                                    className={`${
                                        news.status === "closed"
                                            ? ` bg-darkred`
                                            : news.status === "open"
                                            ? ` bg-green`
                                            : "hidden"
                                    } text-white justify-center text-2xl w-[22%] p-2 mt-2 flex`}
                                >
                                    {news.status}
                                </h2>
                            </div>
                        )}
                    </div>
                </div>

                <div class='grid gap-7 grid-cols-3 mx-28'>
                    <div className='flex object-center w-full h-full col-span-2'>
                        <div className=''>
                            <img
                                src={
                                    i18next.language == "amh"
                                        ? process.env.REACT_APP_BASE_URL +
                                          news.image_amh
                                        : process.env.REACT_APP_BASE_URL +
                                          news.image
                                }
                                onError={(e) => {
                                    e.target.src =
                                        "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3C/svg%3E";
                                }}
                                alt={news.image}
                                className='items-start object-cover w-full h-full bg-cover bg-webinar bg-center'
                            />
                        </div>
                    </div>

                    <div>
                        {/* Social Media and Tweet Embed container */}
                        <div className=''>
                            <div className='flex flex-col h-[70%] gap-16 '>
                                <div className='flex flex-col '>
                                    <div className='flex items-center justify-center py-1 '>
                                        <div className='flex   w-[50%]'>
                                            <div className='p-1 text-3xl text-white rounded-lg bg-dodgerblue'>
                                                <a>
                                                    <FaFacebookF />
                                                </a>{" "}
                                            </div>
                                            <div className='px-2 text-2xl '>
                                                {" "}
                                                <p>Facebook</p>{" "}
                                            </div>
                                        </div>
                                        <div className='w-full h-full text-center '>
                                            <button className='border-2 text-left  rounded-full w-[50%]  hover:bg-MetallicBlue hover:text-white transform-all duration-200 '>
                                                <span className='ml-8'>
                                                    Like{" "}
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                    <div className='flex items-center justify-center py-1 '>
                                        <div className='flex   w-[50%]'>
                                            <div className='p-1 text-3xl text-white rounded-lg  bg-[black]'>
                                                <a>
                                                    <FaXTwitter />
                                                </a>{" "}
                                            </div>
                                            <div className='px-2 text-2xl'>
                                                {" "}
                                                <p>Twitter</p>{" "}
                                            </div>
                                        </div>
                                        <div className='w-full h-full text-center '>
                                            <button className='border-2 text-left rounded-full w-[50%] hover:bg-MetallicBlue hover:text-white transform-all duration-200 '>
                                                <span className='ml-8'>
                                                    Follow{" "}
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                    <div className='flex items-center justify-center py-1 '>
                                        <div className='flex  w-[50%]'>
                                            <div className='p-1 text-3xl text-white rounded-lg bg-darkred'>
                                                <a>
                                                    <RiYoutubeFill />
                                                </a>{" "}
                                            </div>
                                            <div className='px-2 text-2xl '>
                                                {" "}
                                                <p>Youtube</p>{" "}
                                            </div>
                                        </div>
                                        <div className='w-full h-full text-center '>
                                            <button className='border-2 text-left rounded-full w-[50%] hover:bg-MetallicBlue hover:text-white transform-all duration-200 '>
                                                <span className='ml-8'>
                                                    Subscribe{" "}
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                    <div></div>
                                </div>

                                <div className=' w-[100%]  h-fit md:w-[100%] md:h-fit  rounded-3xl '>
                                    <div className=' w-[100%] h-fit  rounded-3xl '>
                                        {/* <TwitterTimelineEmbed
                      sourceType="url"
                      url="https://twitter.com/IDethiopia"
                      screenName="nationalID"
                      options={{ height: 250 }}
                    /> */}
                                        <TwitterBox />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*News Paragraph */}
                    <div className='flex flex-col pb-32 gap-8 h-full col-span-2 text-justify'>
                        <div className='flex w-full text-xl'>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: modifyImageSource(
                                        i18next.language === "en"
                                            ? news.description
                                            : i18next.language === "amh"
                                            ? news.description_amh
                                            : "x",
                                        process.env.REACT_APP_BASE_URL // Replace with your extra URL
                                    ),
                                }}
                            />
                        </div>

                        {news.type == "Tender" && (
                            <button
                                className='h-10 w-[40%] text-2xl text-white px-10 bg-MetallicBlue rounded-2xl hover:bg-[#083247]'
                                onClick={(e) => {
                                    if (news.document) {
                                        if (
                                            news.document
                                                .toLowerCase()
                                                .startsWith("https")
                                        ) {
                                            window.open(
                                                news.document,
                                                "_blank"
                                            );
                                        } else {
                                            window.open(
                                                process.env.REACT_APP_BASE_URL +
                                                    news.document,
                                                "_blank"
                                            );
                                        }
                                    }
                                }}
                            >
                                {t("Read-More")}
                            </button>
                        )}
                        {/* <div className="text-xl">{news.description}</div> */}
                    </div>

                    <div></div>
                </div>
            </div>
        </>
    );
};
export default Body;
