const partyRegFormData = [
    {
        name: "govt",
        formFields: [
            {
                name: "org",
                title: "party-reg-input-title-1",
            },
            {
                name: "repName",
                title: "party-reg-input-title-2",
            },
            { name: "phoneNumber", title: "party-reg-input-title-3" },
            {
                name: "emailAddress",
                type: "email",
                title: "party-reg-input-title-4",
            },
            { name: "headQuarter", title: "party-reg-input-title-5" },
        ],
    },
    {
        name: "private",
        formFields: [
            {
                name: "org",
                title: "party-reg-input-title-1",
            },
            {
                name: "repName",
                title: "party-reg-input-title-2",
            },
            { name: "phoneNumber", title: "party-reg-input-title-3" },
            {
                name: "emailAddress",
                type: "email",
                title: "party-reg-input-title-4",
            },
            { name: "headQuarter", title: "party-reg-input-title-5" },
            {
                name: "commercialRegistration",
                type: "file",
                title: "party-reg-input-title-6",
            },
            {
                name: "renewedBusinessLicense",
                type: "file",
                title: "party-reg-input-title-13",
            },
            {
                name: "tinCertificate",
                type: "file",
                title: "party-reg-input-title-8",
            },
            {
                name: "taxClearance",
                type: "file",
                title: "party-reg-input-title-9",
            },
            {
                name: "titleDeedHq",
                type: "file",
                title: "party-reg-input-title-10",
            },
        ],
    },
    {
        name: "non-profit",
        formFields: [
            {
                name: "org",
                title: "party-reg-input-title-1",
            },
            {
                name: "repName",
                title: "party-reg-input-title-2",
            },
            { name: "phoneNumber", title: "party-reg-input-title-3" },
            {
                name: "emailAddress",
                type: "email",
                title: "party-reg-input-title-4",
            },
            { name: "headQuarter", title: "party-reg-input-title-5" },
            {
                name: "certificateOfRegistration",
                type: "file",
                title: "party-reg-input-title-11",
            },
            {
                name: "titleDeedHq",
                type: "file",
                title: "party-reg-input-title-10",
            },
        ],
    },
    {
        name: "state",
        formFields: [
            {
                name: "org",
                title: "party-reg-input-title-1",
            },
            {
                name: "repName",
                title: "party-reg-input-title-2",
            },
            { name: "phoneNumber", title: "party-reg-input-title-3" },
            {
                name: "emailAddress",
                type: "email",
                title: "party-reg-input-title-4",
            },
            { name: "headQuarter", title: "party-reg-input-title-5" },
            {
                name: "commercialRegistration",
                type: "file",
                title: "party-reg-input-title-12",
            },
            {
                name: "renewedBusinessLicense",
                type: "file",
                title: "party-reg-input-title-13",
            },
        ],
    },
];

export default partyRegFormData;
