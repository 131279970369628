import axios from "axios";

export const GET_ENROLLMENT_IMAGES = "GET_ENROLLMENT_IMAGES";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Requested-With": "nid-website",
};

export const getEnrollmentList = () => async (dispatch) => {

  try {
   
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL +
        `/Enrollment/`,
      {
        headers: headers,
      }
    );

    if (response && response.data) {
      dispatch({
        type: GET_ENROLLMENT_IMAGES,
        payload: response.data,
      });
      localStorage.setItem("enrollmentList", JSON.stringify(response.data));
    } else {
      const cachedEnrollmentList = localStorage.getItem("enrollmentList");
      if (cachedEnrollmentList) {
        dispatch({
          type: GET_ENROLLMENT_IMAGES,
          payload: JSON.parse(cachedEnrollmentList),
        });
      }
    }
  } catch (err) {
    const cachedEnrollmentList = localStorage.getItem("enrollmentList");
    if (cachedEnrollmentList) {
      dispatch({
        type: GET_ENROLLMENT_IMAGES,
        payload: JSON.parse(cachedEnrollmentList),
      });
    }
  }
};