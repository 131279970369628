import React from "react";
import mainLogo from "../../../../assets/check-mark.png";
import i18next from "i18next";
import { useMediaQuery } from "react-responsive";

import { useTranslation } from "react-i18next";

const mandatoryFields = [
    {
        en: "Full Name",
        amh: "ሙሉ ስም",
    },
    {
        en: "Gender",
        amh: "ፆታ",
    },
    {
        en: "Date of Birth",
        amh: "የትዉልድ ቀን",
    },
    {
        en: "Current Address",
        amh: "አሁናዊ አድራሻ",
    },
];

const optionalFields = [
    {
        en: "Phone Number",
        amh: "ስልክ ቁጥር",
    },
    {
        en: "House Number",
        amh: "የቤት ቁጥር",
    },
    {
        en: "Email",
        amh: "ኢሜይል",
    },
    {
        en: "Nationality",
        amh: "ዜግነት",
    },
];

const Dataset = () => {
    const { t } = useTranslation();

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    return (
        <>
            {isDesktopOrLaptop ? (
                <div class='text-darkblue mt-0  w-full  bg-grey '>
                    <div className='flex-col flex pt-20 pb-20 mb-10  bg-white px-28 '>
                        <div className='flex w-[20%] items-center justify-center text-white bg-MetallicBlue px-3 py-1.5 text-5xl'>
                            {" "}
                            {t("dataset-body-fieldlist")}{" "}
                        </div>

                        <div className='w-[100%] flex items-center pl-5 border-b-2 text-darkblue border-MetallicBlue'>
                            {" "}
                        </div>
                        <div className='text-2xl mt-4'>
                            {t("dataset-body-fielddesc")}{" "}
                        </div>
                    </div>

                    <div className='flex flex-col w-full'>
                        <div className='grid grid-cols-2 mx-28 pt-20 h-[70%] place-items-start align-middle  mb-16'>
                            <div className='flex flex-col justify-start w-full text-white place-items-start '>
                                <div className='flex items-center justify-start w-full px-10 text-white bg-MetallicBlue'>
                                    {" "}
                                    <div className='w-2 h-2 mr-4 rounded-full bg-green'></div>
                                    <p className=' py-1.5 text-3xl '>
                                        {" "}
                                        {t("dataset-body-fieldmandatory")}{" "}
                                    </p>
                                </div>

                                <div className='flex flex-col items-start justify-start w-full gap-10 mt-10 text-3xl text-MetallicBlue px-7'>
                                    {mandatoryFields.map((mandatory, index) => (
                                        <div className='flex items-center justify-start w-full '>
                                            <img
                                                src={mainLogo}
                                                className='h-7'
                                                alt='fireSpot'
                                            />
                                            <p className='px-3 '>
                                                {" "}
                                                {i18next.language === "en" &&
                                                    mandatory.en}{" "}
                                                {i18next.language === "amh" &&
                                                    mandatory.amh}
                                            </p>
                                        </div>
                                    ))}
                                    <div className='w-10 h-10 bg-'></div>

                                    {/* <div className="flex items-center justify-start w-full ">
                    <img src={mainLogo} className="h-7" alt="fireSpot" />
                    <p className="px-3">{t("dataset-body-dob")}</p>
                  </div>
                  <div className="flex items-center justify-start w-full ">
                    <img src={mainLogo} className="h-7" alt="fireSpot" />
                    <p className="px-3">{t("dataset-body-gender")}</p>
                  </div> */}
                                </div>
                            </div>

                            <div className='flex flex-col justify-start w-full text-MetallicBlue place-items-start '>
                                <div className='flex items-center justify-start w-full text-white px-10 bg-MetallicBlue'>
                                    {" "}
                                    <div className='w-2 h-2 mr-4 rounded-full bg-green'></div>
                                    <p className=' py-1.5 text-3xl'>
                                        {" "}
                                        {t("dataset-body-fieldoptional")}{" "}
                                    </p>
                                </div>

                                <div className='flex flex-col items-start justify-start w-full gap-10 mt-10 text-3xl px-7'>
                                    {optionalFields.map((optional, index) => (
                                        <div className='flex items-center justify-start w-full '>
                                            <img
                                                src={mainLogo}
                                                className='h-7'
                                                alt='fireSpot'
                                            />
                                            <p className='px-3 '>
                                                {" "}
                                                {i18next.language === "en" &&
                                                    optional.en}{" "}
                                                {i18next.language === "amh" &&
                                                    optional.amh}
                                            </p>
                                        </div>
                                    ))}
                                    {/* <div className="flex items-center justify-start w-full ">
                    <img src={mainLogo} className="h-7" alt="fireSpot" />
                    <p className="px-3 ">{t("dataset-body-fullname")}</p>
                  </div>
                  <div className="flex items-center justify-start w-full ">
                    <img src={mainLogo} className="h-7" alt="fireSpot" />
                    <p className="px-3">{t("dataset-body-dob")}</p>
                  </div>
                  <div className="flex items-center justify-start w-full ">
                    <img src={mainLogo} className="h-7" alt="fireSpot" />
                    <p className="px-3">{t("dataset-body-gender")}</p>
                  </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex py-20 mb-10 text-3xl bg-white px-28 '>
                        <div className='w-[20%] flex items-center justify-center text-white bg-MetallicBlue py-1.5 '>
                            {" "}
                            {t("dataset-body-fieldothers")}
                        </div>
                        <div className='w-[70%] flex items-center pl-5 border-b-2 text-darkblue border-MetallicBlue'>
                            {" "}
                        </div>
                    </div>

                    <div className='flex flex-col items-start text-lg leading-snug tracking-tight text-left gap-4 my-10 px-28 text-MetallicBlue'>
                        <div className='flex w-full'>
                            <div className='w-1 h-8 mr-3 bg-darkblue '> </div>
                            <p className='w-full'>
                                {t("dataset-body-minors")}{" "}
                            </p>
                        </div>

                        <div className='flex w-full'>
                            <div className='w-1 h-8 mr-3 bg-darkred'> </div>
                            <p className='w-full'>
                                {t("dataset-body-individuals")}{" "}
                            </p>
                        </div>

                        <div className='flex w-full'>
                            <div className='w-1 h-8 mr-3 bg-darkorange'> </div>
                            <p className='w-full'>
                                {t("dataset-body-citizenship")}{" "}
                            </p>
                        </div>

                        <div className='flex w-full'>
                            <div className='w-1 h-10 mr-3 bg-green'> </div>
                            <p className='w-full'>
                                {t("dataset-body-biometric")}{" "}
                            </p>
                        </div>
                    </div>

                    <div className='w-full h-20 bg-white'></div>
                </div>
            ) : (
                <div class='text-darkblue mt-0  w-full  bg-grey '>
                    <div className='flex flex-col pt-10 pb-8 text-xl bg-white px-5 '>
                        <div className='w-[70%] flex items-center justify-center text-white bg-MetallicBlue py-1.5 text-2xl'>
                            {" "}
                            {t("dataset-body-fieldlist")}{" "}
                        </div>

                        <div className='w-[100%] flex items-center justify-center border-b-2 text-darkblue border-MetallicBlue'>
                            {t("dataset-body-fielddesc")}{" "}
                        </div>
                    </div>

                    <div className='flex flex-col w-full'>
                        <div className='grid grid-cols-2 pt-8 h-[70%] place-items-start align-middle  mb-7'>
                            <div className='flex flex-col justify-start w-full text-white place-items-start '>
                                <div className='flex items-center justify-start w-full px-3 text-white bg-MetallicBlue'>
                                    {" "}
                                    <div className='w-2 h-2 mr-4 rounded-full bg-green'></div>
                                    <p className='py-1.5 text-xl'>
                                        {" "}
                                        {t("dataset-body-fieldmandatory")}{" "}
                                    </p>
                                </div>

                                <div className='flex flex-col items-start justify-start w-full gap-6 mt-10 text-xl text-MetallicBlue px-7'>
                                    {mandatoryFields.map((mandatory, index) => (
                                        <div className='flex items-center justify-star w-full '>
                                            <img
                                                src={mainLogo}
                                                className='h-7'
                                                alt='fireSpot'
                                            />
                                            <p className='px-3 '>
                                                {" "}
                                                {i18next.language === "en" &&
                                                    mandatory.en}{" "}
                                                {i18next.language === "amh" &&
                                                    mandatory.amh}
                                            </p>
                                        </div>
                                    ))}
                                    <div className='w-10 h-10 bg-'></div>

                                    {/* <div className="flex items-center justify-start w-full ">
                    <img src={mainLogo} className="h-7" alt="fireSpot" />
                    <p className="px-3">{t("dataset-body-dob")}</p>
                  </div>
                  <div className="flex items-center justify-start w-full ">
                    <img src={mainLogo} className="h-7" alt="fireSpot" />
                    <p className="px-3">{t("dataset-body-gender")}</p>
                  </div> */}
                                </div>
                            </div>

                            <div className='flex flex-col justify-start w-full text-MetallicBlue place-items-start '>
                                <div className='flex items-center justify-start w-full text-white px-3 bg-MetallicBlue'>
                                    {" "}
                                    <div className='w-2 h-2 mr-4 rounded-full bg-green'></div>
                                    <p className=' py-1.5 text-xl'>
                                        {" "}
                                        {t("dataset-body-fieldoptional")}{" "}
                                    </p>
                                </div>

                                <div className='flex flex-col items-start justify-start w-full gap-6 mt-10 text-xl px-7'>
                                    {optionalFields.map((optional, index) => (
                                        <div className='flex items-center justify-start w-full '>
                                            <img
                                                src={mainLogo}
                                                className='h-7'
                                                alt='fireSpot'
                                            />
                                            <p className='px-3 '>
                                                {" "}
                                                {i18next.language === "en" &&
                                                    optional.en}{" "}
                                                {i18next.language === "amh" &&
                                                    optional.amh}
                                            </p>
                                        </div>
                                    ))}
                                    {/* <div className="flex items-center justify-start w-full ">
                    <img src={mainLogo} className="h-7" alt="fireSpot" />
                    <p className="px-3 ">{t("dataset-body-fullname")}</p>
                  </div>
                  <div className="flex items-center justify-start w-full ">
                    <img src={mainLogo} className="h-7" alt="fireSpot" />
                    <p className="px-3">{t("dataset-body-dob")}</p>
                  </div>
                  <div className="flex items-center justify-start w-full ">
                    <img src={mainLogo} className="h-7" alt="fireSpot" />
                    <p className="px-3">{t("dataset-body-gender")}</p>
                  </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex py-8 mb-10 text-2xl bg-white px-5 '>
                        <div className='w-[70%] flex items-center justify-center text-white bg-MetallicBlue py-1.5 '>
                            {" "}
                            {t("dataset-body-fieldothers")}
                        </div>
                        <div className='w-[70%] flex items-center pl-5 border-b-2 text-darkblue border-MetallicBlue'>
                            {" "}
                        </div>
                    </div>

                    <div className='flex flex-col items-start text-lg leading-snug tracking-tight text-justify gap-4 my-10 pl-2 pr-6 text-MetallicBlue'>
                        <div className='flex w-full'>
                            <div className='w-2 h-8 mr-3 bg-darkblue '> </div>
                            <p className='w-full'>
                                {t("dataset-body-minors")}{" "}
                            </p>
                        </div>

                        <div className='flex w-full'>
                            <div className='w-2 h-8 mr-3 bg-darkred'> </div>
                            <p className='w-full'>
                                {t("dataset-body-individuals")}{" "}
                            </p>
                        </div>

                        <div className='flex w-full'>
                            <div className='w-2 h-8 mr-3 bg-darkorange'> </div>
                            <p className='w-full'>
                                {t("dataset-body-citizenship")}{" "}
                            </p>
                        </div>

                        <div className='flex w-full'>
                            <div className='w-2 h-10 mr-3 bg-green'> </div>
                            <p className='w-full'>
                                {t("dataset-body-biometric")}{" "}
                            </p>
                        </div>
                    </div>

                    <div className='w-full h-20 bg-white'></div>
                </div>
            )}
        </>
    );
};

export default Dataset;
