import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FaqAccordion from "./faqAccordion";

const FaqCarousel = ({ faqs }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (index) => setActiveIndex(index),
    };

    const chunkSize = 3; // number of FAQs to show in each slide

    const faqChunks = [];
    for (let i = 0; i < faqs.length; i += chunkSize) {
        faqChunks.push(faqs.slice(i, i + chunkSize));
    }

    return (
        <div className='relative w-full mt-10'>
            <Slider {...settings}>
                {faqChunks.map((chunk, index) => (
                    <div key={index} className='flex items-center h-full'>
                        <div className='grid grid-cols-1 gap-4 text-left sm:grid-cols-2 lg:grid-cols-3 '>
                            {chunk.map((faq, index) => (
                                <FaqAccordion key={index} faqs={[faq]} />
                            ))}
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default FaqCarousel;
