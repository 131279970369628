import React, { useEffect } from "react";
import HeroLandingPage from "./hero/heroLandingPage";
import SmallFooter from "../../components/footer/smallFooter";
import Body from "./body/body";

const ApiIntegration = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <HeroLandingPage />
            <Body />

            <SmallFooter />
        </div>
    );
};

export default ApiIntegration;
