export const allLocationList = [
    {
        branch_name_amahric: "አፍሪካ ጎዳና",
        branch_name_english: "Africa Avenue",
        region: "Addia Ababa",
        longitude: "38.767713",
        latitude: "9.003545",
        status: "active",
    },
    {
        branch_name_amahric: "አዲሱ ገበያ",
        branch_name_english: "Addisu Gebeya",
        region: "Addia Ababa",
        longitude: "38.737307",
        latitude: "9.059084",
        status: "active",
    },
    {
        branch_name_amahric: "አራት ኪሎ ቁ2",
        branch_name_english: "Arat Kilo No. 2",
        region: "Addia Ababa",
        longitude: "38.761747",
        latitude: "9.03279",
        status: "active",
    },
    {
        branch_name_amahric: "አቃቂ",
        branch_name_english: "Akaki",
        region: "Addia Ababa",
        longitude: "38.788664",
        latitude: "8.867849",
        status: "active",
    },
    {
        branch_name_amahric: "አዲስ ከተማ",
        branch_name_english: "Addis Ketema",
        region: "Addia Ababa",
        longitude: "38.737572",
        latitude: "9.035276",
        status: "active",
    },
    {
        branch_name_amahric: "አስኮ",
        branch_name_english: "Asko",
        region: "Addia Ababa",
        longitude: "38.693544",
        latitude: "9.066251",
        status: "active",
    },
    {
        branch_name_amahric: "አየር ጤና",
        branch_name_english: "Ayer Tena",
        region: "Addia Ababa",
        longitude: "38.704352",
        latitude: "8.984911",
        status: "active",
    },
    {
        branch_name_amahric: "ባልደራስ",
        branch_name_english: "Balderas",
        region: "Addia Ababa",
        longitude: "38.781716",
        latitude: "9.024664",
        status: "active",
    },
    {
        branch_name_amahric: "ቤቴል",
        branch_name_english: "Bethel",
        region: "Addia Ababa",
        longitude: "38.698003",
        latitude: "9.004407",
        status: "active",
    },
    {
        branch_name_amahric: "ቦሌ መድሃኒዓለም",
        branch_name_english: "Bole Medhanealem",
        region: "Addia Ababa",
        longitude: "38.785333",
        latitude: "8.999256",
        status: "active",
    },
    {
        branch_name_amahric: "ገርጅ",
        branch_name_english: "Gerji",
        region: "Addia Ababa",
        longitude: "38.812334",
        latitude: "8.994966",
        status: "active",
    },
    {
        branch_name_amahric: "ጎፋ",
        branch_name_english: "Gofa",
        region: "Addia Ababa",
        longitude: "38.749818",
        latitude: "8.966632",
        status: "active",
    },
    {
        branch_name_amahric: "ጉለሌ",
        branch_name_english: "Gulele",
        region: "Addia Ababa",
        longitude: "38.725603",
        latitude: "9.0495322",
        status: "active",
    },
    {
        branch_name_amahric: "ጀሞ",
        branch_name_english: "Jemo",
        region: "Addia Ababa",
        longitude: "38.713477",
        latitude: "8.954797",
        status: "active",
    },
    {
        branch_name_amahric: "ጎተራ",
        branch_name_english: "Gotera",
        region: "Addia Ababa",
        longitude: "38.758477",
        latitude: "8.986119",
        status: "active",
    },
    {
        branch_name_amahric: "ካሳንችስ",
        branch_name_english: "Kasanchis",
        region: "Addia Ababa",
        longitude: "38.780756",
        latitude: "9.021669",
        status: "active",
    },
    {
        branch_name_amahric: "ቃሊቲ",
        branch_name_english: "Kaliti",
        region: "Addia Ababa",
        longitude: "38.762130",
        latitude: "8.908308",
        status: "active",
    },
    {
        branch_name_amahric: "ላፍቶ",
        branch_name_english: "Lafto",
        region: "Addia Ababa",
        longitude: "38.745708",
        latitude: "8.95505",
        status: "active",
    },
    {
        branch_name_amahric: "ላምበረት",
        branch_name_english: "Lamberet",
        region: "Addia Ababa",
        longitude: "38.824906",
        latitude: "9.025069",
        status: "active",
    },
    {
        branch_name_amahric: "መስቀል አደባባይ",
        branch_name_english: "Meskel Square",
        region: "Addia Ababa",
        longitude: "38.761053",
        latitude: "9.00965",
        status: "active",
    },
    {
        branch_name_amahric: "ሰንጋተራ",
        branch_name_english: "Sengatera",
        region: "Addia Ababa",
        longitude: "38.748112",
        latitude: "9.015421",
        status: "active",
    },
    {
        branch_name_amahric: "ሽሮሜዳ",
        branch_name_english: "Shiromeda",
        region: "Addia Ababa",
        longitude: "38.762154",
        latitude: "9.063170",
        status: "active",
    },
    {
        branch_name_amahric: "የካአባዶ",
        branch_name_english: "Yeka Abado",
        region: "Addia Ababa",
        longitude: "38.753937",
        latitude: "9.0616081",
        status: "active",
    },
    {
        branch_name_amahric: "ልደታ",
        branch_name_english: "Lideta",
        region: "Addia Ababa",
        longitude: "38.737756",
        latitude: "9.015757",
        status: "active",
    },
    {
        branch_name_amahric: "መገናኛ",
        branch_name_english: "Megenagna",
        region: "Addia Ababa",
        longitude: "38.799895",
        latitude: "9.028297",
        status: "active",
    },
    {
        branch_name_amahric: "ጣና",
        branch_name_english: "Tana",
        region: "Addia Ababa",
        longitude: "38.739321",
        latitude: "9.030109",
        status: "active",
    },
    {
        branch_name_amahric: "አዲስ አበባ ዙሪያ ዞን (አራዳ)",
        branch_name_english: "Addis Ababa Zuria Zone",
        region: "Addia Ababa",
        longitude: "38.7404926",
        latitude: "9.0360024",
        status: "active",
    },
    {
        branch_name_amahric: "አምቦ ዞን",
        branch_name_english: "Ambo Zone",
        region: "Oromiya",
        longitude: "37.85943",
        latitude: "8.982109",
        status: "active",
    },
    {
        branch_name_amahric: "ወሊሶ",
        branch_name_english: "Welliso",
        region: "Oromiya",
        longitude: "37.9699566",
        latitude: "8.5423158",
        status: "active",
    },
    {
        branch_name_amahric: "ወልቂጤ",
        branch_name_english: "welkite",
        region: "Gurage",
        longitude: "37.7787839",
        latitude: "8.2900553",
        status: "active",
    },
    {
        branch_name_amahric: "አዳማ ዞን ፖስታ ቤት",
        branch_name_english: "Adama Zone",
        region: "Oromia",
        longitude: "39.2681684",
        latitude: "8.5406906",
        status: "active",
    },
    {
        branch_name_amahric: "አሰላ",
        branch_name_english: "Assela",
        region: "Oromia",
        longitude: "39.13228",
        latitude: "7.95330",
        status: "active",
    },
    {
        branch_name_amahric: "ደብረዘይት",
        branch_name_english: "Debr Zeit",
        region: "Oromia",
        longitude: "38.98334",
        latitude: "8.74990",
        status: "active",
    },
    {
        branch_name_amahric: "ደብረ ብርሃን",
        branch_name_english: "Debre Birhan",
        region: "Amhara",
        longitude: "39.5289608",
        latitude: "9.6767232",
        status: "active",
    },
    {
        branch_name_amahric: "ሻሸመኔ ዞን",
        branch_name_english: "Shashemene Zone",
        region: "Oromia",
        longitude: "38.5992887",
        latitude: "7.1980926",
        status: "active",
    },
    {
        branch_name_amahric: "ዝዋይ",
        branch_name_english: "Zeway",
        region: "Oromia",
        longitude: "38.7166598",
        latitude: "7.9362591",
        status: "active",
    },
    {
        branch_name_amahric: "ባሌ ሮቤ ዞን",
        branch_name_english: "Bale Robe Zone",
        region: "South Region",
        longitude: null,
        latitude: "4263+C5G",
        status: "active",
    },
    {
        branch_name_amahric: "ባሌ ጎባ",
        branch_name_english: "Bale Goba",
        region: "South Region",
        longitude: null,
        latitude: null,
        status: "active",
    },
    {
        branch_name_amahric: "ወላይታ ዞን",
        branch_name_english: "Welayita Zone",
        region: "South Region",
        longitude: "37.7591023",
        latitude: "6.8642429",
        status: "active",
    },
    {
        branch_name_amahric: "ሆሳዕና",
        branch_name_english: "Hossana Zone",
        region: "South Region",
        longitude: "37.8501969",
        latitude: "7.5511109",
        status: "active",
    },
    {
        branch_name_amahric: "ሀዋሳ",
        branch_name_english: "Hawassa Zone",
        region: "South ",
        longitude: "38.4725225",
        latitude: "7.0499355",
        status: "active",
    },
    {
        branch_name_amahric: "ነጌሌ ቦረና",
        branch_name_english: "Negele Borena",
        region: "South ",
        longitude: "39.5757485",
        latitude: "5.3336186",
        status: "active",
    },
    {
        branch_name_amahric: "አርባምንጭ ዞን ፖስታ ቤት",
        branch_name_english: "Arbaminch Zone",
        region: "South Region",
        longitude: "37.5533428",
        latitude: "6.0357814",
        status: "active",
    },
    {
        branch_name_amahric: "ጅንካ",
        branch_name_english: "Jinka",
        region: "South Region",
        longitude: "36.5645122",
        latitude: "5.7867183",
        status: "active",
    },
    {
        branch_name_amahric: "ዲላ",
        branch_name_english: "Dilla Zone",
        region: "South",
        longitude: "38.3118534",
        latitude: "6.4110951",
        status: "active",
    },
    {
        branch_name_amahric: "ሞያሌ",
        branch_name_english: "Moyalie",
        region: "South",
        longitude: "39.0461447",
        latitude: "3.5445583",
        status: "active",
    },
    {
        branch_name_amahric: "ጭሮ",
        branch_name_english: "Chiro",
        region: "Oromia",
        longitude: "40.8657653",
        latitude: "9.0827392",
        status: "active",
    },
    {
        branch_name_amahric: "ድሬዳዋ ዞን",
        branch_name_english: "Dire Dawa Zone",
        region: "Dire Dawa City Administration",
        longitude: "41.8615981",
        latitude: "9.5943039",
        status: "active",
    },
    {
        branch_name_amahric: "ሀረር ዞን",
        branch_name_english: "Harer Zone",
        region: "Harer",
        longitude: "42.1262305",
        latitude: "9.3110642",
        status: "active",
    },
    {
        branch_name_amahric: "ጎዴ",
        branch_name_english: "Gode",
        region: "Somali ",
        longitude: "43.5523252",
        latitude: "5.95106",
        status: "active",
    },
    {
        branch_name_amahric: "ጂጂጋ ዞን",
        branch_name_english: "Jijiga Zone",
        region: "Somali ",
        longitude: "42.7980819",
        latitude: "9.3505617",
        status: "active",
    },
    {
        branch_name_amahric: "አከስታ ቅርንጫፍ ፖ/ቤት",
        branch_name_english: "Akesta",
        region: "Northe East",
        longitude: "39.180014",
        latitude: "10.871744",
        status: "active",
    },
    {
        branch_name_amahric: "ደሴ ዞን",
        branch_name_english: "Dessie Zone",
        region: "Northe East",
        longitude: "39.634510",
        latitude: "11.126109",
        status: "active",
    },
    {
        branch_name_amahric: "ደሴ ኮምቦልቻ ቅርንጫፍ ፖ/ቤት",
        branch_name_english: "Kombolcha",
        region: "Northe East",
        longitude: "39.740078",
        latitude: "11.085241",
        status: "active",
    },
    {
        branch_name_amahric: "ላሊበላ ቅርንጫፍ ፖ/ቤት",
        branch_name_english: "Lalibella",
        region: "Northe East",
        longitude: "39.046202",
        latitude: "12.036370",
        status: "active",
    },
    {
        branch_name_amahric: "ወልዲያ ቅርንጫፍ ፖ/ቤት",
        branch_name_english: "Woldia Zone",
        region: "Northe East",
        longitude: "39.603200",
        latitude: "11.827060",
        status: "active",
    },
    {
        branch_name_amahric: "ሠመራ ዞን",
        branch_name_english: "Semera Zone",
        region: "Northe East",
        longitude: "41.009581",
        latitude: "11.791301",
        status: "active",
    },
    {
        branch_name_amahric: "ባህርዳር ዞን",
        branch_name_english: "Bahir Dar Zone",
        region: "northe west",
        longitude: "37.393351",
        latitude: "11.594395",
        status: "active",
    },
    {
        branch_name_amahric: "ደብረ ማርቆስ",
        branch_name_english: "Debre Markos Zone",
        region: "Amhara",
        longitude: "37.7289608",
        latitude: "10.3328208",
        status: "active",
    },
    {
        branch_name_amahric: "ፍኖተ ሰላም",
        branch_name_english: "Finote Selam",
        region: "Amhara",
        longitude: "37.255609",
        latitude: "10.680017",
        status: "active",
    },
    {
        branch_name_amahric: "ደብረታቦር",
        branch_name_english: "Debre Tabor",
        region: "Amhara",
        longitude: "38.015522",
        latitude: "11.8566874",
        status: "active",
    },
    {
        branch_name_amahric: "ጎንደር",
        branch_name_english: "Gonder Zone",
        region: "Amhara",
        longitude: "37.4675685",
        latitude: "12.612563",
        status: "active",
    },
    {
        branch_name_amahric: "ሁመራ",
        branch_name_english: "Humera",
        region: "Amhara",
        longitude: "36.6110498",
        latitude: "14.2688977",
        status: "active",
    },
    {
        branch_name_amahric: "ቦንጋ  ",
        branch_name_english: "Bonga",
        region: "South West Region",
        longitude: "36.24527",
        latitude: "7.26556",
        status: "active",
    },
    {
        branch_name_amahric: "ሚዛን   ተፈሪ",
        branch_name_english: "Mizan Teferi",
        region: "South West Region",
        longitude: "35.59026",
        latitude: "6.99655",
        status: "active",
    },
    {
        branch_name_amahric: "ደምብዶሎ",
        branch_name_english: "Demibedelo",
        region: "oromia",
        longitude: "34.80100",
        latitude: "8.53635",
        status: "active",
    },
    {
        branch_name_amahric: "ነቀምት ዞን",
        branch_name_english: "Nekemt Zone",
        region: "oromia",
        longitude: "36.5554",
        latitude: "9.0893",
        status: "active",
    },
    {
        branch_name_amahric: "ጋምቤላ",
        branch_name_english: "Gambella Zone",
        region: "Gambela",
        longitude: "34.60264",
        latitude: "8.24240",
        status: "active",
    },
    {
        branch_name_amahric: "መቱ",
        branch_name_english: "Metu",
        region: "Oromia",
        longitude: "35.58326",
        latitude: "8.29653",
        status: "active",
    },
    {
        branch_name_amahric: "አሶሳ ዞን",
        branch_name_english: "Assosa Zone",
        region: "Benishangul Gumuz",
        longitude: "34.53841",
        latitude: "10.06383",
        status: "active",
    },
    {
        branch_name_amahric: "ኣዲግራት",
        branch_name_english: "Adigrat",
        region: "Tigray",
        longitude: null,
        latitude: null,
        status: "active",
    },
    {
        branch_name_amahric: "መቀለ",
        branch_name_english: "Mekelle Zone",
        region: "Tigray",
        longitude: null,
        latitude: null,
        status: "active",
    },
    {
        branch_name_amahric: "ኣድዋ",
        branch_name_english: "Adwa",
        region: "Tigray",
        longitude: null,
        latitude: null,
        status: "active",
    },
    {
        branch_name_amahric: "ኣክሱም",
        branch_name_english: "Axum",
        region: "Tigray",
        longitude: null,
        latitude: null,
        status: "active",
    },
    {
        branch_name_amahric: "ሽረ",
        branch_name_english: "Shire Zone",
        region: "Tigray",
        longitude: null,
        latitude: null,
        status: "active",
    },
    {
        branch_name_amahric: "አዲስ አበባ ዞን ",
        branch_name_english: "Addis Ababa Zone",
        region: "Addia Ababa",
        longitude: "38.7602115",
        latitude: "9.0318292",
        status: "active",
    },
    {
        branch_name_amahric: "ጅማ ዞን",
        branch_name_english: "Jimma Zone",
        region: " \n                 \nOromia\n",
        longitude: "36.83026",
        latitude: "7.67586",
        status: "active",
    },
    {
        branch_name_amahric: "ቻንጊ ",
        branch_name_english: "Chagni",
        region: "northe west",
        longitude: "36.5127625",
        latitude: "10.9505200",
        status: "active",
    },
];
