import React from 'react'
import op from '../../../../assets/op.png'
import nidethiopost from '../../../../assets/nidethiopost.png'

nidethiopost
const Body = () => {
    return (
        <div className='py-32 max-w-screen'>
            <div className='flex items-center flex-wrap justify-center px-5 space-y-5'>
                <img src={op} className='w-full max-w-[500px]' />
                <img src={nidethiopost} className='w-full max-w-[500px]' />
            </div>
        </div>
    )
}

export default Body