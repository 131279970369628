import React from "react";
import { useTranslation } from "react-i18next";

const Toll = () => {
    const { t, i18n } = useTranslation();
    return (
        <div className='p-4 h-full'>
            <div className='flex flex-col items-center justify-center gap-5 h-fit'>
                <div className='flex flex-col text-center gap-5'>
                    <h1 className='text-3xl md:text-4xl font-semibold text-MetallicBlue'>
                        {t("toll-free")}
                    </h1>
                    <div className='font-semibold text-black/60 text-xl text-justify'>
                        {i18n.language === "amh" ? (
                            <p>
                                ተጨማሪ ጥያቄዎች አሉዎት ወይም እርዳታ ይፈልጋሉ? የድጋፍ ቡድናችን እርስዎን
                                ለመርዳት ዝግጁ ነው። አገልግሎት ለማግኘት በ
                                <a
                                    href='tel:9779'
                                    className='text-MetallicBlue/100'
                                >
                                    9779
                                </a>
                                . ያግኙ።
                            </p>
                        ) : (
                            <p>
                                Have any further questions or need help? Our
                                support team is ready to assist you. Reach out
                                on our free hotline at
                                <a
                                    href='tel:9779'
                                    className='text-MetallicBlue/100'
                                >
                                    {" "}
                                    9779
                                </a>{" "}
                            </p>
                        )}
                    </div>
                </div>
                <a href='tel:9779'>
                    <p className='text-xl text-white px-16 bg-green hover:bg-[#083247] rounded-md py-2'>
                        {t("toll-call-now")}
                    </p>
                </a>
            </div>
        </div>
    );
};

export default Toll;
