import React from "react";
import { useTranslation } from "react-i18next";

const Hero = () => {
    const { t } = useTranslation();
    return (
        <div class='bg-media-hero bg-center bg-cover '>
            <div className='px-10 md:px-0 bg-hero-gradient'>
                <div class='grid grid-cols-1 md:gap-16 md:px-6 md:py-8'></div>
                <div className='flex justify-start text-4xl pt-36 md:pt-24 md:p-0 md:pr-40 md:text-8xl  md:pl-28'>
                    <h1 className='pr-2 mb-0 font-bold text-white '>
                        {t("media-latest")}
                    </h1>

                    <h3 className='pb-2 font-medium  text-green'>
                        {t("media-updates")}
                    </h3>
                </div>

                <div className='pb-16 text-base text-white md:text-4xl md:pl-28  '>
                    <p className='md:mr-[60%]'>{t("media-subtitle")} </p>
                </div>
            </div>
        </div>
    );
};

export default Hero;
