import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useMediaQuery } from "react-responsive";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { useTranslation } from "react-i18next";
import youtubeIcon from "../../assets/youtube-icon.png";
import telegramIcon from "../../assets/telegram-icon.png";
import { AiOutlineMail } from "react-icons/ai";
import { Link, NavLink, useNavigate } from "react-router-dom";
import tiktokLogo from "../../assets/tiktokLogo.png";
import xlogo from "../../assets/xlogo.png";
import { FaPhoneAlt } from "react-icons/fa";
import Hotline from "../../assets/hotline.png";
const SmallFooter = ({ hotline }) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

    const handleDownload = async () => {
        const staticBaseUrl = window.location.origin + "/static/";
        const fileName = "logos.zip";
        const fileUrl = `${staticBaseUrl}${fileName}`;

        // Trigger the download
        window.location.href = fileUrl;
    };

    return (
        <footer class='bg-footer bg-right-bottom bg-cover'>
            {isDesktopOrLaptop ? (
                <div class='grid grid-cols-2 px-6 pl-28 py-8 md:grid-cols-2 '>
                    <div
                        className={`p-6 ${"grid 2xl:grid-cols-2 gap-2 xl:gap-x-24 xl:gap-y-2"}`}
                    >
                        <div>
                            {/****************************************** title ,  Social Media Icons wrapper****************************************************/}
                            <div
                                className={
                                    isDesktopOrLaptop
                                        ? "flex "
                                        : "flex flex-col"
                                }
                            >
                                {/******************************************  Title and Description ****************************************************/}
                                <div className='text-center md:text-start '>
                                    <h2 class='text-4xl lg:text-5xl md:text-5xl   text-white dark:text-white'>
                                        <span className='text-4xl font-extrabold lg:text-5xl md:text-5xl text-green dark:text-green '>
                                            {/* Connect{" "} */}{" "}
                                            {t("footer-connect-with-us")}
                                        </span>{" "}
                                        {/* with us */}
                                    </h2>
                                    <p class='flex px-10 md:px-0  md:justify-start  text-base  text-white '>
                                        {/* For any questions or comments feel free to contact us here */}{" "}
                                        {t("footer-questions")}
                                    </p>
                                </div>

                                {/******************************************  Social Media Icons ****************************************************/}
                            </div>

                            {/****************************************** Contact Form ****************************************************/}

                            {/******************************************  Contact information ****************************************************/}
                            <div className='flex flex-row w-full gap-5 mt-4 lg:flex-col md:gap-8'>
                                <p class='flex justify-start items-start text-white text-xs md:text-sm xl:w-full'>
                                    <LocationOnIcon />
                                    <a
                                        className='h-1 text-xs leading-tight md:font-medium md:ml-2 md:text-base  hover:cursor-pointer'
                                        href='https://maps.app.goo.gl/pRcCGYWL2QQiQDKP7'
                                        target='_blank'
                                    >
                                        {t("address")}
                                    </a>
                                </p>

                                <div class='flex justify-start  text-white md:text-base text-xs font-normal '>
                                    <a
                                        href='mailto:info@id.et'
                                        className='mx-8'
                                    >
                                        {" "}
                                        <AiOutlineMail className='mt-1 -mx-7 ' />
                                    </a>

                                    <div className='flex pt-1 md:pt-0'>
                                        <p className='text-xs font-bold leading-none text-green md:text-base md:mr-2 -mx-8'>
                                            {" "}
                                            {/* Call Us{" "} */} {t("email")}
                                        </p>
                                        <p className='pl-2 text-xs leading-none md:pl-0 md:text-base'>
                                            {/* +251111400787 */}{" "}
                                            <a href='mailto:info@id.et'>
                                                {t("Email")}{" "}
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div class='flex justify-start text-white md:text-base text-xs font-normal '>
                                    <svg
                                        aria-hidden='true'
                                        focusable='false'
                                        data-prefix='fas'
                                        data-icon='phone'
                                        class='md:w-4 w-4 mr-3 leading-none ml-1'
                                        role='img'
                                        xmlns='http://www.w3.org/2000/svg'
                                        viewBox='0 0 512 512'
                                    >
                                        <path
                                            fill='currentColor'
                                            d='M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z'
                                        ></path>
                                    </svg>
                                    <div className='flex pt-1 md:pt-0'>
                                        <p className='text-xs font-bold leading-none text-green md:text-base md:mr-2'>
                                            {" "}
                                            {/* Call Us{" "} */} {t("call-us")}
                                        </p>
                                        <p className='pl-2 text-xs leading-none md:pl-0 md:text-base'>
                                            {/* +251111400787 */}
                                            <a
                                                className='hover:cursor-pointer'
                                                href='tel:+251 113 720 006'
                                            >
                                                {t("Phone")}{" "}
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-col w-fit'>
                            <p className='text-xl 2xl:text-3xl text-center text-white font-semibold'>
                                <span className='font-bold text-green md:text-2xl  '>
                                    {t("hotline")}
                                </span>
                            </p>
                            <a href='tel:9779'>
                                <div className='flex mt-3 md:mt-3 xl:mt-5 xl:justify-start'>
                                    <img
                                        src={Hotline}
                                        className='h-[40px] 2xl:h-[50px]'
                                    />
                                </div>
                            </a>
                        </div>
                    </div>

                    {/****************************************** Twitter Embed , Links , Copyright ****************************************************/}
                    <div className='ml-4 md:ml-10'>
                        <div class='text-center md:text-left'>
                            <h1 className='hidden pt-0 text-2xl font-bold md:mb-3 md:pt-10 text-green md:text-2xl  md:flex'>
                                {/* Links */} {t("links")}
                            </h1>

                            <div className='flex justify-center items-center flex-wrap w-full gap-0 mb-0 text-xs tracking-tighter text-left md:justify-start md:tracking-tight md:gap-1 md:text-base place-items-center'>
                                <a
                                    href='/help'
                                    class='text-white font-medium hover:scale-105 hover:text-green w-full max-w-fit'
                                >
                                    {/* Website Policy */} {t("help")} &nbsp;|
                                    &nbsp;
                                </a>
                                <a
                                    href='https://linktr.ee/NIDP?utm_source=qr_code'
                                    class='text-white font-medium hover:scale-105 hover:text-green w-full max-w-fit'
                                >
                                    {t("footer-socials")}&nbsp;| &nbsp;
                                </a>
                                <a
                                    href='/websitepolicies'
                                    class='text-white font-medium hover:scale-105 hover:text-green w-full max-w-fit'
                                >
                                    {/* Website Policy */} {t("website-policy")}{" "}
                                    &nbsp;| &nbsp;
                                </a>
                                <a
                                    href='/websiteterms'
                                    class='text-white   font-medium hover:scale-105 hover:text-green'
                                >
                                    {" "}
                                    {/* Terms &amp; Conditions&nbsp;|&nbsp; */}{" "}
                                    {t("terms-conditions")} &nbsp;| &nbsp;
                                </a>
                                {/* <a
                  href="#"
                  class="text-white  font-medium hover:scale-105 hover:text-green"
                >
                  {" "}
                  Privacy Policy&nbsp;|&nbsp; 
                  {t("privacy-policy")}{" "}
                  &nbsp;| &nbsp;
                </a> */}
                                <a
                                    href='/websitecopyright'
                                    class='text-white font-medium hover:scale-105 hover:text-green w-full max-w-fit'
                                >
                                    {" "}
                                    {/* Copyright Policy&nbsp;|&nbsp; */}{" "}
                                    {t("copyright-policy")} &nbsp;| &nbsp;
                                </a>
                                <a
                                    href='/career'
                                    class='text-white  font-medium hover:scale-105 hover:text-green'
                                >
                                    {" "}
                                    {/* Copyright Policy&nbsp;|&nbsp; */}{" "}
                                    {t("career-footer")} &nbsp;| &nbsp;
                                </a>
                                <a
                                    href='/cardprint'
                                    class='text-white  font-medium hover:scale-105 hover:text-green'
                                >
                                    {" "}
                                    {/* Copyright Policy&nbsp;|&nbsp; */}{" "}
                                    {t("print")} &nbsp;| &nbsp;
                                </a>
                                <a
                                    class='text-white font-medium hover:scale-105 hover:text-green hover:cursor-pointer'
                                    onClick={handleDownload}
                                    // download='logos.zip'
                                >
                                    {t("logo-footer")}
                                    &nbsp;| &nbsp;
                                </a>
                            </div>

                            <span class='mt-2 text-xs font-medium  text-white dark:text-white md:text-base text-center place-items-center flex md:justify-start justify-center'>
                                {t("national-id")} {t("all-rights")}{" "}
                                {new Date().getFullYear()}
                            </span>
                            <div class='flex md:mt-10 mt-6 ml-0 md:ml-0 gap-3 lg:justify-start justify-center '>
                                <a
                                    target='_blank'
                                    href='https://www.facebook.com/IDethiopia/?ref=pages_you_manage'
                                    className='flex text-white justify-center border-2 border-white rounded-full w-9 h-9 place-items-center hover:border-none hover:bg-green hover:scale-105 '
                                >
                                    <svg
                                        aria-hidden='true'
                                        focusable='false'
                                        data-prefix='fab'
                                        data-icon='facebook-f'
                                        class='w-3 '
                                        role='img'
                                        xmlns='http://www.w3.org/2000/svg'
                                        viewBox='0 0 320 512'
                                    >
                                        <path
                                            fill='currentColor'
                                            d='M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z'
                                        ></path>
                                    </svg>
                                </a>

                                <a
                                    target='_blank'
                                    href='https://twitter.com/IDethiopia'
                                    className='flex text-white justify-center border-2 border-white rounded-full w-9 h-9 place-items-center hover:border-none hover:bg-green hover:scale-105'
                                >
                                    <img
                                        src={xlogo}
                                        className='w-5 pt-0'
                                        alt='fireSpot'
                                    />
                                </a>

                                <a
                                    target='_blank'
                                    href='https://www.youtube.com/channel/UCf3SnLcgf5jzxhkeMTf2fRA'
                                    className='flex text-white justify-center border-2 border-white rounded-full w-9 h-9 place-items-center hover:border-none hover:bg-green hover:scale-105'
                                >
                                    <img
                                        src={youtubeIcon}
                                        className='w-5 pt-0'
                                        alt='fireSpot'
                                    />
                                </a>

                                <a
                                    target='_blank'
                                    href='https://www.linkedin.com/company/idethiopia'
                                    className='flex text-white justify-center border-2 border-white rounded-full w-9 h-9 place-items-center hover:border-none hover:bg-green hover:scale-105'
                                >
                                    <svg
                                        aria-hidden='true'
                                        focusable='false'
                                        data-prefix='fab'
                                        data-icon='linkedin-in'
                                        class='w-5'
                                        role='img'
                                        xmlns='http://www.w3.org/2000/svg'
                                        viewBox='0 0 448 512'
                                    >
                                        <path
                                            fill='currentColor'
                                            d='M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z'
                                        ></path>
                                    </svg>
                                </a>

                                <a
                                    target='_blank'
                                    href='https://t.me/IDethiopia'
                                    className='flex text-white justify-center border-2 border-white rounded-full w-9 h-9 place-items-center hover:border-none hover:bg-green hover:scale-105'
                                >
                                    <img
                                        src={telegramIcon}
                                        className='w-5'
                                        alt='fireSpot'
                                    />
                                </a>
                                <a
                                    target='_blank'
                                    href='https://www.tiktok.com/@idethiopia'
                                    className='flex text-white justify-center border-2 border-white rounded-full w-10 h-10 place-items-center hover:border-none hover:bg-green hover:scale-105'
                                >
                                    <img
                                        src={tiktokLogo}
                                        className='w-4'
                                        alt='fireSpot'
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <footer class='bg-footer bg-right-bottom bg-cover min-h-96'>
                        <div class='grid grid-cols-1 gap-2 pt-8 md:grid-cols-2 bg-mobile-footer-gradient'>
                            <div className='px-6 w-full min-h-96'>
                                {/****************************************** title ,  Social Media Icons wrapper****************************************************/}
                                <div
                                    className={
                                        isDesktopOrLaptop
                                            ? "flex "
                                            : "flex flex-col "
                                    }
                                >
                                    {/******************************************  Title and Description ****************************************************/}
                                    <div className='text-start'>
                                        <h2 class=' text-white dark:text-white'>
                                            <span className='text-xl font-medium text-darkgreen dark:text-darkgreen '>
                                                {t("footer-address-mobile")}
                                                <hr className='w-[16%] border-2 border-white' />
                                                <hr className='bg-white border-white opacity-30' />
                                            </span>
                                        </h2>
                                    </div>

                                    {/******************************************  Social Media Icons ****************************************************/}
                                </div>

                                {/****************************************** Contact Form ****************************************************/}

                                {/******************************************  Contact information ****************************************************/}
                                <div className='flex flex-col w-full gap-5 mt-4 lg:flex-col md:gap-8 mb-3'>
                                    <p class='flex justify-start items-start text-white text-xs md:text-sm mt-4'>
                                        <LocationOnIcon />
                                        <a
                                            className='h-1 text-sm leading-tight md:font-medium md:ml-2 md:text-lg  hover:cursor-pointer'
                                            href='https://maps.app.goo.gl/pRcCGYWL2QQiQDKP7'
                                            target='_blank'
                                        >
                                            {t("address")}
                                        </a>
                                    </p>

                                    <div class='flex justify-start items-center text-white md:text-base text-xs font-normal w-[50%]  '>
                                        <a
                                            href='mailto:info@id.et'
                                            className='me-1 pr-1'
                                        >
                                            {" "}
                                            <AiOutlineMail className='text-2xl' />
                                        </a>
                                        <div className='flex pt-1 md:pt-0'>
                                            <p className='text-sm font-bold leading-none text-darkgreen'>
                                                {" "}
                                                {t("email")}
                                            </p>
                                            <p className='pl-2 text-sm leading-none'>
                                                <a href='mailto:info@id.et'>
                                                    {t("Email")}{" "}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    <div class='flex flex-row justify-start  text-white md:text-base text-xs font-normal w-[100%]  '>
                                        <svg
                                            aria-hidden='true'
                                            focusable='false'
                                            data-prefix='fas'
                                            data-icon='phone'
                                            class='md:w-4 w-4  mr-2 leading-none '
                                            role='img'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 512 512'
                                        >
                                            <path
                                                fill='currentColor'
                                                d='M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z'
                                            ></path>
                                        </svg>
                                        <div className='flex items-center pt-1 md:pt-0'>
                                            <p className='text-xs font-bold leading-none text-darkgreen dark:text-darkgreen md:text-base md:mr-2'>
                                                {" "}
                                                {/* Call Us{" "} */}{" "}
                                                {t("call-us")}
                                            </p>
                                            <p className='pl-2 text-xs leading-none md:pl-0 md:text-base'>
                                                {/* +251111400787 */}
                                                <a
                                                    className='hover:cursor-pointer'
                                                    href='tel:+251 113 720 006'
                                                >
                                                    {t("Phone")}{" "}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='flex flex-col w-full'>
                                        <p className='text-xl 2xl:text-3xl text-white 2xl:text-center font-semibold'>
                                            {t("hotline")}
                                        </p>

                                        <a href='tel:9779'>
                                            <div className='flex mt-2 md:mt-3'>
                                                <img
                                                    src={Hotline}
                                                    className='h-[40px] 2xl:h-[50px]'
                                                />
                                            </div>
                                        </a>
                                    </div>
                                    <div className=''>
                                        <h2 class=' text-white dark:text-white'>
                                            <span className='text-4xl font-medium text-darkgreen dark:text-darkgreen '>
                                                {t("links")}
                                            </span>
                                        </h2>
                                        <hr className='w-[16%] border-2 border-white' />
                                        <hr className='bg-white border-white opacity-30' />
                                    </div>
                                    <div class='flex mt-2 gap-3 '>
                                        <div className='flex justify-center border-2 border-white rounded-full w-8 h-8 place-items-center hover:border-none hover:bg-green hover:scale-105 '>
                                            <a
                                                href='https://www.facebook.com/IDethiopia/?ref=pages_you_manage'
                                                class=' text-white '
                                                target='_blank'
                                            >
                                                <svg
                                                    aria-hidden='true'
                                                    focusable='false'
                                                    data-prefix='fab'
                                                    data-icon='facebook-f'
                                                    class='w-2 '
                                                    role='img'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    viewBox='0 0 320 512'
                                                >
                                                    <path
                                                        fill='currentColor'
                                                        d='M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z'
                                                    ></path>
                                                </svg>
                                            </a>
                                        </div>

                                        <div className='flex justify-center border-2 border-white rounded-full w-8 h-8 place-items-center hover:border-none hover:bg-green hover:scale-105'>
                                            <a
                                                href='https://twitter.com/IDethiopia'
                                                class=' text-white '
                                                target='_blank'
                                            >
                                                <img
                                                    src={xlogo}
                                                    className='w-5 pt-0'
                                                    alt='fireSpot'
                                                />
                                            </a>
                                        </div>

                                        <div className='flex justify-center border-2 border-white rounded-full w-8 h-8 place-items-center hover:border-none hover:bg-green hover:scale-105'>
                                            <a
                                                href='https://www.youtube.com/channel/UCf3SnLcgf5jzxhkeMTf2fRA'
                                                class=' text-white'
                                                target='_blank'
                                            >
                                                <img
                                                    src={youtubeIcon}
                                                    className='w-4 pt-0'
                                                    alt='fireSpot'
                                                />
                                            </a>
                                        </div>

                                        <div className='flex justify-center border-2 border-white rounded-full w-8 h-8 place-items-center hover:border-none hover:bg-green hover:scale-105'>
                                            <a
                                                href='https://www.linkedin.com/company/idethiopia'
                                                class=' text-white'
                                                target='_blank'
                                            >
                                                <svg
                                                    aria-hidden='true'
                                                    focusable='false'
                                                    data-prefix='fab'
                                                    data-icon='linkedin-in'
                                                    class='w-3'
                                                    role='img'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    viewBox='0 0 448 512'
                                                >
                                                    <path
                                                        fill='currentColor'
                                                        d='M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z'
                                                    ></path>
                                                </svg>
                                            </a>
                                        </div>

                                        <div className='flex justify-center border-2 border-white rounded-full w-8 h-8 place-items-center hover:border-none hover:bg-green hover:scale-105'>
                                            <a
                                                href='https://t.me/IDethiopia'
                                                class=' text-white'
                                                target='_blank'
                                            >
                                                <img
                                                    src={telegramIcon}
                                                    className='w-4'
                                                    alt='fireSpot'
                                                />
                                            </a>
                                        </div>
                                        <div className='flex justify-center border-2 border-white rounded-full w-8 h-8 place-items-center hover:border-none hover:bg-green hover:scale-105'>
                                            <a
                                                href='https://www.tiktok.com/@idethiopia'
                                                class=' text-white'
                                                target='_blank'
                                            >
                                                <img
                                                    src={tiktokLogo}
                                                    className='w-4'
                                                    alt='fireSpot'
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                {/****************************************** Twitter Embed , Links , Copyright ****************************************************/}
                                <div className='flex flex-row w-full'>
                                    <div className='text-xs w-full'>
                                        <Link
                                            class='text-white font-medium hover:scale-105 hover:text-green w-full max-w-fit'
                                            to='/websitepolicies'
                                        >
                                            {t("website-policy")}&nbsp;| &nbsp;
                                        </Link>

                                        <Link
                                            class='text-white font-medium hover:scale-105 hover:text-green w-full max-w-fit'
                                            to='/websiteterms'
                                        >
                                            {" "}
                                            {t("terms-conditions")}&nbsp;|&nbsp;
                                        </Link>

                                        <Link
                                            class='text-white font-medium hover:scale-105 hover:text-green w-full max-w-fit'
                                            to='/websitecopyright'
                                        >
                                            {" "}
                                            {t("copyright-policy")}&nbsp;|&nbsp;
                                        </Link>
                                        <Link
                                            class='text-white font-medium hover:scale-105 hover:text-green w-full max-w-fit'
                                            to='/help'
                                        >
                                            {" "}
                                            {/* Copyright Policy&nbsp;|&nbsp; */}{" "}
                                            {t("help")} &nbsp;| &nbsp;
                                        </Link>
                                        <a
                                            class='text-white font-medium hover:scale-105 hover:text-green w-full max-w-fit'
                                            href='https://card-order.fayda.et/'
                                        >
                                            {" "}
                                            {/* Copyright Policy&nbsp;|&nbsp; */}{" "}
                                            {t("print")} &nbsp;| &nbsp;
                                        </a>
                                        <Link
                                            class='text-white font-medium hover:scale-105 hover:text-green w-full max-w-fit'
                                            to='/career'
                                        >
                                            {" "}
                                            {/* Copyright Policy&nbsp;|&nbsp; */}{" "}
                                            {t("career-footer")} &nbsp;| &nbsp;
                                        </Link>
                                        <a
                                            class='text-white font-medium hover:scale-105 hover:text-green hover:cursor-pointer'
                                            href={
                                                process.env.PUBLIC_URL +
                                                "/static/logos.zip"
                                            }
                                            download='logos.zip'
                                        >
                                            {t("logo-footer")}
                                            &nbsp;| &nbsp;
                                        </a>
                                        {/* <a
                      href="#"
                      class="text-white font-medium w-full hover:scale-105 hover:text-green"
                    >
                      {" "}
                      {t("help")}
                    </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                    <div className=' text-center h-20 bg-MetallicBlue pt-6'>
                        <span class='text-xs font-medium text-white dark:text-white'>
                            {new Date().getFullYear()} {t("national-id")}{" "}
                            {t("all-rights")}
                        </span>
                    </div>
                </>
            )}
        </footer>
    );
};
export default SmallFooter;
