import React, { useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import FooterService from "../../components/footer/footer";
import TopBar from "../../components/navbar/navbar";
import Sidebar from "../../components/sidebar/sidebar";
import { ViewContext } from "../../providers/viewContext";
import i18next from "i18next";

const FooterTerms = () => {
    const { view, setView } = useContext(ViewContext);
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            {isDesktopOrLaptop ? (
                <TopBar viewState={view ? "citizen" : "stakeholder"} />
            ) : (
                <Sidebar />
            )}
            {i18next.language == "en" ? (
                <>
                    <div class='bg-faq bg-center bg-cover flex '>
                        <div className='bg-hero-gradient flex h-full w-full '>
                            <div className='flex justify-start text-4xl md:text-8xl  w-full md:pt-56 pt-32 md:pl-40 pl-10'>
                                <h1 className='pr-2 pb-10 font-bold  text-white w-full '>
                                    Terms and Conditions
                                </h1>
                            </div>
                        </div>
                    </div>

                    <div class='container mx-auto px-4 py-8 '>
                        <p class='mb-4'>
                            Welcome to the Fayda ID website, a government
                            website designed by the National ID Program (NIDP)
                            to facilitate access to various government services
                            and resources. By using this website, you agree to
                            comply with and be bound by the following terms and
                            conditions. Please read them carefully before using
                            the website. We encourage you to read the terms and
                            conditions, before using the website.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            1. Use of the Website
                        </h2>
                        <p class='mb-4'>
                            Fayda ID is designed to provide users with access to
                            government services and resources. You are granted
                            permission to use the website only for lawful
                            purposes and in accordance with these terms and
                            conditions. You agree not to use the website in any
                            way that could damage, disable, overburden, or
                            impair the website or interfere with any other
                            party's use of the website.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            2. Intellectual Property
                        </h2>
                        <p class='mb-4'>
                            All content on the website, including but not
                            limited to text, graphics, logos, images, and
                            software, is the property of the National ID Program
                            or its content suppliers and is protected by
                            copyright and other intellectual property laws. You
                            may not use any of the content on the website
                            without the express written consent of NIDP.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>3. User Accounts</h2>
                        <p class='mb-4'>
                            In order to access certain features of the website,
                            you may need to create a user account. You are
                            responsible for maintaining the confidentiality of
                            your account information, including your password.
                            You agree to notify NIDP immediately of any
                            unauthorized use of your account or password or any
                            other breach of security.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>4. User Content</h2>
                        <p class='mb-4'>
                            Any content you post or submit to the website,
                            including but not limited to comments, feedback, and
                            suggestions, will be considered non-confidential and
                            non-proprietary. By posting or submitting content,
                            you grant NIDP a non-exclusive, royalty-free,
                            perpetual, irrevocable, and fully sublicensable
                            right to use, reproduce, modify, adapt, publish,
                            translate, create derivative works from, distribute,
                            and display such content throughout the world in any
                            media.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            5. Limitation of Liability
                        </h2>
                        <p class='mb-4'>
                            NIDP will not be liable for any damages of any kind
                            arising from the use of this website, including but
                            not limited to direct, indirect, incidental,
                            punitive, and consequential damages. NIDP does not
                            warrant that the website will be error-free or
                            uninterrupted.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            6. Indemnification
                        </h2>
                        <p class='mb-4'>
                            You agree to indemnify and hold NIDP harmless from
                            any claim or demand, including reasonable attorneys'
                            fees, made by any third party due to or arising out
                            of your use of the website, your violation of these
                            terms and conditions, or your violation of any
                            rights of another.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>7. Termination</h2>
                        <p class='mb-4'>
                            NIDP may terminate or suspend your access to all or
                            part of the website, without notice, for any reason.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>8. Governing Law</h2>
                        <p class='mb-4'>
                            These terms and conditions shall be governed by and
                            construed in accordance with the laws of Ethiopia.
                            Any legal action or proceeding arising out of or
                            relating to these terms and conditions shall be
                            brought to the courts of Ethiopia.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>9. Modification</h2>
                        <p class='mb-4'>
                            NIDP may modify these terms and conditions at any
                            time and without prior notice. Your continued use of
                            the website after any such modification shall
                            constitute your acceptance of the modified terms and
                            conditions.
                        </p>
                        <p class='mb-4'>
                            By using the Fayda ID website, you acknowledge that
                            you have read and understood these terms and
                            conditions and agree to be bound by them. If you do
                            not agree to these terms and conditions, please do
                            not use the website.
                        </p>
                    </div>
                </>
            ) : (
                <>
                    <div class='bg-faq bg-center bg-cover flex '>
                        <div className='bg-hero-gradient flex h-full w-full '>
                            <div className='flex justify-start text-4xl md:text-8xl  w-full md:pt-56 pt-32 md:pl-40 pl-10'>
                                <h1 className='pr-2 pb-10 font-bold  text-white w-full '>
                                    ደንብ እና ግዴታዎች
                                </h1>
                            </div>
                        </div>
                    </div>

                    <div class='container mx-auto px-4 py-8 '>
                        <p class='mb-4'>
                            እንኳን በብሄራዊ መታወቂያ ፕሮግራም የተለያዩ የመንግስት አገልግሎቶችን እና
                            መረጃዎችን ለማቅረብ ወደተዘጋጀው ወደ ፋይዳ መታወቂያ ድህረ ገፅ በሰላም መጡ። ይህ
                            ድህረ ገፅ በመጠቀሞ፣ በዚህ ደንብ እና ግዴታ ለመገዛት ፈቅደዋል። እባኮን ደንብ
                            እና ግዴታዎቹን በጥንቃቄ ያንብቡ። በዚህ ደንብ እና ግዴታ የማይስማሙ ከሆነ ድህረ
                            ገፁን ባይጠቀሙ ይመከራል።
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            1. ድህረ ገፁን ስለመጠቀም፤
                        </h2>
                        <p class='mb-4'>
                            ፋይዳ መታወቂያ የተዘጋጀው ተጠቃሚዎች የመንግስት አገልግሎት እና መረጃዎች ማግኘት
                            እንዲችሉ ነው። ይህን ድህረ ገፅ መጠቀም የሚችሉት በህግ አግባብ እና ይህን ደንብ
                            እና ግዴታዎች በማክበር ብቻ ነው። ይህን ድህረ ገፅ በሚያበላሽ፣ ጫና በሚያሳድር፣
                            በሚጎዳ፣ ወይም ድህረገፁን በሚያስተጓጉል ወይም ሌሎች ተጠቃሚዎች አጠቃቀም ላይ
                            ጣልቃ በሚገባ መልኩ መጠቀም አይችሉም።
                        </p>

                        <h2 class='text-xl font-bold mb-2'>2. አእምሯዊ ንብረት፤</h2>
                        <p class='mb-4'>
                            በዚህ ድህረ ገፅ የተካተቱ ፅሁፎች፣ ግራፊክስ፣ ምልክቶች፣ ምስሎች፣ አርማዎች፣ እና
                            ሶፍት ዌሮች የብሄራዊ መታወቂያ ወይንም የይዘት አቅራቢዎቹ ንብረት በመሆኑ
                            በአእምሯዊ ንብረት ጥበቃ ህግ የተጠበቀ ነው። በድህረ ገፁ የተቀመጠውን ይዘት
                            ያለብሄራዊ መታወቂያ ፍቃድ መጠቀም አይችሉም።
                        </p>

                        <h2 class='text-xl font-bold mb-2'>3. የተጠቃሚ አካውንት፤</h2>
                        <p class='mb-4'>
                            የድህረ ገፁን አንድ አንድ አገልግሎቶች ለመጠቀም፣ አካውንት እንዲከፍቱ ሊጠየቁ
                            ይችላሉ። የይለፍ ቃልን ጨምሮ የአካውንቶን መረጃዎች በሚስጥራዊነት መጠበቅ ሀላፊነት
                            አለቦት። ከፍቃዶ ውጪ በማንኛውም ሁኔታ የአካውንቶ ወይም የይለፍ ቃሎ ጥቅም ላይ
                            ከዋለ ወይም የደህንነት መተላለፍ ከተፈጠረ፣ ለብሄራዊ መታወቂያ ፕሮግራም ወዲያውኑ
                            ለማሳወቅ ተስማምተዋል።
                        </p>

                        <h2 class='text-xl font-bold mb-2'>4. የተጠቃሚው ይዘት:</h2>
                        <p class='mb-4'>
                            ማንኛውም እርሶ የሚያጋሩት ልጥፍ ወይም አስተያየት፣ ግብረ መልስ፣ እና ጥቆማ ጨምሮ
                            ወደ ድህረ ገፁ የሚልኩት ይዘት፣ ሚስጥራዊ ወይም የንብረትነት ይዘት ያለው
                            አይደለም። ይዘቱን በማጋራቶ፣ ለብሄራዊ መታወቂያ ፕሮግራም በብቸኝነት የማይያዝ፣
                            ከክፍያ ነፃ የሆነ፣ የማይሻር፣ እና ለሌሎች ሊተላለፍ የሚችል የመጠቀም፣ የማባዛት፣
                            የማስተካከል፣ የመለወጥ፣ የማሳተም፣ የመተርጎም፣ ተጓዳኝ ስራዎችን የመፍጠር፣
                            የማሰራጨት፣ እና ማንኛውንም ሚዲያ በመጠቀም ይዘቱን በዓለም ዓቀፍ ሚዲያ የማጋራት
                            መብት ለመስጠት ተስማምተዋል።
                        </p>

                        <h2 class='text-xl font-bold mb-2'>5. የተጠያቂነት ገድብ:</h2>
                        <p class='mb-4'>
                            ይህ ድህረገፅ በመጠቀሞ ሊከሰት የሚችል ማንኛውም ቀጥተኛ፣ ቀጥተኛ ያልሆነ፣
                            ያጋጣሚ፣ ቅጣት የሚያስከትል፣ እና ጉዳት ያለው ሀላፊነት የብሄራዊ መታወቂያ
                            ፕሮግራም ተጠያቂ አይሆንም። ብሄራዊ መታወቂያ ፕሮግራም ድህረ ገፁ ከስህተት የፀዳ
                            ወይም ያልተቋረጠ ስለመሆኑ ዋስትና አይሰጥም።
                        </p>

                        <h2 class='text-xl font-bold mb-2'>6. ካሳ:</h2>
                        <p class='mb-4'>
                            ብሄራዊ መታወቂያ ፕሮግራም እርሶ ይህን ድህረ ገፅ በመጠቀሞ ምክንያት፣ ደንብ እና
                            ግዴታዎችን በመጣሶ፣ ወይም የሌሎች ሰዎችን መብት በመጣሶ ምክንያት በሶስተኛ ወገኞች
                            ለሚቀርብ የሚችል ክስ፣ የጠበቃ ወጪን ጨምሮ ተጠያቂ ይሆናሉ።
                        </p>

                        <h2 class='text-xl font-bold mb-2'>7. መቋረጥ:</h2>
                        <p class='mb-4'>
                            ብሄራዊ መታወቂያ ፕሮግራም ያለምንም ማስታወቂያ እና በማናቸውም ምክንያት ይህን
                            ድረገፅ በከፊል ወይም ሙሉ በሙሉ እንዳይጠቀሙ ሊያደርግ ይችላል።
                        </p>

                        <h2 class='text-xl font-bold mb-2'>8. ገዢ ህግ: </h2>
                        <p class='mb-4'>
                            እነዚህ ደንብ እና ግዴታዎች በኢትዮጵያ ህግ መሰረት የሚገዙ ናቸው። ከእነዚህ ደንብ
                            እና ግዴታዎች ጋር ተያይዞ የሚነሱ ማንኛውም ክስ እና ተጠያቂነቶች ኢትዮጵያ ውስጥ
                            ላለ ፍርድ ቤት የሚቀርቡ ናቸው።
                        </p>

                        <h2 class='text-xl font-bold mb-2'>9. ማሻሻያዎች: </h2>
                        <p class='mb-4'>
                            ብሄራዊ መታወቂያ ፕሮግራም እነዚህ ደንብ እና ግዴታዎች በማንኛውም ግዜ ያለ
                            ማስጠንቀቂያ ሊያሻሽላቸው ይችላል። ከማሻሻያዎቹ በኋላ ድህረ ገፁን መጠቀም ከቀጠሉ
                            በማሻሻያዎቹ እንደተስማሙ ይቆጠራል።
                        </p>
                        <p class='mb-4'>
                            ፋይዳ ድህረ ገፅን በመጠቀሞ፣ እነዚህን ደንብ እና ግዴታዎች አንብበው፣ ተረድተው
                            ለመገዛት ተስማምተዋል። በዚህ ደንብ እና ግዴታዎች የማይሳሙ ከሆነ ድህረ ገፁን
                            አይጠቀሙ።
                        </p>
                    </div>
                </>
            )}

            <FooterService />
        </>
    );
};

export default FooterTerms;
