import React from "react";

const Hero = () => {
    return (
        <div class=' bg-benefits-hero bg-cover md:h-fit h-20 text-white bg-center'>
            <div class=' bg-news-gradient md:h-fit h-20'>
                <div className='flex justify-start mx-32 pt-[15%]  text-7xl '></div>
            </div>
        </div>
    );
};

export default Hero;
