import React from 'react'
import Footer from '../../components/footer/footer'
import HeroLandingPage from './components/hero/heroLandingPage'
import Body from './components/body/Body'
const Temp = () => {
    return (
        <div>
            <HeroLandingPage />
            <Body />
            <Footer />
        </div>
    )
}

export default Temp 