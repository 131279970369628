import React from "react";
import Body from "./components/body/body";
import SmallFooter from "../../components/footer/smallFooter";
import HeroLandingPage from "./components/hero/heroLandingPage";

const Qrcode = () => {
  return (
    <div>
      <HeroLandingPage/>
      <Body />
      <SmallFooter/>
    </div>
  )
}

export default Qrcode;
