import React, { useState, useEffect, useRef } from "react";
import Iframe from "react-iframe";
import i18next from "i18next";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { getGalleries } from "../../../../redux/selectors";
import play from "../../../../assets/play icon-01.png";
import axios from "axios";

const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "nid-website",
};
const Body = () => {
    const one = useMediaQuery({
        query: "(max-height: 300px)",
    });
    const TwoFiveZeroPercent = useMediaQuery({
        query: "(max-height: 400px)",
    });

    const TwoZeroZeroPercent = useMediaQuery({
        query: "(max-height: 500px)",
    });

    const OneSevenFivePercent = useMediaQuery({
        query: "(max-height: 600px)",
    });

    const OneFivePercent = useMediaQuery({
        query: "(max-height: 700px)",
    });

    const OneTwoFivePercent = useMediaQuery({
        query: "(max-height: 800px)",
    });

    const OneOneTenPercent = useMediaQuery({
        query: "(max-height: 900px)",
    });

    const { t } = useTranslation();
    const [IsOpen, setIsOpen] = useState(false);
    const [VidOpen, setVidOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState("");
    const [currentCaption, setCurrentCaption] = useState("");
    const [vidModal, setVidModal] = useState("");
    const imgRef = useRef(null);
    const [Event, setEvent] = useState("Pictures");
    //const list = useSelector(getGalleries);
    const [galleryData, setGalleryData] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 6;

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    const pageCount = Math.ceil(
        // galleryData.filter((item) => item.type === "Podcast").length /
        //   itemsPerPage
        galleryData.filter((item) => item.type === "Podcast").length /
            itemsPerPage
    );

    function toggleVidModal(vidUrl) {
        setVidOpen(!VidOpen);
        setVidModal(vidUrl);
    }

    function handlePageClick({ selected }) {
        setCurrentPage(selected);
    }

    useEffect(() => {
        if (imgRef.current) {
            const imgWidth = imgRef.current.getBoundingClientRect().width;
            const modalContent = document.getElementById("modal-content");
            modalContent.style.width = `${imgWidth}px`;
        }
    }, [currentImage]);

    useEffect(() => {
        fetchGalleryData();
    }, []);

    const fetchGalleryData = async () => {
        try {
            //  const response = await fetch( process.env.REACT_APP_BASE_URL +'/api/resource/Gallery?fields=["image_url", "video_url", "type", "caption", "caption_amh", "title", "title_amh"]');
            const response = await axios.get(
                process.env.REACT_APP_BASE_URL + `/galleryy/`,
                {
                    headers: headers,
                }
            );

            const data = await response.data;

            const filteredData = data.filter((item) => item.type === "Podcast");
            console.log(filteredData);
            const sortedData = filteredData
                .sort((a, b) => {
                    console.log(a.title);
                    const countA = a.count;
                    const countB = b.count;
                    return countA - countB;
                    // Assuming the title format is "Fayda Podcast ep-n"
                    // const episodeA = parseInt(a.title.split("-")[1].trim());
                    // const episodeB = parseInt(b.title.split("-")[1].trim());
                    // return episodeA - episodeB;
                })
                .reverse();
            console.log(sortedData);

            setGalleryData(sortedData);
        } catch (error) {
            console.error("Error fetching gallery data:", error);
        }
    };
    const offset = currentPage * itemsPerPage;
    const vidItems = galleryData.filter((item) => item.type === "Podcast");
    const podcastVidItems = galleryData.slice(offset, offset + itemsPerPage);
    return (
        <>
            {isDesktopOrLaptop ? (
                <div className='flex w-full h-full flex-col'>
                    {VidOpen && (
                        <div
                            className='fixed top-0 left-0 w-full h-full py-20 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-all duration-500'
                            onClick={toggleVidModal}
                        >
                            <div className='relative w-[80%] h-full px-2 flex hover:cursor-pointer transition-all duration-500 '>
                                <Iframe
                                    url={vidModal}
                                    id=''
                                    className='flex w-[100%] h-full '
                                    display='block'
                                    position='relative'
                                />
                            </div>
                        </div>
                    )}
                    <div className='flex h-20 pl-40 bg-[#215F6C] place-items-center align-middle '>
                        <div className='flex items-center w-[35%] h-full'>
                            <input
                                className='block w-[85%] py-1 text-lg text-gray-500 placeholder-gray-400 bg-white appearance-none rounded-3xl  placeholder:text-center'
                                placeholder={t("podcast-search-text")}
                            />
                            <SearchIcon className='-ml-9' />
                        </div>

                        <div className='text-xl font-bold text-white  '>
                            <button
                                className={`content-center px-6 hover:cursor-pointer hover:underline focus:underline focus:decoration-[#00f7c7] decoration-4 decoration-offset-10 `}
                            >
                                {t("podcast-filter-latest")}
                            </button>
                            {/* <button
                className={`content-center px-6 hover:cursor-pointer hover:underline  focus:underline focus:decoration-[#00f7c7] decoration-4 decoration-offset-10 `}
              >
                {t("podcast-filter-name")}
              </button>
              <button
                className={`content-center px-6 hover:cursor-pointer hover:underline  focus:underline focus:decoration-[#00f7c7] decoration-4 decoration-offset-10 `}
              >
                {t("podcast-filter-date")}
              </button> */}
                        </div>
                    </div>

                    <div className='flex flex-col w-full h-full px-32 mb-5'>
                        <div className='grid grid-cols-3 w-full h-full gap-20 mt-20 mb-10  text-xl'>
                            {podcastVidItems.map((listItem) => (
                                <div className='flex w-full flex-col rounded-lg shadow-md'>
                                    {console.log(
                                        "list of vids",
                                        podcastVidItems
                                    )}
                                    <div
                                        className='relative w-full h-full flex text-center rounded-t-lg items-center hover:cursor-pointer transition-all duration-500'
                                        key={listItem.id}
                                        onClick={() =>
                                            toggleVidModal(listItem.video_url)
                                        }
                                    >
                                        <div className='rounded-t-lg flex w-full h-full'>
                                            <img
                                                src={listItem.image_url}
                                                alt='video'
                                                className={` ${
                                                    one
                                                        ? "object-[center_-2px] h-[150px]"
                                                        : TwoFiveZeroPercent
                                                        ? "object-[center_-2px] h-[150px]"
                                                        : TwoZeroZeroPercent
                                                        ? "object-[center_-2px] h-[150px]"
                                                        : OneSevenFivePercent
                                                        ? "object-[center_-25px] h-[150px]"
                                                        : OneFivePercent
                                                        ? "object-[center_-35px] h-[150px]"
                                                        : OneTwoFivePercent
                                                        ? "object-[center_-42px] h-[170px]"
                                                        : OneOneTenPercent
                                                        ? "object-[center_-55px] h-[200px]"
                                                        : "object-[center_-55px] h-[250px]"
                                                } flex w-full rounded-t-lg blur-[2px] object-cover`}
                                            />
                                        </div>
                                        <div className='absolute inset-0 flex items-center justify-center'>
                                            <img
                                                src={play}
                                                alt='play button'
                                                className='object-cover scale-[0.2]'
                                            />
                                        </div>

                                        {/* <Iframe
                  url={listItem?.video_url}
                  className="flex w-[100%] h-60 rounded-t-lg -z-20"
                  display="block"
                  position="relative"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                /> */}
                                    </div>
                                    <div className=' flex flex-col w-full h-full leading-5 pt-2 items-center'>
                                        <h2 className='text-center text-2xl font-medium text-darkgreen'>
                                            {i18next.language === "en"
                                                ? listItem?.title
                                                : i18next.language === "amh"
                                                ? listItem?.title_amh
                                                : ""}
                                        </h2>
                                        <hr className='items-center w-[85%] border-grey' />
                                    </div>
                                    <div className='flex w-full h-full px-5'>
                                        <p className='flex w-full h-full text-base text-center'>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        i18next.language ===
                                                        "en"
                                                            ? listItem?.caption
                                                            : i18next.language ===
                                                              "amh"
                                                            ? listItem?.caption_amh
                                                            : "",
                                                }}
                                            />
                                        </p>
                                        <br />
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className='flex flex-col w-full h-full gap-6 mb-14'>
                            <ReactPaginate
                                previousLabel={"Prev"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                previousClassName='border border-opacity-20 rounded-sm hover:bg-green  px-3 py-1'
                                nextClassName='border border-opacity-20 rounded-sm hover:bg-green px-3 py-1'
                                pageClassName='border border-opacity-20 rounded-sm hover:bg-green px-3 py-1 '
                                containerClassName={
                                    "pagination flex justify-center text-MetallicBlue mt-10 gap-1"
                                }
                                activeClassName={"active bg-green"}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className='flex w-full h-full flex-col'>
                    {VidOpen && (
                        <div
                            className='fixed inset-0 w-full h-full py-20 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-all duration-500'
                            onClick={toggleVidModal}
                        >
                            <div className='relative w-full h-80 px-2 flex hover:cursor-pointer transition-all duration-500 '>
                                <Iframe
                                    url={vidModal}
                                    id=''
                                    className='flex w-[100%] h-full '
                                    display='block'
                                    position='relative'
                                />
                            </div>
                        </div>
                    )}
                    {/* {VidOpen && (
            <div
              className="fixed top-0 left-0 w-full h-full py-20 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-all duration-500"
              onClick={toggleVidModal}
            >
              <div className="relative w-full h-80 px-2 flex hover:cursor-pointer hover:scale-105 transition-all duration-500 ">
                <Iframe
                  url={vidModal}
                  id=""
                  className="flex w-[100%] h-full "
                  display="block"
                  position="relative"
                />
              </div>
            </div>
          )} */}
                    <div className='flex w-full h-20 pl-2 bg-[#215F6C] place-items-center align-middle '>
                        <div className='flex items-center w-[50%] h-full'>
                            <input
                                className='block w-full py-1 text-lg text-gray-500 placeholder-gray-400 bg-white appearance-none rounded-3xl  placeholder:text-start pl-3'
                                placeholder={t("podcast-search-text")}
                            />
                            <SearchIcon className='-ml-9' />
                        </div>

                        <div className='text-lg pl-3 w-[50%] font-bold text-white  '>
                            <button
                                className={`content-center px-3 hover:cursor-pointer hover:underline focus:underline focus:decoration-[#00f7c7] decoration-4 decoration-offset-10 `}
                            >
                                {t("podcast-filter-latest")}
                            </button>
                            {/* <button
                className={`content-center px-3 hover:cursor-pointer hover:underline  focus:underline focus:decoration-[#00f7c7] decoration-4 decoration-offset-10 `}
              >
                {t("podcast-filter-name")}
              </button>
              <button
                className={`content-center px-3 hover:cursor-pointer hover:underline  focus:underline focus:decoration-[#00f7c7] decoration-4 decoration-offset-10 `}
              >
                {t("podcast-filter-date")}
              </button> */}
                        </div>
                    </div>

                    <div className='flex flex-col w-full h-full px-2'>
                        <div className='grid grid-cols-2 w-full h-full gap-8 mt-10 mb-5  text-xl'>
                            {podcastVidItems.map((listItem) => (
                                <div className='flex w-full flex-col rounded-lg shadow-md'>
                                    <div
                                        className='relative w-full h-full flex text-center rounded-t-lg items-center hover:cursor-pointer transition-all duration-500'
                                        key={listItem.id}
                                        onClick={() =>
                                            toggleVidModal(listItem.video_url)
                                        }
                                    >
                                        <div className='rounded-t-lg flex w-full h-full'>
                                            <img
                                                src={listItem.image_url}
                                                alt='video'
                                                className={` ${
                                                    one
                                                        ? "object-[center_-2px] h-[150px]"
                                                        : TwoFiveZeroPercent
                                                        ? "object-[center_-2px] h-[150px]"
                                                        : TwoZeroZeroPercent
                                                        ? "object-[center_-2px] h-[150px]"
                                                        : OneSevenFivePercent
                                                        ? "object-[center_-25px] h-[150px]"
                                                        : OneFivePercent
                                                        ? "object-[center_-35px] h-[150px]"
                                                        : OneTwoFivePercent
                                                        ? "object-[center_-42px] h-[170px]"
                                                        : OneOneTenPercent
                                                        ? "object-[center_-13px] h-[100px]"
                                                        : "object-[center_-55px] h-[250px]"
                                                } flex w-full rounded-t-lg blur-[2px] object-cover`}
                                            />
                                        </div>
                                        <div className='absolute inset-0 flex items-center justify-center'>
                                            <img
                                                src={play}
                                                alt='play button'
                                                className='object-cover scale-[0.2]'
                                            />
                                        </div>

                                        {/* <Iframe
                  url={listItem?.video_url}
                  className="flex w-[100%] h-60 rounded-t-lg -z-20"
                  display="block"
                  position="relative"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                /> */}
                                    </div>
                                    <div className=' flex flex-col w-full h-full leading-5 pt-2 items-center'>
                                        <h2 className='text-center text-xl font-medium text-darkgreen'>
                                            {listItem?.title}
                                        </h2>
                                        <hr className='items-center w-[85%] border-grey' />
                                    </div>
                                    <div className='flex w-full h-full px-2'>
                                        <p className='flex w-full h-full text-base text-center'>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: listItem?.caption,
                                                }}
                                            />
                                        </p>
                                        <br />
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className='flex flex-col w-full h-full gap-6 mb-14'>
                            <ReactPaginate
                                previousLabel={"Prev"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                previousClassName='border border-opacity-20 rounded-sm hover:bg-green  px-3 py-1'
                                nextClassName='border border-opacity-20 rounded-sm hover:bg-green px-3 py-1'
                                pageClassName='border border-opacity-20 rounded-sm hover:bg-green px-3 py-1 '
                                containerClassName={
                                    "pagination flex justify-center text-MetallicBlue mt-10 gap-1"
                                }
                                activeClassName={"active bg-green"}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Body;
