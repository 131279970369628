import React from "react";
// requires a loader
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import checkIcon from "../../../../assets/8-01-Checklist.svg";

const CardSlider = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className='flex flex-col items-center w-full h-full md:scale-100'>
            <Carousel
                axis='horizontal'
                className='w-[100%] pt-8 h-4/5 '
                showArrows={true}
                showStatus={false}
                showThumbs={false}
                swipeable={true}
                autoPlay={true}
                infiniteLoop={true}
                interval={2500}
                swipeScrollTolerance={80}
                // thumbWidth={90}
                transitionTime={1000}
                renderIndicator={false}
                renderArrowPrev={(clickHandler, hasPrev) => {
                    return (
                        <div
                            className={`${
                                hasPrev ? "absolute" : "hidden"
                            } top-0  bottom-0 left-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20 `}
                            onClick={clickHandler}
                        >
                            <FaChevronLeft className='w-6 h-6 text-darkblue ' />
                        </div>
                    );
                }}
                renderArrowNext={(clickHandler, hasNext) => {
                    return (
                        <div
                            className={`${
                                hasNext ? "absolute" : "hidden"
                            } top-0 bottom-0 right-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20`}
                            onClick={clickHandler}
                        >
                            <FaChevronRight className='w-6 h-6 text-darkblue' />
                        </div>
                    );
                }}
            >
                <div className='flex place-content-center'>
                    <div className='flex flex-col content-center text-sm text-center md:px-25 place-items-center md:text-base'>
                        <div className='w-12 h-12 pt-2 border-2 rounded-full bg-MetallicBlue border-green/30'>
                            <img
                                src={checkIcon}
                                className='w-7 h-7 '
                                alt='Icon_checklist'
                            ></img>
                        </div>
                        <h1 className='pt-3 text-2xl font-bold md:text-3xl text-MetallicBlue'>
                            {t("about-get-fayda")}
                        </h1>

                        <p className='px-10 py-4  md:px-28 '>
                            {t("about-get-fayda-text")}
                        </p>
                        <button className='h-8 text-base  text-white px-16 bg-green rounded-2xl hover:[text-darkblue hover:bg-MetallicBlue]'>
                            Learn More
                        </button>
                    </div>
                    {/* <div className="w-1 mt-12 h-44 bg-MetallicBlue"></div> */}
                </div>

                <div className='flex place-content-center'>
                    <div className='flex flex-col content-center text-sm text-center md:px-25 place-items-center md:text-base'>
                        <div className='w-12 h-12 pt-2 border-2 rounded-full bg-MetallicBlue border-green/30'>
                            <img
                                src={checkIcon}
                                className='w-7 h-7 '
                                alt='Icon_checklist'
                            ></img>
                        </div>
                        <h1 className='pt-3 text-2xl font-bold md:text-3xl text-MetallicBlue'>
                            {t("about-update-fayda")}
                        </h1>

                        <p className='px-10 py-4  md:px-28'>
                            {t("about-update-fayda-text")}
                        </p>
                        <button
                            className='h-8 text-base  text-white px-16 bg-green rounded-2xl hover:[text-darkblue hover:bg-MetallicBlue]'
                            onClick={() => navigate("/locations")}
                        >
                            {t("about-update-fayda")}
                        </button>
                    </div>
                    {/* <div className="w-1 mt-12 h-44 bg-MetallicBlue"></div> */}
                </div>

                <div className='flex place-content-center'>
                    <div className='flex flex-col content-center text-sm text-center md:px-25 place-items-center md:text-base'>
                        <div className='w-12 h-12 pt-2 border-2 rounded-full bg-MetallicBlue border-green/30'>
                            <img
                                src={checkIcon}
                                className='w-7 h-7 '
                                alt='Icon_checklist'
                            ></img>
                        </div>
                        <h1 className='pt-3 text-2xl font-bold md:text-3xl text-MetallicBlue'>
                            {t("about-fayda-service")}
                        </h1>

                        <p className='px-10 py-4  md:px-28'>
                            {t("about-fayda-service-text")}
                        </p>
                        <button
                            className='h-8 text-base  text-white px-16 bg-green rounded-2xl hover:[text-darkblue hover:bg-MetallicBlue]'
                            onClick={() => navigate("/services")}
                        >
                            Learn More
                        </button>
                    </div>
                </div>
            </Carousel>
        </div>
    );
};
export default CardSlider;
