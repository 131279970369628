import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import enrollment from "../../../../assets/noun-enrollment-2202368-01 copy.png";
import dataset from "../../../../assets/sf-01 copy.png";
import { ChevronRight } from "@mui/icons-material";

const Principle = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className='bg-right-bottom bg-cover bg-principle'>
            <div className='w-full h-full px-24 text-white md:px-12 bg-MetallicBlue/80'>
                <div className='flex px-6 mx-6 '>
                    <div className=' w-[40%] md:w-[45%]  h-[60%] mt-20 mr-16 md:mr-3 mb-10'>
                        <div className='flex text-4xl mt-14 md:text-4xl lg:text-5xl xl:text-6xl '>
                            <h1 className='pr-2 font-light text-green'>
                                {t("nid")}
                            </h1>
                            <h1 className='pb-10 font-light '>
                                {t("principles")}
                            </h1>
                        </div>

                        <p className='text-xl mb-5 font-normal text-justify text-white'>
                            {t("principles-text")}
                        </p>

                        <button
                            className='h-10 text-xl mt-4 text-white px-16 bg-green rounded-2xl hover:bg-[#083247] relative overflow-hidden'
                            onClick={() => navigate("/principles")}
                        >
                            <span className='relative z-10'>
                                {t("learn-more")}
                            </span>
                            <span className='absolute top-1/2 right-3 transform -translate-y-1/2 transition-transform duration-300'>
                                <svg
                                    className='h-6 w-6 text-white fill-current'
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 20 20'
                                >
                                    <path
                                        fillRule='evenodd'
                                        d='M9.293 14.707a1 1 0 010-1.414L12.586 10l-3.293-3.293a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                                        clipRule='evenodd'
                                    />
                                </svg>
                            </span>
                        </button>
                    </div>
                    <div className='flex items-start justify-start px-10 '>
                        <div className='justify-center flex mt-5  w-0.5 h-[90%] ml-1 bg-white '></div>
                    </div>
                    <div className='py-5 gap-3 mt-24 mb-20 ml-10 place-items-center  w-[60%] md:2-[70] text-base xl:text-base md:text-xs lg:text-sm font-bold text-center'>
                        <div>
                            <h1 className='font-light text-5xl mb-8 md:text-4xl lg:text-5xl text-left'>
                                {t("datacollection-title")}
                            </h1>
                        </div>
                        <div>
                            <p className='text-xl mb-5 font-normal text-justify text-white'>
                                {t("datacollection-text")}
                            </p>
                        </div>
                        <div className='flex'>
                            <div
                                onClick={() => navigate("/enrollment")}
                                className='relative flex justify-center w-full'
                            >
                                <div className='w-[80%] px-10 py-6 flex flex-col justify-center items-center border-2 border-white/20 rounded-2xl hover:text-MetallicBlue transition-all duration-300 ease-out cursor-pointer group bg-white bg-opacity-20'>
                                    <div className='absolute bottom-0 w-0 h-0 group-hover:w-[80%] transition-all ease-out duration-300 group-hover:h-full bg-green flex flex-col justify-center items-center rounded-2xl'></div>
                                    <span className='flex items-center'>
                                        <img
                                            className=' h-16 w-16 px-2 z-30'
                                            src={enrollment}
                                        />
                                        <h2 className='relative text-3xl font-medium'>
                                            {t("principles-enrollment")}
                                        </h2>
                                        <div className='z-30 mt-1'>
                                            <ChevronRight
                                                fontSize='large'
                                                sx={{
                                                    fontSize: 40,
                                                    color: "white",
                                                }}
                                            />
                                        </div>{" "}
                                    </span>
                                </div>
                                {/* <div className="absolute bottom-0 w-[80%] h-full bg-white bg-opacity-20 rounded-2xl"></div> */}
                                <div className='absolute bottom-0 w-20 border-b-4 border-green'></div>
                            </div>

                            <div
                                onClick={() => navigate("/dataset")}
                                className='relative flex justify-center w-full'
                            >
                                <div className='w-[80%] px-10 py-8 flex flex-col justify-center items-center border-2 border-white/20 rounded-2xl hover:text-MetallicBlue transition-all duration-300 ease-out cursor-pointer group bg-white bg-opacity-20'>
                                    <div className='absolute bottom-0 w-0 h-0 group-hover:w-[80%] transition-all ease-out duration-300 group-hover:h-full bg-green flex flex-col justify-center items-center rounded-2xl'>
                                        {" "}
                                    </div>
                                    <span className='flex items-center'>
                                        <img
                                            className='h-20 w-20 px-3 z-30'
                                            src={dataset}
                                        />
                                        <h2 className='relative text-3xl font-medium'>
                                            {t("principles-dataset")}
                                        </h2>
                                        <div className='z-30 mt-1'>
                                            <ChevronRight
                                                fontSize='large'
                                                sx={{
                                                    fontSize: 40,
                                                    color: "white",
                                                }}
                                            />
                                        </div>
                                    </span>
                                </div>
                                <div className='absolute bottom-0 w-20 border-b-4 border-green'>
                                    {" "}
                                </div>
                                {/* <div className="absolute bottom-0 w-[80%] h-full bg-white bg-opacity-20 rounded-2xl"></div> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="py-5 grid grid-cols-3 gap-3 mt-24 mb-20 ml-10 place-items-center  w-[60%] md:2-[70] text-base xl:text-base md:text-xs lg:text-sm font-bold text-center">
            <div className="relative flex justify-center w-full ">
              <div className="w-[80%] h-[85%] px-5 py-4 flex flex-col justify-center items-center border-2 border-white/20 rounded-2xl hover:text-MetallicBlue transition-all duration-300 ease-out cursor-pointer group">
                <div className="absolute bottom-0 w-0 h-0 group-hover:w-[80%] transition-all ease-out duration-300 group-hover:h-full bg-green flex flex-col justify-center items-center rounded-2xl">
                  {" "}
                </div>
                <h2 className="relative text-lg">{t("principles-privacy")}</h2>
          
              </div>
              <div className="absolute bottom-0 w-20 border-b-4 border-green">
                {" "}
              </div>
            </div>

            <div className="relative flex justify-center w-full ">
              <div className="w-[80%] h-[85%] px-16 py-4 flex flex-col justify-center items-center border-2 border-white/20 rounded-2xl hover:text-MetallicBlue transition-all duration-300 ease-out cursor-pointer group">
                <div className="absolute bottom-0 w-0 h-0 group-hover:w-[80%] transition-all ease-out duration-300 group-hover:h-full bg-green flex flex-col justify-center items-center rounded-2xl">
                  {" "}
                </div>
                <h2 className="relative text-lg">
                  {t("principles-inclusion")}
                </h2>
                
              </div>
              <div className="absolute bottom-0 w-20 border-b-4 border-green">
                {" "}
              </div>
            </div>

            <div className="relative flex justify-center w-full">
              <div className="w-[80%] h-[85%] px-2 py-4 flex flex-col justify-center items-center border-2 border-white/20 rounded-2xl hover:text-MetallicBlue transition-all duration-300 ease-out cursor-pointer group">
                <div className="absolute bottom-0 w-0 h-0 group-hover:w-[80%] transition-all ease-out duration-300 group-hover:h-full bg-green flex flex-col justify-center items-center rounded-2xl">
                  {" "}
                </div>
                <h2 className="relative text-lg">{t("principles-auth")}</h2>
             
              </div>
              <div className="absolute bottom-0 w-20 border-b-4 border-green">
                {" "}
              </div>
            </div>

            <div className="relative flex justify-center w-full ">
              <div className="w-[80%] h-[85%]  px-16 py-4 flex flex-col justify-center items-center border-2 border-white/20 rounded-2xl hover:text-MetallicBlue transition-all duration-300 ease-out cursor-pointer group">
                <div className="absolute bottom-0 w-0 h-0 group-hover:w-[80%] transition-all ease-out duration-300 group-hover:h-full bg-green flex flex-col justify-center items-center rounded-2xl">
                  {" "}
                </div>
                <h2 className="relative text-lg">{t("principles-usage")}</h2>
              </div>
              <div className="absolute bottom-0 w-20 border-b-4 border-green">
                {" "}
              </div>
            </div>

            <div className="relative flex justify-center w-full h-full">
              <div className="w-[80%] h-full px-2 py-4 flex flex-col justify-center items-center border-2 border-white/20 rounded-2xl hover:text-MetallicBlue transition-all duration-300 ease-out cursor-pointer group">
                <div className="absolute bottom-0 w-0 h-0 group-hover:w-[80%] transition-all ease-out duration-300 group-hover:h-full bg-green flex flex-col justify-center items-center rounded-2xl">
                  {" "}
                </div>
                <h2 className="relative text-lg">{t("principle-design")}</h2>
            
              </div>
              <div className="absolute bottom-0 w-20 border-b-4 border-green">
                {" "}
              </div>
            </div>

            <div
              onClick={() => navigate("/principles")}
              className="relative w-[80%] h-[85%]  px-5 py-4  flex flex-col justify-center  items-center border-2 border-white/20 rounded-2xl cursor-pointer overflow-hidden transition-all duration-150 ease-in-out hover:pl-10 hover:pr-6 bg-gray-50 group"
            >
              <div class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
                <svg
                  class="w-5 h-5 text-green-400"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  ></path>
                </svg>
              </div>
              <div class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
                <svg
                  class="w-5 h-5 text-green-400"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  ></path>
                </svg>
              </div>
              <h2 className="">{t("learn-more")}</h2>
            </div>
          </div> */}
                </div>
            </div>
        </div>
    );
};
export default Principle;
