import React, { useEffect } from "react";

import { ScaleIn, Animate } from "../../../../hooks/animations";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { getPartners } from "../../../../redux/selectors";
import { Link } from "react-router-dom";

const Partner = () => {
    const list = useSelector(getPartners);

    const { t } = useTranslation();

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    useEffect(() => {
        console.log(list);
    }, []);

    return (
        // <>
        //     {isDesktopOrLaptop ? (
        //         <div className='hidden md:inline'>
        //             <Animate.FadeUp>
        //                 <div className='flex flex-col items-center justify-center text-center bg-transparent '>
        //                     <h1 className='w-full pb-5 mb-0 -mt-12 text-6xl font-semibold  text-MetallicBlue '>
        //                         {t("about-partners")}
        //                     </h1>
        //                     <div className='bg-green h-1 w-[6%] '></div>
        //                 </div>
        //             </Animate.FadeUp>
        //             <div className='mt-2 mb-10'>
        //                 <p className='pt-2 pb-5 text-xl text-center text-MetallicBlue '>
        //                     {t("about-partners-text")}
        //                 </p>
        //             </div>

        //             <div className='gap-10 pl-40 pr-40 mb-20'>
        //                 <AliceCarousel
        //                     className='relative w-full'
        //                     mouseTracking={true}
        //                     animationType={ScaleIn}
        //                     animationDuration={4000}
        //                     infinite={true}
        //                     autoPlay={true}
        //                     paddingLeft={10}
        //                     // paddingRight={1}
        //                     disableDotsControls
        //                     disableButtonsControls
        //                     fadeOutAnimation={true}
        //                     autoHeight={true}
        //                     responsive={{
        //                         0: {
        //                             items: 9,
        //                         },
        //                         1024: {
        //                             items: 5,
        //                             itemsFit: "contain",
        //                         },
        //                     }}
        //                     renderPrevButton={(clickHandler, hasPrev) => {
        //                         return (
        //                             <div
        //                                 className={`${
        //                                     hasPrev ? "absolute " : "hidden"
        //                                 }  bottom-14 left-10 flex p-3 opacity-30 hover:opacity-100 cursor-pointer z-10`}
        //                                 onClick={clickHandler}
        //                             >
        //                                 <svg
        //                                     fill='#09384f'
        //                                     height='60px'
        //                                     width='60px'
        //                                     xmlns='http://www.w3.org/2000/svg'
        //                                     viewBox='0 0 300 800'
        //                                     xmlSpace='preserve'
        //                                     stroke='#09384f'
        //                                     strokeWidth={0.00511641}
        //                                     className=''
        //                                 >
        //                                     <path
        //                                         className='cls-arrow'
        //                                         d='M148.32 255.76L386.08 18c4.053-4.267 3.947-10.987-.213-15.04a10.763 10.763 0 00-14.827 0L125.707 248.293a10.623 10.623 0 000 15.04L371.04 508.667c4.267 4.053 10.987 3.947 15.04-.213a10.763 10.763 0 000-14.827L148.32 255.76z'
        //                                     />
        //                                 </svg>
        //                             </div>
        //                         );
        //                     }}
        //                     renderNextButton={(clickHandler, hasNext) => {
        //                         return (
        //                             <div
        //                                 className={`${
        //                                     hasNext ? "absolute" : "hidden"
        //                                 }  bottom-20 right-10 flex p-3 opacity-30 hover:opacity-100 cursor-pointer z-10`}
        //                                 onClick={clickHandler}
        //                             >
        //                                 <svg
        //                                     fill='#09384f'
        //                                     height='60px'
        //                                     width='60px'
        //                                     xmlns='http://www.w3.org/2000/svg'
        //                                     viewBox='0 0 300 800'
        //                                     xmlSpace='preserve'
        //                                     transform='rotate(180)'
        //                                     stroke='#09384f'
        //                                 >
        //                                     <path d='M148.32 255.76L386.08 18c4.053-4.267 3.947-10.987-.213-15.04a10.763 10.763 0 00-14.827 0L125.707 248.293a10.623 10.623 0 000 15.04L371.04 508.667c4.267 4.053 10.987 3.947 15.04-.213a10.763 10.763 0 000-14.827L148.32 255.76z' />
        //                                 </svg>
        //                             </div>
        //                         );
        //                     }}
        //                     items={list.map((listItem) => (
        //                         <div className='flex items-center justify-center my-5 mr-9 bg-cover shadow hover:shadow-lg rounded-2xl'>
        //                             <img
        //                                 src={
        //                                     process.env.REACT_APP_BASE_URL +
        //                                     listItem.logo
        //                                 }
        //                                 alt='partners'
        //                                 className='object-contain scale-75 h-60 '
        //                             />
        //                         </div>
        //                     ))}
        //                 />
        //             </div>
        //         </div>
        //     ) : (
        //         <div className=''>
        //             <Animate.FadeUp>
        //                 <div className='flex flex-col items-center justify-center text-center bg-transparent '>
        //                     <h1 className='w-full pb-5 mt-8 text-4xl font-bold  text-MetallicBlue '>
        //                         {t("about-partners")}
        //                     </h1>
        //                     <div className='bg-darkgreen h-1 w-[10%] '></div>
        //                 </div>
        //             </Animate.FadeUp>

        //             <div className='gap-5 mb-8'>
        //                 <AliceCarousel
        //                     className='relative w-full'
        //                     mouseTracking={true}
        //                     animationType={ScaleIn}
        //                     animationDuration={4000}
        //                     infinite={true}
        //                     autoPlay={true}
        //                     paddingLeft={10}
        //                     // paddingRight={1}
        //                     disableDotsControls
        //                     disableButtonsControls
        //                     fadeOutAnimation={true}
        //                     autoHeight={true}
        //                     responsive={{
        //                         0: {
        //                             items: 2,
        //                         },
        //                         1024: {
        //                             items: 2,
        //                             itemsFit: "contain",
        //                         },
        //                     }}
        //                     renderPrevButton={(clickHandler, hasPrev) => {
        //                         return (
        //                             <div
        //                                 className={`${
        //                                     hasPrev ? "absolute " : "hidden"
        //                                 }  bottom-14 left-10 flex p-3 opacity-30 hover:opacity-100 cursor-pointer z-10`}
        //                                 onClick={clickHandler}
        //                             >
        //                                 <svg
        //                                     fill='#09384f'
        //                                     height='60px'
        //                                     width='60px'
        //                                     xmlns='http://www.w3.org/2000/svg'
        //                                     viewBox='0 0 300 800'
        //                                     xmlSpace='preserve'
        //                                     stroke='#09384f'
        //                                     strokeWidth={0.00511641}
        //                                     className=''
        //                                 >
        //                                     <path
        //                                         className='cls-arrow'
        //                                         d='M148.32 255.76L386.08 18c4.053-4.267 3.947-10.987-.213-15.04a10.763 10.763 0 00-14.827 0L125.707 248.293a10.623 10.623 0 000 15.04L371.04 508.667c4.267 4.053 10.987 3.947 15.04-.213a10.763 10.763 0 000-14.827L148.32 255.76z'
        //                                     />
        //                                 </svg>
        //                             </div>
        //                         );
        //                     }}
        //                     renderNextButton={(clickHandler, hasNext) => {
        //                         return (
        //                             <div
        //                                 className={`${
        //                                     hasNext ? "absolute" : "hidden"
        //                                 }  bottom-20 right-10 flex p-3 opacity-30 hover:opacity-100 cursor-pointer z-10`}
        //                                 onClick={clickHandler}
        //                             >
        //                                 <svg
        //                                     fill='#09384f'
        //                                     height='60px'
        //                                     width='60px'
        //                                     xmlns='http://www.w3.org/2000/svg'
        //                                     viewBox='0 0 300 800'
        //                                     xmlSpace='preserve'
        //                                     transform='rotate(180)'
        //                                     stroke='#09384f'
        //                                 >
        //                                     <path d='M148.32 255.76L386.08 18c4.053-4.267 3.947-10.987-.213-15.04a10.763 10.763 0 00-14.827 0L125.707 248.293a10.623 10.623 0 000 15.04L371.04 508.667c4.267 4.053 10.987 3.947 15.04-.213a10.763 10.763 0 000-14.827L148.32 255.76z' />
        //                                 </svg>
        //                             </div>
        //                         );
        //                     }}
        //                     items={list.map((listItem) => (
        //                         <div className='flex items-center justify-center my-5 mr-3 bg-cover shadow hover:shadow-lg rounded-2xl'>
        //                             <img
        //                                 src={
        //                                     process.env.REACT_APP_BASE_URL +
        //                                     listItem.logo
        //                                 }
        //                                 alt='partners'
        //                                 className='object-contain scale-75 h-36'
        //                             />
        //                         </div>
        //                     ))}
        //                 />
        //             </div>
        //         </div>
        //     )}
        // </>
        <div
            className={`px-5 flex flex-col gap-5 ${
                isDesktopOrLaptop ? "-mt-16 pb-16" : "pb-10"
            }`}
        >
            <div className='flex flex-col items-center justify-center text-center bg-transparent '>
                <h1 className='w-full pb-5 mb-0 mt-8 text-4xl md:text-6xl font-semibold  text-MetallicBlue '>
                    {t("about-partners")}
                </h1>
                <div className='bg-green h-1 w-[6%] '></div>
            </div>
            <div className='mt-2 mb-5'>
                <p className='pt-2 pb-5 text-xl text-center text-MetallicBlue '>
                    {t("about-partners-text")}
                </p>
            </div>
            <Link to='/partners'>
                <div className='text-center w-fit mx-auto px-16 py-[0.43rem] text-xl duration-100 ease-in-out hover:scale-105 rounded-full shadow-sm bg-[#47F4C7] hover:bg-[#235D71] text-white font-semibold'>
                    More
                </div>
            </Link>
        </div>
    );
};

export default Partner;
