import React, { useState } from "react";
import HeroLandingPage from "./components/hero/heroLandingPage";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGalleries } from "../../redux/selectors";
import GalleryComponent from "./components/galleryComponent/galleryComponent";
import SmallFooter from "../../components/footer/smallFooter";
import GalleryComponentMobile from "./components/galleryComponentMobile/galleryComponentMobile";
import { getGalleryList } from "../../redux/actions";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import Video from "./components/Video/Video";

// import Allevents from "../media/components/events/allevents";

const Gallery = ({ title }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    // const [Event, setEvent] = useState("Latest");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        // const timer = setTimeout(() => {
        //   setIsLoading(false);
        // }, 10000);

        // Group the dispatch calls based on their functionality
        const dataDispatches = [dispatch(getGalleryList())];

        // Execute the dispatch calls in parallel
        Promise.all(dataDispatches)
            .then(() => {
                setIsLoading(false);
                // Do something after all the dispatch calls have completed
            })
            .catch((error) => {
                // Handle any errors that occur during the dispatch calls
            });

        // return () => {
        //   clearTimeout(timer);
        // };
    }, [dispatch]);

    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <HeroLandingPage />

                    {isDesktopOrLaptop ? (
                        <>
                            <GalleryComponent title={title} />
                            {/* <Video /> */}
                            {/* <div className="absolute right-16 -bottom-80 hover:opacity-95 hover:cursor-pointer pl-10">
          <svg
            fill="#000"
            height="100px"
            width="100px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 300 800"
            xmlSpace="preserve"
            transform="rotate(180)"
            stroke="#FFF"
          >
            <path d="M148.32 255.76L386.08 18c4.053-4.267 3.947-10.987-.213-15.04a10.763 10.763 0 00-14.827 0L125.707 248.293a10.623 10.623 0 000 15.04L371.04 508.667c4.267 4.053 10.987 3.947 15.04-.213a10.763 10.763 0 000-14.827L148.32 255.76z" />
          </svg>
        </div> */}
                        </>
                    ) : (
                        <>
                            <GalleryComponentMobile />
                        </>
                    )}

                    <SmallFooter />
                </>
            )}
        </>
    );
};
export default Gallery;
