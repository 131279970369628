import React from "react";
import visionIcon from "../../../../assets/visionIcon.png"
import valuesIcon from "../../../../assets/valuesIcon.png"
import missionIcon from "../../../../assets/missionIcon.png"
import { useNavigate } from "react-router-dom";
import {useMediaQuery} from 'react-responsive'
import CardSliderStakeholder from '../cardSlider/CardSliderStakeholder';
import {  Animate } from "../../../../hooks/animations";
import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next'


const AboutStakeholder = () => {

  // const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })

  const isDesktopOrLaptop = useMediaQuery({minWidth:1224});
  const navigate = useNavigate();
  const {t} = useTranslation()
  
  return (
    <div className="flex flex-col items-center w-full h-full pt-20 pb-20 scale-90 md:scale-100 ">
      <Animate.FadeUp>
      <div className="flex justify-center w-full text-3xl md:text-4xl ">
      
        <hr class="h-1 my-8 rounded-sm bg-green border-0 border-b-green w-16 md:w-28 lg:w-28"></hr>
        <h1 className="px-3 pt-3 pb-6 font-bold md:pt-2 lg:pt-1 md:text-6xl text-MetallicBlue">{t("about-who-are")}</h1>
        <hr class="h-1 my-8 rounded-sm bg-green border-0 border-b-green w-16 md:w-28 lg:w-28"></hr>
      
      </div>
      </Animate.FadeUp>

      <div className="flex flex-row items-center justify-center w-full text-sm lg:w-4/5 ">
      <Animate.FadeUp>
        <p className="px-10 text-center md:text-xl md:px-8">
        {t("about-who-are-text")}{" "}
        </p>

        <div className="flex justify-center pt-5">
        <button className="h-10 text-xl  text-white px-16 bg-green rounded-2xl hover:bg-[#083247]" onClick={() => navigate("/about")}>
                {t("learn-more")}
              </button>
              </div>
        </Animate.FadeUp>
      </div>

      {isDesktopOrLaptop? 
       <div className="grid justify-center w-full grid-cols-3 gap-20 px-24 pt-20 md:gap-28 place-items-center justify-items ">


       <Animate.ScaleIn>
         <div class="  flex flex-col place-items-center max-w-sm p-5 py-3 bg-white rounded-xl  shadow-[5px_12px_50px_-14px_rgb(0,0,0,0.3)] text-center px-8">
         <Animate.ScaleIn>
           <img
             src={visionIcon}
             alt="vision Icon"
             className="w-32 "
           ></img>
           </Animate.ScaleIn>
           <Animate.ScaleIn>
           <h5 class="mb-2 text-4xl font-bold my-4">{t("Vision")}</h5>
           </Animate.ScaleIn>

           <Animate.ScaleIn>
           <p class="font-normal text-lg dark:text-gray-400">
           {t("vision-text")}
           </p>
           </Animate.ScaleIn>
         </div>
       </Animate.ScaleIn>



       <Animate.ScaleIn>
       <div class=" flex flex-col place-items-center max-w-sm p-5 py-3 bg-white rounded-xl  shadow-[5px_12px_50px_-14px_rgb(0,0,0,0.3)] text-center px-8">
         <Animate.ScaleIn>
         {/* <div className="w-24 h-24 bg-darkred"> */}
           <img
             src={missionIcon}
             alt="Mission Icon"
             className="w-32 "
           ></img>
           {/* </div> */}
            </Animate.ScaleIn>

            <Animate.ScaleIn>
           <h5 class="mb-2 text-4xl font-bold my-4">{t("Mission")}</h5>
           </Animate.ScaleIn>

           <Animate.ScaleIn>
           <p class="font-normal text-lg dark:text-gray-400">
           {t("mission-text")}
           </p>
           </Animate.ScaleIn>
         </div>
       </Animate.ScaleIn>



       <Animate.ScaleIn>
         <div class=" flex flex-col place-items-center py-3 max-w-sm p-5 bg-white rounded-xl  shadow-[5px_12px_50px_-14px_rgb(0,0,0,0.3)] text-center px-8">
         <Animate.ScaleIn>
           <div className="flex items-center justify-center w-20 h-20 bg-cover">
           <img
             src={valuesIcon}
             alt="Values Icon"
             className="w-32 bg-cover "
           ></img>
           </div>
         </Animate.ScaleIn>

         <Animate.ScaleIn>
           <h5 class="mb-2 text-4xl font-bold my-5">{t("Values")}</h5>
           </Animate.ScaleIn>

           <Animate.ScaleIn>
           <p class="font-normal text-lg dark:text-gray-400">
           <Trans i18nKey="values-text"/>
          
           </p>
           </Animate.ScaleIn>
         </div>
         </Animate.ScaleIn>
       </div> 
      :
      <CardSliderStakeholder/>}
    </div>
  );
};

export default AboutStakeholder;
