import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import infoIcon from "../../../assets/Info-icon.png";
import axios from "axios";
import OIDC_flow from "../../../assets/OIDC_flow.png";
const Body = () => {
    return (
        <>
            <div className='w-full justify-center px-5 md:px-10 lg:px-48 py-10 pb-20 space-y-5'>
                <div className='flex flex-col gap-y-3'>
                    <p className='text-4xl md:text-5xl font-semibold text-MetallicBlue'>
                        Welcome to Our Api Resources
                    </p>
                    <p>
                        Our Api makes it easy for developers to connect with our
                        platform and integrate our services into their
                        applications. This page provides everything you need to
                        get started, from detailed documentation to quick-access
                        resources.
                    </p>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <p className='text-3xl font-semibold  text-MetallicBlue'>
                        Key Features of Our Api
                    </p>
                    <ul className='list-disc ps-6 space-y-1'>
                        <li>
                            <span className='font-semibold'>
                                Simple Integration:
                            </span>{" "}
                            Connect seamlessly with our system using a
                            straightforward Api setup.
                        </li>
                        <li>
                            <span className='font-semibold'>Scalability:</span>{" "}
                            Designed to support both small applications and
                            large-scale projects.
                        </li>
                        <li>
                            <span className='font-semibold'>
                                Security-First:{" "}
                            </span>
                            Our Api includes robust security protocols to
                            protect your data.
                        </li>
                    </ul>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <p className='text-3xl font-semibold text-MetallicBlue'>
                        Getting Started
                    </p>
                    <p className=''>
                        To begin integrating our Api, start by reviewing our
                        documentation. It covers all the essential steps,
                        including authentication, available endpoints, and usage
                        examples.
                    </p>
                    <a
                        href='https://drive.google.com/file/d/15_jexlbhlf3pKNfCc22gc83Vpc8pm_Un/view?usp=sharing'
                        target='_blank'
                        className='w-fit'
                    >
                        <p className='text-white bg-MetallicBlue w-fit px-5 py-2 rounded-md  hover:scale-105 ease-in-out duration-300'>
                            Download Api Documentation (PDF)
                        </p>
                    </a>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <p className='text-3xl font-semibold  text-MetallicBlue'>
                        Authentication and Identity Management
                    </p>
                    <p>
                        Our Api uses modern, secure methods for authentication
                        and identity management to ensure your application's
                        safety and reliability.
                    </p>
                    <ul className='list-disc ps-6 space-y-1'>
                        <li>
                            <span className='font-semibold'>OAuth 2.0: </span>
                            OAuth 2.0 is an industry-standard protocol designed
                            for secure, delegated access to web resources. It
                            allows applications to access user data on another
                            system without requiring the user to share their
                            password directly. Instead, OAuth 2.0 issues tokens
                            after users authenticate, which the application can
                            then use to make requests on behalf of the user. To
                            use OAuth 2.0, developers must register their
                            applications to receive client credentials, then
                            implement the token exchange process. This includes
                            obtaining an authorization code, exchanging it for
                            an access token, and then using that token to access
                            protected resources. By using OAuth 2.0,
                            applications can securely interact with a user’s
                            account while minimizing the risk of exposing
                            sensitive credentials.
                        </li>
                        <li>
                            <span className='font-semibold'>
                                OpenID Protocol:{" "}
                            </span>
                            OpenID is a decentralized authentication protocol
                            that allows users to use a single set of credentials
                            across multiple sites. Through OpenID, users can log
                            in to various services without needing a separate
                            username and password for each one. Instead, they
                            authenticate through a trusted OpenID provider,
                            which then verifies their identity to the requesting
                            application. To implement OpenID, developers need to
                            redirect users to the OpenID provider for
                            authentication, then handle the provider’s response
                            to confirm the user’s identity. This process
                            simplifies account management for users and enhances
                            security for applications by outsourcing
                            authentication to a secure, specialized provider.
                        </li>
                        <li>
                            <span className='font-semibold'>
                                OIDC with Fayda eSignet:{" "}
                            </span>
                            OpenID Connect (OIDC) is an identity layer built on
                            top of the OAuth 2.0 protocol, providing a way to
                            verify user identity in addition to handling access
                            authorization. When integrated with Fayda eSignet,
                            OIDC enables secure, streamlined user authentication
                            and access management tailored specifically for
                            Ethiopian users. Fayda eSignet serves as the OpenID
                            provider, managing user identity verification and
                            authorization in compliance with local standards. By
                            using OIDC with Fayda eSignet, applications can
                            authenticate users with a high degree of trust,
                            ensuring data security while facilitating easy,
                            centralized access to services across platforms in
                            the Ethiopian digital ecosystem.
                        </li>
                    </ul>
                    <div className='max-w-[900px] px-5 mt-5'>
                        <img
                            src={OIDC_flow}
                            alt='OIDC_flow'
                            className='object-cover'
                        />
                    </div>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <p className='text-3xl font-semibold text-MetallicBlue'>
                        Resources for Developers
                    </p>
                    <ul className='list-disc ps-6 space-y-1'>
                        <li>
                            <span className='font-semibold'>
                                Quick Start Guide:
                            </span>{" "}
                            A step-by-step walkthrough to get your Api
                            integration up and running quickly.
                        </li>
                        <li>
                            <span className='font-semibold'>
                                Api Reference:{" "}
                            </span>
                            Detailed information on each endpoint, parameters,
                            and expected responses.
                        </li>
                        <li>
                            <span className='font-semibold'>Code Samples:</span>{" "}
                            Explore examples to see how each endpoint functions
                            in real scenarios.
                        </li>
                    </ul>
                </div>

                {/* <div className='flex flex-col gap-y-3'>
                    <p className='text-xl font-semibold text-MetallicBlue'>
                        Have Questions?{" "}
                    </p>
                    <p>
                        Our support team is here to help! If you have any
                        questions or need assistance, please reach out to our{" "}
                        <a href=''>support@id.et</a>
                    </p>
                </div> */}
            </div>
        </>
    );
};
export default Body;
