import { GET_ENROLLMENT_IMAGES } from "../actions/enrollmentActions";

 const initialState = {
    enrollmentImages: []
 }

 const enrollmentReducer = (state = initialState, action) => {
   
   
    switch(action.type){
    
        case GET_ENROLLMENT_IMAGES:  
        return{
            ...state,
            enrollmentImages: action.payload
          };

          default: 
            return state;
    }
 };
 export default enrollmentReducer;