import React from "react";
import { useMediaQuery } from "react-responsive";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import img0 from "../../../../assets/Group 74 copy.jpg";
import img1 from "../../../../assets/1-01-01.jpg";
import img2 from "../../../../assets/2-01.jpg";
import img3 from "../../../../assets/3-01.jpg";
import img4 from "../../../../assets/4-01.jpg";
import img5 from "../../../../assets/5-01.jpg";
import img6 from "../../../../assets/6-01.jpg";
import img7 from "../../../../assets/7-01.jpg";
import img8 from "../../../../assets/8-01.jpg";
import img9 from "../../../../assets/9-01.jpg";
import img10 from "../../../../assets/10-01.jpg";

const Hero = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    const list = [
        img0,
        img1,
        img2,
        img3,
        img4,
        img5,
        img6,
        img7,
        img8,
        img9,
        img10,
    ];

    return (
        <>
            {isDesktopOrLaptop ? (
                <div class='w-full h-full bg-center'>
                    <AliceCarousel
                        className='fixed w-full h-full'
                        animationDuration={3500}
                        infinite={true}
                        autoPlay={true}
                        disableDotsControls
                        disableButtonsControls
                        fadeOutAnimation={true}
                        responsive={{
                            0: {
                                items: 1,
                            },
                            1024: {
                                items: 2,
                                itemsFit: "contain",
                            },
                        }}
                        items={list.map((listItem, index) => (
                            <div className='rounded-lg w-full h-full'>
                                <div className='w-full h-[400px]'>
                                    <img
                                        src={listItem}
                                        className='w-full h-full object-cover'
                                        alt='gallery img'
                                    />
                                </div>
                            </div>
                        ))}
                    />
                    <div class='absolute bg-news-gradient top-0 w-full h-[400px]'>
                        <div className='flex justify-start mx-32 pt-[13%] pb-20 text-8xl items-center'>
                            <h1 className='pr-2 font-extrabold text-white '>
                                Media
                            </h1>
                            <h1 className='mb-0 ml-2 font text-green '>
                                Gallery
                            </h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div class='bg-gallery-hero bg-cover h-72 relative text-white bg-center'>
                    <div class=' bg-news-gradient h-full'>
                        <div className='flex justify-start pl-[5%] font-semibold  text-5xl items-center absolute bottom-5'>
                            <h1 className='pr-1 text-white '>Media</h1>
                            <h1 className='ml-2 pt-0.5 text-green '>Gallery</h1>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Hero;
