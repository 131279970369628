import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import "react-alice-carousel/lib/alice-carousel.css";
import { Carousel } from "react-responsive-carousel";
import TextTruncate from "react-text-truncate";
import { Link } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";

const Ecosystem = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    const { t } = useTranslation();

    const items = [
        {
            title: "about-pre-reg",
            text: "about-pre-reg-text",
        },
        {
            title: "about-reg",
            text: "about-reg-text",
        },
        {
            title: "about-credential-provision",
            text: "about-credential-provision-text",
        },
        {
            title: "about-call-center",
            text: "about-call-center-text",
        },
        {
            title: "about-integrations",
            text: "about-integrations-text",
        },
    ];

    return (
        <>
            {isDesktopOrLaptop ? (
                <div className='relative flex flex-col items-center mt-10 bottom-12'>
                    <div className='flex flex-col items-center justify-center mt-20 text-4xl text-center '>
                        <div className='flex pb-6 text-4xl md:text-3xl lg:text-6xl xl:text-6xl'>
                            <h1 className='pr-2 font-semibold  text-MetallicBlue'>
                                {t("ecosystem")}
                            </h1>
                        </div>
                        <hr className='w-20 border-2 border-green' />
                    </div>

                    <div className='flex flex-col items-center pb-16 text-base font-light pt-7'>
                        {/* <h1 className="pb-8  text-2x1 md:text-6xl font-extralight "> {t('about-who-involved')}</h1> */}
                        <p className='w-[75%]  text-lg md:text-xl  text-center '>
                            {t("about-who-involved-text")}
                        </p>
                    </div>

                    <div className='relative w-[75%] grid grid-cols-2 grid-rows-3 justify-center gap-8'>
                        <div className='flex flex-col items-center pt-5 border-t-8 h-72 border-t-green backdrop-brightness-95 rounded-2xl'>
                            <h1 className='pb-2 text-xl font-semibold md:text-2xl'>
                                {t("about-pre-reg")}
                            </h1>
                            <hr className='w-[80%]' />

                            <p className='relative px-16 py-2 pt-5 overflow-auto text-xl leading-tight text-center  '>
                                {t("about-pre-reg-text")}
                            </p>
                        </div>

                        <div className='flex flex-col items-center pt-5 border-t-8 h-72 border-t-darkorange backdrop-brightness-95 rounded-2xl'>
                            <div className=''>
                                <h1 className='pb-2 text-xl font-semibold md:text-2xl'>
                                    {t("about-reg")}
                                </h1>
                            </div>
                            <hr className='relative w-[80%] ' />

                            <p className='relative py-2 overflow-auto text-xl leading-tight text-center px-14 '>
                                {t("about-reg-text")}
                            </p>
                        </div>

                        <div className='flex flex-col items-center pt-5 text-sm border-t-8 h-72 md:text-base border-t-darkred backdrop-brightness-95 rounded-2xl'>
                            <h1 className='pb-2 text-xl font-semibold md:text-2xl'>
                                {t("about-credential-provision")}
                            </h1>
                            <hr className='relative w-[80%] ' />

                            <p className='relative px-16 py-2 pt-5 overflow-auto text-xl leading-tight text-center '>
                                {t("about-credential-provision-text")}
                            </p>
                        </div>

                        <div className='flex flex-col items-center pt-5 text-sm border-t-8 h-72 md:text-base border-t-darkblue backdrop-brightness-95 rounded-2xl'>
                            <h1 className='pb-2 text-xl text-center w-[80%] font-semibold md:text-2xl'>
                                {t("about-call-center")}
                            </h1>
                            <hr className='relative w-[80%] ' />

                            <p className='relative px-16 py-2 pt-5 overflow-auto text-xl leading-tight text-center '>
                                {t("about-call-center-text")}
                            </p>
                        </div>
                        <div className='col-span-2 px-0 '>
                            <div className='flex flex-col items-center pt-5 text-sm border-t-8 h-72 md:text-base border-t-lightblue backdrop-brightness-95 rounded-2xl bg-[#f2f2f2]'>
                                <h1 className='pb-2 text-xl font-semibold md:text-2xl'>
                                    {t("about-integrations")}
                                </h1>
                                <hr className='relative w-[80%] ' />

                                <p className='relative px-16 py-2 pt-5 overflow-auto text-xl leading-tight text-center '>
                                    {t("about-integrations-text")}
                                </p>
                                <Link
                                    to='/authentication'
                                    className='font-bold italic text-MetallicBlue mt-3'
                                >
                                    {t("Read-More")}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='flex mb-4 flex-col items-center mt-5'>
                    <div className='flex flex-col items-center'>
                        <div className='flex pb-3 text-3xl'>
                            <h1 className='font-semibold  text-MetallicBlue'>
                                {t("ecosystem")}
                            </h1>
                        </div>
                        <hr className='w-[25%] border-2 border-green' />
                    </div>

                    <div className='flex flex-col items-center pb-3 text-base font-light'>
                        {/* <h1 className="pb-8  text-2x1 md:text-6xl font-extralight "> {t('about-who-involved')}</h1> */}
                        <p className='w-[75%] text-lg  text-center '>
                            {t("about-who-involved-text")}
                        </p>
                    </div>

                    <Carousel
                        axis='horizontal'
                        className='w-[100%] h-[50%] pt-4 self-center px-5 items-center justify-center'
                        showArrows={true}
                        showStatus={false}
                        showThumbs={false}
                        swipeable={true}
                        // autoPlay={true}

                        infiniteLoop={true}
                        interval={2500}
                        swipeScrollTolerance={80}
                        // thumbWidth={90}
                        transitionTime={1000}
                        renderIndicator={false}
                        renderArrowPrev={(clickHandler, hasPrev) => (
                            <div
                                className={`${
                                    hasPrev ? "absolute" : "hidden"
                                }  flex justify-center items-center text-4xl h-full opacity-30 hover:opacity-100 cursor-pointer z-10`}
                                onClick={clickHandler}
                            >
                                <FaChevronLeft />
                            </div>
                        )}
                        renderArrowNext={(clickHandler, hasNext) => (
                            <div
                                className={`${
                                    hasNext ? "absolute" : "hidden"
                                }  right-0 flex justify-end text-4xl w-fit items-center inset-y-0 opacity-30 hover:opacity-100 cursor-pointer z-40`}
                                onClick={clickHandler}
                            >
                                <FaChevronRight />
                            </div>
                        )}
                    >
                        {items.map((item) => (
                            <div className='flex'>
                                <div className='flex flex-row justify-center h-64'>
                                    <div className='flex flex-col w-[80%] text-center items-center border-grey border-2 rounded-2xl'>
                                        <h1 className='pb-1 text-xl font-semibold'>
                                            {t(item.title)}
                                        </h1>
                                        <hr className='w-[80%]' />
                                        {/* <hr className="w-[50%] pb-5" /> */}

                                        <p className='flex flex-col items-center text-lg leading-tight tracking-tighter text-justify py-2 px-6  font-light'>
                                            {/* {t("about-Enrollment-strategy-text")} */}
                                            <TextTruncate
                                                line={9}
                                                element='span'
                                                truncateText='…'
                                                text={t(item.text)}
                                                textTruncateChild={
                                                    <Link className='font-bold italic text-MetallicBlue'>
                                                        {t("Read-More")}
                                                    </Link>
                                                }
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            )}
        </>
    );
};

export default Ecosystem;
