import { GET_ABOUT_LIST } from "../actions/aboutActions";
import { GET_HISTORY_LIST } from "../actions/aboutActions";
import { GET_PARTNERS_LIST } from "../actions/aboutActions";
import { GET_HERO_HOME } from "../actions/aboutActions";

 const initialState = {
    aboutList: [],
    historyList: [],
    partnersList: [],
    heroHome: []
 }

 const aboutReducer = (state = initialState, action) => {
   
    
    switch(action.type){
    
      case GET_HERO_HOME:  
      return{
          ...state,
         heroHome: action.payload
        };  
      case GET_ABOUT_LIST:  
        return{
            ...state,
           aboutList: action.payload
          };
         case GET_HISTORY_LIST:
            return{
               ...state,
           historyList: action.payload
            };
         case GET_PARTNERS_LIST:
            return{
               ...state,
            partnersList: action.payload
            };
          default: 
            return state;
    }
 };
 export default aboutReducer;