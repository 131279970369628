import { useCallback, useState } from "react";
import { tableConfig } from "./tableConfig";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const TableComponent = ({ data }) => {
    const [sortKey, setSortKey] = useState("document");
    const [sortOrder, setSortOrder] = useState("ascn");

    const headers = [
        { key: "document", label: "DOCUMENT" },
        { key: "date", label: "DATE" },
        // { key: "document_code", label: "CODE" },
        { key: "document_type", label: "DOCUMENT TYPE" },
    ];

    const sortData = ({ tableData, sortKey, reverse }) => {
        const data = tableConfig;
        if (!sortKey) return tableData;

        const sortedData = data.sort((a, b) => {
            return a[sortKey] > b[sortKey] ? 1 : -1;
        });

        if (reverse) {
            return sortedData.reverse();
        }

        return sortedData;
    };

    const SortButton = ({ sortOrder, columnKey, sortKey, onClick }) => {
        return (
            <button
                onClick={onClick}
                className={`text-[#225D71] ${
                    sortKey === columnKey && sortOrder === "desc"
                        ? "sort-button sort-reverse"
                        : "sort-button"
                }`}
            >
                {sortKey === columnKey ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </button>
        );
    };
    const sortedData = useCallback(
        () =>
            sortData({
                tableData: data,
                sortKey,
                reverse: sortOrder === "desc",
            }),
        [data, sortKey, sortOrder]
    );

    const changeSort = (key) => {
        setSortOrder(sortOrder === "ascn" ? "desc" : "ascn");

        setSortKey(key);
    };

    return (
        <table className=''>
            <thead className='font-semibold text-lg'>
                <tr>
                    {headers.map((row) => {
                        return (
                            <td key={row.key}>
                                <div className='flex items-center gap-1'>
                                    {row.label}{" "}
                                    <SortButton
                                        columnKey={row.key}
                                        onClick={() => changeSort(row.key)}
                                        {...{
                                            sortOrder,
                                            sortKey,
                                        }}
                                    />
                                </div>
                            </td>
                        );
                    })}
                </tr>
            </thead>

            <tbody className='w-full'>
                {sortedData().map((doc, index) => {
                    return (
                        <tr key={index} className='even:bg-[#add8e6]'>
                            <td className='w-[50%]'>
                                <a
                                    href={"https://" + doc.document_link}
                                    target='_blank'
                                >
                                    <p className='py-2 text-[#2b6cb0]'>
                                        {doc.document}
                                    </p>
                                </a>
                            </td>
                            <td>
                                <p>{doc.date}</p>
                            </td>
                            {/* <td>
                                <p>{doc.document_code && doc.document_code}</p>
                            </td> */}
                            <td>
                                <p>{doc.document_type}</p>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default TableComponent;
