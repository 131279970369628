import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Modal from "./Modal";
import { GrNext } from "react-icons/gr";
import { GrPrevious } from "react-icons/gr";

const Video = ({ videosLists }) => {
    const [openModal, setOpenModal] = useState(false);
    const [showVideo, setShowVideo] = useState(
        ""
    );
    const [showVideoIndex, setShowVideoIndex] = useState(0);
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const handleOpenModal = (showVideo) => {
        setShowVideo(showVideo);
        setOpenModal(true);
    };

    const handleNext = () => {
        let totalLength = videosLists.length;
        if (showVideoIndex < totalLength - 1)
            setShowVideoIndex((prev) => prev + 1);
    };
    const handlePrev = () => {
        if (showVideoIndex > 0) setShowVideoIndex((prev) => prev - 1);
    };

    return (
        <div className='w-full z-20 py-10'>
            <div className='flex gap-5'>
                {!(showVideoIndex > 0) ? (
                    <div className='invisible'>Prev</div>
                ) : (
                    <button onClick={() => handlePrev()} className='text-3xl'>
                        <GrPrevious />
                    </button>
                )}
                <div className='w-full grid grid-cols-2 gap-5'>
                    {videosLists[showVideoIndex]?.map((videos) => (
                        <button
                            key={videos.video_url}
                            className='w-full relative bg-black hover:scale-[101%]'
                            onClick={() => handleOpenModal(videos.video_url)}
                        >
                            <div className='z-10 h-full min-h-[330px]'>
                                <ReactPlayer
                                    url={videos.video_url}
                                    width='100%'
                                    height='100%'
                                />
                            </div>
                            <div className='absolute top-0 w-full h-full z-50'></div>
                        </button>
                    ))}
                </div>
                {videosLists.length - 1 === showVideoIndex ? (
                    <div className='invisible'>Next</div>
                ) : (
                    <button onClick={() => handleNext()} className='text-3xl'>
                        <GrNext />
                    </button>
                )}
            </div>
            {openModal ? (
                <Modal videoUrl={showVideo} closeModal={handleCloseModal} />
            ) : null}
        </div>
    );
};

export default Video;
