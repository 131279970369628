import React, { useState, useEffect, useRef } from "react";
import QrScanner from "qr-scanner";
import QrReader from "react-qr-reader";

import { useTranslation } from "react-i18next";
import verified from "../../../../assets/verified-01.png";
import card from "../../../../assets/card mockuup-01.png";
import FaydaAppIcon from "../../../../assets/get-fayda-app-icon.svg";
import { useMediaQuery } from "react-responsive";

const Scanner = () => {
    const downloadLinkRef = useRef(null);
    const [devices, setDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState("");
    const videoRef = useRef();
    const [photo, setPhoto] = useState();
    const [name, setName] = useState();
    const [version, setVersion] = useState();
    const [FCN, setFCN] = useState();
    const [DOB, setDOB] = useState();
    const [gender, setGender] = useState();
    const [isVerified, SetIsVerified] = useState();
    const [startCamera, setStartCamera] = useState(true);
    const [startUpload, setStartUpload] = useState(false);
    const [reloadScanner, setReloadScanner] = useState(false);
    const [defaultCameraFlag, setDefaultCameraFlag] = useState(false);
    const [videoWidth, setVideoWidth] = useState(0);
    const [videoHeight, setVideoHeight] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);

    const { t } = useTranslation();

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    const base64UrlEncode = (str) => {
        // Convert the string to Base64 format
        let base64 = btoa(str);

        // Replace '+' with '-' and '/' with '_'
        base64 = base64.replace(/\+/g, "-").replace(/\//g, "_");

        // Remove any trailing '=' characters
        base64 = base64.replace(/=+$/, "");

        return base64;
    };

    const pemToCryptoKey = async (pemKey) => {
        //console.log("pemKey", pemKey);
        // Remove the header and footer and convert from base64 to a Uint8Array
        const base64Key = pemKey
            .replace(/-----BEGIN PUBLIC KEY-----/, "")
            .replace(/-----END PUBLIC KEY-----/, "")
            .replace(/\s+/g, "");
        // console.log("base64Key", base64Key);
        const keyData = Uint8Array.from(atob(base64Key), (c) =>
            c.charCodeAt(0)
        );
        // console.log("keyData", keyData);

        const key = await crypto.subtle.importKey(
            "spki",
            keyData,
            {
                name: "RSASSA-PKCS1-v1_5",
                hash: { name: "SHA-256" },
            },
            true,
            ["verify"]
        );
        // console.log("key", key);

        return key;
    };

    const verifySignature = async (signature_org, public_key, messageData) => {
        // console.log("signature_org", signature_org);
        // console.log("public_key", public_key);
        // console.log("messageData", messageData);
        const [headerSplit, payloadSplit, signatureSplit] =
            signature_org.split(".");
        // console.log("headerSplit", headerSplit);
        // console.log("payloadSplit", payloadSplit);
        // console.log("signatureSplit", signatureSplit);

        //encode payload to base64url safe
        const payload_base_64 = base64UrlEncode(messageData);
        // console.log("payload_base_64", payload_base_64);

        const data = `${headerSplit}.${payload_base_64}`;
        // console.log("data", data);
        const dataByte = new TextEncoder().encode(data);
        // console.log("dataByte", dataByte);

        // Decode the signature from base64
        const signature = signatureSplit + "==";
        // console.log("signature", signature);

        const signature_to_base64 = atob(
            signature.replace(/-/g, "+").replace(/_/g, "/")
        );
        // console.log("signature_to_base64", signature_to_base64);
        const decoded_signature = Uint8Array.from(signature_to_base64, (c) =>
            c.charCodeAt(0)
        );
        // console.log("decoded_signature", decoded_signature);
        // // Load the public key for verification
        const key = await pemToCryptoKey(public_key);
        // console.log("imported_key", key);

        SetIsVerified(
            await crypto.subtle.verify(
                {
                    name: "RSASSA-PKCS1-v1_5",
                    hash: "SHA-256",
                },
                key,
                decoded_signature,
                dataByte
            )
        );

        // console.log(`Signature verified? ${isVerified}`);
        return isVerified;
        //return true;
    };

    const scanCode = async (res) => {
        // console.log("This is the result!!", res);
        if (!res.includes("SIGN")) {
            const errorMessage =
                "Invalid QR code, Please provide a valid QR code.";
            alert(errorMessage);
            SetIsVerified(false);
            // console.log("The string does not include the substring 'SIGN'");
            setData({
                verified: false,
            });
            return;
        } else {
            const faydaPK = `-----BEGIN PUBLIC KEY-----
            MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5QwiCvWp6XRFEqr9hkZ+
            +nvXsyZ8IOvXX+peWYFFkwvS0wrAlBjNGH6cxWARoHyjhW0yZijrO2lniBsUeuzL
            iaKuaXyQ5xMoYqRqZB909l2KbdmG8J2/NSX8FU4IEkHX9jQHLyf4z2qsNYRu1Cvw
            OIMoncW4i+jEFN37SXc23+bzEaKFMKR0zMLWbmZk8giCbDH1lVrXNSJI2KVoLocD
            MICD1SLG95iw3CYwXWsPSoVQsTFYQkDqwqyrNBOmo4oQ6iXeGXuejZNLWt8BtdVv
            24KjX6bDHtaA31InzvXMNgU5cZpNFWk+Uq5F48gk9z96grJn2e2NcXR0pW+jp0r8
            HwIDAQAB
            -----END PUBLIC KEY-----`;
            const faydaPK2 = `-----BEGIN PUBLIC KEY-----
            MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0L0yxop/NwMRrUl2ThfZ
            NjS84euK7BMHLlrZ+MbnTneBeSDy/1dJbrxTaChW6S4W4bA9VvfR0YSk7s0Rq17l
            AQ/UkaUuVbTSLXzGJUicGI6eFWpp0WYuDz0y0lEQQL0+LgAb+4AsilVyNBnvKTbn
            Ai2jEiCRIZKELbWsbvreNePzDRvCn+CH8Vy0p4QcJ4fM1UHU+QFYrPOCvv997iVy
            TnXlVO9hGV0IRFIhYRklBUFi3LvgJNVvCvQUO6tI27Y+HSBG8/XRxylO3it0iGws
            fetX5ldF/9TdZ3zw+FZuOrUKt763V+2eyAz4vomH+HvfnEaBstaKI6V5OhFZCc62
            uwIDAQAB
            -----END PUBLIC KEY-----
            `
            const faydaPK3 = `-----BEGIN PUBLIC KEY-----
            MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAxMWfApFZBq/qIotUjrkY
            bdyXQcHOUgZsqZy3QUcXr50t+DfwYKkSfMnTZFHekptAWA4HM5upav6xqFuP+3Pi
            KmajY2Imkl60bF4Bt9qcch74jPSObH/1CE0xs75dqLb+CfoTG1+i4n3Fz5cN9Mxp
            EGE7pmvIdYtaAUCoKA/cjQ5QY426ttp4EQcnh5vmyr1e7ur9LjqVVsurfefxlsR+
            sRfB2RFuaEGrLO/75UeUSY2x1nTHzOvycWYaCve1YfunHkXA8JvUy73+b33ixIgw
            QuqUkOuyKhytFr5l6N1gfjTvOOysclCH5o8jy7KyzgyK17kHXPAaKuxYXH9pGtAF
            0wIDAQAB
            -----END PUBLIC KEY-----    
            `

            const split_sign_dlt = res.split(":SIGN:")[0].split(":DLT:")[1];
            const hasQuestionMark = split_sign_dlt.indexOf("?") !== -1;
            const excludeQuestionMark = hasQuestionMark ? split_sign_dlt.split("?") : [split_sign_dlt];
            const userphoto = res.split(":SIGN:")[0].split(":DLT:")[0];
            const base64photo = userphoto.replace(/-/g, "+").replace(/_/g, "/");
            const payload_user = res.split(":SIGN:")[0];
            let version_;
            if (payload_user.includes(":V:")) {
                const split1 = payload_user.split(":V:")[1];
                const split3 = payload_user.split(":V:")[0];
                const fullname_ = split3.split(":DLT:")[1];
                setName(fullname_);
                const split4 = split1.split(":A:")[0];
                version_ = split4.split(":G:")[0];
                const gender = split4.split(":G:")[1];
                setVersion(version_);
                setGender(gender);
                const split2 = split1.split(":A:")[1];
                const fcn_ = split2.split(":D:")[0];
                setFCN(fcn_);
                const dob_ = split2.split(":D:")[1];
                setDOB(dob_);
            } else {
                setName(
                    excludeQuestionMark
                    // res.split(":SIGN:")[0].split(":DLT:")[1].replace(/'/g, "")
                );
            }

            setPhoto("data:image/png;base64, " + base64photo);
            const userinformation = res.split(":SIGN:")[0];
            const signature = res.split(":SIGN:")[1];

            // The original data that was signed
            const originalData = userinformation;

            // The digital signature, which includes the encrypted hash value
            const signature_org = signature;

            const isSIGNVerified = await verifySignature(
                signature_org,
                version_ === "2" ? faydaPK 
                : version_ === "3" ? faydaPK2 
                : version_ === "4" ? faydaPK3 
                : "",
                originalData
            );
            // .then((isValid) => console.log(isValid))
            // .catch((error) => console.error(error));
            // console.log("Is the signature verified", isSIGNVerified);

            // if (isSIGNVerified === true) {
                // console.log("Signature is valid");
                // verified = true;
            // } else {
                // console.log("Signature is invalid");
                // verified = false;
            // }
        }
    };

    // _____________________ACtually SAFE CODE_________________________________

    useEffect(() => {
        const getCameraDevices = async () => {
            try {
                await navigator.mediaDevices.getUserMedia({ video: true });
                const mediaDevices =
                    await navigator.mediaDevices.enumerateDevices();
                let results = [];
                for (const device of mediaDevices) {
                    if (device.kind === "videoinput") {
                        results.push({
                            id: device.deviceId,
                            label: device.label,
                        });
                    }
                }

                const videoDevices = mediaDevices.filter(
                    (device) => device.kind === "videoinput"
                );
                setDevices(videoDevices);

                const lastDevice =
                    videoDevices[videoDevices.length - 1].deviceId;
                const lastDevicebyLabel = results[results.length - 1].label;
                const constraints1 = {
                    video: {
                        deviceId: lastDevice,
                        width: { ideal: 1280 },
                        height: { ideal: 720 },
                        frameRate: { ideal: 30 },
                        advanced: [
                            { autoFocus: "continuous" },
                            { exposureMode: "auto" },
                            { whiteBalanceMode: "auto" },
                        ],
                    },
                };

                startScanner(constraints1, lastDevicebyLabel);
            } catch (error) {
                console.log("Error accessing media devices:", error);
            }
        };

        getCameraDevices();
    }, [selectedDeviceId, reloadScanner]);

    const startScanner = async (selectedDeviceId1, byLabel) => {
        try {
            if (selectedDeviceId1) {
                const dicOption = {
                    preferredCamera: (QrScanner.DeviceId =
                        selectedDeviceId1.video.deviceId),
                    maxScansPerSecond: 7,
                    highlightScanRegion: true
                        ? selectedDeviceId1.video.deviceId != ""
                        : false,
                    highlightCodeOutline: true,
                };

                const dicOptionIphone = {
                    preferredCamera: (QrScanner.FacingMode = "environment"),
                    maxScansPerSecond: 5,
                    highlightScanRegion: true
                        ? QrScanner.FacingMode != ""
                        : false,
                    highlightCodeOutline: true,
                };

                let scanner;

                if (selectedDeviceId1?.video?.deviceId !== undefined) {
                    scanner = new QrScanner(
                        videoRef.current,
                        async (result) => {
                            if (result) {
                                console.log("result from scan: ", result);
                                scanCode(result.data);
                                scanner.stop();
                                setStartUpload(true);
                                setStartCamera(false);
                            }
                        },
                        dicOption
                    );
                } else {
                    scanner = new QrScanner(
                        videoRef.current,
                        async (result) => {
                            if (result) {
                                scanCode(result.data);
                                scanner.stop();
                                setStartUpload(true);
                                setStartCamera(false);
                            }
                        },
                        dicOptionIphone
                    );
                }

                await scanner.start();

                return () => {
                    scanner.destroy();
                };
            }
        } catch (error) {
            console.log("Error starting scanner:", error);
        }
    };

    const handleReloadButtonClick = () => {
        setStartUpload(false);
        setReloadScanner((prevState) => !prevState);
        setStartCamera(true);
    };

    // _____________________Actually SAFE CODE_________________________________^

    //to handle camera change if camera list is available
    const handleDeviceChange = (event) => {
        setSelectedDeviceId(event.target.value);
    };

    const fileUpload = (event) => {
        setSelectedFile(event.target.files[0]);
        // setStartCamera(false);
    };

    useEffect(() => {
        if (selectedFile) {
            handleUpload();
            // setStartCamera(false);
            setStartUpload(true);
        }
    }, [selectedFile]);
    // useEffect(() => {
    //   // Simulate a click on the download link when the component mounts
    //   downloadLinkRef.current.click();
    // }, []);

    const handleUpload = () => {
        // setSelectedFile(event.target.files[0]);
        if (selectedFile) {
            // console.log("Uploading file:", selectedFile);
            QrScanner.scanImage(selectedFile)
                .then((result) => {
                    scanCode(result);
                })
                .catch((error) => console.log(error || "No QR code found."));
        } else {
            // console.log("No file selected");
        }
    };

    const TwoZeroZeroPercent = useMediaQuery({
        query: "(max-height: 500px)",
    });

    const OneSevenFivePercent = useMediaQuery({
        query: "(max-height: 600px)",
    });

    const OneFivePercent = useMediaQuery({
        query: "(max-height: 700px)",
    });

    return (
        <>
            {/* {
      selectedDeviceId &&
    <>  */}
            {isDesktopOrLaptop ? (
                <div className='flex flex-col w-full h-full items-center justify-center mb-14 -mt-28 '>
                    <div className='flex flex-col w-[50%] h-full items-center justify-center gap-6 mt-5'>
                        <div className='flex w-[38%] h-full bg-center bg-cover rounded-2xl'>
                            <img
                                src={card}
                                alt='card'
                                className='w-full h-full object-cover'
                            />
                        </div>
                        <div className='px-10 text-center'>
                            <h2 className='text-MetallicBlue text-4xl'>
                                {t("qrscanner-body-title")}
                            </h2>
                            <h2 className='text-darkgreen text-4xl font-bold'>
                                {t("qrscanner-body-title2")}
                            </h2>

                            <div className='text-start'>
                                <p className='text-xl leading-6 tracking-tighter'>
                                    {t("qrscanner-body-description")}
                                </p>
                            </div>

                            <div className='mt-4 w-full gap-4   shadow-lg  h-full justify-center flex py-1 align-middle bg-grey border-4 border-white rounded-lg  place-items-center  hover:bg-green transition-all  duration-300 hover:scale-105'>
                                <div className='flex w-[10%]'>
                                    <img
                                        src={FaydaAppIcon}
                                        alt='FaydaAppIcon '
                                        className='h-[10%]'
                                    />
                                </div>
                                <a
                                    ref={downloadLinkRef}
                                    href={
                                        process.env.PUBLIC_URL +
                                        "/static/fayda-verifier.apk"
                                    }
                                    download
                                    type='application/vnd.android.package-archive'
                                >
                                    <div className='flex flex-col items-center justify-center leading-tight rounded-lg cursor-pointer  '>
                                        <h1 className='lg:text-xl md:text-xl font-medium text-black'>
                                            Click Here to Download the
                                        </h1>
                                        <h1 className='font-semibold lg:text-2xl md:text-xl sm:text-lg text-MetallicBlue '>
                                            Fayda Offline Authenticator
                                        </h1>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className='flex flex-col w-full items-center justify-center'>
                            {startCamera && (
                                <div className='flex w-full items-center justify-center'>
                                    <video
                                        ref={videoRef}
                                        className='w-[90%] h-[90%]'
                                        autoPlay
                                        playsInline
                                        muted
                                        controls={false}
                                        // onLoadedMetadata={handleVideoLoaded}
                                    ></video>
                                </div>
                            )}
                        </div>
                        {isVerified && (
                            <div className='flex flex-col w-full h-full items-center justify-center'>
                                {startUpload && (
                                    <div className='flex flex-col w-[70%] place-self-center items-center justify-center text-start p-5 rounded-xl shadow-2xl'>
                                        <div className='flex flex-col w-[100%] h-full '>
                                            {photo && (
                                                <img
                                                    className='w-[80%] h-[80%] scale-90 self-center rounded-lg'
                                                    src={photo}
                                                    alt='Base64 encoded image'
                                                />
                                            )}
                                            {/* <div className="relative">
                    <img
                      src={verified}
                      alt=""
                      className="shadow-sm rounded-3xl w-full h-full object-cover border-2 border-grey"
                    />
                    <div
                      className={`${
                        TwoZeroZeroPercent
                          ? "absolute -top-2 left-0 w-full h-full flex flex-col justify-center items-center text-3xl"
                          : OneSevenFivePercent
                          ? "absolute -top-0 left-10 w-full h-full flex flex-col justify-center items-center text-2xl"
                          : OneFivePercent
                          ? "absolute -top-2 left-0 w-full h-full flex flex-col justify-center items-center text-3xl"
                          : "absolute -top-2 left-0 w-full h-full flex flex-col justify-center items-center text-3xl"
                      }`}
                    >
                      <p
                        className={`${
                          TwoZeroZeroPercent
                            ? "text-white -ml-16 tracking-widest font-medium"
                            : OneSevenFivePercent
                            ? "text-white -ml-28 tracking-widest font-medium"
                            : OneFivePercent
                            ? "text-white -ml-4 tracking-widest font-medium"
                            : "text-white -ml-16 tracking-widest font-medium"
                        }`}
                      >
                        VERIFIED IDENTITY!
                      </p>
                      <span
                        className={`${
                          TwoZeroZeroPercent
                            ? "text-MetallicBlue font-bold mt-10"
                            : OneSevenFivePercent
                            ? "text-MetallicBlue font-bold mt-10 -ml-20"
                            : OneFivePercent
                            ? "text-MetallicBlue font-bold mt-10"
                            : "text-MetallicBlue font-bold mt-10"
                        }`}
                      >
                        {name}
                        <br />
                        Version: {version}
                        <br />
                        FCN: {FCN}
                        <br />
                        Date of Birth: {DOB}
                      </span>
                    </div>
                  </div> */}
                                            <div className='flex w-full h-full rounded-2xl shadow-xl border border-grey border-opacity-70'>
                                                <div className='flex flex-col w-full bg-white h-full rounded-t-2xl rounded-b-2xl'>
                                                    <div className='flex w-full items-center bg-[#2ea86d] h-full py-1 pl-5 rounded-t-2xl'>
                                                        <div>
                                                            <svg
                                                                xmlns='http://www.w3.org/2000/svg'
                                                                className='h-12 w-10 text-white mx-auto'
                                                                fill='none'
                                                                viewBox='0 0 24 24'
                                                                stroke='currentColor'
                                                            >
                                                                {/* White Background Circle */}
                                                                <circle
                                                                    cx='12'
                                                                    cy='12'
                                                                    r='11'
                                                                    fill='white'
                                                                />

                                                                {/* Green Circle */}
                                                                <circle
                                                                    cx='12'
                                                                    cy='12'
                                                                    r='10'
                                                                    fill='transparent'
                                                                    stroke='#2ea86d'
                                                                />

                                                                {/* Checkmark Path */}
                                                                <path
                                                                    strokeLinecap='butt'
                                                                    strokeLinejoin='miter'
                                                                    strokeWidth='1.5'
                                                                    d='M5 13l4 4L19 7'
                                                                    stroke='#2ea86d'
                                                                />
                                                            </svg>
                                                        </div>
                                                        <h2 className='text-white md:text-4xl tracking-widest font-medium pl-5'>
                                                            VERIFIED IDENTITY!
                                                        </h2>
                                                    </div>
                                                    <div className='flex pl-3 text-xl bg-white rounded-b-2xl py-3'>
                                                        <div
                                                            className={`${
                                                                TwoZeroZeroPercent
                                                                    ? "text-MetallicBlue font-bold"
                                                                    : OneSevenFivePercent
                                                                    ? "text-MetallicBlue font-bold"
                                                                    : OneFivePercent
                                                                    ? "text-MetallicBlue font-bold"
                                                                    : "text-MetallicBlue font-bold"
                                                            }`}
                                                        >
                                                            {name && (
                                                                <>
                                                                    Name: {name}
                                                                </>
                                                            )}
                                                            <br />
                                                            {gender && (
                                                                <>
                                                                    Gender:{" "}
                                                                    {gender}
                                                                </>
                                                            )}
                                                            <br />
                                                            {DOB && (
                                                                <>
                                                                    Date of
                                                                    Birth: {DOB}
                                                                </>
                                                            )}
                                                            <br />
                                                            {version && (
                                                                <>
                                                                    Version:{" "}
                                                                    {version}
                                                                </>
                                                            )}
                                                            <br />
                                                            {FCN && (
                                                                <>FCN: {FCN}</>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className='flex items-center w-[50%] gap-10'>
                            <div className='w-[100%]'>
                                <button
                                    className='bg-MetallicBlue py-2 w-full hover:bg-green text-white text-2xl font-bold rounded active:scale-105 transition-all duration-150 ease-in-out'
                                    onClick={handleReloadButtonClick}
                                >
                                    Reload QR
                                </button>
                            </div>
                            <div className='relative w-[100%] bg-MetallicBlue text-white text-center font-bold py-2 rounded'>
                                <span className='text-2xl'>Upload File</span>
                                <input
                                    className='absolute inset-0 opacity-0 z-50'
                                    type='file'
                                    onChange={() => fileUpload()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='flex flex-col items-center justify-center mb-14 -mt-28 '>
                    <div className='flex flex-col items-center justify-center gap-6 mt-5 w-full h-full'>
                        <div className='flex w-[70%] h-full bg-center bg-cover rounded-2xl'>
                            <img
                                src={card}
                                alt='card'
                                className='w-full h-full object-cover'
                            />
                        </div>
                        <div className='px-7 text-center'>
                            <h2 className='text-MetallicBlue text-4xl'>
                                {t("qrscanner-body-title")}
                            </h2>
                            <h2 className='text-darkgreen text-4xl font-bold'>
                                {t("qrscanner-body-title2")}
                            </h2>
                            <div className='text-start ml-3'>
                                <p className='text-xl leading-6 tracking-tight'>
                                    {t("qrscanner-body-description")}
                                </p>
                            </div>
                            <div className=' bg-grey mt-4 w-full gap-4   shadow-lg  h-full justify-center flex py-1 align-middle  border-4 border-white rounded-lg  place-items-center  hover:bg-green transition-all  duration-300 hover:scale-105'>
                                <div className='flex w-[10%]'>
                                    <img
                                        src={FaydaAppIcon}
                                        alt='FaydaAppIcon '
                                        className='h-[10%]'
                                    />
                                </div>
                                <a
                                    ref={downloadLinkRef}
                                    href={
                                        process.env.PUBLIC_URL +
                                        "/static/fayda-verifier.apk"
                                    }
                                    download
                                    type='application/vnd.android.package-archive'
                                >
                                    <div className='flex flex-col items-center justify-center leading-tight rounded-lg cursor-pointer  '>
                                        <h1 className='lg:text-xl md:text-xl font-medium text-black'>
                                            Click Here to Download the
                                        </h1>
                                        <h1 className='font-semibold lg:text-2xl md:text-xl sm:text-lg text-MetallicBlue '>
                                            Fayda Offline Authenticator
                                        </h1>
                                    </div>
                                </a>
                            </div>
                        </div>
                        {/* <div>
              <label htmlFor="devices">Select camera:</label>
              <select
                id="devices"
                value={selectedDeviceId}
                onChange={handleDeviceChange}
              >
                {devices.map((device) => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label}
                  </option>
                ))}
              </select>
            </div> */}

                        <div className='flex flex-col w-full items-center justify-center'>
                            {startCamera && (
                                <div className='flex w-full items-center justify-center'>
                                    <video
                                        ref={videoRef}
                                        className='w-[90%] h-[90%]'
                                        autoPlay
                                        playsInline
                                        muted
                                        controls={false}
                                    ></video>
                                </div>
                            )}
                        </div>
                        {isVerified && (
                            <div className='flex flex-col w-full h-full items-center justify-center'>
                                {startUpload && (
                                    <div className='flex flex-col w-[90%] place-self-center items-center justify-center text-start p-5 rounded-xl shadow-2xl'>
                                        <div className='w-[100%] h-full'>
                                            {/* <p className="text-start w-full">QR Code: {code}</p> */}
                                            {photo && (
                                                <img
                                                    className='w-full h-full scale-90 rounded-lg'
                                                    src={photo}
                                                    alt='Base64 encoded image'
                                                />
                                            )}
                                            {/* <div className="relative">
                        <img
                          src={verified}
                          alt=""
                          className={`${
                            isVerified
                              ? "shadow-sm rounded-3xl border-[0.5px] border-grey h-72"
                              : "hidden"
                          }`}
                        />
                        <div
                          className={`${
                            isVerified
                              ? "absolute md:-top-2 top-0 left-0 w-full h-full flex flex-col justify-center items-center"
                              : "hidden"
                          } `}
                        >
                          <p className="text-white md:-ml-10 md:text-4xl -ml-16 mb-4 tracking-widest font-medium">
                            VERIFIED IDENTITY!
                          </p>
                          <span className="text-MetallicBlue font-bold md:text-4xl md:mt-14 text-2xl mt-2">
                            {name}
                            <br />
                            Version: {version}
                            <br />
                            FCN: {FCN}
                            <br />
                            Date of Birth: {DOB}
                          </span>
                        </div>
                      </div> */}
                                            <div className='flex w-full h-full rounded-2xl shadow-xl border border-grey border-opacity-70'>
                                                <div className='flex flex-col w-full bg-white h-full rounded-t-2xl rounded-b-2xl'>
                                                    <div className='flex w-full items-center bg-[#2ea86d] h-full py-1 pl-5 rounded-t-2xl'>
                                                        <div>
                                                            <svg
                                                                xmlns='http://www.w3.org/2000/svg'
                                                                className='h-12 w-10 text-white mx-auto'
                                                                fill='none'
                                                                viewBox='0 0 24 24'
                                                                stroke='currentColor'
                                                            >
                                                                {/* White Background Circle */}
                                                                <circle
                                                                    cx='12'
                                                                    cy='12'
                                                                    r='11'
                                                                    fill='white'
                                                                />

                                                                {/* Green Circle */}
                                                                <circle
                                                                    cx='12'
                                                                    cy='12'
                                                                    r='10'
                                                                    fill='transparent'
                                                                    stroke='#2ea86d'
                                                                />

                                                                {/* Checkmark Path */}
                                                                <path
                                                                    strokeLinecap='butt'
                                                                    strokeLinejoin='miter'
                                                                    strokeWidth='1.5'
                                                                    d='M5 13l4 4L19 7'
                                                                    stroke='#2ea86d'
                                                                />
                                                            </svg>
                                                        </div>
                                                        <h2 className='text-white md:text-4xl tracking-widest font-medium pl-5'>
                                                            VERIFIED IDENTITY!
                                                        </h2>
                                                    </div>
                                                    <div className='flex pl-3 text-xl bg-white rounded-b-2xl py-3'>
                                                        <div
                                                            className={`${
                                                                TwoZeroZeroPercent
                                                                    ? "text-MetallicBlue font-bold"
                                                                    : OneSevenFivePercent
                                                                    ? "text-MetallicBlue font-bold"
                                                                    : OneFivePercent
                                                                    ? "text-MetallicBlue font-bold"
                                                                    : "text-MetallicBlue font-bold"
                                                            }`}
                                                        >
                                                            {name && (
                                                                <>
                                                                    Name: {name}
                                                                </>
                                                            )}
                                                            <br />
                                                            {gender && (
                                                                <>
                                                                    Gender:{" "}
                                                                    {gender}
                                                                </>
                                                            )}
                                                            <br />
                                                            {DOB && (
                                                                <>
                                                                    Date of
                                                                    Birth: {DOB}
                                                                </>
                                                            )}
                                                            <br />
                                                            {version && (
                                                                <>
                                                                    Version:{" "}
                                                                    {version}
                                                                </>
                                                            )}
                                                            <br />
                                                            {FCN && (
                                                                <>FCN: {FCN}</>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className='flex items-center w-[60%] gap-10'>
                            <div id='scrollto' className='w-[100%]'>
                                <button
                                    className='bg-MetallicBlue py-2 w-full active:bg-green active:scale-105 transition-all duration-150 ease-in-out text-white font-bold rounded'
                                    onClick={handleReloadButtonClick}
                                    // onClick={() => {
                                    // const element = document.getElementById("scrollto");
                                    // const yOffset = -10;
                                    // const y =
                                    //   element.getBoundingClientRect().top +
                                    //   window.scrollY +
                                    //   yOffset;
                                    // setStartCamera((prevState) => {
                                    //   // setStartCamera(true);
                                    //   console.log(prevState);
                                    //   if (prevState == false) {
                                    //     setStartUpload(false);
                                    //     setStartCamera(true);
                                    //     return (prevState) => !prevState;
                                    //   }
                                    //   if (prevState) {
                                    //     setStartUpload(true);
                                    //     setStartCamera(false);
                                    //     return startCamera;
                                    //   }
                                    //   return prevState;
                                    // });

                                    //   setTimeout(() => {
                                    //     window.scrollTo({ top: y, behavior: "smooth" });
                                    //   }, 500);
                                    // }}
                                >
                                    Reload QR
                                </button>
                            </div>
                            <div className='relative w-[100%] bg-MetallicBlue text-white text-center font-bold py-2 rounded'>
                                <span className=''>Upload File</span>
                                <input
                                    className='absolute inset-0 opacity-0 z-50'
                                    type='file'
                                    onChange={() => fileUpload()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Scanner;
