import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import infoIcon from "../../../assets/Info-icon.png";
import axios from "axios";

const Body = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [emailSent, setEmailSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({});
    const handleFormChange = (e) => {
        const { name, value } = e.target;
        console.log(name);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    const handleFormSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        setEmailSent(true);
    };

    useEffect(() => {
        if (emailSent) {
            console.log(formData);
            console.log("sent");
            fetch(
                "https://script.google.com/macros/s/AKfycbwquw0E4qR3C4THoYUcwkp-db9CuE8lPX0uw0pYO2_8EgiJzWnQZpWhImtW31ldPSY/exec",
                {
                    redirect: "follow",
                    method: "POST",
                    body: JSON.stringify(formData),
                    headers: {
                        "Content-Type": "text/plain;charset=utf-8",
                    },
                }
            )
                .then((response) => {
                    if (response.ok) {
                        console.log("Data added to sheet.");

                        alert("Request submitted successfully!");
                        navigate("/", { replace: true });
                    } else {
                        console.error("Error adding data to sheet.");
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
            setFormData({});
        }
        setEmailSent(false);
    }, [emailSent]);
    return (
        <>
            <div className='flex flex-row w-full justify-center px-48 py-10 pb-20'>
                <form onSubmit={handleFormSubmit} className='w-[85%]'>
                    <div className='flex flex-row w-[100%] px-20 justify-center shadow-2xl rounded-md mt-20  overflow-hidden'>
                        <div className='flex flex-col w-full gap-7 p-5'>
                            <div className='flex absolute bg-MetallicBlue w-[18%] justify-center -mt-10 rounded-sm text-white py-2'>
                                <img
                                    src={infoIcon}
                                    className='flex h-5 mt-1 pr-2'
                                    alt='fireSpot'
                                />
                                (<h3 className='text-[#ff0000]'>*</h3>)
                                <p className='pl-2'> {t("help-body-title")}</p>
                            </div>
                            <div className='flex flex-col w-full gap-10 pt-10'>
                                <div className='flex w-full'>
                                    <div className='flex w-[20%]'>
                                        <h3>{t("cardprice-body-company")}</h3>
                                        <h3 className='pl-1 text-[#ff0000]'>
                                            *
                                        </h3>
                                    </div>
                                    <div className='flex w-[80%] justify-end'>
                                        <input
                                            className='border-2 w-full rounded-sm pl-2 opacity-25 focus:opacity-100 text-b'
                                            type='text'
                                            name='company'
                                            value={formData.company}
                                            onChange={handleFormChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className='flex w-full'>
                                    <div className='flex w-[20%]'>
                                        <h3>{t("cardprice-body-tin")}</h3>
                                        <h3 className='pl-1 text-[#ff0000]'>
                                            *
                                        </h3>
                                    </div>
                                    <div className='flex w-[80%] justify-end'>
                                        <input
                                            className='border-2 w-full rounded-sm pl-2 opacity-25 focus:opacity-100'
                                            type='text'
                                            name='tin'
                                            value={formData.tin}
                                            onChange={handleFormChange}
                                            maxLength={13}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className='flex w-full'>
                                    <div className='flex w-[20%]'>
                                        <h3>
                                            {t("cardprice-body-estimatedPrice")}
                                        </h3>
                                        <h3 className='pl-1 text-[#ff0000]'>
                                            *
                                        </h3>
                                    </div>
                                    <div className='flex w-[80%] justify-end'>
                                        <input
                                            className='border-2 w-full rounded-sm pl-2 opacity-25 focus:opacity-100 text-b'
                                            type='text'
                                            name='estimated_price'
                                            value={formData.estimated_price}
                                            onChange={handleFormChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className='flex w-full'>
                                    <div className='flex w-[20%]'>
                                        <h3>{t("cardprice-body-location")}</h3>
                                        <h3 className='pl-1 text-[#ff0000]'>
                                            *
                                        </h3>
                                    </div>
                                    <div className='flex w-[80%] justify-end'>
                                        <input
                                            className='border-2 w-full rounded-sm pl-2 opacity-25 focus:opacity-100 text-b'
                                            type='text'
                                            name='location'
                                            value={formData.location}
                                            onChange={handleFormChange}
                                            required
                                        />
                                    </div>
                                </div>
                                {/* <div className="flex w-full">
                  <div className="flex w-[20%]">
                    <h3> {t("help-rid")}</h3>
                    
                  </div>
                  <div className="flex w-[80%] justify-end">
                    <input
                      className="border-2 w-full rounded-sm pl-2 opacity-25 focus:opacity-100"
                      type="text"
                      name="registration_id"
                      value={formData.registration_id}
                      onChange={handleFormChange}
                      minLength={29}
                      maxLength={29} 
                     
                    />
                  </div>
                </div> */}
                            </div>

                            <button
                                type='submit'
                                className='border rounded-lg py-1 w-[18%] ml-40 bg-MetallicBlue text-white hover:bg-green hover:text-MetallicBlue transition-all duration-200 hover:scale-105'
                            >
                                {isLoading ? (
                                    <div className='flex justify-center items-center'>
                                        <div className='w-8 h-8 border-2 border-t-[transparent] border-lightblue rounded-full animate-spin'></div>
                                    </div>
                                ) : (
                                    t("help-submit")
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};
export default Body;
