import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./sidebarData";
import SubMenu from "./subMenu";
import { IconContext } from "react-icons/lib";
import SearchIcon from "@mui/icons-material/Search";
import ButtonDropDown from "../dropdown/buttonDropDown";
import logoOnly from "../../assets/logo_only.png";

import { useScrollPosition } from "../../hooks/useScrollPosition";

const Nav = styled.div`
  position: absolute;
  z-index: 999;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon = styled(Link)`
  position: absoulte;

  font-size: 2rem;
  height: 80px;
  display: flex;
  margin-top: 0.75rem;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #315d71;
  width: 80%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 999;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;



const Sidebar = ({ viewState }) => {
  const ref = useRef(null);
  const [index, setIndex] = useState(0);
  const [sidebar, setSidebar] = useState(false);
  const navigate = useNavigate();

  const showSidebar = () => setSidebar(!sidebar);
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        
        setSidebar(false);
        // handle click outside logic here
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav className="z-40 flex items-center w-full hover:cursor-pointer ">
          <NavIcon to="#" className="fixed right-5">
            <FaIcons.FaBars
              onClick={() => setSidebar(!sidebar)}
              style={scrollPosition > 0 ? { color: "#235D71", zIndex: 40 } : {}}
            />
          </NavIcon>

          <div className="flex mt-3 ml-20">
            <ButtonDropDown />
          </div>

          <img
            src={logoOnly}
            alt="National Id logo"
            className="absolute h-16 left-3 top-4 "
            onClick={() => navigate("/")}
          ></img>
        </Nav>

        <SidebarNav sidebar={sidebar} >
          <SidebarWrap>
            <NavIcon to="#">
             

              <div className="flex items-center w-full mt-5 px-7">
                <AiIcons.AiOutlineClose
                  onClick={showSidebar}
                  className="flex w-[10%]"
                />

                {/* <img src={logo} alt="National Id logo" className="absolute h-10 ml-10 w-23 md:object-scale-down md:h-18"></img> */}
                <label class="relative text-gray-400 mx-4  block w-[90%]">
                  <SearchIcon className="absolute w-8 h-8 transform -translate-y-1/2 pointer-events-none top-1/2 left-2" />

                  <input
                    className="block w-full py-3 text-xs text-gray-500 placeholder-gray-400 bg-white border border-gray-900 rounded-lg appearance-none w- pl-14"
                    placeholder="Search .."
                  />
                </label>
              </div>
            </NavIcon>
            {SidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;
