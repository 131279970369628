import React, { useState, useEffect } from "react";
import { Worker } from "@react-pdf-viewer/core";
import { useDispatch } from "react-redux";
import {
  getAboutList,
  getCareerList,
  getNewsList,
  getFaqList,
  getTestList,
  getResourcesList,
  getEventsList,
  getHistoryList,
  getTopNewsList,
  getGalleryList,
  getPartnersList,
  getEnrollmentList,
  getStrategiesList,
  getLocations,
  getPrinciplesList,
  getHeroHome

} from "../src/redux/actions";
import LoadingSpinner from "./components/loadingSpinner/loadingSpinner";
import RoutesComponent from "./Routes";
import { useTranslation } from "react-i18next";


function App() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { i18n } = useTranslation();

  // useEffect(() => {
  //   // const timer = setTimeout(() => {
  //   //   setIsLoading(false);
  //   // }, 10000);

  //   // Group the dispatch calls based on their functionality
  //   const dataDispatches = [
  //     dispatch(getLocations())
  //   ];


  //   // Execute the dispatch calls in parallel
  //   Promise.all(dataDispatches)
  //     .then(() => {
  //       setIsLoading(false);
  //       // Do something after all the dispatch calls have completed
  //     })
  //     .catch((error) => {
  //       // Handle any errors that occur during the dispatch calls
  //     });


  // }, [dispatch]);
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <div className={`${i18n.language === "amh" ? "font-noto" : "font-barlow"
        }`}>

        <RoutesComponent />

      </div>
    </Worker>
  );
}

export default App;
