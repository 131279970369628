import React, { useState } from "react";
import OTPInput from "react-otp-input";
import CodeInput from "react-code-input";

function OTP({ onValidate }) {
  const [otp, setOTP] = useState("");

  function handleChange(value) {
    setOTP(value);
  }

  function handleComplete(value) {
    onValidate(value);
  }

  return (
    <div className="flex flex-row justify-center items-center">
      {/* Using react-otp-input */}
      <OTPInput
        value={otp}
        onChange={handleChange}
        numInputs={6}
        separator={<span>&nbsp;</span>}
        inputStyle={{
          width: "2.5rem",
          height: "2.5rem",
          marginRight: "0.5rem",
          borderRadius: "0.5rem",
          border: "1px solid #a0aec0",
        }}
        focusStyle={{
          border: "2px solid #3182ce",
        }}
      />

      {/* Using react-code-input */}
      <CodeInput
        type="number"
        fields={6}
        value={otp}
        onChange={handleChange}
        onComplete={handleComplete}
        inputStyle={{
          width: "2.5rem",
          height: "2.5rem",
          marginRight: "0.5rem",
          borderRadius: "0.5rem",
          border: "1px solid #a0aec0",
          fontSize: "1.5rem",
          fontWeight: "bold",
          textAlign: "center",
        }}
        inputStyleInvalid={{
          border: "1px solid #e53e3e",
        }}
        containerStyle={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    </div>
  );
}

export default OTP;