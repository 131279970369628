export const tableConfig = [
    {
        "document": "Official Documents- Financing Agreement for Credit 7463 - ET and Grant E272 - ET.pdf(English)",
        "date": "December 21, 2023",
        "document_type": "Financing Agreement",
        "document_code": "ABC121",
        "document_link": "documents1.worldbank.org/curated/en/099122123090519104/pdf/P1790401f9447f0c8185031717ad18a7a00.pdf"
    },
    {
        "document": "Official Documents- Disbursement and Financial Information Letter for Credit 7463-ET and Grant E272-ET.pdf (English)",
        "date": "December 20, 2023",
        "document_type": "Disbursement Letter",
        "document_link": "documents1.worldbank.org/curated/en/099122123091039377/pdf/P179040175f3a60a418d711c352c011157f.pdf"

    },
    {
        "document": "Environmental and Social Commitment Plan (ESCP) Ethiopia Digital ID for Inclusion and Services Project (P179040) (English)",
        "date": "November 21, 2023",
        "document_type": "Environmental and Social Commitment Plan",
        "document_code": "ABC123",
        "document_link": "documents1.worldbank.org/curated/en/099112123121033792/pdf/P1790400ee01e40d0b5a5039cb2a4cd39e.pdf"

    },
    {
        "document": "Ethiopia - Digital ID for Inclusion and Services Project (English)",
        "date": "November 20, 2023",
        "document_type": "Project Appraisal Document",
        "document_code": "PAD00159",
        "document_link": "documents1.worldbank.org/curated/en/099112223132535848/pdf/BOSIB0efb09b920d90858a0135df22da7d1.pdf"

    },
    {
        "document": "Labor Management Procedures Ethiopia Digital ID for Inclusion and Services Project (P179040) (English)",
        "date": "November 16, 2023",
        "document_type": "Procedure and Checklist",
        "document_code": "ABC125",
        "document_link": "documents1.worldbank.org/curated/en/099111623071624427/pdf/P1790400aaa40107f09ee50a4d71389c8a3.pdf"

    },
    {
        "document": "Appraisal Project Information Document (PID) (English)",
        "date": "October 26, 2023",
        "document_type": "Project Information Document",
        "document_code": "",
        "document_link": "documents1.worldbank.org/curated/en/099102623014028935/pdf/P1790400ca6a780f0b5310ea7369de19c5.pdf"

    },
    {
        "document": "Environmental and Social Commitment Plan (ESCP) - Ethiopia Digital ID for Inclusion and Services - P179040 (English)",
        "date": "October 25, 2023",
        "document_type": "Environmental and Social Commitment Plan",
        "document_code": "",
        "document_link": "documents1.worldbank.org/curated/en/099102523122014530/pdf/P17904005e72c00640a89109dd3b3ef3952.pdf"

    },
    {
        "document": "Stakeholder Engagement Plan (SEP) - Ethiopia Digital ID for Inclusion and Services - P179040 (English)",
        "date": "October 25, 2023",
        "document_type": "Stakeholder Engagement Plan",
        "document_code": "",
        "document_link": "documents1.worldbank.org/curated/en/099102523122041137/pdf/P17904007927fb0b60add90b04e13c46a1d.pdf"

    },
    {
        "document": "Appraisal Environmental and Social Review Summary (ESRS) - Ethiopia Digital ID for Inclusion and Services - P179040 (English)",
        "date": "October 25, 2023",
        "document_type": "Environmental and Social Review Summary",
        "document_code": "ESRSA03049",
        "document_link": "documents1.worldbank.org/curated/en/099102523122010001/pdf/P1790400f12bd00ec0abca07db7f4723cda.pdf"

    },
    {
        "document": "Concept Project Information Document (PID) - Ethiopia Digital ID for Inclusion and Services - P179040 (English)",
        "date": "May 4, 2023",
        "document_type": "Project Information Document",
        "document_code": "PIDC34522",
        "document_link": "documents1.worldbank.org/curated/en/099050423043542218/pdf/P179040047aa80090863804fc00eaf3fd7.pdf"

    },
    {
        "document": "Concept Environmental and Social Review Summary (ESRS) - Ethiopia Digital ID for Inclusion and Services - P179040 (English)",
        "date": "May 3, 2023",
        "document_type": "Environmental and Social Review Summary",
        "document_code": "ESRSC03519",
        "document_link": "documents1.worldbank.org/curated/en/099050323145510253/pdf/P1790400e863f9088099f703b42cd1a8ae5.pdf"

    },
];
