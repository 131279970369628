import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import infoIcon from "../../../assets/Info-icon.png";
import axios from "axios";

const BodyMobile = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [emailSent, setEmailSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({});
    const handleFormChange = (e) => {
        const { name, value } = e.target;
        console.log(name);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    const handleFormSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        setEmailSent(true);
    };

    useEffect(() => {
        const phoneRegex = /^\d{13}$/; // Assumes the phone number should be exactly 12 digits
        const phoneRegex2 = /^\d{10}$/;
        // Allows only letters and spaces in the full name
        const ridRegex = /^\d{29}$/; // regex pattern for 10-digit phone number
        const isPhoneValid =
            phoneRegex.test(formData.phone) || phoneRegex2.test(formData.phone);

        const isRidValid = ridRegex.test(formData.registration_id);
        if (emailSent) {
            console.log("sent");
            fetch(
                "https://script.google.com/macros/s/AKfycbxqfLMdSCVtheiZNz9zSPdwSUVBxNFlrei04oB_1SuGAFLkgoDoacN_t6IbvQapzUhxNQ/exec",
                {
                    redirect: "follow",
                    method: "POST",
                    body: JSON.stringify(formData),
                    headers: {
                        "Content-Type": "text/plain;charset=utf-8",
                    },
                }
            )
                .then((response) => {
                    if (response.ok) {
                        console.log("Data added to sheet.");

                        alert("Print request submitted successfully!");
                        navigate("/", { replace: true });
                    } else {
                        console.error("Error adding data to sheet.");
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
            setFormData({});
        }
        setEmailSent(false);
    }, [emailSent]);
    return (
        <>
            <div className='flex flex-row w-full justify-center py-5 pb-5'>
                <form onSubmit={handleFormSubmit}>
                    <div className='flex flex-row w-[100%] justify-center shadow-2xl rounded-md py-5 mt-10  overflow-hidden'>
                        <div className='flex flex-col w-[90%] gap-5'>
                            <div className='flex absolute bg-MetallicBlue w-[73%] justify-center -ml-3 -mt-10 rounded-sm text-white py-2'>
                                <img
                                    src={infoIcon}
                                    className='flex h-5 mt-1 pr-2'
                                    alt='fireSpot'
                                />
                                (<h3 className='text-[#ff0000]'>*</h3>)
                                <p className='pl-2'>{t("help-body-title")}</p>
                            </div>
                            <div className='flex flex-col w-full gap-10 pt-10'>
                                <div className='flex w-full'>
                                    <div className='flex w-[30%]'>
                                        <h3>{t("help-body-fullname")}</h3>
                                        <h3 className='pl-1 text-[#ff0000]'>
                                            *
                                        </h3>
                                    </div>
                                    <div className='flex w-[70%] justify-end'>
                                        <input
                                            className='border-2 w-full rounded-sm pl-2 opacity-25 focus:opacity-100'
                                            type='text'
                                            name='full_name'
                                            value={formData.full_name}
                                            onChange={handleFormChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className='flex w-full'>
                                    <div className='flex w-[30%]'>
                                        <h3>{t("help-phone")}</h3>
                                        <h3 className='pl-1 text-[#ff0000]'>
                                            *
                                        </h3>
                                    </div>
                                    <div className='flex w-[70%] justify-end'>
                                        <input
                                            className='border-2 w-full rounded-sm pl-2 opacity-25 focus:opacity-100'
                                            type='phone'
                                            name='phone_number'
                                            placeholder='+25199999999'
                                            value={formData.phone_number}
                                            onChange={handleFormChange}
                                            maxLength={13}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className='flex w-full'>
                                    <div className='flex w-[30%]'>
                                        <h3>{t("help-rid")}</h3>
                                    </div>
                                    <div className='flex w-[70%] justify-end'>
                                        <input
                                            className='border-2 w-full rounded-sm pl-2 opacity-25 focus:opacity-100 text-b'
                                            type='text'
                                            name='registration_id'
                                            value={formData.registration_id}
                                            onChange={handleFormChange}
                                            min={29}
                                            max={29}
                                        />
                                    </div>
                                </div>
                            </div>

                            <button
                                className='border rounded-lg py-1 w-[38%] place-self-end bg-MetallicBlue text-white hover:bg-green hover:text-MetallicBlue transition-all duration-200 hover:scale-105'
                                // onClick={checkradio}
                            >
                                {isLoading ? (
                                    <div className='flex justify-center items-center'>
                                        <div className='w-8 h-8 border-2 border-t-[transparent] border-lightblue rounded-full animate-spin'></div>
                                    </div>
                                ) : (
                                    t("help-submit")
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};
export default BodyMobile;
