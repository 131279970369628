import Body from "./components/body/body";
import HeroLandingPage from "./components/hero/heroLandingPage";
import SmallFooter from "../../components/footer/smallFooter";

const EthioPostLocations = () => {
    return (
        <div>
            <HeroLandingPage />
            <Body />
            <SmallFooter />
        </div>
    );
};

export default EthioPostLocations;
