import React from "react";


const Hero = () => {

  return (
    <div class="bg-qrscanner-hero bg-center bg-cover">
      <div className="px-10 pb-80">
      </div>
    </div>
  );
};

export default Hero;
