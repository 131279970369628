import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import OTP from "../otp/otp";
import UserProfile from "./userProfile";
import Demographic from "./demographic";
import Cards from "./cards";
import TopBar from "../../../../components/navbar/navbar";
import Sidebar from "../../../../components/sidebar/sidebar";
import axios from "axios";
import smsIcon from "../../../../assets/sms icon.svg";
import Barcode from "react-barcode";
import Download from "./download";
import React, { useState, useRef, useEffect } from "react";
import OtpInput from "react-otp-input";

const Body = () => {
    const { t } = useTranslation();

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    const [timerReached, setTimerReached] = useState(false);
    const [countDown, setCountDown] = useState(30);
    useEffect(() => {
        if (countDown === 0) setTimerReached(true);
        if (countDown === 0) return;
        const timer = setTimeout(() => {
            setCountDown((prev) => prev - 1);
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [countDown]);
    console.log(countDown);
    const baseUrl = "https://mobile-mini.fayda.et";
    const search = useLocation().search;
    //  const [faydaNumber, setFaydaNumber] = useState("");
    // const [otp, setOTP] = useState("");
    const [otp, setOtp] = useState("");
    const [fin, setFin] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [accessToken, setAccessToken] = useState("");
    const [refreshToken, setRefreshToken] = useState("");
    const [faydaNumber, setFaydaNumber] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [success, setSuccess] = useState(false);
    const [userData, setUserData] = useState({});
    const [otpValidated, setOtpValidated] = useState(false);
    const access_token = new URLSearchParams(search).get("tb");
    const decoded_access_token = decodeURIComponent(access_token);
    // const refresh_token = new URLSearchParams(search).get("refreshtoken");
    // const faydaNumber = new URLSearchParams(search).get("uin");
    const [uinResend, setUinResend] = useState(null);
    const generateTransactionID = () => {
        const timestamp = Date.now().toString(); // get current timestamp in string format
        const random = Math.random().toString(36).substring(2, 8); // generate a random string
        const transactionID = `${timestamp}-${random}`; // combine the timestamp and random string
        return transactionID;
    };

    if ("OTPCredential" in window) {
        const ac = new AbortController();

        navigator.credentials
            .get({
                otp: { transport: ["sms"] },
                signal: ac.signal,
            })
            .then((otp) => {
                setOtp({ otp: otp.code });
                ac.abort();
            })
            .catch((err) => {
                ac.abort();
                console.log(err);
            });
    }
    async function sendOtp(uin, access_token, refresh_token) {
        try {
            setIsLoading(true);
            // headers: {
            //   "Content-Type": "application/json",
            //   Authorization: "Bearer " + accesstoken,
            //   "Refresh-Token": refreshtoken,
            // },
            //  body: JSON.stringify(requestBody),

            let requestBody = {
                uin: uin,
            };
            let headers = {
                "Content-Type": "application/json",
                Authorization: "Bearer " + access_token,
                "Refresh-Token": refresh_token,
            };
            axios
                .post(`${baseUrl}/sendOtp`, requestBody, {
                    headers,
                })
                .then((response) => {
                    if (response.status !== 200) {
                        setIsLoading(false);
                        throw new Error("Failed to send OTP");
                    } else if (response.status === 500) {
                        alert(
                            "Session Expired! Please reload again from telebirr"
                        );
                    } else {
                        // Handle successful response
                        console.log("OTP sent successfully");
                        setTransactionId(response.data.transactionId);
                        setFin(uin);
                        setSuccess(response.data.status);
                        setOtpSent(true);
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                    alert("Session Expired! Please reload again from telebirr");
                });
            // const data = await response.data;
            // console.log(data);
        } catch (error) {
            console.error(error);
        }
    }

    async function validateOtp(otp) {
        try {
            // const response = await fetch(
            //   `${baseUrl}/validateOtp?otp=${otp}&transaction_id=${transactionId}`,
            //   {
            //     method: "GET",
            //     headers: {
            //       "Content-Type": "application/json",
            //       Authorization: "Bearer " + accessToken,
            //       "Refresh-Token": refreshToken,
            //     },
            //   }
            // );
            setIsLoading(true);
            let requestBody = {
                otp: otp,
                transaction_id: transactionId,
                uin: uinResend,
            };
            let headers = {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
                "Refresh-Token": refreshToken,
            };
            console.log(requestBody);
            try {
                const response = await axios.post(
                    `${baseUrl}/validateOtp`,
                    requestBody,
                    {
                        headers,
                        responseType: "blob",
                    }
                );

                if (response.status !== 200) {
                    throw new Error("Failed to validate OTP");
                } else if (response.status === 503) {
                    alert("OTP Invalid! Please try again");
                    setIsLoading(false);
                    return; // Stop execution if OTP is invalid
                }

                console.log("OTP validation successful");
                setOtpValidated(true);
                setIsLoading(false);

                // If the API response contains the PDF bytes
                if (response.data) {
                    const blob = new Blob([response.data], {
                        type: "application/pdf",
                    });

                    // Create a download link
                    const url = URL.createObjectURL(blob);

                    // Programmatically trigger the download
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = "Fayda_Letter.pdf";
                    link.click();

                    // Clean up the URL object
                    URL.revokeObjectURL(url);
                }
            } catch (error) {
                console.error("Error:", error);
                alert("OTP Invalid! Please try again");
                setIsLoading(false);
            }

            // const data = await response.data;
        } catch (error) {
            console.error(error);
        }
    }

    function handleFaydaNumberChange(event) {
        setFaydaNumber(event.target.value);
    }

    function handleOtpValidate(event) {
        event.preventDefault();
        validateOtp(otp);
    }

    const handleOTPChange = (event, index) => {
        if (isNaN(event.target.value)) return; // Prevent non-numeric input

        const newOtp = [...otp];
        newOtp[index] = event.target.value.slice(0, 1); // Ensure single digit
        setOtp(newOtp);

        // Automatically move to next input if current input is filled and it's not the last input
        if (event.target.value && index < otp.length - 1) {
            inputRefs.current[index + 1].current.focus();
        }
    };

    const handleSendOtp = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        console.log(decoded_access_token);
        const [encryptedAccessToken, encryptedSymmetricKey, encryptedIv] =
            decoded_access_token.split(".");
        let requestBody = {
            encrypted_data: encryptedAccessToken,
            encrypted_symmetric_key: encryptedSymmetricKey,
            iv: encryptedIv,
        };
        console.log(requestBody);
        try {
            const response = await axios.post(
                `${baseUrl}/decrypt`,
                requestBody,
                {
                    "Content-Type": "application/json",
                }
            );
            const parts = response.data.decrypted_data.split(".");
            setFaydaNumber(parts[0]);
            setAccessToken(parts[1] + "." + parts[2] + "." + parts[3]);
            setRefreshToken(parts[4] + "." + parts[5] + "." + parts[6]);
            sendOtp(
                parts[0],
                parts[1] + "." + parts[2] + "." + parts[3],
                parts[4] + "." + parts[5] + "." + parts[6]
            );
            setUinResend(parts[0]);
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
        setCountDown(30);
        setTimerReached(false);
        // axios
        //     .post(`${baseUrl}/decrypt`, requestBody, {
        //         "Content-Type": "application/json",
        //     })
        //     .then((data) => {
        //         const parts = data.data.decrypted_data.split(".");
        //         setFaydaNumber(parts[0]);
        //         setAccessToken(parts[1] + "." + parts[2] + "." + parts[3]);
        //         setRefreshToken(parts[4] + "." + parts[5] + "." + parts[6]);
        //         // console.log("otp sending");

        //         sendOtp(
        //             parts[0],
        //             parts[1] + "." + parts[2] + "." + parts[3],
        //             parts[4] + "." + parts[5] + "." + parts[6]
        //         );
        //         setUinResend(parts[0]);
        //         // if (!otpSent) {

        //         // }
        //     })
        //     .catch((error) => {
        //         console.error(error);
        //         setIsLoading(false)
        //     })
        //     .finally(() => {
        //         setIsLoading(false);
        //     });
    };

    const handleClear = () => {
        setAccessToken("");
        setRefreshToken("");
        setFaydaNumber("");
    };

    useEffect(() => {
        async function fetchUserData() {
            console.log("get user", faydaNumber);
            try {
                //   `${baseUrl}/get_user_data?uin=${faydaNumber}`,
                //   {
                //     method: "GET",
                //     headers: {
                //       "Content-Type": "application/json",
                //       Authorization: "Bearer " + accessToken,
                //       "Refresh-Token": refreshToken,
                //     },
                //   }
                // );
                let requestBody = {
                    uin: faydaNumber,
                };
                let headers = {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                    "Refresh-Token": refreshToken,
                };
                axios
                    .get(`${baseUrl}/get_user_data?uin=${faydaNumber}`, {
                        headers,
                    })
                    .then((data) => {
                        if (data.status !== 200) {
                            throw new Error("Failed to fetch user data");
                        } else if (data.status === 401) {
                            alert(
                                "Unauthorized! Please reload again from telebirr"
                            );
                        } else {
                            // Handle successful response
                            console.log("User data fetched successfully");
                            console.log(data.data);
                            setUserData(data.data);
                        }
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                        alert(
                            "Unauthorized! Please reload again from telebirr"
                        );
                    });

                // TODO: Do something with the user data
            } catch (error) {}
        }
        if (otpValidated) {
            // fetchUserData();
        }
    }, [otpValidated]);

    if (!otpValidated) {
        return (
            <>
                {isDesktopOrLaptop ? (
                    <TopBar viewState={"citizen"} />
                ) : (
                    <Sidebar />
                )}
                {isDesktopOrLaptop ? (
                    <TopBar viewState={"citizen"} />
                ) : (
                    <Sidebar />
                )}
                {isDesktopOrLaptop ? (
                    <div class='bg-media-hero bg-center bg-cover '>
                        <div className='px-10 md:px-0 bg-hero-gradient'>
                            <div class='grid grid-cols-1 md:gap-16 md:px-6 py-16'></div>
                            <div className='flex justify-start pt-36 md:pt-24 md:p-0 md:pr-40 text-7xl  md:pl-28'>
                                <h1 className='pr-2 mb-0 font-bold text-white '>
                                    {t("getid-fayda")}
                                </h1>{" "}
                                <h1 className='pr-2 mb-0 font-bold text-green '>
                                    {t("getid-id")}
                                </h1>
                            </div>

                            <div className='pb-10 mt-4 text-white text-2xl md:pl-28  '>
                                {/* <p className="md:mr-[40%]"> {t("help-hero-description")}</p> */}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div class='bg-center bg-cover '>
                        <div className='bg-hero-gradient px-8'>
                            <div class='grid grid-cols-1 py-8'></div>
                            <div className='flex justify-start gap-3 pt-10 text-4xl  '>
                                <img
                                    src={smsIcon}
                                    alt='vision Icon'
                                    className='w-28 md:w-40 p-0.5'
                                ></img>
                                {/* <h1 className="font-bold text-green ">
                {t("help-hero-titlemobile3")}
              </h1> */}
                                <div className='flex flex-col pb-10 mt-4 text-white text-lg  leading-6 tracking-tighter text-justify'>
                                    <h1 className='text-5xl font-extralight'>
                                        Welcome
                                    </h1>
                                    <p className=''>
                                        {" "}
                                        {t("getid-redirect-questions")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className='flex justify-center  pt-20 px-5   items-center h-[80%]'>
                    <div class='w-full max-w-sm  rounded-lg  '>
                        <form
                            onSubmit={
                                !otpSent ? handleSendOtp : handleOtpValidate
                            }
                            className='shadow-lg pb-10 px-5 py-5 rounded-lg'
                        >
                            <div className='space-y-6'>
                                {/* <h5 class="text-xl font-medium text-darkblue dark:text-darkblue">SMS</h5> */}

                                <>
                                    <div>
                                        <label
                                            for='tel'
                                            class='block mb-2 text-center text-3xl font-semibold text-darkblue dark:text-darkblue'
                                        >
                                            {t("getid-otp-verification")}
                                        </label>
                                        {otpSent ? (
                                            // <>
                                            //     <input
                                            //         type='tel'
                                            //         name='tel'
                                            //         id='tel'
                                            //         class={`${
                                            //             otpSent
                                            //                 ? "bg-white"
                                            //                 : "bg-grey"
                                            //         } border text-sm rounded-md focus:darkblue focus:border-darkblue block w-full p-2.5  dark:text-darkblue`}
                                            //         placeholder='OTP'
                                            //         required
                                            //         value={otp}
                                            //         onChange={handleOTPChange}
                                            //         readOnly={!otpSent}
                                            //     />
                                            //     <p className='text-sm text-center '>
                                            //         Didn't get the OTP?{" "}
                                            //         <span
                                            //             onClick={handleSendOtp}
                                            //             className='text-green'
                                            //         >
                                            //             Resend
                                            //         </span>
                                            //     </p>
                                            // </>
                                            <div className='flex flex-col items-center'>
                                                <div className='flex justify-center space-x-2 mb-6'>
                                                    <OtpInput
                                                        value={otp}
                                                        onChange={setOtp}
                                                        numInputs={6}
                                                        shouldAutoFocus={true}
                                                        inputType='tel'
                                                        inputStyle={{
                                                            borderRadius:
                                                                "0.5rem",
                                                            width: "2.7rem",
                                                            height: "2.7rem",
                                                            textAlign: "center",
                                                            color: "black",
                                                            border: "solid black 2px",
                                                        }}
                                                        renderSeparator={
                                                            <span className='px-2'></span>
                                                        }
                                                        renderInput={(
                                                            props
                                                        ) => (
                                                            <input
                                                                className=''
                                                                {...props}
                                                            />
                                                        )}
                                                    />
                                                </div>

                                                <div className='text-sm text-center '>
                                                    {t("getid-didnt-get-otp")}{" "}
                                                    <button
                                                        onClick={handleSendOtp}
                                                        className={`font-semibold ml-1 ${
                                                            timerReached
                                                                ? "text-green"
                                                                : "text-[lightgray]"
                                                        }`}
                                                        disabled={!timerReached}
                                                    >
                                                        {t("getid-resend")}
                                                    </button>
                                                </div>
                                                <p>
                                                    {t("getid-resend-in")}{" "}
                                                    <span>{countDown}</span>{" "}
                                                    <span>
                                                        {t(
                                                            "getid-resend-in-span"
                                                        )}
                                                    </span>
                                                </p>
                                            </div>
                                        ) : (
                                            <>
                                                <p className='text-sm text-center '>
                                                    {t("getid-click-btn-below")}
                                                    {/* <span onClick={handleSendOtp} className="text-green">
                            Resend
                          </span> */}
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </>

                                <div className='flex justify-center'>
                                    {otpSent ? (
                                        <button
                                            type='submit'
                                            className='w-[60%] min-w-fit rounded-full  text-white bg-darkblue focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                                        >
                                            {isLoading ? (
                                                <div className='flex justify-center items-center'>
                                                    <div className='w-8 h-8 border-2 border-t-[transparent] border-white rounded-full animate-spin'></div>
                                                </div>
                                            ) : (
                                                t("getId-verify")
                                            )}
                                        </button>
                                    ) : (
                                        <button
                                            type='submit'
                                            className='w-[80%] rounded-full  text-white bg-darkblue focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                                        >
                                            {isLoading ? (
                                                <div className='flex justify-center items-center'>
                                                    <div className='w-8 h-8 border-2 border-t-[transparent] border-white rounded-full animate-spin'></div>
                                                </div>
                                            ) : (
                                                t("getid-send-otp")
                                            )}
                                        </button>
                                    )}
                                </div>
                                {/* <div class="text-sm font-medium  text-MetallicBlue dark:lightblue">
                        Not registered? <a href="https://register.fayda.et" class="text-darkblue hover:underline dark:text-lightblue"> Get ID </a>
                    </div> */}
                            </div>
                        </form>
                        {/* </form> */}
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            {isDesktopOrLaptop ? <TopBar viewState={"citizen"} /> : <Sidebar />}
            {faydaNumber ? (
                isDesktopOrLaptop ? (
                    <>
                        <div className='relative bg-media-hero bg-center bg-cover'>
                            <div className='px-10 md:px-0 bg-hero-gradient'>
                                <div className='grid grid-cols-1 md:gap-16 md:px-6 py-16'></div>
                                <div className='flex justify-start pt-36 md:pt-24 md:p-0 md:pr-40 text-7xl  md:pl-28'>
                                    <h1 className='pr-2 mb-0 font-bold text-white '>
                                        {t("getid-fayda")}
                                    </h1>{" "}
                                    <h1 className='pr-2 mb-0 font-bold text-green '>
                                        {t("getid-id")}
                                    </h1>
                                </div>
                                <div className='pb-10 mt-4 text-white text-2xl md:pl-28  '>
                                    {/* <p className="md:mr-[40%]"> {t("help-hero-description")}</p> */}
                                </div>
                            </div>

                            <div className='absolute bottom-0 left-0 px-6 py-6'>
                                <UserProfile img={userData.data.photo} />
                            </div>
                        </div>
                        <Download
                            uin={faydaNumber}
                            accessToken={accessToken}
                            refreshToken={refreshToken}
                        />
                        {/* <Demographic userData={userData.data} />
            <Cards
              barcode={
                <Barcode value={userData.data.fcn} width={2} height={100} />
              }
              userData={userData.data}
              front={userData.data.fronts}
              back={userData.data.backs}
            /> */}
                    </>
                ) : (
                    <>
                        <>
                            <div className='bg-media-hero bg-center bg-cover relative mb-28'>
                                <div className='bg-hero-gradient px-5'>
                                    <div className='grid grid-cols-1 py-20  '></div>
                                </div>
                                {/* 
                  <div className="absolute top-0 left-0 px-6 mt-24 z-10">
                    <UserProfile
                      img={userData.data.photo}
                      name={userData.data.fullName_eng}
                      fcn={userData.data.fcn}
                    />
                  </div> */}
                            </div>
                            <Download
                                uin={faydaNumber}
                                accessToken={accessToken}
                                refreshToken={refreshToken}
                            />
                            {/* <Demographic userData={userData.data} />
                <Cards
                  otp={otp}
                  userData={userData.data}
                  front={userData.data.fronts}
                  back={userData.data.backs}
                  accessToken={accessToken}
                  refreshToken={refreshToken}
                /> */}
                        </>
                    </>
                )
            ) : (
                <div className='flex justify-center items-center'>
                    <div className='w-8 h-8 border-2 border-t-[transparent] border-lightblue rounded-full animate-spin'></div>
                </div>
            )}
        </>
    );
};
export default Body;
