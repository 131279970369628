import React from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
const Hero = ({ faqs }) => {
    const { t, i18n } = useTranslation();
    return (
        <div class='bg-media-hero bg-center bg-cover '>
            <div className='bg-hero-gradient'>
                {/* <div class="grid grid-cols-1 gap-16 px-6 py-0">
    </div> */}
                <div className='flex justify-center md:justify-start items-center pb-[5%] md:pr-40 md:text-8xl  md:px-28 px-10 text-3xl pt-[5%]'>
                    <div className='w-full '>
                        <h1 className='flex w-full pr-2 mt-32 mb-0 font-bold text-white'>
                            {/* {
              
                i18next.language === "en"
                  ? faqs.question
                  : i18next.language === "amh"
                  ? faqs.question_amh
                  : ""
              } */}
                            {/* <p
                                className=' text-white text-6xl '
                                dangerouslySetInnerHTML={{
                                    __html:
                                        i18next.language === "en"
                                            ? faqs.question
                                            : i18next.language === "amh"
                                            ? faqs.question_amh
                                            : "",
                                }}
                            /> */}
                            <p>
                                {i18n.language === "en"
                                    ? parse(faqs.question)
                                    : parse(faqs.question_amh)}
                            </p>
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
