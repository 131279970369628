import { MdOutlineDownload } from "react-icons/md";
import authentication from "../../../../assets/authentication-29.png";
import authenticationWhite from "../../../../assets/authentication-white.png";

import ReactPlayer from "react-player/lazy";
import background from "../../../../assets/10-01.jpg";
import { useTranslation } from "react-i18next";
import { useState } from "react";
const Body = () => {
    const { t } = useTranslation();
    const [videoPlayingIndex, setVideoPlayingIndex] = useState(null);
    const authenticationConfig = [
        {
            title: "auth-title-1",
            descritpion: "auth-description-1",
            pdfLink:
                "https://drive.google.com/file/d/1CE8nBTB3LGD3VzWitAy7oY4FW_NOiXCl/view?usp=sharing",
            small: "auth-description-1-ie",
            VideoLink: "https://www.youtube.com/watch?v=zyIUUAhKUVo",
        },
        {
            title: "auth-title-2",
            descritpion: "auth-description-2",
            pdfLink:
                "https://drive.google.com/file/d/1i_8MWLixqvi9qARSkE4Nv0CBnPwUyvBc/view?usp=sharing",
            small: "auth-description-2-ie",
            VideoLink: "https://www.youtube.com/watch?v=LnxQKFxXEYQ",
        },
        {
            title: "auth-title-3",
            descritpion: "auth-description-3",
            pdfLink:
                "https://docs.google.com/presentation/d/1GhzeTdpDHNBi6ckxx98F_3b8fr5vq3nTdIyZdVL4X2o/edit#slide=id.g2c1f5f55582_1_234",
            small: "auth-description-3-ie",
        },
    ];

    return (
        <div className='flex flex-col justify-center items-center '>
            {authenticationConfig.map((authItem, index) => (
                <div
                    key={authItem.title}
                    className='w-full'
                    style={{
                        backgroundImage:
                            index % 2 !== 0 ? `url(${background})` : "",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <div
                        className={`${
                            index % 2 !== 0 && " bg-about-pilot"
                        } w-full  py-8 md:py-16 flex justify-center`}
                    >
                        <div
                            className={`flex flex-col lg:flex-row w-full items-center justify-center gap-24 px-5 md:px-10 lg:px-24 2xl:px-36 max-w-[1800px] py-10 ${
                                index % 2 !== 0
                                    ? "lg:flex-row-reverse text-white"
                                    : ""
                            }`}
                        >
                            <div
                                className={`flex w-full gap-5 ${
                                    index % 2 !== 0 ? "flex-row-reverse" : ""
                                } `}
                            >
                                <div
                                    className={`md:flex hidden items-center justify-center w-full h-full max-w-[40px] max-h-[40px] md:max-w-[50px] md:max-h-[50px] overflow-clip rounded-full ring-2 ${
                                        index % 2 !== 0
                                            ? "ring-white md:place-self-start"
                                            : "ring-MetallicBlue"
                                    }`}
                                >
                                    <img
                                        src={
                                            index % 2 === 0
                                                ? authentication
                                                : authenticationWhite
                                        }
                                        className='p-1 object-cover'
                                    />
                                </div>
                                <div
                                    className={`space-y-5 flex flex-col w-full ${
                                        index % 2 !== 0
                                            ? "md:justify-end md:items-end md:text-right"
                                            : ""
                                    }`}
                                >
                                    <div className='flex items-center gap-3 '>
                                        <div
                                            className={`flex md:hidden items-center justify-center max-w-[40px] max-h-[40px] md:max-w-[50px] md:max-h-[50px] overflow-clip rounded-full ring-2 ${
                                                index % 2 !== 0
                                                    ? "ring-white place-self-end md:place-self-start"
                                                    : "ring-MetallicBlue"
                                            }`}
                                        >
                                            <img
                                                src={
                                                    index % 2 === 0
                                                        ? authentication
                                                        : authenticationWhite
                                                }
                                                className='p-1 object-cover'
                                            />
                                        </div>
                                        <p
                                            className={`text-3xl font-semibold ${
                                                index % 2 === 0
                                                    ? "text-MetallicBlue"
                                                    : "text-white"
                                            }`}
                                        >
                                            {t(authItem.title)}
                                        </p>
                                    </div>
                                    <p
                                        className={`${
                                            index % 2 == 0
                                                ? " text-right"
                                                : "text-left"
                                        }text-lg md:text-2xl`}
                                    >
                                        {t(authItem.descritpion)}
                                    </p>
                                    {index !== 2 ? (
                                        <p className='text-lg md:text-2xl'>
                                            {t(authItem.small)}
                                        </p>
                                    ) : (
                                        <></>
                                    )}
                                    {index !== 2 && (
                                        <a
                                            href={authItem.pdfLink}
                                            download
                                            className='flex items-center justify-center  gap-2 w-fit px-4 text-white py-1 hover:bg-[#083247] bg-[#47F4C7] duration-300 hover:scale-110 text-xl rounded-md'
                                        >
                                            {t("auth-download")}
                                            <MdOutlineDownload />
                                        </a>
                                    )}
                                </div>{" "}
                            </div>
                            <div className='w-full min-w-fit lg:max-w-[37%]'>
                                <div
                                    className={`
                                    mx-auto
                                    rounded-lg
                                    overflow-clip 
                                    ${
                                        index !== 2
                                            ? "border-4 min-h-[180px] aspect-video border-[#e0e0e0]"
                                            : "w-fit h-fit drop-shadow-xl shadow-md shadow-MetallicBlue"
                                    }
                                    `}
                                >
                                    {index !== 2 ? (
                                        <ReactPlayer
                                            url={authItem.VideoLink}
                                            width='100%'
                                            height='100%'
                                            controls={true}
                                            onPlay={() =>
                                                setVideoPlayingIndex(index)
                                            }
                                            playing={
                                                index !== null &&
                                                index === videoPlayingIndex
                                                    ? true
                                                    : false
                                            }
                                        />
                                    ) : (
                                        // <div className='py-2 px-28 xl:px-44 text-center rounded-md border-MetallicBlue border-2 text-xl'>
                                        //     {t("auth-description-3-ie")}
                                        // </div>
                                        <a href={authItem.pdfLink} download>
                                            <p className='flex items-center justify-center  gap-2 w-fit px-4 text-white py-1  hover:scale-110 hover:bg-[#083247] bg-[#47F4C7] duration-300 text-xl rounded-md'>
                                                {t("auth-download")}
                                                <MdOutlineDownload />
                                            </p>
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}{" "}
        </div>
    );
};

export default Body;
