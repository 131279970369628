import { GET_STRATEGIES_LIST } from "../actions/strategiesActions";

 const initialState = {
    strategiesImages: []
 }

 const strategiesReducer = (state = initialState, action) => {
   
    
    switch(action.type){
    
        case GET_STRATEGIES_LIST:  
        return{
            ...state,
            strategiesImages: action.payload
          };

          default: 
            return state;
    }
 };
 export default strategiesReducer;