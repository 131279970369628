import React from "react";
import faqbackground from "../../../../assets/faq_background.jpg";
import { useTranslation } from "react-i18next";

function HeroSection() {
    const { t } = useTranslation();
    return (
        <div className='bg-gradient-to-b from-gray-800 via-gray-700 to-gray-800'>
            <div className='relative'>
                <img
                    src={faqbackground}
                    alt='Background'
                    className='object-cover w-full h-screen'
                />
                <div className='absolute top-0 left-0 w-full h-full bg-hero-gradient'></div>
                <div className='absolute inset-0 flex flex-col items-center justify-center p-4 text-white sm:p-8 md:p-12 lg:p-20'>
                    <h1 className='text-4xl font-normal text-center sm:text-5xl md:text-6xl lg:text-7xl  '>
                        {t("faq-welcome")}
                        <br />
                        <span className='font-bold text-green'>
                            {t("faq-nid-online")}
                        </span>{" "}
                        {t("faq-helpdesk")}
                    </h1>
                    <div className='w-12 h-1 my-4 bg-green'></div>
                    <div className='mt-4 text-center'>
                        <h2 className='text-2xl font-bold sm:text-3xl md:text-4xl lg:text-5xl '>
                            {t("faq-title")}
                        </h2>
                        <p className='mt-4 text-lg sm:text-xl md:text-2xl lg:text-3xl '>
                            {t("faq-desc")}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeroSection;
