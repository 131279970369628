import { GET_CAREER_LIST } from "../actions/careerActions";

const initialState = {
  careerList: [],
};

const careerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAREER_LIST:
      return {
        ...state,
        careerList: action.payload,
      };
    default:
      return state;
  }
};
export default careerReducer;