import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/footer";
import HeroLandingPage from "../about/components/hero/heroLandingPage";
import Partners from "./components/partners/partners";
import History from "./components/history/history";
import Registrations from "./components/registrations/registrations";
import Ecosystem from "./components/Ecosystem/Ecosystem";
import { useDispatch, useSelector } from "react-redux";
import { getHistory, getPartners } from "../../redux/selectors";
import OverlayedPrinciple from "../about/components/history/OverlayedPrinciple";
import Vacancy from "./components/Vacancy/vacancy";
import { getPartnersList } from "../../redux/actions";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";

const history = {
    data: [
        {
            date: "2011 - Initiation ",
            event: " The concept was initiated under the National Intelligence and Security Service (NISS) in cooperation with the Information Network Security Agency (INSA) and other government bodies.",
            year: "2011",
            date_amh: "2011 - የጽንሰ-ሀሳብ መጀመር",
            event_amh:
                "ከብሔራዊ መረጃ እና ደህንነት አገልግሎት (NISS) ፣ ከኢንሳ እና ከሌሎች የመንግስት አካላት ጋር በመተባበር ጽንሰ-ሀሳብ ተጀመረ ።",
            year_amh: "2011",
        },
        {
            date: "2018 -  Pre-project Preparation",
            event: "The Prime Minister’s  Office re-initiated the program with the Minister of Peace and the Minister of Innovation and Technology, tasking them with carrying out the operation.",
            year: "2018",
            date_amh: "2018 - የፕሮጀክት ቅደመ ዝግጅት",
            event_amh:
                "የጠቅላይ ሚኒስትር ጽ/ቤት ከሰላም ሚኒስትር እና ከኢኖቬሽን እና ቴክኖሎጂ ሚኒስትር ጋር በመሆን ስራውን እንዲያካሂዱ በማድረግ ፕሮግራሙን እንደገና አስጀመረ። ",
            year_amh: "2018",
        },
        {
            date: "2019 - 2021 - Technical and Legal Platform Development",
            event: "This process was led by the Ministry of Peace (MOP), in cooperation with the Ministry of Innovation and Technology (MInT) as well as the Prime Minister's Office. ",
            year: "2019",
            date_amh: "2019 - 2021 - የቴክኒክ እና የህግ መድረክ መዘርጋት",
            event_amh:
                " ይህ ሂደት  በሰላም ሚኒስቴር (MOP) መሪነት ከኢኖቬሽን እና ቴክኖሎጂ ሚኒስቴር (MINT) እንዲሁም ከጠቅላይ ሚኒስትር ፅህፈት ቤት ጋር በመተባበር ተጀመረ ።",
            year_amh: "2019",
        },
        {
            date: "2021 - The National ID Program Office Established",
            event: "The National ID Program was re-structured under the PM Office in Sept 2021 to ensure a robust, reliable, and forward-looking digital identification platform for residents.",
            year: "2021 ",
            date_amh: "2021 - የብሔራዊ መታወቂያ ፕሮግራም ጽህፈት ቤት ተቋቋመ",
            event_amh:
                " የብሔራዊ መታወቂያ ፕሮግራም በመስከረም 2021 በጠቅላይ ሚኒስትር ጽህፈት ቤት እንደገና ተዋቅሮ ለነዋሪዎች ጠንካራ ፣ አስተማማኝ እና ወደፊት ቀጣይነት ያለው የዲጂታል መታወቂያ ስርዓት እየገነባ ይገኛል።",
            year_amh: "2021",
        },
    ],
};

const partners = {
    data: [
        {
            logo: "/files/TECH5_logo-01-300x101.png",
        },
        {
            logo: "/files/psnp.png",
        },
        {
            logo: "/files/transport-mini.jpg",
        },
        {
            logo: "/files/safaricom.png",
        },
        {
            logo: "/files/revenue-logo (1).jpg",
        },
        {
            logo: "/files/pssssa.jpg",
        },
        {
            logo: "/files/oromia-bank.png",
        },
        {
            logo: "/files/id4d.jpeg",
        },
        {
            logo: "/files/hibret.jpeg",
        },
        {
            logo: "/files/nbe.png",
        },
        {
            logo: "/files/ethswich.jpg",
        },
        {
            logo: "/files/mosip-transparent.png",
        },
        {
            logo: "/files/Industrial-Parks-Development-Corpotation-Logo-1-removebg-preview.png",
        },
        {
            logo: "/files/ECAlogo.png",
        },
        {
            logo: "/files/download-removebg-preview.png",
        },
        {
            logo: "/files/download.png",
        },
        {
            logo: "/files/document-authentication-logo (1).jpg",
        },
        {
            logo: "/files/dbe.jpeg",
        },
        {
            logo: "/files/commauthority.jpg",
        },
        {
            logo: "/files/coop-logo.png",
        },
    ],
};

function AboutPage() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const historyList = history;

    const [citizen, setCitizen] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        // const timer = setTimeout(() => {
        //   setIsLoading(false);
        // }, 10000);

        // Group the dispatch calls based on their functionality
        const dataDispatches = [dispatch(getPartnersList())];

        // Execute the dispatch calls in parallel
        Promise.all(dataDispatches)
            .then(() => {
                setIsLoading(false);
                // Do something after all the dispatch calls have completed
            })
            .catch((error) => {
                // Handle any errors that occur during the dispatch calls
            });

        // return () => {
        //   clearTimeout(timer);
        // };
    }, [dispatch]);

    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <HeroLandingPage citizen={citizen} />

                    <OverlayedPrinciple />
                    {/* <History list={historyList} /> */}

                    <Ecosystem />
                    <Registrations />
                    <Vacancy />
                    <Partners />
                    <Footer />
                </>
            )}
        </>
    );
}

export default AboutPage;
