import React from "react";
import { useTranslation } from "react-i18next";

const Hero = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className=''>
                <header class='bg-ContactUsHero bg-cover bg-center '>
                    <div class=' bg-top-bot-gradient'>
                        <div class='grid grid-cols-1 gap-16 px-6 py-20 '></div>
                        <div className='p-6  flex '>
                            <div className='flex flex-col mb-12'>
                                <h1 className='lg:px-24 py-2 text-7xl text-green  font-medium'>
                                    <span className='text-white px-2 mr-1 font-medium'>
                                        {t("contactus-hero-text1")}
                                    </span>
                                    {t("contactus-hero-text2")}
                                </h1>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        </>
    );
};

export default Hero;
