import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Carousel } from "react-responsive-carousel";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import { ImCancelCircle } from "react-icons/im";
import { Animate } from "../../../../hooks/animations";
import FaqCarousel from "./faqCarousel";
import { useSelector } from "react-redux";
import { getFaq } from "../../../../redux/selectors";
import FilterPagination from "./filterPagination";

function parseTextEditor(text) {
    const lines = text.split("\n");

    let result = [];

    for (let i = 0; i < lines.length; i++) {
        let line = lines[i].trim();

        if (line.startsWith("-")) {
            result.push({ type: "bullet", content: line.substring(1).trim() });
        } else {
            result.push({ type: "text", content: line });
        }
    }

    return result;
}

function TextEditor({ text }) {
    const lines = parseTextEditor(text);

    return (
        // <div>
        //   {lines.map((line, index) => {
        //     if (line.type === "bullet") {
        //       return <li key={index}>{line.content}</li>;
        //     } else {
        //       return <p key={index}>{line.content}</p>;
        //     }
        //   })}
        // </div>
        <div
            dangerouslySetInnerHTML={{
                __html: text,
                // i18next.language === "en"
                // ? faqs.answer
                // : i18next.language === "amh"
                // ? faqs.answer_amh
                // : ""
            }}
        />
    );
}

export default function Faq({ searchFaqParams }) {
    const oldfaq = useSelector(getFaq);
    const list = oldfaq.sort((a, b) => a.number - b.number);

    const [faqToShow, setFaqToShow] = useState(null);
    const { t } = useTranslation();
    const [showModal, setShowModal] = React.useState(false);
    const [answer, setAnswer] = useState("");

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);

        const currentUrl = window.location.href;

        if (currentUrl.substring(currentUrl.lastIndexOf("/") + 1) !== "faq") {
            const faqNumber =
                currentUrl
                    .substring(currentUrl.lastIndexOf("/") + 1)
                    .match(/\d+/)[0] - 1;

            setFaqToShow(parseInt(faqNumber));
            setShowModal(true);

            setTimeout(() => {
                const faqElement = document.getElementById("faq");
                if (faqElement) {
                    faqElement.scrollIntoView({ behavior: "smooth" });
                }
            }, 100);

            if (i18next.language === "en") {
                setAnswer(<TextEditor text={list[faqNumber].answer} />);
            } else if (i18next.language === "amh") {
                setAnswer(<TextEditor text={list[faqNumber].answer_amh} />);
            }
        } else {
        }
    }, []);
    return (
        <>
            {isDesktopOrLaptop ? (
                <div className='mt-12'>
                    <div className='font-sans antialiased bg-gray-900 bg-cover text-gray bg-faq '>
                        <div className='relative bg-MetallicBlue/80 '>
                            <div className={isDesktopOrLaptop ? "" : ""}>
                                <div
                                    id='faq'
                                    className={
                                        isDesktopOrLaptop
                                            ? " w-[100%] text-white  font-extralight text-2xl py-20"
                                            : " relative  text-white text-lg p-8"
                                    }
                                >
                                    <FilterPagination
                                        data={
                                            searchFaqParams
                                                ? list.filter(
                                                      (item) =>
                                                          item.question
                                                              .toLowerCase()
                                                              .includes(
                                                                  searchFaqParams.toLowerCase()
                                                              ) |
                                                          item.question_amh
                                                              .toLowerCase()
                                                              .includes(
                                                                  searchFaqParams.toLowerCase()
                                                              ) |
                                                          item.answer
                                                              .toLowerCase()
                                                              .includes(
                                                                  searchFaqParams.toLowerCase()
                                                              ) |
                                                          item.answer_amh
                                                              .toLowerCase()
                                                              .includes(
                                                                  searchFaqParams.toLowerCase()
                                                              )
                                                  )
                                                : list
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 2. show/open modal/popup */}

                    <Animate.ScaleCard>
                        {(faqToShow !== null) & showModal ? (
                            <>
                                <div className=' relative -mt-[1000px]'>
                                    <div className=''>
                                        <Animate.ScaleIn>
                                            <div className='fixedoutline-none focus:outline-none w-full p-[340px] h-full bg-MetallicBlue/90  '>
                                                <div className='mx-12 text-end '>
                                                    <button
                                                        className='text-sm font-bold text-black uppercase transition-all duration-150 ease-linear bg-transparent rounded shadow outline-none active:bg-emerald-600 hover:shadow-lg focus:outline-none'
                                                        type='button'
                                                        onClick={() =>
                                                            setShowModal(false)
                                                        }
                                                    >
                                                        <ImCancelCircle className='-mt-20 text-5xl ' />
                                                    </button>
                                                </div>

                                                <div className='w-full antialiased text-black bg-white bg-gray-900 bg-cover animated fadeInUp text-g ns p-36 -mt-28'>
                                                    <Animate.ScaleIn>
                                                        <h3 className='-mx-24 -mt-20 text-4xl font-semibold '>
                                                            {t("faq-answer")}
                                                        </h3>
                                                    </Animate.ScaleIn>
                                                    <Animate.ScaleIn>
                                                        <div className='relative flex-auto p-6'>
                                                            <p className='-mx-32 -my-4 text-2xl font-light leading-relaxed text-black text-slate-500 '>
                                                                {answer}
                                                            </p>
                                                        </div>
                                                    </Animate.ScaleIn>
                                                </div>
                                            </div>
                                        </Animate.ScaleIn>
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </Animate.ScaleCard>
                </div>
            ) : (
                <div className='px-10 mt-10 ml-6 mb-14'>
                    <h1 className='mb-3 text-3xl font-bold  text-MetallicBlue'>
                        {t("general-faqs")}
                    </h1>
                    <hr className='border-2 border-green w-[19%]' />
                    <FaqCarousel faqs={list} />
                </div>
            )}
        </>
    );
}
