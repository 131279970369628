import React from "react";
import HeroLandingPage from "./components/hero/heroLandingPage";
import Body from "./components/Body/body";
import SmallFooter from "../../components/footer/smallFooter";
import Map from "./components/Body/map";
import { useMediaQuery } from "react-responsive";

const Contact = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    return (
        <>
            <HeroLandingPage />
            {isDesktopOrLaptop ? (
                <Map height='500px' />
            ) : (
                <Map height='400px' />
            )}
            <Body />
            <SmallFooter hotline={true} />
        </>
    );
};

export default Contact;
