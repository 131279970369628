
const menuData = [


  {
    title: 'grievance-form-title',
    path: '/grievance-form',

  },
  {
    title: 'menu-update-title',
    path: '/update',

  },
  {
    title: 'menu-toll-free-title',
    path: '/call',

  },
];

export default menuData;
