import axios from "axios";


export const GET_STRATEGIES_LIST = "GET_STRATEGIES_LIST";


const headers = {
 
  "Accept": "application/json",
  "Content-Type": "application/json",
  "X-Requested-With": "nid-website",
 
}

export const  getStrategiesList = () => async (dispatch) => {

  
  try {
   
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL+`/Strategies/Strategiesdetail`,
      {
        headers: headers,
      }
    );
  
    if (response && response.data) {
      dispatch({
        type: GET_STRATEGIES_LIST,
        payload: response.data,
      });
      localStorage.setItem("strategiesList", JSON.stringify(response.data));
    } else {
      const cachedStrategiesList = localStorage.getItem("strategiesList");
      if (cachedStrategiesList) {
        dispatch({
          type: GET_STRATEGIES_LIST,
          payload: JSON.parse(cachedStrategiesList),
        });
      }
    }
  } catch (err) {
    const cachedStrategiesList = localStorage.getItem("strategiesList");
    if (cachedStrategiesList) {
      dispatch({
        type: GET_STRATEGIES_LIST,
        payload: JSON.parse(cachedStrategiesList),
      });
    }
  }
  };