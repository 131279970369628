import { useEffect, useRef, useState } from "react";
import partyRegFormData from "./partyRegConfig";
import { useTranslation } from "react-i18next";
import { RiErrorWarningLine } from "react-icons/ri";
import { FaStarOfLife } from "react-icons/fa6";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const keysToAppend = [
    "org",
    "repName",
    "phoneNumber",
    "emailAddress",
    "headQuarter",
    "certificateOfRegistration",
    "commercialRegistration",
    "renewedBusinessLicense",
    "tinCertificate",
    "taxClearance",
    "titleDeedHq",
];

const Body = () => {
    const [formData, setFormData] = useState({
        org: "",
        repName: "",
        phoneNumber: "",
        emailAddress: "",
        headQuarter: "",
        commercialRegistration: "",
        certificateOfRegistration: "",
        renewedBusinessLicense: "",
        taxClearance: "",
        tinCertificate: "",
        titleDeedHq: "",
    });
    const { t } = useTranslation();
    // state for the formType the user wants
    const [isLoading, setIsLoading] = useState(false);
    const [formType, setFormType] = useState(null);
    const [error, setError] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [successMessage, setSuccessMessage] = useState("");
    const [formError, setFormError] = useState(null);
    const recaptchaKey = process.env.REACT_APP_RECAPTCHA;
    const captchaRef = useRef(null);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const token = captchaRef.current.getValue();
        const formDataToSend = new FormData();

        console.log({ captchaToken: token });

        try {
            const captchaResponse = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/verify_captcha/`,
                { captchaToken: token }
            );

            if (captchaResponse.data.error === "Invalid captcha token") {
                alert(captchaResponse.data.error);
                return;
            }

            keysToAppend.forEach((key) => {
                if (formData[key]) {
                    formDataToSend.append(key, formData[key]);
                }
            });
            formDataToSend.append("institution", formType);

            const headers = {
                "MAP-API-KEY": process.env.REACT_APP_RELYING_API_KEY,
                "Content-Type": "multipart/form-data",
            };

            const uploadResponse = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/upload_to_flask_api/`,
                formDataToSend,
                { headers: headers }
            );
            console.log(uploadResponse);

            if (uploadResponse.status === 200) {
                setSuccessMessage(
                    uploadResponse.data.message === "Data inserted successfully"
                        ? "You have successfully applied for Registration"
                        : ""
                );
            } else {
                setFormError(uploadResponse.data.error);
            }
        } catch (error) {
            console.error(error);
            setFormError("An error occurred while submitting the form.");
        }

        handleClearForm();
        setIsLoading(false);
    };
    const handleFormData = (e, index) => {
        const { name, value, type, files } = e.target;
        setError(null);
        if (name === "phoneNumber") {
            var pattern = /[0-9]/g;
            const text = value;
            var filteredString = text.replace(/[^\d]/g, "");
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: filteredString,
            }));
            return;
        }

        if (type === "text" || type === "email") {
            var patternEmail = /[^.@\w-]/g;
            var pattern = /[^.@\w\s-]/g;
            const text = value;
            var filteredString =
                type === "email"
                    ? text.replace(patternEmail, "")
                    : text.replace(pattern, "");
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: filteredString,
            }));
            return;
        }
        if (type === "file") {
            const file = files[0];
            const allowedExtensions = ["jpg", "jpeg", "pdf", "png"];
            const fileExtension = file.name.split(".").pop().toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                setError(
                    "Please select a file with .jpg, .jpeg, .png, or .pdf extension."
                );
                setCurrentIndex(index);
                e.target.value = null;
                return;
            }
        }
        if (type === "file") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: files[0],
            }));
            return;
        }
    };

    const formDisplayed = () => {
        return (
            <div className='flex flex-col gap-5 md:gap-10'>
                {formType && (
                    <div>
                        <div className='space-y-2 flex flex-col md:flex-row items-center gap-1'>
                            <label
                                name='org'
                                className='font-semibold text-sm w-full'
                            >
                                <p className='w-fit'>
                                    {" "}
                                    {t("party-reg-input-title-1") + ":"}{" "}
                                    <span className='pl-1 text-[#ff0000]'>
                                        *
                                    </span>
                                </p>
                            </label>

                            <input
                                onChange={(e) => handleFormData(e)}
                                maxLength={50}
                                name='org'
                                type='text'
                                value={formData.org}
                                className='border-2 border-[darkgray] py-1 rounded-md outline-none px-3 w-full md:max-w-[70%] md:min-w-[70%]'
                                required
                            />
                        </div>
                        {/* {currentIndex === index && error && errorDisplayComponent()} */}
                    </div>
                )}
                {formType && (
                    <div>
                        <div className='space-y-2 flex flex-col md:flex-row items-center gap-1'>
                            <label
                                name='repName'
                                className='font-semibold text-sm w-full'
                            >
                                <p className='w-fit'>
                                    {" "}
                                    {t("party-reg-input-title-2") + ":"}{" "}
                                    <span className='pl-1 text-[#ff0000]'>
                                        *
                                    </span>
                                </p>
                            </label>

                            <input
                                onChange={(e) => handleFormData(e)}
                                maxLength={50}
                                name='repName'
                                type='text'
                                value={formData.repName}
                                className='border-2 border-[darkgray] py-1 rounded-md outline-none px-3 w-full md:max-w-[70%] md:min-w-[70%]'
                                required
                            />
                        </div>
                        {/* {currentIndex === index && error && errorDisplayComponent()} */}
                    </div>
                )}
                {formType && (
                    <div>
                        <div className='space-y-2 flex flex-col md:flex-row items-center gap-1'>
                            <label
                                name='phoneNumber'
                                className='font-semibold text-sm w-full'
                            >
                                <p className='w-fit'>
                                    {" "}
                                    {t("party-reg-input-title-3") + ":"}{" "}
                                    <span className='pl-1 text-[#ff0000]'>
                                        *
                                    </span>
                                </p>
                            </label>

                            <input
                                onChange={(e) => handleFormData(e)}
                                name='phoneNumber'
                                value={formData.phoneNumber}
                                className='border-2 border-[darkgray] py-1 rounded-md outline-none px-3 w-full md:max-w-[70%] md:min-w-[70%]'
                                required
                                minLength={10}
                                maxLength={10}
                            />
                        </div>
                        {/* {currentIndex === index && error && errorDisplayComponent()} */}
                    </div>
                )}
                {formType && (
                    <div>
                        <div className='space-y-2 flex flex-col md:flex-row items-center gap-1'>
                            <label
                                name='emailAddress'
                                className='font-semibold text-sm w-full'
                            >
                                <p className='w-fit'>
                                    {" "}
                                    {t("party-reg-input-title-4") + ":"}{" "}
                                    <span className='pl-1 text-[#ff0000]'>
                                        *
                                    </span>
                                </p>
                            </label>

                            <input
                                onChange={(e) => handleFormData(e)}
                                maxLength={50}
                                name='emailAddress'
                                type='email'
                                value={formData.emailAddress}
                                className='border-2 border-[darkgray] py-1 rounded-md outline-none px-3 w-full md:max-w-[70%] md:min-w-[70%]'
                                required
                            />
                        </div>
                        {/* {currentIndex === index && error && errorDisplayComponent()} */}
                    </div>
                )}
                {formType && (
                    <div>
                        <div className='space-y-2 flex flex-col md:flex-row items-center gap-1'>
                            <label
                                name='headQuarter'
                                className='font-semibold text-sm w-full'
                            >
                                <p className='w-fit'>
                                    {" "}
                                    {t("party-reg-input-title-5") + ":"}{" "}
                                    <span className='pl-1 text-[#ff0000]'>
                                        *
                                    </span>
                                </p>
                            </label>

                            <input
                                onChange={(e) => handleFormData(e)}
                                maxLength={50}
                                name='headQuarter'
                                type='text'
                                value={formData.headQuarter}
                                className='border-2 border-[darkgray] py-1 rounded-md outline-none px-3 w-full md:max-w-[70%] md:min-w-[70%]'
                                required
                            />
                        </div>
                        {/* {currentIndex === index && error && errorDisplayComponent()} */}
                    </div>
                )}
                {partyRegFormData
                    .filter((form) => form.name === formType)
                    .map((formDis) => (
                        <div key={formDis.title} className='space-y-8'>
                            {formDis.formFields
                                .filter(
                                    (formTypeSingle) =>
                                        formTypeSingle.type === "file"
                                )
                                .map((field, index) => (
                                    <div>
                                        <div
                                            className={`${
                                                field.type
                                                    ? "flex-col md:flex-row"
                                                    : ""
                                            } space-y-2 flex md:items-center gap-1`}
                                        >
                                            <label
                                                name={field.name}
                                                className='font-semibold text-sm w-full'
                                            >
                                                <p className='w-fit'>
                                                    {" "}
                                                    {t(field.title) + ":"}{" "}
                                                    <span className='pl-1 text-[#ff0000]'>
                                                        *
                                                    </span>
                                                </p>
                                            </label>

                                            <div className='max-w-[70%] min-w-[70%] w-full md:px-3'>
                                                <input
                                                    onChange={(e) =>
                                                        handleFormData(e, index)
                                                    }
                                                    name={field.name}
                                                    type={
                                                        field.type
                                                            ? field.type
                                                            : "text"
                                                    }
                                                    className={`w-full ${
                                                        field.type === "file"
                                                            ? ""
                                                            : "border-2  border-[darkgray] py-1 rounded-md"
                                                    } outline-none w-full max-w-[70%] min-w-[70%]`}
                                                    required
                                                    accept={
                                                        field.type === "file"
                                                            ? ".jpg,.jpeg,.pdf,.png"
                                                            : undefined
                                                    }
                                                />
                                                <p>
                                                    (.png, .jpg, .jpeg or .pdf)
                                                </p>
                                            </div>
                                        </div>
                                        {currentIndex === index &&
                                            error &&
                                            errorDisplayComponent()}
                                    </div>
                                ))}
                        </div>
                    ))}
                {formType && (
                    <div className='w-full flex justify-center'>
                        <ReCAPTCHA sitekey={recaptchaKey} ref={captchaRef} />
                    </div>
                )}
            </div>
        );
    };

    const errorDisplayComponent = () => {
        return (
            <div className='text-[red] text-md mt-1 font-semibold w-fit text-center'>
                {error && error}
            </div>
        );
    };

    const handleClearForm = () => {
        setFormData({
            org: "",
            repName: "",
            phoneNumber: "",
            emailAddress: "",
            headQuarter: "",
            commercialRegistration: "",
            certificateOfRegistration: "",
            renewedBusinessLicense: "",
            taxClearance: "",
            tinCertificate: "",
            titleDeedHq: "",
        });
    };
    useEffect(() => {
        handleClearForm();
        setSuccessMessage("");
        setSuccessMessage("");

        // clears form type if user changes the selected type of instiutition
    }, [formType]);
    return (
        <div className='relative md:px-24 lg:px-32 py-32 w-full'>
            <form
                onSubmit={(e) => handleSubmit(e)}
                className='relative w-full max-w-[1000px] mx-auto md:px-28 flex flex-col gap-5  py-20 px-5 shadow-2xl'
            >
                <div className=' bg-MetallicBlue absolute -top-5 text-white px-5 md:px-16 py-1 flex items-center gap-1'>
                    <RiErrorWarningLine className='rotate-180 text-2xl' />
                    <p className='text-xl flex items-center'>
                        (<h3 className='text-[#ff0000]'>*</h3>)
                    </p>
                    Required Fields
                </div>
                {/* <p>{t("party-reg-title")}</p>
                <p>{t("party-reg-text-1")}</p> */}
                <p>{t("party-reg-text-2")}</p>
                <div className='space-y-1 w-full'>
                    <p>{t("institution-type")}:</p>
                    <select
                        name=''
                        id=''
                        onChange={(e) => setFormType(e.target.value)}
                        className='max-w-[250px] py-1 w-full outline-none border-2 px-1 border-[gray] rounded-md'
                    >
                        <option value={null} disabled hidden selected default>
                            Select your Institution
                        </option>

                        <option value='govt'>{t("party-reg-select-1")}</option>
                        <option value='private'>
                            {t("party-reg-select-2")}
                        </option>
                        <option value='non-profit'>
                            {t("party-reg-select-3")}
                        </option>
                        <option value='state'>{t("party-reg-select-4")}</option>
                    </select>
                </div>
                {formDisplayed()}
                {successMessage && (
                    <p className='text-[green] font-semibold text-xl text-center'>
                        {successMessage}
                    </p>
                )}
                {error && (
                    <p className='text-[reg] font-semibold text-xl text-center'>
                        {error}
                    </p>
                )}
                {error && (
                    <p className='text-[reg] font-semibold text-xl text-center'>
                        {formError}
                    </p>
                )}
                {formType !== null && (
                    <button
                        disabled={isLoading}
                        className='text-white rounded-md bg-MetallicBlue w-fit mx-auto px-16 py-1.5 mt-5'
                    >
                        {isLoading ? "Loading..." : "Register"}
                    </button>
                )}
            </form>
        </div>
    );
};

export default Body;
