import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import HeroLandingPage from "./hero/heroLandingPage";
import SmallFooter from "../../components/footer/smallFooter";
import Body from "./body/body";
import BodyMobile from "./body/bodyMobile";


const CardPrice = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh' }}>
    //   <iframe
    //     src="https://docs.google.com/forms/d/e/1FAIpQLSd9hOMYK__VoQa0-LI8umj-mtfsMOgLe5RJ31f8BU254PUWOQ/viewform?embedded=true"
    //     width="640"
    //     height="800"
    //     frameborder="0"
    //     marginheight="0"
    //     marginwidth="0"
    //   >
    //     Loading...
    //   </iframe>
    // </div>

    <div>
      <HeroLandingPage />
     
      {
        isDesktopOrLaptop?
        <Body/>:
        <BodyMobile/>
       
      }
    

      <SmallFooter />
    </div>
  );
};

export default CardPrice;
