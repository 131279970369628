import React, { useMemo, useState } from "react";
import Pagination from "@mui/material/Pagination";
import parser from "html-react-parser";
import i18next from "i18next";
import { CloseFullscreenOutlined } from "@mui/icons-material";

const FilterPagination = ({ data }) => {
    const [currentPage, setCurrentPage] = useState(1);
    //changes the page thats being displayed
    const handlePageChange = (e, p) => {
        setCurrentPage(p);
    };
    const indexOfLastPost = currentPage * 6;
    const indexOfFirstPost = indexOfLastPost - 6;

    const [answer, setAnswer] = useState("");
    const [modal, setModal] = useState(false);

    // memoizes the pages that are being displayed
    const displayBlogs = useMemo(() => {
        return data?.slice(indexOfFirstPost, indexOfLastPost).map((faq) => (
            <div
                key={faq.question}
                className='h-[350px] bg-white text-black text-center flex flex-col justify-evenly items-center rounded-lg shadow-md hover:bg-[#47F4C7] w-full px-5'
            >
                <p className='text-black text-3xl font-bold'>
                    FAQ {faq.number}
                </p>
                <div>
                    {i18next.language === "en"
                        ? parser(faq.question)
                        : i18next.language === "amh"
                        ? parser(faq.question_amh)
                        : ""}
                </div>
                <button
                    onClick={() => {
                        setAnswer(
                            i18next.language === "en"
                                ? parser(faq.answer)
                                : i18next.language === "amh" &&
                                      parser(faq.answer_amh)
                        );
                        setModal(true);
                    }}
                    className='bg-[#47F4C7] text-white hover:bg-[#275F74] px-4 py-1 w-fit rounded-md shadow-md'
                >
                    ANSWER
                </button>
            </div>
        ));
    }, [data, indexOfFirstPost, indexOfLastPost]);
    return (
        <div className='flex flex-col items-center p-5 w-full gap-10'>
            <div className='flex flex-col items-center w-full gap-10 relative'>
                <div className='grid grid-cols-3 gap-5 px-32 w-full'>
                    {data.length != 0 ? (
                        displayBlogs
                    ) : (
                        <div className='h-[350px] bg-white text-black text-center flex flex-col justify-evenly items-center rounded-lg shadow-md hover:bg-[#47F4C7] w-full'>
                            Faq not Found
                        </div>
                    )}
                </div>
                {modal && (
                    <div className='absolute top-0 left-0 right-0 bottom-0 bg-[#4F7D8D] px-32 -mb-2'>
                        <div className=' flex flex-col h-full items-center justify-center'>
                            <div className='w-full text-black bg-white px-5 text-justify py-32 relative min-h-[60%] space-y-10'>
                                <button
                                    onClick={() => {
                                        setAnswer("");
                                        setModal(false);
                                    }}
                                    className='absolute right-0 h-fit w-fit p-5 top-0 text-black hover:scale-110'
                                >
                                    <CloseFullscreenOutlined />
                                </button>
                                <p className='font-bold text-3xl'>Answer</p>
                                <div className='w-full'>{answer}</div>{" "}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Pagination
                count={Math.round(data.length / 6)}
                variant='outlined'
                shape='rounded'
                onChange={handlePageChange}
            />
        </div>
    );
};

export default FilterPagination;
