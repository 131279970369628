import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Trans } from "react-i18next";

const Hero = () => {
    const { t } = useTranslation();

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    // const { t } =
    return (
        <>
            {isDesktopOrLaptop ? (
                <div class='bg-media-hero bg-center bg-cover '>
                    <div className='px-10 md:px-0 bg-hero-gradient'>
                        <div class='grid grid-cols-1 md:gap-16 md:px-6 py-16'></div>
                        <div className='flex justify-start pt-36 md:pt-24 md:p-0 md:pr-40 text-7xl  md:pl-28'>
                            <h1 className='pr-2 mb-0 font-bold text-white '>
                                {t("cardprice-title")}
                            </h1>
                        </div>

                        <div className='pb-10 mt-4 text-white text-3xl md:pl-28  '>
                            <p className=''>{t("cardprice-desc")}</p>
                        </div>
                    </div>
                </div>
            ) : (
                <div class='bg-media-hero bg-center bg-cover '>
                    <div className='bg-hero-gradient px-5'>
                        <div class='grid grid-cols-1 py-8'></div>
                        <div className='flex flex-col justify-start pt-28 text-4xl  '>
                            <div className='flex pb-2'>
                                <h1 className='font-bold text-green pr-2'>
                                    {t("cardprice-title")}
                                </h1>{" "}
                            </div>
                            {/* <h1 className="font-bold text-green ">
                {t("help-hero-titlemobile3")}
              </h1> */}
                        </div>

                        <div className='pb-10 mt-4 text-white text-lg  leading-6 tracking-tighter text-justify'>
                            <p className='md:mr-[40%]'>{t("cardprice-desc")}</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Hero;
