import { GET_BLOG_LIST } from "../actions/blogActions";
const initialState = {
  blogList: [],
};

const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BLOG_LIST:
      return {
        ...state,
        blogList: action.payload,
      };
    default:
      return state;
  }
};
export default blogReducer;