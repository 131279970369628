import React, { useEffect, useState } from "react";
import axios from "axios";

function UserId() {
  const [imageData, setImageData] = useState(null);
  const getImage = async () => {
    try {
      //196.189.124.207:8080/api/registerStudent
      console.log("getting")
      const response = await axios.get("http://localhost:3001/get_img");
      
      setImageData(response.data);

      return response.status >= 200 && response.status < 300;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    console.log("fetching")
    getImage()
  }, []);

  if(imageData){
  return (
    
    <img
      src={`data:image/png;base64,${imageData}`}
      alt="Base64 encoded image"
    />
    
  );
  }
}

export default UserId;
