import Body from "./components/body/body";
import HeroLandingPage from "./components/hero/heroLandingPage";
import SmallFooter from "../../components/footer/smallFooter";

const Worldbank = () => {
    return (
        <>
            <HeroLandingPage />
            <Body />
            <SmallFooter />
        </>
    );
};

export default Worldbank;
