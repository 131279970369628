import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const GoogleCaptchaWrapper = ({ children }) => {
    const recaptchaKey = process.env.REACT_APP_RECAPTCHA;
    console.log(recaptchaKey)
    return (
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
            {children}
        </GoogleReCaptchaProvider>
    );
};

export default GoogleCaptchaWrapper;
