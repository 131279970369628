import React from "react";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import valuesIcon from "../../../../assets/valuesIcon.png"
import visionIcon from "../../../../assets/visionIcon.png"
import missionIcon from "../../../../assets/missionIcon.png"

import {  Animate } from "../../../../hooks/animations";
import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next'

const Principle = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const {t} = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      {isDesktopOrLaptop ? (
        <div className="">
               {/* <div className="flex flex-col items-center w-full h-full pt-0 scale-90 md:scale-100 "> */}
             
                <div className="grid justify-center w-full grid-cols-3 gap-20 px-24 -mt-36 -pt-20 pb-7 md:gap-28 place-items-center justify-items bg-geen ">


            <Animate.ScaleIn>
              <div class="  flex flex-col place-items-center max-w-sm p-5 py-3 bg-white rounded-xl  shadow-[5px_12px_50px_-14px_rgb(0,0,0,0.3)] text-center px-8">
              <Animate.ScaleIn>
                <img
                  src={visionIcon}
                  alt="vision Icon"
                  className="w-32 "
                ></img>
                </Animate.ScaleIn>
                <Animate.ScaleIn>
                <h5 class="mb-2 text-4xl font-bold my-4">{t("Vision")}</h5>
                </Animate.ScaleIn>

                <Animate.ScaleIn>
                <p class="font-normal text-lg dark:text-gray-400">
                {t("vision-text")}
                </p>
                </Animate.ScaleIn>
              </div>
            </Animate.ScaleIn>



            <Animate.ScaleIn>
            <div class=" flex flex-col place-items-center max-w-sm p-5 py-3 bg-white rounded-xl  shadow-[5px_12px_50px_-14px_rgb(0,0,0,0.3)] text-center px-8">
              <Animate.ScaleIn>
              {/* <div className="w-24 h-24 bg-darkred"> */}
                <img
                  src={missionIcon}
                  alt="Mission Icon"
                  className="w-32 "
                ></img>
                {/* </div> */}
                 </Animate.ScaleIn>

                 <Animate.ScaleIn>
                <h5 class="mb-2 text-4xl font-bold my-4">{t("Mission")}</h5>
                </Animate.ScaleIn>

                <Animate.ScaleIn>
                <p class="font-normal text-lg dark:text-gray-400">
                {t("mission-text")}
                </p>
                </Animate.ScaleIn>
              </div>
            </Animate.ScaleIn>



            <Animate.ScaleIn>
              <div class=" flex flex-col place-items-center py-3 max-w-sm p-5 bg-white rounded-xl  shadow-[5px_12px_50px_-14px_rgb(0,0,0,0.3)] text-center px-8">
              <Animate.ScaleIn>
                <div className="flex items-center justify-center w-20 h-20 bg-cover">
                <img
                  src={valuesIcon}
                  alt="Values Icon"
                  className="w-32 bg-cover "
                ></img>
                </div>
              </Animate.ScaleIn>

              <Animate.ScaleIn>
                <h5 class="mb-2 text-4xl font-bold my-5">{t("Values")}</h5>
                </Animate.ScaleIn>

                <Animate.ScaleIn>
                <p class="font-normal text-lg dark:text-gray-400">
                <Trans i18nKey="values-text"/>
       
                </p>
                </Animate.ScaleIn>
              </div>
              </Animate.ScaleIn>
            </div> 

            {/* // :
      // <CardSliderStakeholder/>} */}
            {/* </div>  */}
          {/* <AboutWithoutTitle /> */}


        </div>
      ) : (
       <div>
      
       </div>
      )}
    </>
  );
};
export default Principle;
