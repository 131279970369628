import React from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const Updatenumber = ({ id }) => {
    const { t } = useTranslation();
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    return (
        <>
            {isDesktopOrLaptop ? (
                <div
                    id={id}
                    class='relative flex  h-80 items-center justify-center my-5 gap-10 '
                >
                    <div className='relative flex flex-col w-[60%] text-left pl-28 pt-12 h-full'>
                        <h1 className='mt-3 mb-3 text-6xl font-medium text-MetallicBlue '>
                            {t("services-update-fayda-text")}
                        </h1>

                        <hr className='w-[12%] h-1 border-none bg-green mb-6' />

                        <p className='text-2xl text-justify  opacity-80 '>
                            {t("services-update-fayda-text2")}
                        </p>
                    </div>

                    <div className=' w-[40%]  h-full justify-center flex items-center'>
                        {/* <h2 className='relative w-full py-6 text-2xl font-medium text-center border-2 rounded-full  border-MetallicBlue text-MetallicBlue'>Learn More </h2> */}

                        <a
                            href='https://resident.fayda.et'
                            className='px-0  text-xl py-3 w-[45%] shadow-lg rounded-md text-center cursor-pointer transition-all transform duration-300 border-MetallicBlue/40 hover:bg-MetallicBlue border-2 hover:text-white ease-in-out text-MetallicBlue'
                        >
                            {t("update-now")}
                        </a>
                    </div>
                </div>
            ) : (
                //**********************************************************Mobile Version **********************************************************
                <div
                    id={id}
                    class=' flex flex-col h-92 items-center  my-2 gap-0 justify-center px-8  pb-8  '
                >
                    <div className='flex flex-col items-center justify-center w-full h-full pt-5 text-left'>
                        <h1 className='mt-3 mb-2 text-4xl font-medium text-MetallicBlue '>
                            {t("services-update-fayda-text")}
                        </h1>

                        <hr className='w-[22%]  border-b-2  bg-green mb-4' />

                        <p className='text-xl text-justify  opacity-80 '>
                            {t("services-update-fayda-text2")}
                        </p>
                    </div>

                    <div className='flex items-center justify-center w-full h-full'>
                        {/* <h2 className='relative w-full py-6 text-2xl font-medium text-center border-2 rounded-full  border-MetallicBlue text-MetallicBlue'>Learn More </h2> */}

                        <a
                            href='https://resident.fayda.et'
                            className='w-full px-0 py-4 text-xl transition-all cursor-pointer duration-300 transform border-2 rounded-md shadow-lg text-center hover:bg-MetallicBlue border-MetallicBlue/40 hover:text-white ease-in-out text-MetallicBlue'
                        >
                            {t("update-now")}
                        </a>
                    </div>
                </div>
            )}
        </>
    );
};

export default Updatenumber;
