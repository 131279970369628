import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Trans } from "react-i18next";

const Hero = () => {
    const { t } = useTranslation();

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    return (
        <>
            {isDesktopOrLaptop ? (
                <div class='bg-media-hero bg-center bg-cover'>
                    <div className='px-10 md:px-0 bg-hero-gradient xl:px-32'>
                        <div class='grid grid-cols-1 md:gap-16 md:px-6 py-16'></div>
                        <div className='flex w-full justify-start pt-36 md:pt-24 md:px-28 pb-10 text-5xl text-center '>
                            <div className='pr-2 mb-0 font-bold text-white w-full'>
                                <p>{t("party-reg-title")}</p>
                            </div>
                        </div>

                        {/* <div className='pb-16 mt-4 text-white text-3xl px-28  '>
                            <p>{t("party-reg-text-1")}</p>
                        </div> */}
                    </div>
                </div>
            ) : (
                <div class='bg-media-hero bg-center bg-cover '>
                    <div className='bg-hero-gradient px-5'>
                        <div class='grid grid-cols-1 py-8'></div>
                        <div className='flex flex-col justify-start pt-28 text-4xl  '>
                            <div className='flex pb-2'>
                                <h1 className='font-bold text-green pr-2'>
                                    <p>{t("party-reg-title")}</p>
                                </h1>{" "}
                            </div>
                            {/* <h1 className="font-bold text-green ">
                {t("help-hero-titlemobile3")}
              </h1> */}
                        </div>

                        {/* <div className='pb-10 mt-4 text-white text-lg  leading-6 tracking-tighter text-justify'>
                            <p>{t("party-reg-text-1")}</p>
                        </div> */}
                    </div>
                </div>
            )}
        </>
    );
};

export default Hero;
