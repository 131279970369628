import React from "react";
import Hero from "./components/Hero";
import SmallFooter from "../../components/footer/smallFooter";
import Body from "./components/Body";

const Partners = () => {
    return (
        <div>
            <Hero />
            <Body />
            <SmallFooter />
        </div>
    );
};

export default Partners;
