import React, { useState } from "react";
import axios from "axios";

const baseUrl = "https://mobile-mini.fayda.et";
const Download = ({ uin, accessToken, refreshToken }) => {
    const [isLoading, setIsLoading] = useState();
    async function fetchPDF() {
        try {
            let requestBody = {
                uin: uin,
            };
            let headers = {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
                "Refresh-Token": refreshToken,
            };

            const response = await axios.post(
                `${baseUrl}/fill_form`,
                requestBody,
                {
                    headers,
                    responseType: "blob",
                }
            );

            if (response.status !== 200) {
                alert("Failed to download");
                throw new Error("Failed to fetch user data");
            } else if (response.status === 401) {
                alert("Unauthorized! Please reload again from telebirr");
            } else {
                // Handle successful response

                return response.data; // Return the response data
            }
        } catch (error) {
            console.error("Error:", error);
            alert("Unauthorized! Please reload again from telebirr");
            throw error; // Rethrow the error to propagate it
        }
    }
    const handleRedownload = async () => {
        setIsLoading(true);
        // Get the form data from the user input
        // const formData = {
        //   name: 'John Doe',
        //   email: 'john.doe@example.com',
        //   // Add more form fields as needed
        // };

        // get request response here

        const filledPdfBytes = await fetchPDF();

        // Create a blob from the filled PDF
        const blob = new Blob([filledPdfBytes], { type: "application/pdf" });

        // Create a download link
        const url = URL.createObjectURL(blob);

        // Programmatically trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = "Fayda_Letter.pdf";
        link.click();

        // Clean up the URL object
        URL.revokeObjectURL(url);
        // alert(
        //   "Your pdf is encrypted. The passowrd is your first name in English and year of birth in EC. Example: Abebe1980"
        // );
        setIsLoading(false);
        // Code to download the PDF goes here
    };
    return (
        <div className='flex flex-col justify-center items-center lg:mt-20 mt-32  bg-white'>
            <div className='w-full  rounded-lg lg:p-10 p-2 lg:shadow-md bg-gray-50 dark:bg-gray-800'>
                <h2 className='lg:text-6xl text-3xl font-semibold text-center text-darkblue dark:text-lightblue mb-6'>
                    Your PDF is being prepared...
                </h2>
                <p className='text-center lg:text-3xl text-xl text-gray-700 dark:text-gray-300 mb-10'>
                    Your PDF will be downloaded automatically. If it doesn't,
                    please click the button below to download it manually.
                </p>
                <div className='flex justify-center'>
                    {isLoading ? (
                        <div className='flex justify-center items-center'>
                            <div className='w-8 h-8 border-2 border-t-[transparent] border-white rounded-full animate-spin'></div>
                        </div>
                    ) : (
                        <button
                            onClick={handleRedownload}
                            className='rounded-full hover:bg-green  text-white bg-darkblue hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium lg:text-3xl text-xl  px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                        >
                            Re-download PDF
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Download;
