import { useTranslation } from "react-i18next";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import i18next from "i18next";

const categories = [
    {
        en: "All",
        amh: "ሁሉም",
    },
    {
        en: "Technical Department",
        amh: "የቴክኒክ ክፍል",
    },
    {
        en: "Planning and Evaluation",
        amh: "እቅድ እና ግምገማ",
    },
    {
        en: "Program Department",
        amh: "ፕሮግራም ክፍል",
    },
    {
        en: "Communications & Knowledge Management",
        amh: "ኮሚኒኬሽን (ዕውቀት አስተዳደር)",
    },
    {
        en: "Final, Procurement and Operations",
        amh: "ግዥ እና አሰራር አንዳንድ ዝርዝር ጉዳዮች",
    },
    {
        en: "Specifications",
        amh: "ዝርዝሮች",
    },
];

export default function Search(props) {
    const { t } = useTranslation();
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    //const [Event, setEvent] = useState("All");
    const [open, setOpen] = useState(false);

    const handleCallback = (childData) => {
        props.setEvent(childData);
    };

    return (
        <>
            {isDesktopOrLaptop ? (
                <div className='flex flex-row justify-center bg-darkblue w-full p-10'>
                    <input
                        onChange={(e) => props.searchHandler(e.target.value)}
                        className='rounded-2xl w-[290px] text-center py-1'
                        placeholder='Search Jobs'
                        type='text'
                    />
                    <button
                        className='ml-12'
                        onClick={() => props.sortHandler("latest")}
                    >
                        <p className='text-white'>
                            {t("career-search-latest")}
                        </p>
                    </button>
                    <button
                        className='ml-12'
                        onClick={() => props.sortHandler("oldest")}
                    >
                        <p className='text-white'>
                            {t("career-search-oldest")}
                        </p>
                    </button>
                </div>
            ) : (
                <div className='flex flex-col items-center justify-center gap-0  bg-darkblue place-items-center align-middle'>
                    <div className='flex items-center w-full h-full gap-2 px-5 align-middle'>
                        <div className='flex items-center bg-white rounded-3xl  w-[60%] my-3'>
                            <input
                                onChange={(e) =>
                                    props.searchHandler(e.target.value)
                                }
                                className='block w-full py-1 pl-3 text-base text-gray-500 placeholder-gray-400 appearance-none rounded-3xl placeholder:text-center'
                                placeholder='Search Documents'
                            />
                            <SearchIcon className='' />
                        </div>

                        <div className='flex w-[40%]'>
                            <div className='relative inline-block'>
                                <input
                                    onClick={() => setOpen(!open)}
                                    className='block w-full py-1 pr-10 text-base text-gray-500 placeholder-gray-400 bg-white appearance-none rounded-3xl  placeholder:text-center hover:cursor-pointer'
                                    placeholder='Categories'
                                    readOnly
                                />
                                <svg
                                    className='absolute top-0 right-0 w-10 h-full text-gray-400 pointer-events-none'
                                    viewBox='0 0 20 20'
                                    fill='currentColor'
                                >
                                    <path
                                        fillRule='evenodd'
                                        d='M10 12a1 1 0 01-.71-.29l-4-4a1 1 0 111.42-1.42L10 9.59l3.29-3.3a1 1 0 111.42 1.42l-4 4a1 1 0 01-.71.29z'
                                        clipRule='evenodd'
                                    />
                                </svg>
                                {open ? (
                                    <div className='absolute z-10 mt-2 w-[100%] text-center py-2 rounded-lg bg-white shadow-lg'>
                                        <div className='w-full max-h-60 z-10 overflow-y-scroll'>
                                            {categories.map((category, key) => (
                                                <div
                                                    className='py-2 hover:bg-gray-100 cursor-pointer'
                                                    onClick={() =>
                                                        handleCallback(
                                                            category.en
                                                        )
                                                    }
                                                >
                                                    {i18next.language ===
                                                        "en" &&
                                                        category.en}{" "}
                                                    {i18next.language ===
                                                        "amh" && category.amh}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='w-full text-base font-bold  text-darkblue  bg-[#c7c7c7] py-2  pl-5'>
                        <button
                            onClick={() => props.sortHandler("latest")}
                            className={`content-center px-6 hover:cursor-pointer hover:underline focus:underline focus:decoration-[#00f7c7] decoration-4 decoration-offset-10 `}
                        >
                            {t("career-search-latest")}
                        </button>
                        <button
                            onClick={() => props.sortHandler("oldest")}
                            className={`content-center px-6 hover:cursor-pointer hover:underline  focus:underline focus:decoration-[#00f7c7] decoration-4 decoration-offset-10 `}
                        >
                            {t("career-search-oldest")}
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}
