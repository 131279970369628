import React from "react";
import { useMediaQuery } from "react-responsive";
import QuestionMark from "../../../../assets/question-mark.svg";
import { Fragment, useState } from "react";
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import i18next from "i18next";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getFaq } from "../../../../redux/selectors";

const faydaInfo = [
    {
        question: {
            en: "What is the Ethiopian “Fayda” Digital ID?",
            am: "ፋይዳ ዲጂታል መታወቂያ ምንድን ነው?",
        },
        answer: {
            en: "Fayda Digital ID is a foundational ID that enables individuals to prove their identity and offers service providers a trusted real-time digital authentication.",
            am: "ፋይዳ ዲጂታል መታወቂያ ግለሰቦች ማንነታቸውን እንዲያረጋግጡ የሚያስችል እና ለአገልግሎት አቅራቢዎች ታማኝ ዲጂታል ማረጋገጫ በዚያው ቅጽበት የሚሰጥ መታወቂያ ስርዓት ነው ። ",
        },
    },
    {
        question: {
            en: "Who is eligible for Fayda ID?",
            am: "ለፋይዳ ዲጂታል መታወቂያ ብቁ የሆነው ማነው?",
        },
        answer: {
            en: "All residents of Ethiopia are eligible for the Digital ID. In the “ID Proclamation” a resident is legally defined as: a natural person living in Ethiopia, with or without a proof of Ethiopian citizenship, a foreign resident living or working in the territory of Ethiopia in accordance with the country’s law",
            am: "ኢትዮጵያ ውስጥ የሚገኝ ህጋዊ ነዋሪ በሙሉ ለፋይዳ ዲጂታል መታወቂያ ብቁ ነው። በ ረቂቅ አዋጁ ነዋሪ የሚገለፀው በህጋዊ መልኩ ኢትዮጵያ ውስጥ የሚኖር ፣ ኢትዮጵያዊ ዜግነት ያለው ወይም የሌለው የተፈጥሮ ሰው እንዲሁም በሀገሪቱ ህግ መሰረት በኢትዮጵያ ውስጥ የሚኖር ወይም የሚሰራ የውጭ ሀገር ዜጋ ነው።",
        },
    },
    {
        question: {
            en: "What kind of data is collected during Fayda registration?",
            am: "ኦንላይን ቅደም-ምዝገባ ማድረግ እችላለው?",
        },
        answer: {
            en: "Only select demographic data and biometric information is collected, solely for the purpose of uniquely identifying the individual. For demographic data; Full name, Date of Birth, Gender, Nationality, and Current Address are collected. For biometric data; fingerprint, iris, and face photograph are collected for absolute identification measures. Other fields such as Phone Number and email address are optionally used to deliver the unique FIN (Fayda Identification Number) for the registrant.",
            am: "አዎ ይችላሉ ፤ በድረገጻችን id.et ላይ በመግባት የቅድመ-ምዝገባ በማድረግ ቀጠሮ መያዝ ይችላሉ። እባክዎን የቅድመ ምዝገባ ዝርዝር መረጃ ለማግኘት  https://register.fayda.et/#/faq ይመልከቱ። እባክዎን የቅድመ ምዝገባ ሂደት ዝርዝር መረጃ ለማግኘት https://register.fayda.et/#/about-us ይመልከቱ።",
        },
    },
    {
        question: {
            en: "Where can I register for Fayda ID?",
            am: "የፋይዳ ዲጂታል መታወቂያን የት ማግኘት እችላለው?",
        },
        answer: {
            en: "Eligible residents shall visit our website id.et for pre-registration and must be present physically based on their appointed date and time for biometric data registration. To find the closest live registration center, click https://id.gov.et/locations.",
            am: "ለምዝገባ ብቁ የሆኑ ነዋሪዎች https://id.gov.et/locations።/ ውስጥ በሚያገኙት ጊዜያዊ የመመዝገቢያ ጣቢያዎች መመዝገብ ይችላሉ::",
        },
    },
    {
        question: {
            en: "How much does it cost to get a Digital ID?",
            am: "የፋይዳ ዲጂታል መታወቂያን ለማግኘት ምን ያህል ያስከፍላል?",
        },
        answer: {
            en: "Fayda Digital ID registration is free. According to the Ethiopian Digital ID Proclamation, if you have been registered, you  will successfully receive your “Fayda Identification Number (Fayda ID)  which will be sent to you via SMS. Then you are a “FaydaID holder”. However, if you want a printed card credential, then payment has to be made directly or through part of a program or service provider you are subscribed to.",
            am: "የፋይዳ ዲጂታል መታወቂያ ምዝገባ ከክፍያ ነጻ ነው። በኢትዮጵያ ዲጂታል መታወቂያ አዋጅ መሰረት ተመዝግበው በተሳካ ሁኔታ ፋይዳ መታወቂያ ቁጥር በስልክዎ ከደረሰዎት በኃላ የዲጂታል መታወቂያ ባለቤት ይሆናሉ። ነገር ግን የታተመ ካርድ ከፈለጉ፣ ክፍያ በቀጥታ ወይም በተዘዋዋሪ በተመዘገቡበት ፕሮግራም ወይም አገልግሎት አቅራቢ በኩል ክፍያ መፈፀም ይኖርብዎታል። ",
        },
    },
    {
        question: {
            en: "How long does it take to register for Fayda  ID ??",
            am: "የፋይዳ ዲጂታል መታወቂያ ለመመዝገብ ምን ያህል ደቂቃ ይፈጃል?",
        },
        answer: {
            en: "Registration for Fayda  ID takes an average of 5-10 minutes as long as all requirements are properly presented at the time of registration.",
            am: "የፋይዳ ዲጂታል መታወቂያ ምዝገባ በአማካኝ ከ5 እስከ 10 ደቂቃ ይፈጃል።",
        },
    },
];

function Icon({ id, open }) {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            className={`${
                id === open ? "rotate-180" : ""
            } h-5 w-5 transition-transform `}
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
            strokeWidth={2}
        >
            <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M19 9l-7 7-7-7'
            />
        </svg>
    );
}

const Faq = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(-1);
    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    const customAnimation = {
        mount: { scale: 1 },
        unmount: { scale: 0.9 },
    };

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    //   const handleChange = () => {
    //     const [panel, setExpanded] = useState(1);
    //     setExpanded(panel);
    //   };
    return (
        <>
            {faydaInfo ? (
                <div className='flex items-center justify-center w-full bg-cover bg-MetallicBlue/90  '>
                    <div className='w-full h-full pt-6 pb-5 text-white bg-MetallicBlue/90 md:py-24 md:px-20'>
                        <div className='flex flex-col w-full h-full b md:flex-row'>
                            {isDesktopOrLaptop ? (
                                <>
                                    <div className='flex h-full'>
                                        <div className='md:w-[35%] md:mx-5 mx-4 flex flex-col items-center md:justify-center  h-full mb-10 md:mb-0 '>
                                            <div className='flex items-center justify-center w-full gap-0 pb-0 mb-5 '>
                                                <img
                                                    src={QuestionMark}
                                                    className='h-10 '
                                                    alt='fireSpot'
                                                />
                                                <div className='flex text-base '>
                                                    <h1 className='px-2 text-xl font-extrabold text-white md:text-4xl'>
                                                        {t("faq")}
                                                    </h1>
                                                </div>
                                            </div>

                                            <div className=''>
                                                <p className='px-3 font-medium text-center text-white text-md md:text-justify md:text-xl'>
                                                    {t("faq-text")}{" "}
                                                </p>
                                            </div>

                                            <div className='w-full px-16 md:px-3'>
                                                <button
                                                    className='text-white/80  w-full  md:mt-5 self-center shadow-md  text-xl font-semibold scale-90 h-11 btnnid rounded-md md:scale-100 hover:bg-[#083247]'
                                                    onClick={() =>
                                                        navigate("/faq")
                                                    }
                                                >
                                                    {t("faq-questions")}
                                                </button>
                                            </div>
                                        </div>

                                        <div className='w-[75%] mx-0  pt-[2%]   justify-center flex items-center '>
                                            <Fragment>
                                                <div className='grid h-full grid-cols-2 gap-4 pt-5 m-2 align-middle '>
                                                    <div className='flex flex-col gap-2'>
                                                        {faydaInfo
                                                            .slice(0, 3)
                                                            .map(
                                                                (
                                                                    listItem,
                                                                    key
                                                                ) => (
                                                                    <div className='px-5 text-black bg-white rounded-xl'>
                                                                        <Accordion
                                                                            icon={
                                                                                <Icon
                                                                                    id={
                                                                                        key
                                                                                    }
                                                                                    open={
                                                                                        open
                                                                                    }
                                                                                />
                                                                            }
                                                                            className=''
                                                                            open={
                                                                                open ===
                                                                                key
                                                                            }
                                                                            animate={
                                                                                customAnimation
                                                                            }
                                                                        >
                                                                            <AccordionHeader
                                                                                className='py-2.5'
                                                                                onClick={() =>
                                                                                    handleOpen(
                                                                                        key
                                                                                    )
                                                                                }
                                                                                onMouseEnter={() =>
                                                                                    handleOpen(
                                                                                        key
                                                                                    )
                                                                                }
                                                                                onMouseLeave={() =>
                                                                                    handleOpen(
                                                                                        -1
                                                                                    )
                                                                                }
                                                                            >
                                                                                <div className='w-full text-lg leading-tight '>
                                                                                    {i18next.language ===
                                                                                        "en" &&
                                                                                        listItem
                                                                                            .question
                                                                                            .en}
                                                                                    {i18next.language ===
                                                                                        "amh" &&
                                                                                        listItem
                                                                                            .question
                                                                                            .am}
                                                                                </div>
                                                                            </AccordionHeader>

                                                                            <AccordionBody>
                                                                                <div className='flex gap-4 text-sm leading-tight text-left text-black '>
                                                                                    <p className='mr-8 text-justify '>
                                                                                        {i18next.language ===
                                                                                            "en" &&
                                                                                            listItem
                                                                                                .answer
                                                                                                .en}
                                                                                        {i18next.language ===
                                                                                            "amh" &&
                                                                                            listItem
                                                                                                .answer
                                                                                                .am}
                                                                                    </p>
                                                                                </div>
                                                                            </AccordionBody>
                                                                        </Accordion>
                                                                    </div>
                                                                )
                                                            )}
                                                    </div>

                                                    <div className='flex flex-col gap-2'>
                                                        {faydaInfo
                                                            .slice(3, 6)
                                                            .map(
                                                                (
                                                                    listItem,
                                                                    key
                                                                ) => (
                                                                    <div className='px-5 text-black bg-white rounded-xl '>
                                                                        <Accordion
                                                                            icon={
                                                                                <Icon
                                                                                    id={
                                                                                        key +
                                                                                        3
                                                                                    }
                                                                                    open={
                                                                                        open
                                                                                    }
                                                                                />
                                                                            }
                                                                            className=''
                                                                            open={
                                                                                open ===
                                                                                key +
                                                                                    3
                                                                            }
                                                                            animate={
                                                                                customAnimation
                                                                            }
                                                                        >
                                                                            <AccordionHeader
                                                                                className='py-2.5  text-lg leading-tight'
                                                                                onClick={() =>
                                                                                    handleOpen(
                                                                                        key +
                                                                                            3
                                                                                    )
                                                                                }
                                                                                onMouseEnter={() =>
                                                                                    handleOpen(
                                                                                        key +
                                                                                            3
                                                                                    )
                                                                                }
                                                                                onMouseLeave={() =>
                                                                                    handleOpen(
                                                                                        -1
                                                                                    )
                                                                                }
                                                                            >
                                                                                <div className=''>
                                                                                    {i18next.language ===
                                                                                        "en" &&
                                                                                        listItem
                                                                                            .question
                                                                                            .en}
                                                                                    {i18next.language ===
                                                                                        "amh" &&
                                                                                        listItem
                                                                                            .question
                                                                                            .am}
                                                                                </div>
                                                                            </AccordionHeader>

                                                                            <AccordionBody>
                                                                                <div className='flex gap-4 text-sm leading-tight text-left text-black '>
                                                                                    <p className='mr-8 text-justify '>
                                                                                        {i18next.language ===
                                                                                            "en" &&
                                                                                            listItem
                                                                                                .answer
                                                                                                .en}
                                                                                        {i18next.language ===
                                                                                            "amh" &&
                                                                                            listItem
                                                                                                .answer
                                                                                                .am}
                                                                                    </p>
                                                                                </div>
                                                                            </AccordionBody>
                                                                        </Accordion>
                                                                    </div>
                                                                )
                                                            )}
                                                    </div>
                                                </div>
                                            </Fragment>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default Faq;
