import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const SidebarLink = styled(Link)`
    display: flex;
    color: #e1e9fc;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 60px;
    text-decoration: none;
    font-family: "Barlow Semi Condensed", "sans-serif";
    font-size: 18px;
    background-color: ${(props) =>
        props.isActive ? "#252831" : "transparent"};
    border-left: ${(props) => (props.isActive ? "4px solid #47f4c7" : "none")};
    &:hover {
        background: #252831;
        border-left: 4px solid #47f4c7;
        cursor: pointer;
    }
`;

const SidebarLabel = styled.span`
    margin-left: 16px;
`;

const DropdownLink = styled(Link)`
    background: #414757;
    height: 60px;
    padding-left: 3rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #f5f5f5;
    font-family: "Barlow Semi Condensed", "sans-serif";
    font-size: 20px;
    &:hover {
        background: #632ce4;
        cursor: pointer;
    }
`;

const SubMenu = ({ item }) => {
    const [subnav, setSubnav] = useState(false);
    const { t } = useTranslation();
    const [allowNavigation, setAllowNavigation] = useState(true);

    const showSubMenu = () => {
        setSubnav(!subnav);
    };
    const showSubnav = () => {
        console.log();
        if (item.subNav) {
            console.log(false);

            setAllowNavigation(false);
        } else {
            console.log(item.subNav);
            setAllowNavigation(true);
        } // allow navigation
    };

    useEffect(() => {
        showSubnav();
    }, []);
    const handleRedirectLink = (linkRedirect) => {
        console.log("linkRedirect", linkRedirect);
        window.open(linkRedirect, "_self");
    };
    return (
        <>
            {allowNavigation ? (
                <SidebarLink to={item.path}>
                    <div>
                        <SidebarLabel>{t(item.title)}</SidebarLabel>
                    </div>
                    <div>
                        {item.subNav && subnav
                            ? item.iconOpened
                            : item.subNav
                            ? item.iconClosed
                            : null}
                    </div>
                </SidebarLink>
            ) : (
                <SidebarLink onClick={() => showSubMenu()}>
                    <div>
                        <SidebarLabel>{t(item.title)}</SidebarLabel>
                    </div>
                    <div>
                        {item.subNav && subnav
                            ? item.iconOpened
                            : item.subNav
                            ? item.iconClosed
                            : null}
                    </div>
                </SidebarLink>
            )}

            {subnav &&
                item.subNav.map((item, index) => {
                    return item.type === "internal" ? (
                        <DropdownLink key={index} to={item.path}>
                            <SidebarLabel> {t(item.title)}</SidebarLabel>
                        </DropdownLink>
                    ) : (
                        <div
                            className='hover:bg-[#632ce4] hover:cursor-pointer text-[20px]  text-[#f5f5f5] decoration-0 items-center flex ps-[3rem] h-[60px] bg-[#414757]'
                            onClick={() =>
                                handleRedirectLink(`https://` + item.path)
                            }
                        >
                            <SidebarLabel> {t(item.title)}</SidebarLabel>
                        </div>
                    );
                })}
        </>
    );
};

export default SubMenu;
