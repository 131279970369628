import React from "react";
import ReactPlayer from "react-player";
import ReactDom from "react-dom";
const Modal = ({ videoUrl, closeModal }) => {
    return ReactDom.createPortal(
        <div className='bg-black/40 fixed top-0 bottom-0 left-0 right-0 w-full z-30'>
            <div className='relative w-full flex justify-center items-center h-[100vh]'>
                <div className='w-full flex justify-center px-5 py-10'>
                    <div className='w-full max-w-[1200px] bg-gray-700 hover:scale-[101%] duration-300 ease-in-out aspect-video z-50 min-h-[320px]'>
                        <ReactPlayer
                            url={videoUrl}
                            width='100%'
                            height='100%'
                        />
                    </div>
                </div>
                <div
                    className='absolute top-0 w-full h-[100vh] z-40'
                    onClick={closeModal}
                ></div>
            </div>
        </div>,
        document.getElementById("portal")
    );
};

export default Modal;
