import React from "react";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { FadeIn, Animate } from "../../../../hooks/animations";
import { useSelector } from "react-redux";
import { getTest } from "../../../../redux/selectors";

const Testimonials = () => {
  const list = useSelector(getTest)

  
  const one = useMediaQuery({
    query: "(max-height: 300px)",
  });


  const TwoFiveZeroPercent = useMediaQuery({
    query: "(max-height: 400px)",
  });

  const TwoZeroZeroPercent = useMediaQuery({
    query: "(max-height: 500px)",
  });

  const OneSevenFivePercent = useMediaQuery({
    query: "(max-height: 600px)",
  });

  const OneFivePercent = useMediaQuery({
    query: "(max-height: 700px)",
  });

  const OneTwoFivePercent = useMediaQuery({
    query: "(max-height: 800px)",
  });

  const OneOneTenPercent = useMediaQuery({
    query: "(max-height: 900px)",
  });

  const { t } = useTranslation();
  return (
    <>
      {list ? (
        <div className="flex flex-col items-center justify-center w-full h-full px-2 py-0 md:mb-20">
          <Animate.FadeIn>
            <h1 className="pt-5 text-5xl font-semibold uppercase md:text-6xl text-MetallicBlue">
             {t('testimonials')}
            </h1>
          </Animate.FadeIn>

          <hr className="md:w-[5%] w-[20%] border-b-4  mt-2 md:mt-4 md:mb-14 mb-5 border-green" />

          {/* <Animate.FadeIn>
          <p className="text-xs text-center px-14 md:text-xl mb-9">
            What customers say about us and why you will enjoy our services!
          </p>
        </Animate.FadeIn> */}

          {/* <div className="absolute h-[25%] w-[32%] left-10 mt-10 z-10 backdrop-blur-sm">{}</div>
        <div className="absolute h-[25%] w-[32%] right-10 mt-10 z-10 backdrop-blur-sm">{}</div> */}

          <AliceCarousel
            className="fixed w-full h-full "
            mouseTracking={true}
            animationType={FadeIn}
            animationDuration={3500}
            infinite={true}
            autoPlay={true}
            paddingLeft={10}
            paddingRight={10}
            disableDotsControls
            disableButtonsControls
            fadeOutAnimation={true}
            autoHeight={true}
            responsive={{
              0: {
                items: 1,
              },
              1024: {
                items: 3,
                itemsFit: "contain",
              },
            }}
            items={list.map((listItem) => (
              <div className="m-2 mb-10 text-6xl bg-white border-2 border-t-8 border-black border-opacity-25 rounded-lg shadow-lg h-60 md:mb-0 md:h-60 border-t-green/80">
                <div className="">
                  <article class=" h-full w-full">
                    <div className="mb-5 h-28">
                      <p 
                       
                       className={`${ one?
                         " text-[#3f3f3f] text-base h-fit md:text-xs  px-4 py-3 pt-5 md:px-7  md:py-4 font-semibold leading-tight p-2 text-justify bg-"
                       : TwoFiveZeroPercent? 
                       "text-[#3f3f3f] text-base h-fit md:text-xs  px-4 py-3 pt-5 md:px-7  md:py-4 font-semibold leading-tight p-2 text-justify bg- "
                       : TwoZeroZeroPercent? 
                       " text-[#3f3f3f] text-base h-fit md:text-xs  px-4 py-3 pt-5 md:px-7  md:py-4 font-semibold leading-tight p-2 text-justify bg- "
                       : OneSevenFivePercent? 
                       " text-[#3f3f3f] text-base h-fit md:text-xs  px-4 py-3 pt-5 md:px-7  md:py-4 font-semibold leading-tight p-2 text-justify bg- "
                       : OneFivePercent? 
                       "  text-[#3f3f3f] text-base h-fit md:text-sm  px-4 py-3 pt-5 md:px-7  md:py-4 font-semibold leading-tight p-2 text-justify bg-"
                       : OneTwoFivePercent? 
                       " text-[#3f3f3f] text-base h-fit md:text-base  px-4 py-3 pt-5 md:px-7  md:py-4 font-semibold leading-tight p-2 text-justify bg-"
                       : OneOneTenPercent? 
                       "text-[#3f3f3f] text-base h-fit md:text-base  px-4 py-3 pt-5 md:px-7  md:py-4 font-semibold leading-tight p-2 text-justify  bg-":
                       
                        "text-[#3f3f3f] text-base h-fit md:text-base  px-4 py-3 pt-5 md:px-7  md:py-4 font-semibold leading-tight p-2 text-justify bg-" }
                     
                       }`} 
                                                                 
                       >
                        { i18next.language === "en" && listItem.description}
                        {i18next.language === "amh" && listItem.description_amh}
                      </p>
                    </div>
                    <header class="flex flex-col justify-start items-start p-1 mb-10 px-4 md:px-7 h-full w-full md:mt-0 mt-2">
                      <h1 class="md:text-xl text-base text-darkblue font-bold  w-full">
                      { i18next.language === "en" && listItem.client_name}
                        {i18next.language === "amh" && listItem.clientname_amh}
                      </h1>

                      <h4 className="text-[#7e7e7e] font-medium md:text-xl  text-sm h-full w-full">
                      { i18next.language === "en" && listItem.address}
                        {i18next.language === "amh" && listItem.address_amh}
                      </h4>
                    </header>
                  </article>
                </div>
              </div>
            ))}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default Testimonials;
