import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { getFaq } from "../../../../redux/selectors";

import TopBar from "../../../../components/navbar/navbar";
import Sidebar from "../../../../components/sidebar/sidebar";
import Hero from "./hero";

const HeroLandingPage = ({ citizen, title, index }) => {
  const list = useSelector(getFaq);
  const [item, setItem] = useState();
  
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  useEffect(() => {
    list.map((listItem) => {
      if (parseInt(listItem.number) === index) {
        setItem(listItem);
      }
      console.log(parseInt(listItem.number) == index);
    });
  }, []);

  return (
    <>
      {item && (
        <>
          {isDesktopOrLaptop ? (
            <TopBar viewState={citizen ? "citizen" : "stakeholder"} />
          ) : (
            <Sidebar />
          )}
          <Hero citizen={citizen} title={title} faqs={item} />
        </>
      )}
    </>
  );
};

export default HeroLandingPage;
