import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import i18next from "i18next";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getFaq } from "../../../../redux/selectors";

function parseTextEditor(text) {
    const lines = text.split("\n");

    let result = [];

    for (let i = 0; i < lines.length; i++) {
        let line = lines[i].trim();

        if (line.startsWith("-")) {
            result.push({ type: "bullet", content: line.substring(1).trim() });
        } else {
            result.push({ type: "text", content: line });
        }
    }

    return result;
}

function TextEditor({ text }) {
    const lines = parseTextEditor(text);

    return (
        <div>
            {lines.map((line, index) => {
                if (line.type === "bullet") {
                    return <li key={index}>{line.content}</li>;
                } else {
                    return <p key={index}>{line.content}</p>;
                }
            })}
        </div>
    );
}

const BodyFaqs = ({ index }) => {
    const list = useSelector(getFaq);
    const { t, i18n } = useTranslation();
    const [item, setItem] = useState();
    const faqs = list[index];
    useEffect(() => {
        list.map((listItem) => {
            if (parseInt(listItem.number) === index) {
                setItem(listItem);
            }
            console.log(parseInt(listItem.number) == index);
        });
    }, []);

    return (
        <>
            {item && (
                <div className='flex flex-col  '>
                    <div className='flex flex-col items-center pt-10 pb-16 text-3xl px-5 '>
                        <div className='flex w-full '>
                            <div className='w-[35%] flex items-start py-1 justify-center text-white bg-MetallicBlue'>
                                {" "}
                                {t("faq-answer")}
                            </div>

                            <div className='w-[85%] flex items-center pl-5 border-b-2 text-darkblue border-MetallicBlue'>
                                {" "}
                            </div>
                        </div>

                        <div className='self-start w-full mt-7'>
                            <p className='w-full text-xl font-semibold text-MetallicBlue'>
                                {/* <TextEditor>
              text=
              {i18next.language === "en"
                ? faqs.answer
                : i18next.language === "amh"
                ? faqs.answer_amh
                : ""}
            </TextEditor> */}
                                <div>
                                    {i18n.language === "en"
                                        ? parse(item.answer)
                                        : parse(item.answer_amh)}
                                </div>
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default BodyFaqs;
