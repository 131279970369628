import React from "react";
import { useMediaQuery } from "react-responsive";
import { Carousel } from "react-responsive-carousel";
import TextTruncate from "react-text-truncate";
import i18next from "i18next";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getEvents } from "../../../../redux/selectors";
import { useTranslation } from "react-i18next";

function isHTML(str) {
    const htmlRegex = /<[a-z][\s\S]*>/i;
    return htmlRegex.test(str);
}

function TruncatedHTML(prop) {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(prop, "text/html");
    const paragraphs = htmlDoc.querySelectorAll("p");

    // Get the first <p> element from the DOM tree

    const secondParagraphText = paragraphs[0]?.textContent.trim();
    // Get the text content of the first <p> element

    // Output the text content of the first <p> element
    return secondParagraphText;
}

const Allevents = ({ type }) => {
    const list = useSelector(getEvents);
    const groupSize = 3; // Number of items in each group
    const { t } = useTranslation();
    // Divide the list into groups of size groupSize
    const groupedItems = [];
    for (let i = 0; i < list.length; i += groupSize) {
        groupedItems.push(list.slice(i, i + groupSize));
    }
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    return (
        <>
            {list.length > 0 ? (
                <>
                    {isDesktopOrLaptop ? (
                        <div className='flex flex-col mx-28'>
                            <div className='z-10 text-lg font-medium left-16  '>
                                <button className='px-16 text-white rounded-sm shadow-2xl bg-button-gradient'>
                                    <h1>{t("media-events")}</h1>
                                </button>
                                <hr className='w-[100%] border-MetallicBlue border ' />
                            </div>

                            <div className='flex flex-col w-[100%] text-white  font-extralight text-2xl  px-0'>
                                <Carousel
                                    axis='horizontal'
                                    className=''
                                    showArrows={true}
                                    showStatus={false}
                                    showThumbs={false}
                                    swipeable={true}
                                    // autoPlay={true}

                                    infiniteLoop={true}
                                    interval={2500}
                                    swipeScrollTolerance={80}
                                    // thumbWidth={90}
                                    transitionTime={1000}
                                    renderIndicator={false}
                                    renderArrowPrev={(
                                        clickHandler,
                                        hasPrev
                                    ) => {
                                        return (
                                            <div
                                                className={`${
                                                    hasPrev
                                                        ? "absolute "
                                                        : "hidden"
                                                } top-0 bg- bottom-32 flex justify-center pl-10 items-center p-10  cursor-pointer z-20   h-full`}
                                                onClick={clickHandler}
                                            >
                                                <svg
                                                    fill='#000000'
                                                    height='80px'
                                                    width='80px'
                                                    // xmlns="http://www.w3.org/2000/svg"
                                                    viewBox='100 0 900 800'
                                                    xmlSpace='preserve'
                                                    stroke='#fff'
                                                    strokeWidth={0.00511641}
                                                    className=''
                                                >
                                                    <path
                                                        className='cls-arrow'
                                                        d='M148.32 255.76L386.08 18c4.053-4.267 3.947-10.987-.213-15.04a10.763 10.763 0 00-14.827 0L125.707 248.293a10.623 10.623 0 000 15.04L371.04 508.667c4.267 4.053 10.987 3.947 15.04-.213a10.763 10.763 0 000-14.827L148.32 255.76z'
                                                    />
                                                </svg>
                                            </div>
                                        );
                                    }}
                                    renderArrowNext={(
                                        clickHandler,
                                        hasNext
                                    ) => {
                                        return (
                                            <div
                                                className={`${
                                                    hasNext
                                                        ? "absolute"
                                                        : "hidden"
                                                } top-0   right-0 flex justify-center items-center p-10  cursor-pointer z-20 pr-10 h-full`}
                                                onClick={clickHandler}
                                            >
                                                <svg
                                                    fill='#000000'
                                                    height='80px'
                                                    width='70px'
                                                    // xmlns="http://www.w3.org/2000/svg"
                                                    viewBox='100 0 700 250'
                                                    xmlSpace='preserve'
                                                    transform='rotate(180)'
                                                    stroke='#FFF'
                                                    className=''
                                                >
                                                    <path d='M148.32 255.76L386.08 18c4.053-4.267 3.947-10.987-.213-15.04a10.763 10.763 0 00-14.827 0L125.707 248.293a10.623 10.623 0 000 15.04L371.04 508.667c4.267 4.053 10.987 3.947 15.04-.213a10.763 10.763 0 000-14.827L148.32 255.76z' />
                                                </svg>
                                            </div>
                                        );
                                    }}
                                >
                                    {groupedItems.map((group, groupIndex) => (
                                        <div
                                            className='flex w-full h-full grid-cols-2 gap-16 p-10 px-[12%] text-left text-black'
                                            key={groupIndex}
                                        >
                                            {group.map((listItem, index) =>
                                                (type === "All") |
                                                (type === listItem.type) ? (
                                                    <div className='flex flex-col w-full'>
                                                        <div className=''>
                                                            <img
                                                                src={
                                                                    process.env
                                                                        .REACT_APP_BASE_URL +
                                                                    listItem.image
                                                                }
                                                                onError={(
                                                                    e
                                                                ) => {
                                                                    e.target.src =
                                                                        "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3C/svg%3E";
                                                                }}
                                                                alt=''
                                                                className='items-start text-sm text-left h-60 rounded-t-xl bg-cover bg-webinar bg-center'
                                                            />
                                                        </div>

                                                        <div className='flex flex-col'>
                                                            <div className='w-full h-full px-3 pt-2'>
                                                                <h2 className='flex mb-2 text-xl font-bold leading-tight text-left'>
                                                                    <TextTruncate
                                                                        line={3}
                                                                        element='span'
                                                                        truncateText='…'
                                                                        text={
                                                                            listItem.title
                                                                        }
                                                                        textTruncateChild={
                                                                            <Link
                                                                                to={`/updates/${
                                                                                    listItem.type
                                                                                }/${listItem.title.replace(
                                                                                    / /g,
                                                                                    "-"
                                                                                )}`}
                                                                                state={{
                                                                                    news: listItem,
                                                                                }}
                                                                                className='font-bold text-MetallicBlue '
                                                                            >
                                                                                {" "}
                                                                                ...{" "}
                                                                            </Link>
                                                                        }
                                                                    />
                                                                </h2>
                                                            </div>

                                                            <div className='px-3 leading-tight'>
                                                                <p className='text-sm font-medium leading-tight text-left'>
                                                                    <TextTruncate
                                                                        line={
                                                                            2.8
                                                                        }
                                                                        element='span'
                                                                        truncateText='…'
                                                                        text={
                                                                            isHTML(
                                                                                (i18next.language ===
                                                                                    "en" &&
                                                                                    listItem.description) ||
                                                                                    (i18next.language ===
                                                                                        "amh" &&
                                                                                        listItem.description_amh)
                                                                            )
                                                                                ? TruncatedHTML(
                                                                                      (i18next.language ===
                                                                                          "en" &&
                                                                                          listItem.description) ||
                                                                                          (i18next.language ===
                                                                                              "amh" &&
                                                                                              listItem.description_amh)
                                                                                  )
                                                                                : (i18next.language ===
                                                                                      "en" &&
                                                                                      listItem.description) ||
                                                                                  (i18next.language ===
                                                                                      "amh" &&
                                                                                      listItem.description_amh)
                                                                        }
                                                                        textTruncateChild={
                                                                            <Link
                                                                                to={`/updates/${
                                                                                    listItem.type
                                                                                }/${listItem.title.replace(
                                                                                    / /g,
                                                                                    "-"
                                                                                )}`}
                                                                                state={{
                                                                                    news: listItem,
                                                                                }}
                                                                                className='font-bold text-MetallicBlue italic '
                                                                            >
                                                                                {" "}
                                                                                Read
                                                                                More
                                                                                ...{" "}
                                                                            </Link>
                                                                        }
                                                                    />
                                                                </p>
                                                            </div>

                                                            <div className='absolute bottom-0 px-3'>
                                                                <h1 className='my-2 text-lg font-normal'>
                                                                    {
                                                                        listItem.date
                                                                    }
                                                                </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            )}
                                        </div>
                                    ))}
                                </Carousel>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className='flex px-6 pb-8 text-xl bg-white '>
                                <div className='w-[34%] flex items-center justify-center text-white bg-MetallicBlue'>
                                    {" "}
                                    <h1 className='py-1 text-xl'>Events</h1>
                                </div>

                                <div className='w-[70%] flex items-center pl-5 border-b-2 text-darkblue border-MetallicBlue'>
                                    {" "}
                                </div>
                            </div>

                            <div class=' '>
                                <div className='flex flex-col items-center pt-5 text-lg text-white '>
                                    <Carousel
                                        axis='horizontal'
                                        className='w-full '
                                        showArrows={true}
                                        showStatus={false}
                                        swipeable={true}
                                        // autoPlay={true}
                                        infiniteLoop={true}
                                        interval={2500}
                                        swipeScrollTolerance={80}
                                        // thumbWidth={90}
                                        transitionTime={1000}
                                        renderIndicator={false}
                                        renderArrowPrev={(
                                            clickHandler,
                                            hasPrev
                                        ) => {
                                            return (
                                                <div
                                                    className={`${
                                                        hasPrev
                                                            ? "absolute "
                                                            : "hidden"
                                                    } top-0 left-0  flex h-full   bg-white   justify-center items-center  opacity-40  hover:opacity-100 cursor-pointer z-20   `}
                                                    onClick={clickHandler}
                                                >
                                                    <svg
                                                        fill='#000000'
                                                        height='60px'
                                                        width='60px'
                                                        viewBox='0 0 500 800'
                                                        xmlSpace='preserve'
                                                        stroke='#fff'
                                                        strokeWidth={0.00511641}
                                                        className=''
                                                    >
                                                        <path
                                                            className='cls-arrow'
                                                            d='M148.32 255.76L386.08 18c4.053-4.267 3.947-10.987-.213-15.04a10.763 10.763 0 00-14.827 0L125.707 248.293a10.623 10.623 0 000 15.04L371.04 508.667c4.267 4.053 10.987 3.947 15.04-.213a10.763 10.763 0 000-14.827L148.32 255.76z'
                                                        />
                                                    </svg>
                                                </div>
                                            );
                                        }}
                                        renderArrowNext={(
                                            clickHandler,
                                            hasNext
                                        ) => {
                                            return (
                                                <div
                                                    className={`${
                                                        hasNext
                                                            ? "absolute"
                                                            : "hidden"
                                                    } top-0  right-0  bg-white h-full grid  justify-center items-center   opacity-40 hover:opacity-100 cursor-pointer z-20 `}
                                                    onClick={clickHandler}
                                                >
                                                    <svg
                                                        fill='#000000'
                                                        height='60px'
                                                        width='60px'
                                                        viewBox='0 0 700 290'
                                                        xmlSpace='preserve'
                                                        transform='rotate(180)'
                                                        stroke='#FFF'
                                                        className=''
                                                    >
                                                        <path d='M148.32 255.76L386.08 18c4.053-4.267 3.947-10.987-.213-15.04a10.763 10.763 0 00-14.827 0L125.707 248.293a10.623 10.623 0 000 15.04L371.04 508.667c4.267 4.053 10.987 3.947 15.04-.213a10.763 10.763 0 000-14.827L148.32 255.76z' />
                                                    </svg>
                                                </div>
                                            );
                                        }}
                                    >
                                        <div className=' w-full h-full flex grid-cols-2 gap-6 p-10 px-[20%] text-left text-black  '>
                                            {list
                                                .slice(0, 1)
                                                .map((listItem, key) =>
                                                    (type === "All") |
                                                    (type === listItem.type) ? (
                                                        <div className='flex flex-col w-full '>
                                                            {/* <div className="flex items-start object-cover h-48 px-5 text-sm text-left bg-center bg-cover bg-webinar rounded-t-xl "></div> */}
                                                            <div className=''>
                                                                <img
                                                                    src={
                                                                        i18next.language ===
                                                                        "amh"
                                                                            ? process
                                                                                  .env
                                                                                  .REACT_APP_BASE_URL +
                                                                              listItem.image_amh
                                                                            : process
                                                                                  .env
                                                                                  .REACT_APP_BASE_URL +
                                                                              listItem.image
                                                                    }
                                                                    onError={(
                                                                        e
                                                                    ) => {
                                                                        e.target.src =
                                                                            "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3C/svg%3E";
                                                                    }}
                                                                    alt=''
                                                                    className='items-start text-sm text-left h-60 rounded-t-xl object-cover bg-cover bg-webinar bg-center'
                                                                />
                                                            </div>
                                                            <div className='flex flex-col'>
                                                                {/* EVENT TITLE */}
                                                                <div className='w-full h-full  pt-2'>
                                                                    <h2 className='flex mb-2 text-sm font-bold leading-tight text-left'>
                                                                        {
                                                                            listItem.title
                                                                        }
                                                                    </h2>
                                                                </div>

                                                                {/* EVENT DESCRIPTION */}
                                                                <div className='leading-tight '>
                                                                    <p className='text-xs font-medium leading-tight text-left'>
                                                                        <TextTruncate
                                                                            line={
                                                                                4
                                                                            }
                                                                            element='span'
                                                                            truncateText='…'
                                                                            text={
                                                                                isHTML(
                                                                                    (i18next.language ===
                                                                                        "en" &&
                                                                                        listItem.description) ||
                                                                                        (i18next.language ===
                                                                                            "amh" &&
                                                                                            listItem.description_amh)
                                                                                )
                                                                                    ? TruncatedHTML(
                                                                                          (i18next.language ===
                                                                                              "en" &&
                                                                                              listItem.description) ||
                                                                                              (i18next.language ===
                                                                                                  "amh" &&
                                                                                                  listItem.description_amh)
                                                                                      )
                                                                                    : (i18next.language ===
                                                                                          "en" &&
                                                                                          listItem.description) ||
                                                                                      (i18next.language ===
                                                                                          "amh" &&
                                                                                          listItem.description_amh)
                                                                            }
                                                                            textTruncateChild={
                                                                                <Link
                                                                                    to={`/updates/${
                                                                                        listItem.type
                                                                                    }/${listItem.title.replace(
                                                                                        / /g,
                                                                                        "-"
                                                                                    )}`}
                                                                                    state={{
                                                                                        news: listItem,
                                                                                    }}
                                                                                    className='font-bold text-MetallicBlue italic'
                                                                                >
                                                                                    {" "}
                                                                                    Read
                                                                                    More
                                                                                    ...{" "}
                                                                                </Link>
                                                                            }
                                                                        />
                                                                    </p>
                                                                </div>

                                                                <div className='absolute bottom-0 px-3 text-center'>
                                                                    <h1 className='my-2 text-sm font-normal '>
                                                                        {
                                                                            listItem.date
                                                                        }
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                )}
                                        </div>

                                        <div className=' w-full h-full flex grid-cols-2 gap-6 p-10 px-[20%] text-left text-black  '>
                                            {list
                                                .slice(1, 2)
                                                .map((listItem, key) =>
                                                    (type === "All") |
                                                    (type === listItem.type) ? (
                                                        <div className='flex flex-col w-full '>
                                                            {/* <div className="flex items-start h-48 px-5 text-sm text-left bg-center bg-cover bg-webinar rounded-t-xl "></div> */}
                                                            <div className=''>
                                                                <img
                                                                    src={
                                                                        i18next.language ===
                                                                        "amh"
                                                                            ? process
                                                                                  .env
                                                                                  .REACT_APP_BASE_URL +
                                                                              listItem.image_amh
                                                                            : process
                                                                                  .env
                                                                                  .REACT_APP_BASE_URL +
                                                                              listItem.image
                                                                    }
                                                                    onError={(
                                                                        e
                                                                    ) => {
                                                                        e.target.src =
                                                                            "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3C/svg%3E";
                                                                    }}
                                                                    alt=''
                                                                    className='items-start text-sm text-left h-60 rounded-t-xl object-cover bg-cover bg-webinar bg-center'
                                                                />
                                                            </div>
                                                            <div className='flex flex-col '>
                                                                {/* EVENT TITLE */}
                                                                <div className='w-full h-full  pt-2'>
                                                                    <h2 className='flex mb-2 text-sm font-bold leading-tight text-left'>
                                                                        {
                                                                            listItem.title
                                                                        }
                                                                    </h2>
                                                                </div>

                                                                {/* EVENT DESCRIPTION */}
                                                                <div className=' leading-tight '>
                                                                    <p className='text-xs font-medium leading-tight text-left'>
                                                                        <TextTruncate
                                                                            line={
                                                                                4
                                                                            }
                                                                            element='span'
                                                                            truncateText='…'
                                                                            text={
                                                                                isHTML(
                                                                                    (i18next.language ===
                                                                                        "en" &&
                                                                                        listItem.description) ||
                                                                                        (i18next.language ===
                                                                                            "amh" &&
                                                                                            listItem.description_amh)
                                                                                )
                                                                                    ? TruncatedHTML(
                                                                                          (i18next.language ===
                                                                                              "en" &&
                                                                                              listItem.description) ||
                                                                                              (i18next.language ===
                                                                                                  "amh" &&
                                                                                                  listItem.description_amh)
                                                                                      )
                                                                                    : (i18next.language ===
                                                                                          "en" &&
                                                                                          listItem.description) ||
                                                                                      (i18next.language ===
                                                                                          "amh" &&
                                                                                          listItem.description_amh)
                                                                            }
                                                                            textTruncateChild={
                                                                                <Link
                                                                                    to={`/updates/${
                                                                                        listItem.type
                                                                                    }/${listItem.title.replace(
                                                                                        / /g,
                                                                                        "-"
                                                                                    )}`}
                                                                                    state={{
                                                                                        news: listItem,
                                                                                    }}
                                                                                    className='font-bold text-MetallicBlue italic '
                                                                                >
                                                                                    {" "}
                                                                                    Read
                                                                                    More
                                                                                    ...{" "}
                                                                                </Link>
                                                                            }
                                                                        />
                                                                    </p>
                                                                </div>

                                                                <div className='absolute bottom-0 px-3 text-center'>
                                                                    <h1 className='my-2 text-sm font-normal '>
                                                                        {
                                                                            listItem.date
                                                                        }
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                )}
                                        </div>

                                        <div className=' w-full h-full flex grid-cols-2 gap-6 p-10 px-[20%] text-left text-black '>
                                            {list
                                                .slice(2, 3)
                                                .map((listItem, key) =>
                                                    (type === "All") |
                                                    (type === listItem.type) ? (
                                                        <div className='flex flex-col w-full '>
                                                            {/* <div className="flex items-start h-48 px-5 text-sm text-left bg-center bg-cover bg-webinar rounded-t-xl "></div> */}
                                                            <div className=''>
                                                                <img
                                                                    src={
                                                                        i18next.language ===
                                                                        "amh"
                                                                            ? process
                                                                                  .env
                                                                                  .REACT_APP_BASE_URL +
                                                                              listItem.image_amh
                                                                            : process
                                                                                  .env
                                                                                  .REACT_APP_BASE_URL +
                                                                              listItem.image
                                                                    }
                                                                    onError={(
                                                                        e
                                                                    ) => {
                                                                        e.target.src =
                                                                            "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3C/svg%3E";
                                                                    }}
                                                                    alt=''
                                                                    className='items-start text-sm text-left h-60 rounded-t-xl object-cover bg-cover bg-webinar bg-center'
                                                                />
                                                            </div>

                                                            <div className='flex flex-col '>
                                                                {/* EVENT TITLE */}
                                                                <div className='w-full h-full  pt-2'>
                                                                    <h2 className='flex mb-2 text-sm font-bold leading-tight text-left'>
                                                                        {
                                                                            listItem.title
                                                                        }
                                                                    </h2>
                                                                </div>

                                                                {/* EVENT DESCRIPTION */}
                                                                <div className=' leading-tight '>
                                                                    <p className='text-xs font-medium leading-tight text-left'>
                                                                        <TextTruncate
                                                                            line={
                                                                                4
                                                                            }
                                                                            element='span'
                                                                            truncateText='…'
                                                                            text={
                                                                                isHTML(
                                                                                    (i18next.language ===
                                                                                        "en" &&
                                                                                        listItem.description) ||
                                                                                        (i18next.language ===
                                                                                            "amh" &&
                                                                                            listItem.description_amh)
                                                                                )
                                                                                    ? TruncatedHTML(
                                                                                          (i18next.language ===
                                                                                              "en" &&
                                                                                              listItem.description) ||
                                                                                              (i18next.language ===
                                                                                                  "amh" &&
                                                                                                  listItem.description_amh)
                                                                                      )
                                                                                    : (i18next.language ===
                                                                                          "en" &&
                                                                                          listItem.description) ||
                                                                                      (i18next.language ===
                                                                                          "amh" &&
                                                                                          listItem.description_amh)
                                                                            }
                                                                            textTruncateChild={
                                                                                <Link
                                                                                    to={`/updates/${
                                                                                        listItem.type
                                                                                    }/${listItem.title.replace(
                                                                                        / /g,
                                                                                        "-"
                                                                                    )}`}
                                                                                    state={{
                                                                                        news: listItem,
                                                                                    }}
                                                                                    className='font-bold text-MetallicBlue italic '
                                                                                >
                                                                                    {" "}
                                                                                    Read
                                                                                    More
                                                                                    ...{" "}
                                                                                </Link>
                                                                            }
                                                                        />
                                                                    </p>
                                                                </div>

                                                                <div className='absolute bottom-0 px-3 text-center'>
                                                                    <h1 className='my-2 text-sm font-normal '>
                                                                        {
                                                                            listItem.date
                                                                        }
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                )}
                                        </div>
                                    </Carousel>
                                </div>
                            </div>
                        </>
                    )}
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default Allevents;
