import React, { useEffect } from "react";
import SmallFooter from "../../components/footer/smallFooter";
import HeroLandingPage from "./components/hero/heroLandingPage";
import PrinciplesContainer from "./components/body/principlesContainer";
import Footer from "../../components/footer/footer";
import { useMediaQuery } from "react-responsive";

const Principles = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  return (
    <>
      <HeroLandingPage />
      <PrinciplesContainer  />
      {isDesktopOrLaptop ? <SmallFooter /> : <Footer />}
    </>
  );
};

export default Principles;
