import axios from "axios";

export const GET_RESOURCES_LIST = "GET_RESOURCES_LIST";

// export const ADD_ABOUT = "ADD_ABOUT";
// export const DELETE_ABOUT = "DELETE_ABOUT";
// export const UPDATE_ABOUT = "UPDATE_ABOUT";


const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Requested-With": "nid-website",
};

export const getResourcesList = () => async (dispatch) => {
  // const cachedList = localStorage.getItem("resourcesList");
  // if (cachedList) {
  //   dispatch({
  //     type: GET_RESOURCES_LIST,
  //     payload: JSON.parse(cachedList),
  //   });
  // } else {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL + `/Resources/Categories`,
      {
        headers: headers,
      }
    );

    if (response && response.data) {
      dispatch({
        type: GET_RESOURCES_LIST,
        payload: response.data,
      });
      localStorage.setItem("resourcesList", JSON.stringify(response.data));
    } else {
      const cachedResourcesList = localStorage.getItem("resourcesList");
      if (cachedResourcesList) {
        dispatch({
          type: GET_RESOURCES_LIST,
          payload: JSON.parse(cachedResourcesList),
        });
      }
    }
  } catch (err) {
    const cachedResourcesList = localStorage.getItem("resourcesList");
    if (cachedResourcesList) {
      dispatch({
        type: GET_RESOURCES_LIST,
        payload: JSON.parse(cachedResourcesList),
      });
    }
  }
};