import React from "react";
import { useTranslation } from "react-i18next";
import Icon from "../../../../assets/question-mark.png";
import { useMediaQuery } from "react-responsive";

const Helpful = () => {
    const { t } = useTranslation();
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    return (
        <>
            <div className='flex flex-row sm:flex-row items-center w-full bg-[#f6db9f] px-8 py-4 sm:px-28 sm:py-6'>
                <img
                    src={Icon}
                    className='h-10 place-self-start mt-2'
                    alt='fireSpot'
                />

                <div className='flex flex-col lg:flex-row justify-center w-full md:flex-row items-left gap-5'>
                    <div className='flex pl-3 mb-4 flex-col min-w-[20%] lg:min-w-[15%]'>
                        <h3 className='md:pl-3 md:pr-2 text-3xl text-left opacity-50  sm:text-4xl'>
                            {t("faq-helpful-title1")}
                        </h3>
                        <h3 className='md:pl-3 text-3xl font-bold text-left  sm:text-4xl sm:w-auto'>
                            {t("faq-helpful-title2")}
                        </h3>
                    </div>

                    {isDesktopOrLaptop && (
                        <div className='w-0.5 h-16 bg-black/20 mx-0 sm:mx-10'></div>
                    )}

                    <div className='flex flex-col items-center w-full'>
                        <p className='w-full pl-3 text-lg font-light leading-tight text-justify  sm:text-2xl sm:w-auto'>
                            {t("faq-helpful-text")}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Helpful;
