import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import HeroLandingPage from "../resources/components/hero/heroLandingPage";
import FooterService from "../../components/footer/footer";
import Sidebar from "./components/sidebar/sidebar";

import { getResources } from "../../redux/selectors";
import { ViewContext } from "../../providers/viewContext";
import { getResourcesList } from "../../redux/actions";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";

const Resources = ({ title }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [citizen, setCitizen] = useState(true);
    const { view, setView } = useContext(ViewContext);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        // const timer = setTimeout(() => {
        //   setIsLoading(false);
        // }, 10000);

        // Group the dispatch calls based on their functionality
        const dataDispatches = [dispatch(getResourcesList())];

        // Execute the dispatch calls in parallel
        Promise.all(dataDispatches)
            .then(() => {
                setIsLoading(false);
                // Do something after all the dispatch calls have completed
            })
            .catch((error) => {
                // Handle any errors that occur during the dispatch calls
            });

        // return () => {
        //   clearTimeout(timer);
        // };
    }, [dispatch]);
    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <HeroLandingPage citizen={citizen} />
                    <Sidebar title={title} citizen={view} />
                    <FooterService />
                </>
            )}
        </>
    );
};
export default Resources;
