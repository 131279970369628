import React, { useEffect, useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useMediaQuery } from "react-responsive";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import youtubeIcon from "../../assets/youtube-icon.png";
import tiktokLogo from "../../assets/tiktokLogo.png";
import Hotline from "../../assets/hotline.png";
import telegramIcon from "../../assets/telegram-icon.png";
import { useTranslation } from "react-i18next";
import { AiOutlineMail } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { send } from "emailjs-com";
import TwitterBox from "../twitterBox";
import xlogo from "../../assets/xlogo.png";
const Footer = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    const IsMobile = useMediaQuery({
        query: "(min-width: 600px)",
    });
    const [toSend, setToSend] = useState({
        name: "",
        email: "",
        comments: "",
    });
    const [emailSent, setEmailSent] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();

        // axios.post('http://localhost:5000/send-email', {from,to,subject,message})
        //   .then(response => {
        //     console.log(response.data);
        //   })
        //   .catch(error => {
        //     console.log(error);
        //   });
        send("service_m23zees", "template_95bm4jn", toSend, "Ul0geNHTaIto046vz")
            .then((response) => {
                setEmailSent(true);
            })
            .catch((err) => {});
    };
    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };

    const handleDownloadClick = () => {
        // Construct the URL to the static file
        const staticBaseUrl = window.location.origin + "/static/";
        const fileName = "logos.zip";
        const fileUrl = `${staticBaseUrl}${fileName}`;

        // Trigger the download
        window.location.href = fileUrl;
    };

    return (
        <>
            {isDesktopOrLaptop ? (
                <footer id='contact' class='bg-footer bg-center bg-cover '>
                    <div class='grid grid-cols-1 gap-16 md:pl-20  py-8 md:grid-cols-2 '>
                        <div className='p-6'>
                            {/****************************************** title ,  Social Media Icons wrapper****************************************************/}
                            <div
                                className={
                                    isDesktopOrLaptop
                                        ? "flex "
                                        : "flex flex-col "
                                }
                            >
                                {/* *****************************************  Title and Description *************************************************** */}
                                <div className='text-center md:text-start '>
                                    <h2 class='text-4xl lg:text-5xl md:text-5xl  text-white dark:text-white'>
                                        <span className='text-4xl font-extrabold lg:text-6xl md:text-6xl text-green dark:text-green'>
                                            {t("footer-connect-with-us")}
                                        </span>{" "}
                                    </h2>
                                    <p class='flex px-10 md:px-0  md:justify-start  text-xl  text-white'>
                                        {t("footer-questions")}
                                    </p>
                                </div>

                                {/******************************************  Social Media Icons ****************************************************/}
                                <div class='flex md:mt-10 mt-6 ml-0 md:ml-8 justify-center gap-3 '>
                                    <a
                                        href='https://www.facebook.com/IDethiopia/?ref=pages_you_manage'
                                        target='_blank'
                                        className='flex text-white justify-center border-2 border-white rounded-full w-9 h-9 place-items-center hover:border-none hover:bg-green hover:scale-105 '
                                    >
                                        <svg
                                            aria-hidden='true'
                                            focusable='false'
                                            data-prefix='fab'
                                            data-icon='facebook-f'
                                            class='w-3 '
                                            role='img'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 320 512'
                                        >
                                            <path
                                                fill='currentColor'
                                                d='M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z'
                                            ></path>
                                        </svg>
                                    </a>

                                    <a
                                        href='https://twitter.com/IDethiopia'
                                        target='_blank'
                                        className='flex text-white justify-center border-2 border-white rounded-full w-9 h-9 place-items-center hover:border-none hover:bg-green hover:scale-105'
                                    >
                                        <img
                                            src={xlogo}
                                            className='w-5 pt-0'
                                            alt='fireSpot'
                                        />
                                    </a>

                                    <a
                                        href='https://www.youtube.com/channel/UCf3SnLcgf5jzxhkeMTf2fRA'
                                        target='_blank'
                                        className='flex text-white justify-center border-2 border-white rounded-full w-9 h-9 place-items-center hover:border-none hover:bg-green hover:scale-105'
                                    >
                                        <img
                                            src={youtubeIcon}
                                            className='w-5 pt-0 text-white'
                                            alt='fireSpot'
                                        />
                                    </a>

                                    <a
                                        href='https://www.linkedin.com/company/idethiopia'
                                        target='_blank'
                                        className='flex text-white justify-center border-2 border-white rounded-full w-9 h-9 place-items-center hover:border-none hover:bg-green hover:scale-105'
                                    >
                                        <svg
                                            aria-hidden='true'
                                            focusable='false'
                                            data-prefix='fab'
                                            data-icon='linkedin-in'
                                            class='w-5'
                                            role='img'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 448 512'
                                        >
                                            <path
                                                fill='currentColor'
                                                d='M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z'
                                            ></path>
                                        </svg>
                                    </a>

                                    <a
                                        href='https://t.me/IDethiopia'
                                        target='_blank'
                                        className='flex text-white justify-center border-2 border-white rounded-full w-9 h-9 place-items-center hover:border-none hover:bg-green hover:scale-105'
                                    >
                                        <img
                                            src={telegramIcon}
                                            className='w-5'
                                            alt='fireSpot'
                                        />
                                    </a>
                                    <a
                                        target='_blank'
                                        href='https://www.tiktok.com/@idethiopia'
                                        className='flex text-white justify-center border-2 border-white rounded-full w-9 h-9 place-items-center hover:border-none hover:bg-green hover:scale-105'
                                    >
                                        <img
                                            src={tiktokLogo}
                                            className='w-5'
                                            alt='fireSpot'
                                        />
                                    </a>
                                </div>
                            </div>
                            {/****************************************** Contact Form ****************************************************/}
                            <div className='mt-6 '>
                                <form onSubmit={onSubmit}>
                                    <div className='flex gap-5 '>
                                        <div className='w-2/4 '>
                                            <label class='text-base  font-normal text-white'>
                                                {t("footer-name")}
                                            </label>
                                            <input
                                                type='text'
                                                name='name'
                                                class='block p-1 px-3 w-full md:placeholder:text-left text-base text-black shadow-md  bg-white focus:ring-blue-500 '
                                                value={toSend.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className='w-2/4'>
                                            <label class=' text-base font-normal text-white '>
                                                {t("footer-email")}
                                            </label>
                                            <input
                                                type='email'
                                                name='email'
                                                class=' block w-full p-1  px-3 md:placeholder:text-left text-base text-black shadow-md  bg-white focus:ring-blue-500'
                                                value={toSend.email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div class='mt-4'>
                                        <label class='text-base font-medium text-white'>
                                            {t("footer-comments")}
                                        </label>

                                        <textarea
                                            type='text'
                                            name='comments'
                                            rows='2'
                                            class='mt-2 p-4 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none bg-white resize-none'
                                            value={toSend.comments}
                                            onChange={handleChange}
                                            required
                                        ></textarea>
                                    </div>
                                    <button
                                        type='submit'
                                        class='inline-block mt-2 px-2 py-2 rounded-sm  w-[50%] lg:w-[20%] bg-[#21cfac] text-white font-normal text-md leading-tight  rounded-base shadow-md hover:bg-[#083247]  hover:shadow-lg focus:bg-[#3a6c7d] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#304f55] active:shadow-lg transition duration-150 ease-in-out'
                                    >
                                        {t("footer-send-message")}
                                    </button>
                                </form>
                                {emailSent && (
                                    <div className='fixed z-50 inset-0 overflow-y-auto'>
                                        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                                            {/* background overlay */}
                                            <div className='fixed inset-0 transition-opacity'>
                                                <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
                                            </div>

                                            {/* popup */}
                                            <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
                                                <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                                                    <div className='sm:flex sm:items-start'>
                                                        <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10'>
                                                            <svg
                                                                className='h-6 w-6 text-green-600'
                                                                xmlns='http://www.w3.org/2000/svg'
                                                                fill='none'
                                                                viewBox='0 0 24 24'
                                                                stroke='currentColor'
                                                                aria-hidden='true'
                                                            >
                                                                <path
                                                                    strokeLinecap='round'
                                                                    strokeLinejoin='round'
                                                                    strokeWidth={
                                                                        2
                                                                    }
                                                                    d='M5 13l4 4L19 7'
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                                                            <div className='mt-2'>
                                                                <p className='text-xl text-gray-500'>
                                                                    {t(
                                                                        "footer-notification-message"
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
                                                    <button
                                                        type='button'
                                                        className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium bg-green text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm'
                                                        onClick={() =>
                                                            setEmailSent(false)
                                                        }
                                                    >
                                                        {t(
                                                            "footer-notification-close"
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/******************************************  Contact information ****************************************************/}
                            <div className='flex flex-row w-full gap-5 mt-8 md:gap-0'>
                                <div class='flex justify-start items-start text-white text-xs md:text-sm w-full pe-2'>
                                    <LocationOnIcon />
                                    <a
                                        className='h-1 text-xs leading-tight md:font-medium md:ml-2 md:text-base hover:cursor-pointer'
                                        href='https://maps.app.goo.gl/pRcCGYWL2QQiQDKP7'
                                        target='_blank'
                                    >
                                        <p>{t("address")}</p>
                                        {/* <p>{t("address-cont")}</p> */}
                                    </a>
                                </div>

                                <div class='flex justify-start  text-white md:text-base text-xs font-normal w-full  '>
                                    <div className='flex flex-col gap-4'>
                                        <div className='flex items-center pt-1 md:pt-0'>
                                            <a
                                                href='mailto:info@id.et'
                                                className='w-4 mr-4 leading-none md:w-4'
                                            >
                                                {" "}
                                                <AiOutlineMail className='text-2xl' />
                                            </a>
                                            <p className='text-xs font-bold leading-none text-green md:text-base md:mr-2'>
                                                {" "}
                                                {t("email")}{" "}
                                            </p>
                                            <p className='pl-2 text-xs leading-none md:pl-0 md:text-base'>
                                                {" "}
                                                <a href='mailto:info@id.et'>
                                                    {t("Email")}{" "}
                                                </a>
                                            </p>
                                        </div>
                                        <div className='flex pt-1 md:pt-0'>
                                            <svg
                                                aria-hidden='true'
                                                focusable='false'
                                                data-prefix='fas'
                                                data-icon='phone'
                                                class='md:w-4 w-4  mr-4 leading-none '
                                                role='img'
                                                xmlns='http://www.w3.org/2000/svg'
                                                viewBox='0 0 512 512'
                                            >
                                                <path
                                                    fill='currentColor'
                                                    d='M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z'
                                                ></path>
                                            </svg>
                                            <p className='text-xs font-bold leading-none text-green md:text-base md:mr-2'>
                                                {" "}
                                                {t("call-us")}{" "}
                                            </p>
                                            <p className='pl-2 text-xs leading-none md:pl-0 md:text-base'>
                                                {" "}
                                                <a
                                                    className='hover:cursor-pointer'
                                                    href='tel:+251 113 720 006'
                                                >
                                                    {t("Phone")}{" "}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col w-full '>
                                    <p className='text-xl 2xl:text-2xl text-center text-white font-semibold'>
                                        <span className='font-bold text-green md:text-xl '>
                                            {t("hotline")}
                                        </span>
                                    </p>
                                    <a href='tel:9779'>
                                        <div className='flex mt-3 md:mt-3 xl:mt-5 xl:justify-center'>
                                            <img
                                                src={Hotline}
                                                className='h-[40px] 2xl:h-[50px]'
                                            />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/****************************************** Twitter Embed , Links , Copyright ****************************************************/}
                        <div className='ml-6 md:ml-10 md:mt-8  md:h-[75%] h-[75%] '>
                            <div className=' w-[100%]  h-[100%] md:w-[70%] md:h-[80%]  rounded-3xl md:flex hidden  '>
                                <div className=' w-[100%] h-[100%]  rounded-3xl '>
                                    {/* <TwitterTimelineEmbed
                    sourceType="url"
                    url="https://twitter.com/IDethiopia"
                    screenName="nationalID"
                    options={{ height: 250 }}
                  /> */}
                                    <TwitterBox />
                                </div>
                            </div>

                            <div class='text-center md:text-left mt-5'>
                                <h1 className='hidden pt-0 text-2xl font-bold md:mb-3 md:pt-10 text-green md:text-2xl md:flex'>
                                    {t("links")}
                                </h1>

                                <div className='flex flex-wrap justify-center w-full gap-0 mb-0 text-xs tracking-tighter text-left md:justify-start md:tracking-tight md:gap-1 md:text-base place-items-center'>
                                    <Link
                                        class='text-white font-medium hover:scale-105 hover:text-green'
                                        to='/help'
                                    >
                                        {t("help")}&nbsp;| &nbsp;
                                    </Link>
                                    <Link
                                        class='text-white font-medium hover:scale-105 hover:text-green'
                                        to='https://linktr.ee/NIDP?utm_source=qr_code'
                                    >
                                        {t("footer-socials")}&nbsp;| &nbsp;
                                    </Link>
                                    <Link
                                        class='text-white font-medium hover:scale-105 hover:text-green'
                                        to='/websitepolicies'
                                    >
                                        {t("website-policy")}&nbsp;| &nbsp;
                                    </Link>

                                    <Link
                                        class='text-white font-medium hover:scale-105 hover:text-green'
                                        to='/websiteterms'
                                    >
                                        {" "}
                                        {t("terms-conditions")}&nbsp;|&nbsp;
                                    </Link>

                                    <Link
                                        class='text-white font-medium hover:scale-105 hover:text-green'
                                        to='/websitecopyright'
                                    >
                                        {" "}
                                        {t("copyright-policy")}&nbsp;|&nbsp;
                                    </Link>
                                    <Link
                                        class='text-white font-medium hover:scale-105 hover:text-green'
                                        to='/career'
                                    >
                                        {" "}
                                        {/* Copyright Policy&nbsp;|&nbsp; */}{" "}
                                        {t("career-footer")} &nbsp;| &nbsp;
                                    </Link>
                                    <Link
                                        class='text-white font-medium hover:scale-105 hover:text-green'
                                        to='/cardprint'
                                    >
                                        {" "}
                                        {/* Copyright Policy&nbsp;|&nbsp; */}{" "}
                                        {t("print")}&nbsp;| &nbsp;
                                    </Link>
                                    {/* <a
                                        href='https://card-order.fayda.et/'
                                        class='text-white font-medium hover:scale-105 hover:text-green'
                                    >
                                        {t("print")}&nbsp;| &nbsp;
                                    </a>
                                    {/* <Link
                                        to='/cardprint'
                                        href='https://card-order.fayda.et/'
                                        class='text-white font-medium hover:scale-105 hover:text-green'
                                    >
                                        {t("card")}&nbsp;| &nbsp;
                                    </Link> */}
                                    <a
                                        class='text-white font-medium hover:scale-105 hover:text-green hover:cursor-pointer'
                                        onClick={handleDownloadClick}
                                        // download='logos.zip'
                                    >
                                        {t("logo-footer")}
                                    </a>
                                </div>
                                <span class='mt-0 text-xs font-medium  text-white dark:text-white md:text-base text-center place-items-center flex md:justify-start justify-center'>
                                    {new Date().getFullYear()}{" "}
                                    {t("national-id")} {t("all-rights")}
                                </span>
                            </div>
                        </div>
                    </div>
                </footer>
            ) : (
                <div className='flex flex-col w-full'>
                    <div className='pb-8 pt-5 backdrop-brightness-95'>
                        <div class='flex text-4xl text-MetallicBlue px-10'>
                            <h2 className='text-4xl font-bold pr-[3%]'>
                                {t("footer-connect-with-us")}
                            </h2>
                            {/* <h2 className="text-4xl font-light">
                {t("footer-connect-with-us-mobile")}
              </h2> */}
                        </div>

                        {/****************************************** Contact Form ****************************************************/}
                        <div className='flex flex-col px-10 mt-6 text-MetallicBlue'>
                            <form onSubmit={onSubmit}>
                                <div className='flex flex-col gap-5 '>
                                    <div className='w-full '>
                                        <label class='text-base font-normal'>
                                            {t("footer-name")}
                                        </label>
                                        <input
                                            type='text'
                                            name='name'
                                            class='flex p-1 px-3 w-full md:placeholder:text-left text-base text-black shadow-md  bg-white focus:ring-blue-500 rounded-sm border-MetallicBlue border'
                                            value={toSend.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>

                                    <div className='w-full'>
                                        <label class=' text-base font-normal '>
                                            {t("footer-email")}
                                        </label>
                                        <input
                                            type='email'
                                            name='email'
                                            class=' block w-full p-1  px-3 md:placeholder:text-left text-base text-black shadow-md  bg-white focus:ring-blue-500 rounded-sm border-MetallicBlue border'
                                            value={toSend.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div class='mt-4'>
                                    <label class='text-base font-medium'>
                                        {t("footer-comments")}
                                    </label>

                                    <textarea
                                        type='text'
                                        name='comments'
                                        value={toSend.comments}
                                        onChange={handleChange}
                                        rows='2'
                                        class='mt-2 p-4 w-full text-sm text-gray-900 focus:ring-0 focus:outline-none bg-white resize-none border-MetallicBlue border rounded-sm'
                                    ></textarea>
                                </div>
                                <button
                                    type='submit'
                                    class='inline-block mt-2 px-2 py-2 rounded-sm  w-[50%] lg:w-[20%] bg-[#21cfac] text-white font-normal text-md leading-tight  rounded-base shadow-md hover:bg-[#083247]  hover:shadow-lg focus:bg-[#3a6c7d] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#304f55] active:shadow-lg transition duration-150 ease-in-out'
                                >
                                    {t("footer-send-message")}
                                </button>
                            </form>
                            {emailSent && (
                                <div className='sticky bottom-0 z-50'>
                                    <div className='bg-white px-4 py-5 border-b border-gray-200 sm:px-6'>
                                        <div className='-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap'>
                                            <div className='mt-4 ml-4'>
                                                <div className='flex items-center'>
                                                    <div className='flex-shrink-0'>
                                                        <svg
                                                            className='h-8 w-8 text-green-600'
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            fill='none'
                                                            viewBox='0 0 24 24'
                                                            stroke='currentColor'
                                                            aria-hidden='true'
                                                        >
                                                            <path
                                                                strokeLinecap='round'
                                                                strokeLinejoin='round'
                                                                strokeWidth={2}
                                                                d='M5 13l4 4L19 7'
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className='ml-3'>
                                                        <div className='mt-2 text-sm text-gray-500'>
                                                            <p>
                                                                {t(
                                                                    "footer-notification-message"
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mt-4 pr-4  items-end flex-shrink-0 sm:mt-0 sm:ml-4 sm:flex-shrink-0'>
                                                <button
                                                    type='button'
                                                    className='w-full inline-flex justify-center i rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium bg-green text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm'
                                                    onClick={() =>
                                                        setEmailSent(false)
                                                    }
                                                >
                                                    {t(
                                                        "footer-notification-close"
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {/****************************************** Contact Form ****************************************************/}

                    <footer class='bg-footer bg-center bg-cover'>
                        <div class='grid grid-cols-1 pt-8 bg-mobile-footer-gradient'>
                            <div className='px-6 w-full'>
                                {/****************************************** title ,  Social Media Icons wrapper****************************************************/}
                                <div
                                    className={
                                        isDesktopOrLaptop
                                            ? "flex "
                                            : "flex flex-col "
                                    }
                                >
                                    {/******************************************  Title and Description ****************************************************/}
                                    <div className='text-start'>
                                        <h2 class=' text-white dark:text-white'>
                                            <a href='https://maps.app.goo.gl/pRcCGYWL2QQiQDKP7'>
                                                {" "}
                                                <span
                                                    className={`${
                                                        !IsMobile
                                                            ? "text-2xl"
                                                            : "text-4xl"
                                                    } font-medium text-darkgreen dark:text-darkgreen`}
                                                >
                                                    {!IsMobile
                                                        ? t(
                                                              "footer-address-mobile"
                                                          )
                                                        : t("footer-Address")}
                                                    <hr className='w-[16%] border-2 border-white' />
                                                    <hr className='bg-white border-white opacity-30' />
                                                </span>
                                            </a>
                                        </h2>
                                    </div>

                                    {/******************************************  Social Media Icons ****************************************************/}
                                </div>

                                {/****************************************** Contact Form ****************************************************/}

                                {/******************************************  Contact information ****************************************************/}
                                <div className='flex flex-col w-full gap-5 mt-4 lg:flex-col md:gap-8 mb-5'>
                                    <div class='flex justify-start items-start w-[100%] text-white text-xs md:text-sm md:w-[60%] mt-4 pe-2'>
                                        <LocationOnIcon />
                                        <a href='https://maps.app.goo.gl/pRcCGYWL2QQiQDKP7'>
                                            <span className='h-1 text-sm leading-tight md:font-medium md:ml-2 md:text-lg hover:cursor-pointer'>
                                                <p> {t("address")}</p>{" "}
                                                {/* <p>{t("address-cont")}</p>{" "} */}
                                            </span>
                                        </a>
                                    </div>

                                    <div class='flex items-center justify-start  text-white md:text-base text-xs font-normal w-[50%]  '>
                                        <a
                                            href='mailto:info@id.et'
                                            className='me-1 pr-1'
                                        >
                                            {" "}
                                            <AiOutlineMail className='text-2xl' />
                                        </a>
                                        <div className='flex items-center md:pt-0'>
                                            <p className='text-sm font-bold leading-none text-darkgreen'>
                                                {" "}
                                                {t("email")}
                                            </p>
                                            <p className='pl-2 text-xs leading-none'>
                                                <a href='mailto:info@id.et'>
                                                    {t("Email")}{" "}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    <div class='flex flex-row justify-start  text-white md:text-base text-xs font-normal w-[100%]  '>
                                        <svg
                                            aria-hidden='true'
                                            focusable='false'
                                            data-prefix='fas'
                                            data-icon='phone'
                                            class='md:w-4 w-4  mr-2 leading-none '
                                            role='img'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 512 512'
                                        >
                                            <path
                                                fill='currentColor'
                                                d='M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z'
                                            ></path>
                                        </svg>
                                        <div className='flex pt-1 md:pt-0'>
                                            <p className='text-xs font-bold leading-none text-darkgreen dark:text-darkgreen md:text-base md:mr-2'>
                                                {" "}
                                                {/* Call Us{" "} */}{" "}
                                                {t("call-us")}
                                            </p>
                                            <p className='pl-2 text-xs leading-none md:pl-0 md:text-base'>
                                                {/* +251111400787 */}
                                                <a
                                                    className='hover:cursor-pointer'
                                                    href='tel:+251 113 720 006'
                                                >
                                                    {t("Phone")}{" "}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='flex flex-col w-fit'>
                                        <p className='text-xl 2xl:text-2xl text-white font-semibold'>
                                            <span className='font-bold text-green md:text-xl '>
                                                {t("hotline")}
                                            </span>
                                        </p>
                                        <a href='tel:9779'>
                                            <div className='flex mt-3 md:mt-3 xl:mt-5 xl:justify-start'>
                                                <img
                                                    src={Hotline}
                                                    className='h-[40px] 2xl:h-[50px]'
                                                />
                                            </div>
                                        </a>
                                    </div>
                                    <div className=''>
                                        <h2 class=' text-white dark:text-white'>
                                            <span className='text-4xl font-medium text-darkgreen dark:text-darkgreen'>
                                                {t("links")}
                                            </span>
                                        </h2>
                                        <hr className='w-[16%] border-2 border-white' />
                                        <hr className='bg-white border-white opacity-30' />
                                    </div>
                                    <div class='flex mt-2 gap-3 '>
                                        <div className='flex justify-center border-2 border-white rounded-full w-8 h-8 place-items-center hover:border-none hover:bg-green hover:scale-105 '>
                                            <a
                                                href='https://www.facebook.com/IDethiopia/?ref=pages_you_manage'
                                                class=' text-white '
                                                target='_blank'
                                            >
                                                <svg
                                                    aria-hidden='true'
                                                    focusable='false'
                                                    data-prefix='fab'
                                                    data-icon='facebook-f'
                                                    class='w-2 '
                                                    role='img'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    viewBox='0 0 320 512'
                                                >
                                                    <path
                                                        fill='currentColor'
                                                        d='M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z'
                                                    ></path>
                                                </svg>
                                            </a>
                                        </div>
                                        <div className='flex justify-center border-2 border-white rounded-full w-8 h-8 place-items-center hover:border-none hover:bg-green hover:scale-105'>
                                            <a
                                                href='https://twitter.com/IDethiopia'
                                                class=' text-white '
                                                target='_blank'
                                            >
                                                <img
                                                    src={xlogo}
                                                    className='w-4'
                                                    alt='fireSpot'
                                                />
                                            </a>
                                        </div>

                                        <div className='flex justify-center border-2 border-white rounded-full w-8 h-8 place-items-center hover:border-none hover:bg-green hover:scale-105'>
                                            <a
                                                href='https://www.youtube.com/channel/UCf3SnLcgf5jzxhkeMTf2fRA'
                                                class=' text-white'
                                                target='_blank'
                                            >
                                                <img
                                                    src={youtubeIcon}
                                                    className='w-4'
                                                    alt='fireSpot'
                                                />
                                            </a>
                                        </div>

                                        <div className='flex justify-center border-2 border-white rounded-full w-8 h-8 place-items-center hover:border-none hover:bg-green hover:scale-105'>
                                            <a
                                                href='https://www.linkedin.com/company/idethiopia'
                                                class=' text-white'
                                                target='_blank'
                                            >
                                                <svg
                                                    aria-hidden='true'
                                                    focusable='false'
                                                    data-prefix='fab'
                                                    data-icon='linkedin-in'
                                                    class='w-3'
                                                    role='img'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    viewBox='0 0 448 512'
                                                >
                                                    <path
                                                        fill='currentColor'
                                                        d='M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z'
                                                    ></path>
                                                </svg>
                                            </a>
                                        </div>

                                        <div className='flex justify-center border-2 border-white rounded-full w-8 h-8 place-items-center hover:border-none hover:bg-green hover:scale-105'>
                                            <a
                                                href='https://t.me/IDethiopia'
                                                class=' text-white'
                                                target='_blank'
                                            >
                                                <img
                                                    src={telegramIcon}
                                                    className='w-4'
                                                    alt='fireSpot'
                                                />
                                            </a>
                                        </div>
                                        <div className='flex justify-center border-2 border-white rounded-full w-8 h-8 place-items-center hover:border-none hover:bg-green hover:scale-105'>
                                            <a
                                                href='https://www.tiktok.com/@idethiopia'
                                                class=' text-white'
                                                target='_blank'
                                            >
                                                <img
                                                    src={tiktokLogo}
                                                    className='w-4'
                                                    alt='fireSpot'
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                {/****************************************** Twitter Embed , Links , Copyright ****************************************************/}
                                <div className='flex flex-row w-full'>
                                    <div className='text-xs w-full'>
                                        <a
                                            href='/scanId'
                                            class='text-white font-medium hover:scale-105 w-fit hover:text-green'
                                        >
                                            {/* Website Policy */}{" "}
                                            {t("footer-scan")} &nbsp;| &nbsp;
                                        </a>
                                        <a
                                            href='/help'
                                            class='text-white font-medium hover:scale-105 w-fit hover:text-green'
                                        >
                                            {/* Website Policy */} {t("help")}{" "}
                                            &nbsp;| &nbsp;
                                        </a>
                                        <a
                                            href='#'
                                            class='text-white font-medium hover:scale-105 w-fit hover:text-green'
                                        >
                                            {/* Website Policy */}{" "}
                                            {t("website-policy")} &nbsp;| &nbsp;
                                        </a>
                                        <a
                                            href='#'
                                            class='text-white font-medium hover:scale-105 hover:text-green w-[120%]'
                                        >
                                            {" "}
                                            {/* Terms &amp; Conditions&nbsp;|&nbsp; */}{" "}
                                            {t("terms-conditions")} &nbsp;|
                                            &nbsp;
                                        </a>
                                        <a
                                            href='#'
                                            class='text-white font-medium hover:scale-105 hover:text-green'
                                        >
                                            {" "}
                                            {/* Privacy Policy&nbsp;|&nbsp; */}{" "}
                                            {t("privacy-policy")} &nbsp;| &nbsp;
                                        </a>
                                        <a
                                            href='#'
                                            target='_blank'
                                            class='text-white font-medium hover:scale-105 col-start-1 hover:text-green'
                                        >
                                            {" "}
                                            {/* Copyright Policy&nbsp;|&nbsp; */}{" "}
                                            {t("copyright-policy")} &nbsp;|
                                            &nbsp;
                                        </a>
                                        <a
                                            href='/career'
                                            target='_blank'
                                            class='text-white font-medium w-[240%] hover:scale-105 hover:text-green'
                                        >
                                            {" "}
                                            {/* Copyright Policy&nbsp;|&nbsp; */}{" "}
                                            {t("career-footer")} &nbsp;| &nbsp;
                                        </a>
                                        <a
                                            href='https://card-order.fayda.et/'
                                            class='text-white font-medium hover:scale-105 hover:text-green hover:cursor-pointer'
                                        >
                                            {" "}
                                            {/* Copyright Policy&nbsp;|&nbsp; */}{" "}
                                            {t("print")} &nbsp;| &nbsp;
                                        </a>
                                        <a
                                            class='text-white font-medium hover:scale-105 hover:text-green hover:cursor-pointer'
                                            href={
                                                process.env.PUBLIC_URL +
                                                "/static/logos.zip"
                                            }
                                            download='logos.zip'
                                        >
                                            {t("logo-footer")}
                                            &nbsp;| &nbsp;
                                        </a>
                                        {/* <a
                      href="#"
                      class="text-white font-medium w-full hover:scale-105 hover:text-green"
                    >
                      {" "}
                      {t("help")}
                    </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='text-center h-20 bg-MetallicBlue pt-6'>
                            <span class='text-xs font-medium text-white dark:text-white'>
                                {new Date().getFullYear()} {t("national-id")}{" "}
                                {t("all-rights")}
                            </span>
                        </div>
                    </footer>
                </div>
            )}
        </>
    );
};
export default Footer;
