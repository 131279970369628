import React, {useEffect} from "react";
import Body from "./components/body/bodyNew";
import HeroLandingPage from "./components/hero/heroLandingPage";
import SmallFooter from "../../components/footer/smallFooter";

const Benefits = () => {
  useEffect(() => {
    
    window.scrollTo(0, 0);
  
  }, [])
  return (
    <>
      <HeroLandingPage/>
      <Body />
      <SmallFooter/>
    </>
  );
};

export default Benefits;
