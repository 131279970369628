import React, { useState, useEffect, useCallback, useRef } from "react";

import HeroLandingPage from "../contactus/components/hero/heroLandingPage";
import FaqTitle from "./components/faqtitle/faqTitle";
import Helpful from "./components/helpful/helpful";

import Faq from "./components/faq/faq";
import Footer from "../../components/footer/footer";

import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getFaq } from "../../redux/selectors";
import { getFaqList } from "../../redux/actions";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import { useDebounce } from "../../hooks/useDebounce";

function ContactUs() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [citizen, setCitizen] = useState(true);
    const [searchFaq, setSearchFaq] = useState("");
    const debouncedFaqSearch = useDebounce(searchFaq); // used to delay search until user is finished typing

    const handleSearchFaq = (e) => {
        setSearchFaq(e.target.value);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        // const timer = setTimeout(() => {
        //   setIsLoading(false);
        // }, 10000);

        // Group the dispatch calls based on their functionality
        const dataDispatches = [dispatch(getFaqList())];

        // Execute the dispatch calls in parallel
        Promise.all(dataDispatches)
            .then(() => {
                setIsLoading(false);
                // Do something after all the dispatch calls have completed
            })
            .catch((error) => {
                // Handle any errors that occur during the dispatch calls
            });

        // return () => {
        //   clearTimeout(timer);
        // };
    }, [dispatch]);
    const FaqComponent = useRef();
    useEffect(() => {
        searchFaq &&
            FaqComponent.current?.scrollIntoView({ behaviour: "smooth" });
    }, [debouncedFaqSearch]);
    return (
        <div>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Card />
                    <HeroLandingPage
                        citizen={citizen}
                        handleSearchFaq={handleSearchFaq}
                    />
                    <FaqTitle />
                    {/* <NewFaq list={faqList} /> */}
                    <div ref={FaqComponent}>
                        <Faq searchFaqParams={debouncedFaqSearch} />
                    </div>
                    <Helpful />
                    <Footer />
                </>
            )}
        </div>
    );
}

export default ContactUs;
