import React, { useEffect, useState } from "react";
import HeroLandingPage from "./components/hero/heroLandingPage";
import SmallFooter from "../../components/footer/smallFooter";
import Content2 from "./components/content2/content2";
import { useDispatch, useSelector } from "react-redux";
import { getStrategiesList } from "../../redux/actions";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import Content1 from "./components/content1/content1";
import Content3 from "./components/content3/content3";
import Content4 from "./components/content4/content4";

const Strategies = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        // const timer = setTimeout(() => {
        //   setIsLoading(false);
        // }, 10000);

        // Group the dispatch calls based on their functionality
        const dataDispatches = [dispatch(getStrategiesList())];

        // Execute the dispatch calls in parallel
        Promise.all(dataDispatches)
            .then(() => {
                setIsLoading(false);
                // Do something after all the dispatch calls have completed
            })
            .catch((error) => {
                // Handle any errors that occur during the dispatch calls
            });

        // return () => {
        //   clearTimeout(timer);
        // };
    }, [dispatch]);

    // const strategiesImages = useSelector(getStrategies);

    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <HeroLandingPage />
                    {/* <Tempo />  */}

                    {/* <HeroLandingPage /> */}
                    <Content2 />
                    {/* <Content1 />
                    <Content3 />
                    <Content4 /> */}
                    <SmallFooter />
                </>
            )}
        </>
    );
};

export default Strategies;
