import React, {useState, useEffect} from "react";

import HeroLandingPage from "../dataset/components/hero/heroLandingPage";

import SmallFooter from "../../components/footer/smallFooter";


import Body from "./components/body/body";



function Dataset() {
  const [citizen, setCitizen] = useState(true);


  useEffect(() => {
    
    window.scrollTo(0, 0);
  
  }, [])
  return ( 
  <> 


<HeroLandingPage citizen={citizen} />
<Body/>
<SmallFooter/>




  </>
  );
}

export default Dataset;
