import axios from "axios";

export const GET_NEWS_LIST = "GET_NEWS_LIST";
export const GET_TOP_NEWS = "GET_TOP_NEWS";
export const GET_TEST_LIST = "GET_TEST_LIST";
export const GET_FAQ_LIST = "GET_FAQ_LIST";
// export const ADD_ABOUT = "ADD_ABOUT";
// export const DELETE_ABOUT = "DELETE_ABOUT";
// export const UPDATE_ABOUT = "UPDATE_ABOUT";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Requested-With": "nid-website",
};

export const getTopNewsList = () => async (dispatch) => {
  try {
    const response = await axios
      .get(
        process.env.REACT_APP_BASE_URL+`/News/TrendingNews`,
        {
          headers: headers,
        }
      );
  
    if (response && response.data) {
      dispatch({
        type: GET_TOP_NEWS,
        payload: response.data,
      });
      localStorage.setItem("topNewsList", JSON.stringify(response.data));
    } else {
      const cachedTopNewsList = localStorage.getItem("topNewsList");
      if (cachedTopNewsList) {
        dispatch({
          type: GET_TOP_NEWS,
          payload: JSON.parse(cachedTopNewsList),
        });
      }
    }
  } catch (err) {
    const cachedTopNewsList = localStorage.getItem("topNewsList");
    if (cachedTopNewsList) {
      dispatch({
        type: GET_TOP_NEWS,
        payload: JSON.parse(cachedTopNewsList),
      });
    }
  }
};

export const getNewsList = () => async (dispatch) => {
  try {
    
    const response = await axios.get(
    
      process.env.REACT_APP_BASE_URL+`/News/NewsSection`,
      {
        headers: headers,
      }
    );
  
    if (response && response.data) {
      dispatch({
        type: GET_NEWS_LIST,
        payload: response.data,
      });
      localStorage.setItem("newsList", JSON.stringify(response.data));
    } else {
      const cachedNewsList = localStorage.getItem("newsList");
      if (cachedNewsList) {
        dispatch({
          type: GET_NEWS_LIST,
          payload: JSON.parse(cachedNewsList),
        });
      }
    }
  } catch (err) {
    const cachedNewsList = localStorage.getItem("newsList");
    if (cachedNewsList) {
      dispatch({
        type: GET_NEWS_LIST,
        payload: JSON.parse(cachedNewsList),
      });
    }
  }
};

export const getTestList = () => async (dispatch) => {
  // const cachedList = localStorage.getItem("testList");
  // if (cachedList) {
  //   dispatch({
  //     type: GET_TEST_LIST,
  //     payload: JSON.parse(cachedList),
  //   });
  // } else {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL+`/Home/Testimonials`,
        {
          headers: headers,
        }
      );
    
      if (response && response.data) {
        dispatch({
          type: GET_TEST_LIST,
          payload: response.data,
        });
        localStorage.setItem("testList", JSON.stringify(response.data));
       
      } else {
        const cachedTestList = localStorage.getItem("testList");
        if (cachedTestList) {
          dispatch({
            type: GET_TEST_LIST,
            payload: JSON.parse(cachedTestList),
          });
        }
      }
    } catch (err) {
      const cachedTestList = localStorage.getItem("testList");
      if (cachedTestList) {
        dispatch({
          type: GET_TEST_LIST,
          payload: JSON.parse(cachedTestList),
        });
      }
    }

  
};
export const getFaqList = () => async (dispatch) => {
  // const cachedList = localStorage.getItem("faqList");
  // if (cachedList) {
  //   dispatch({
  //     type: GET_FAQ_LIST,
  //     payload: JSON.parse(cachedList),
  //   });
  // } else {
    try {
      const response = await axios.get(
        
        process.env.REACT_APP_BASE_URL+ `/Faq/`,
        {
          headers: headers,
        }
      );
    
      if (response && response.data) {
        dispatch({
          type: GET_FAQ_LIST,
          payload: response.data,
        });
        localStorage.setItem("faqList", JSON.stringify(response.data));
      } else {
        const cachedFaqList = localStorage.getItem("faqList");
        if (cachedFaqList) {
          dispatch({
            type: GET_FAQ_LIST,
            payload: JSON.parse(cachedFaqList),
          });
        }
      }
    } catch (err) {
      const cachedFaqList = localStorage.getItem("faqList");
      if (cachedFaqList) {
        dispatch({
          type: GET_FAQ_LIST,
          payload: JSON.parse(cachedFaqList),
        });
      }
    }
};