import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Trans } from "react-i18next";

const Hero = () => {
    const { t } = useTranslation();

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    // const { t } =
    return (
        <>
            <div class='bg-media-hero bg-center bg-cover '>
                <div className='px-10 md:px-0 bg-hero-gradient'>
                    <div className='flex justify-start pt-36 md:pt-32 pb-10 md:pr-40 text-5xl md:text-7xl md:pl-28'>
                        <h1 className='pr-2 mb-0 font-bold text-white '>
                            <span className='text-green'>Fayda</span> API
                        </h1>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Hero;
