import React from "react";
import TableComponent from "../table/Table";
import { useTranslation } from "react-i18next";
import { tableConfig } from "../table/tableConfig";
import WB_NID from "../../../../assets/WB&NID.jpg";
import ProcurementTable from "../table/ProcurementTable";
const Body = () => {
    const { t } = useTranslation();
    return (
        <div className='flex justify-center items-center px-5 pb-[5%] md:pr-14  md:px-14 lg:px-14 xl:px-32'>
            <div className='space-y-5'>
                <div className='space-y-5 px-5 sm:px-0'>
                    <div className='overflow-x-scroll w-[90vw] sm:w-auto sm:overflow-x-auto'>
                        <ProcurementTable />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Body;
